define('services/caccounts/preprocess',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'arrayCustomFromTypeView',
    'entities/caccounts/preprocess'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    ArrayCustomFromTypeView
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.PreProcess,
                collection: app.ns.PreProcessCollection
            };
        },
        getName: function () {
            return 'preProcess';
        },
        getUrl: function () {
            return 'rest/v2/preProcess/';
        },
        getDynamicJson: function () {
            return 'PreProcessJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.sequence'),
                name: 'sequence',
                formatter: viewDynamic.defaultFormatter,
                index: 'sequence',
                search: true,
                width: 25
            }, {
                label: _.i18n('preprocess.configuration.type'),
                name: 'configuration',
                formatter: viewDynamic.typeFormatter,
                sortable: false,
                search: true,
                index: 'configurationType',
                width: 80
            }, {
                label: _.i18n('preprocess.configuration.param'),
                name: 'configuration',
                formatter: viewDynamic.paramFormatter,
                search: false,
                sortable: false,
                width: 80
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 500, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'configuration',
                    'param': {
                        'type': 'ARRAYLIST',
                        'config': _.bind(function () {
                            return new ArrayCustomFromTypeView({
                                fieldNameParam: 'PREPROCESS_TYP',
                                model: model,
                                fieldName: 'configuration',
                                fieldNameCodePrefix: 'PPROC_'
                            });
                        }, this)
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});

