define('entities/errors',[
    'entities/ns',
    'backbone',
    'backboneRelational'
], function (
    app,
    Backbone
) {
    'use strict';

    app.ns.Error = Backbone.RelationalModel.extend({
        service: 'services/global/errors',
        defaults: {},
        idAttribute: 'uniqueId',
        relations: [{
            type: Backbone.HasMany,
            key: 'errors',
            relatedModel: 'Error',
            collectionType: 'ErrorCollection'
        }]
    });

    app.ns.ErrorCollection = Backbone.Collection.extend({
        model: app.ns.Error
    });
});
