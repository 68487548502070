define('pcrSetupAssayItem',[
    'module',
    'backbone.marionette',
    'template!pcrSetupAssayItem'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            input: 'input'
        },

        events: {
            'click input': 'onChangeInput'
        },
        className: 'col-xs-12 assayItem p-r-0 p-l-0',

        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('assay').get('code');
            templateData.wellSize = this.model.get('wells').length;
            templateData.sampleSecId = this.model.get('assay').get('secId');
            return templateData;
        },

        onChangeInput: function (e) {
            if (!this.selected) {
                this.selected = e.currentTarget.checked;
            } else {
                this.selected = false;
                this.ui.input.prop('checked', this.selected);
            }
            this.displaySelected(this.selected);
            this.trigger('item:change', this, this.selected);
        },
        select: function () {
            this.ui.input.prop('checked', true);
            this.selected = true;
            this.trigger('item:change', this, this.selected, true);
        },
        clearSelection: function () {
            this.ui.input.prop('checked', false);
        },
        enableInput: function (enable) {
            this.ui.input.prop('disabled', !enable);
        },
        displaySelected: function (selected) {
            if (selected) {
                this.$el.addClass('selected');
            } else {
                this.$el.removeClass('selected');
            }
            this.selected = selected;
        },
        isSelectable: function () {
            return this.ui.input.prop('disabled') === false;
        }
    });
});
