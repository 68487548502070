
define('template!unauthorizedViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n    <h1>' +
((__t = ( _.i18n("common.unauthorized") )) == null ? '' : __t) +
'</h1>\n    ';
 if(message) {;
__p += '\n    <h2>' +
((__t = ( message )) == null ? '' : __t) +
'</h2>\n    ';
 };
__p += '\n</div>';

}
return __p
};});

