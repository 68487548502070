define('runPcrValidationPlateView',[
    'module',
    'backbone.marionette',
    'runPcrValidationPlateGridCollectionView',
    'underscore'
], function (
    module,
    Marionette,
    GridCollectionView,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="plate-region"></div>'),
        className: 'plate content',
        regions: {
            plate: '.plate-region'
        },
        modelEvents: {
            'change:assayResultVersion': 'changeAssayResultVersion'
        },
        serializeData: function () {
            return {
                targetCode: this.model.get('assayResultVersion').get('target').get('code')
            };
        },
        onRender: function () {
            this.showChildView('plate', new GridCollectionView({
                model: this.model,
                collection: this.model.get('run').get('wells')
            }));
            this.listenTo(this.getChildView('plate'), 'resetWellSelected', this.resetWellSelected);
        },
        resetWellSelected: function () {
            var assayResultVersion = this.model.get('assayResultVersion');
            var wells = this.model.get('run').get('wells').filter(function (well) {
                return well.get('assayVersion') && well.get('assayVersion').get('results').find(function (result) {
                    return result === assayResultVersion;
                });
            });
            this.model.get('wellSelected').reset(wells);
        },
        changeAssayResultVersion: function () {
            this.resetWellSelected();
            this.render();
        }
    });
});

