define('systemModuleItemView',[
    'module',
    'backbone.marionette',
    'underscore',
    'systemModuleItemRoleView'
], function (
    module,
    Marionette,
    _,
    RoleView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: _.template('<h2><%= code %></h2><ul class="js-roles"></ul>'),
        childViewContainer: '.js-roles',
        childView: RoleView,
        serializeData: function () {
            return {
                code: this.model.get('module')
            };
        }
    });
});

