
define('template!prepWellContentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row col-xs-12 p-r-0">\n    <div class="col-w-all col-w-100 m-t-10 d-f" style="justify-content: center;">\n        <div class="col-w-all col-w-47" style="padding: 5px;">\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.waiting') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-68 d-f css-prepRun-listView-assayWaiting noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-7 d-f noWrapHidden assayWaiting">\n                    <span class="mdi mdi-plus-box-outline clickable"></span>\n                    <span class="mdi mdi-minus-box-outline clickable"></span>\n                </div>\n            </div>\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.running') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ';
 if(assaysToDisplay.running.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
' ';
 }
                    ;
__p += '\n                </div>\n                <div class="col-w-all col-w-75 d-f css-prepRun-listView-assayRunning noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n                </div>\n            </div>\n            <div class="col-w-all col-w-100 d-f m-b-2" style="height: 19px;">\n                <div class="col-w-all col-w-19 p-l-5 p-r-5 d-f noWrapHidden">\n                    <label>\n                        ' +
((__t = ( _.i18n('assays.done') )) == null ? '' : __t) +
'\n                    </label>\n                </div>\n                <div class="col-w-all col-w-1 d-f noWrapHidden"></div>\n                <div class="col-w-all col-w-5 d-f noWrapHidden">\n                    ';
 if(assaysToDisplay.done.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
' ';
 } ;
__p += '\n                </div>\n                <div class="col-w-all col-w-75 d-f css-prepRun-listView-assayDone noWrapHidden"\n                     style="font-size: 15px;">\n                    ' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<div style="display: grid; grid-template-columns: 1fr 300px; gap: 20px;">\n    <div class="d-f column gap-5">\n        <div class="js-pcrWells"></div>\n        ';
 if(displayPool) { ;
__p += '\n        <div class="js-wellsPool"></div>\n        ';
 } ;
__p += '\n        ';
 if(displayPooled) { ;
__p += '\n        <div class="js-wellsPooled"></div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="d-f column gap-5">\n        <div class="js-pcrKitLots"></div>\n        ';
 if (pcrKitsLotsForValidation) { ;
__p += '\n        <div class="js-pcrKitLotsValidation"></div>\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
};});

