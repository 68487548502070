define('arrayCollectionView',[
    'module',
    'backbone.marionette',
    'arrayCollectionItemView',
    'template!arrayCollectionView'
], function (
    module,
    Marionette,
    ItemView,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: ItemView,
        childViewOptions: function () {
            return {
                service: this.options.service,
                readOnly: this.options.readOnly
            };
        },
        childViewEvents: {
            'changeChild': 'onChildChange'
        },
        onChildChange: function () {
            this.trigger('change');
        }
    });
});

