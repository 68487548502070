define('adminAttachmentsCreateEditView',[
    'module',
    'dialogFormView',
    'template!adminAttachmentsCreateEditView',
    'savingBehavior',
    'underscore',
    'autocompleteView',
    'dateUtils',
    'jquery',
    'dynamicCreateEditCustomComment'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    AutocompleteView,
    DateUtils,
    $,
    DynamicCreateEditCustomComment
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            uploadFile: '.js-upload-file',
            inputName: '.js-info-input[data-field-name="name"]'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'change @ui.uploadFile': 'onFileChange',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            selectContentType: '.js-select-contentype-region',
            typeRegion: '.js-type',
            commentRegion: '.js-comment'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        initialize: function () {
            this.file = null;
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.model.isNew();
            templateData.formattedUploadDate = DateUtils.toDateTimeShortYearFormatString(this.model.get('uploadDate'));

            return templateData;
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save();
            }
        },

        onRender: function () {
            this.triggerMethod('enable:cancel:confirm');

            if (this.model.isNew()) {
                this.ui.uploadFile.trigger('click');
            }

            var UserCodeListService = require('services/caccounts/userCodelist');
            this.getRegion('typeRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(UserCodeListService.getAutocompleteParam({
                    modelProperty: 'type',
                    codeListCode: this.options.codeListCode,
                    callBackOnChange: this.changeType,
                    acceptDirectInput: this.changeType
                }))));
            this.getRegion('commentRegion').show(new DynamicCreateEditCustomComment({
                'model': this.model
            }));
        },

        changeType: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
        },

        onSave: function () {
            if (this.file === null) {
                this.box.modal('hide');
            } else {
                this.model.importFile(this.file).done(_.bind(function () {
                    this.box.modal('hide');
                }, this));
            }
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateFieldValue: function (codeError) {
            var fields = this.$el.find('*[data-field-name="' + codeError.field + '"]');
            fields.each(function () {
                if ($(this).val() === codeError.value) {
                    $(this).popover('show');
                    setTimeout(function () {
                        $('.popover').fadeOut('slow', function () {
                        });
                    }, 2000);
                    $(this).css('border-color', 'red');
                    $(this).addClass('invalid');
                    $('div[data-field-name="' + codeError.field + '"]').addClass('has-error');
                }
            });
        },
        onFileChange: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                if (this.model.get('name') === null || this.model.get('name') === undefined || this.model.get('name') === '') {
                    this.model.set('name', e.target.files[0].name);
                    this.ui.inputName.val(this.model.get('name'));
                }
                this.file = e.target.files[0];
            } else {
                this.file = null;
            }

        }
    });
});
