define('arrayCriCurveNoEditView',[
    'module',
    'underscore',
    'dialogFormView',
    'template!arrayCustomCurveNoEditView',
    'backbone'
], function (
    module,
    _,
    DialogFormView,
    ArrayCustomCurveNoEditTpl,
    Backbone
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: ArrayCustomCurveNoEditTpl,

        className: 'col-w-all col-w-100 d-f',

        initialize: function () {
            this.model = new Backbone.Model(this.model.get('algoResult'));
            var split = this.model.get('param') ? this.model.get('param').split(';') : [];
            this.model.set('dyeName', null);
            if (split.length > 0) {
                this.model.set('dyeName', split[0]);
            }
        },
        serializeData: function () {
            return {
                'dyeName': this.model.get('dyeName')
            };
        }
    });
});
