/**
 * Created by Matteo on 11/07/2015.
 */
define('selectCaccountMainRouter',[
    'require',
    'module',
    'marionetteAppRouter'
], function (
    require,
    module,
    AppRouter
) {
    'use strict';

    var Controller = {
        listCAccounts: function () {
            require(['selectCaccountListController'], function (ListController) {
                ListController.listCAccounts();
            });
        }
    };

    var SelectCAccountRouter = AppRouter.extend({
        appRoutes: {
            'select/caccount': 'listCAccounts'
        },
        controller: Controller
    });

    module.exports = new SelectCAccountRouter();
});

