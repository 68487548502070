define('runPlateLayoutEditHeaderView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= name %>'),
        tagName: 'div',
        attributes: {
            style: 'text-align: center; border-right: 1px; border-right-color: #a59f9f; border-right-style: solid; border-bottom: 1px; border-bottom-color: #a59f9f; border-bottom-style: solid; padding-bottom: 5px; padding-top: 5px;' +
                'margin: 0;font-weight: 700; font-size: 16px; color: #333333;'
        },
        serializeData: function () {
            return {
                name: this.model.get('name') !== '00' ? this.model.get('name') : ''
            };
        }
    });
});
