define('roleView',[
    'module',
    'dialogCollectionView',
    'rolePrivilegeView',
    'template!roleView',
    'underscore',
    'jquery'
], function (
    module,
    DialogCollectionView,
    child,
    Tpl,
    _,
    $
) {
    'use strict';

    module.exports = DialogCollectionView.extend({
        template: Tpl,
        childView: child,
        className: 'roles',
        events: {
            'click .js-checked': 'onRoleChecked',
            'click .js-unchecked': 'onRoleUnchecked'
        },

        childViewOptions: function (model) {
            return {
                parentModel: this.model,
                model: model,
                collection: model.get('privileges'),
                readOnly: this.options.readOnly,
                findDepends: _.bind(this.findDepends, this)
            };
        },
        findDepends: function () {
            var deferred = $.Deferred();
            var roleService = require('services/caccounts/role');
            roleService.findDepends(this.model.get('privileges'))
                .done(_.bind(function (rolePrivileges) {
                    var tmp = [];
                    _.map(rolePrivileges, _.bind(function (rolePrivilege) {
                        if (this.model.get('privileges').indexOf(rolePrivilege) === -1) {
                            tmp.push({code: rolePrivilege});
                        }
                    }, this));
                    this.model.get('depends').reset(tmp);
                }, this))
                .always(function () {
                    deferred.resolve();
                });
            return deferred.promise();
        },
        onRoleChecked: function (e) {
            e.currentTarget.checked = true;
            this._onRole(true, e.currentTarget.value);
        },
        onRoleUnchecked: function (e) {
            e.currentTarget.checked = false;
            this._onRole(false, e.currentTarget.value);
        },
        _onRole: function (checked, role) {
            var defers = [];
            var loader = $('.js-global-loader');
            loader.show();
            this.children.each(function (child) {
                child.children.each(function (privilege) {
                    if (privilege.model.get('code').endsWith(role)) {
                        defers.push(privilege.setCheck(checked));
                    }
                });
            });
            $.when.apply($, defers).done(_.bind(function () {
                loader.hide();
            }, this));
        },
        serializeData: function () {
            return {
                readOnly: this.options.readOnly
            };
        }
    });
});

