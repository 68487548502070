define('runTracsEditPopupView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'template!runTracsEditPopupTpl',
    'dateUtils'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    runTracsEditPopupTpl,
    dateConverter
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: runTracsEditPopupTpl,

        initialize: function (options) {
            this.model = options.model;
        },

        serializeData: function () {
            this.model.set('wells', this.model.get('wells').join(';'));
            var templateData = this.model.toJSON();

            templateData.createDateFormatted = dateConverter.toDateTimeFormatStringFromSettings(this.model.get('creatDate'), this.options.settings);

            return templateData;
        },

        hide: function () {
            this.triggerMethod('reloadGrid');
            this.triggerMethod('hide');
            this.destroy();
        }
    });
})
;
