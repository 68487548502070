define('translationTextItemView',[
    'module',
    'formView',
    'template!translationTextItemView'
], function (
    module,
    FormView,
    Tpl
) {
    'use strict';

    module.exports = FormView.extend({

        template: Tpl,

        ui: {
            input: '.js-info-input',
            deleteLanguage: '.js-remove-icon'
        },

        events: {
            'click @ui.deleteLanguage': '_onDeleteLanguage',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        modelEvents: {
            'change:value': 'render'
        },

        className: 'col-xs-12 m-b-5',
        attributes: {
            style: 'display: flex;'
        },

        _onDeleteLanguage: function () {
            this.onChange('value', '');
        }
    });
});
