define('commonCancelActionView',[
    'module',
    'backbone.marionette',
    'backbone',
    'template!commonCancelActionView'
], function (
    module,
    Marionette,
    Backbone,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            action: '.js-action',
            cancel: '.js-cancel-popup'
        },
        className: 'row enterEditPlateLayout-buttons',

        triggers: {
            'click @ui.action': 'action:click',
            'click @ui.cancel': 'cancel:click'
        },

        attributes: {
            style: 'display: flex; justify-content: center;'
        },
        enableButtons: function (enable) {
            if (enable) {
                this.ui.action.removeAttr('disabled');
                this.ui.action.removeClass('disabled-grey');
            } else {
                this.ui.action.attr('disabled', 'disabled');
                this.ui.action.addClass('disabled-grey');
            }
        }
    });
});
