define('dynamicListHeaderItemView',['module',
        'backbone.marionette',
        'underscore',
        'template!dynamicListHeaderItemView',
        'template!dynamicListHeaderItemButtonView'],
    function (module,
              Marionette,
              _,
              Tpl,
              ButtonTpl
    ) {
        'use strict';

        module.exports = Marionette.View.extend({
            getTemplate: function () {
                return this.model.get('button') ? ButtonTpl : Tpl;
            },

            attributes: function () {
                if (this.model.get('button')) {
                    return {'style': 'width: 24px; line-height: 24px;'};
                }
                return {};
            },

            className: function () {
                return 'm-l-2 m-r-2 ' + (this.model.get('param') && this.model.get('param').display ? this.model.get('param').display['class'] : '');
            },

            serializeData: function () {
                var templateData = {};
                templateData.name = this.model.get('name');
                templateData.field = this.model.get('field');
                return templateData;
            }
        });
    });

