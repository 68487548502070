define('runGenerateSampleIdResponseView',[
    'module',
    'backbone',
    'underscore',
    'template!runGenerateSampleIdResponseView',
    'wellSmpTypeView',
    'backbone.marionette'
], function (
    module,
    Backbone,
    _,
    Tpl,
    SmpTypeView,
    Marionette
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        regions: {
            collection: '.js-collection',
            smpTypeRegion: '.js-select-smpType'
        },

        modelEvents: {
            'change:response': 'render'
        },

        onRender: function () {
            var selectView = new SmpTypeView({
                smpType: this.model.get('response') && this.model.get('response').sampleClass ? this.model.get('response').sampleClass.sampleType : 'D'
            });
            this.getRegion('smpTypeRegion').show(selectView);
            if (this.model.get('response')) {
                var ItemModel = Backbone.Model.extend({
                    defaults: {
                        pos: '',
                        value: ''
                    }
                });
                var ItemCollection = Backbone.Collection.extend({
                    model: ItemModel
                });
                var ItemView = Marionette.View.extend({
                    tagName: 'li',
                    template: _.template('<%= pos %> : <%= value %>')
                });
                var ListView = Marionette.CollectionView.extend({
                    tagName: 'ul',
                    childView: ItemView
                });

                var items = new ItemCollection();
                for (var i = 0; i < this.model.get('response').sampleIds.length && i < 5; i++) {
                    var sampleId = this.model.get('response').sampleIds[i];
                    items.push(new ItemModel({pos: sampleId.position, value: sampleId.sampleId}));
                }
                if (this.model.get('response').sampleIds.length >= 5) {
                    items.push(new ItemModel({pos: '...', value: '...'}));
                    var lastSampleId = this.model.get('response').sampleIds[this.model.get('response').sampleIds.length - 1];
                    items.push(new ItemModel({pos: lastSampleId.position, value: lastSampleId.sampleId}));
                }
                var listView = new ListView({
                    collection: items
                });
                this.getRegion('collection').show(listView);
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.sampleId = this.model.get('sampleId');
            templateData.sampleClass = {
                code: '-',
                name: '-',
                orderPrefix: null,
                orderIncrement: null
            };
            if (this.model.get('response') && this.model.get('response').sampleClass) {
                var order = this.model.get('response').sampleClass.regexOrderId;

                if (order) {
                    var positionsSplitted = order.split('-');
                    var correspondance = null;
                    var end = 0;
                    if (positionsSplitted.length > 1) {
                        try {
                            end = parseInt(positionsSplitted[1], 10);
                            if (end >= templateData.sampleId.length) {
                                end = templateData.sampleId.length;
                            }
                            correspondance = templateData.sampleId.substring(0, end);
                        } catch (ignored) {
                            // Ignored exception
                        }
                    }
                    if (positionsSplitted.length === 1) {
                        try {
                            correspondance = templateData.sampleId.substring(parseInt(positionsSplitted[0], 10) - 1);
                            end = templateData.sampleId.length;
                        } catch (ignored) {
                            // Ignored exception
                        }
                    }

                    if (correspondance !== null) {
                        templateData.sampleClass.orderPrefix = correspondance;
                        templateData.sampleClass.orderIncrement = templateData.sampleId.replace(templateData.sampleId.substring(0, end), '');
                    }
                }
                templateData.sampleClass.code = this.model.get('response').sampleClass.code;
                templateData.sampleClass.name = this.model.get('response').sampleClass.name;
            }
            return templateData;
        }
    });
});
