/**
 * Created by GSP on 2/10/2015.
 */
define('menuItemsUtils',[
    'module',
    'settings',
    'underscore',
    'menuItems',
    'privileges'
], function (
    module,
    Settings,
    _,
    MenuItems,
    Privileges
) {
    'use strict';

    module.exports = {
        getMenuItems: function () {
            var currentUserModel = Settings.get('currentUserModel'),
                menuConfig = {}, subMenus, cMenuItem;

            if (currentUserModel) {
                menuConfig.menuItems = [];
                _.each(MenuItems, _.bind(function (menuItem) {
                    subMenus = [];
                    cMenuItem = _.clone(menuItem);

                    var access = false;
                    if (cMenuItem.role) {
                        access = currentUserModel.hasType(cMenuItem.role, Privileges.READ);
                    }
                    if (cMenuItem.subMenu && _.isArray(cMenuItem.subMenu) && cMenuItem.subMenu.length > 0) {
                        access = this._subMenuAccess(cMenuItem.subMenu, currentUserModel);
                    }

                    if (access) {
                        //main menu
                        if (_.isEmpty(cMenuItem.subMenu)) {
                            menuConfig.menuItems.push(cMenuItem);
                        } else {
                            //subMenus
                            _.each(cMenuItem.subMenu, function (subMenu) {
                                if (currentUserModel.hasType(subMenu.role, Privileges.READ) === true) {
                                    subMenus.push(subMenu);
                                }
                            });
                            cMenuItem.subMenu = subMenus;
                            menuConfig.menuItems.push(cMenuItem);
                        }
                    }
                }, this));
            } else {
                menuConfig.menuItems = MenuItems;
            }

            return menuConfig.menuItems;
        },
        /**
         * if any of menus has a valid role, then return true or else false
         * @param menus
         * @param currentUserModel
         * @private
         */
        _subMenuAccess: function (menus, currentUserModel) {
            return _.any(menus, _.bind(function (menu) {
                if (currentUserModel.hasType(menu.role, Privileges.READ) === true) {
                    return true;
                }
                // check also subMenu of menu
                if (menu.subMenu && _.isArray(menu.subMenu) && menu.subMenu.length > 0) {
                    return this._subMenuAccess(menu.subMenu, currentUserModel);
                }
            }, this));
        }
    };
});

