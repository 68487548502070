
define('template!dynamicCreateEditCodeNameSequence', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label>' +
((__t = ( _.i18n('common.codeName') )) == null ? '' : __t) +
'</label>\n';
 if(codeListCode) { ;
__p += '\n<div class="js-codeList" data-field-name="code"></div>\n';
 } else { ;
__p += '\n<input type="text"\n       class="form-control js-info-input js-code inputBackground"\n       data-field-name="code" placeholder=""\n       value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n       data-placement="top" autocomplete="off">\n';
 } ;
__p += '\n<input type="text"\n       class="form-control js-info-input inputBackground"\n       data-field-name="name" placeholder=""\n       value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off">\n<input type="text"\n       class="form-control js-info-input inputBackground"\n       data-field-name="sequence" placeholder=""\n       value="' +
((__t = ( sequence )) == null ? '' : __t) +
'" autocomplete="off">\n';

}
return __p
};});

