define('tabsItem',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<a href="javascript:void(0);"><%= name %></a>'),
        tagName: 'li',
        triggers: {
            'click': 'click'
        },
        serializeData: function () {
            return {key: this.model.cid, name: this.model.get('name')};
        },
        setActive: function (active) {
            this.$el.toggleClass('active', active);
        }
    });
});
