define('pcrSetupPrepWellCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'pcrSetupPrepWellItem',
    'pcrSetupPrepWellItemHeader'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Item,
    ItemHeader
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: function (item) {
            if (item.get('type') === 'header') {
                return ItemHeader;
            } else {
                return Item;
            }
        },
        childViewOptions: function () {
            return {
                width: this.options.wellWidth,
                assay: this.options.assay,
                protocol: this.options.protocol
            };
        },
        childViewEvents: {
            'item:click': function (view) {
                this.model.get('refPcrSetup').set('mode', 'prepWell');
                var prepWells = this.model.get('refPcrSetup').get('prepWellSelected');
                prepWells.each(function (well) {
                    well.unset('selected');
                });
                prepWells.reset();
                this.lastView = view;
                prepWells.push(view.model);
                prepWells.each(function (well) {
                    well.set('selected', true);
                });
                prepWells.sort();
                this.trigger('enableButtons', !prepWells.isEmpty());
            },
            'item:ctrlClick': function (view) {
                this.model.get('refPcrSetup').set('mode', 'prepWell');
                var prepWells = this.model.get('refPcrSetup').get('prepWellSelected');
                var firstModel = prepWells.first();
                if (firstModel && firstModel.get('refRun') !== view.model.get('refRun')) {
                    prepWells.each(function (well) {
                        well.unset('selected');
                    });
                    prepWells.reset();
                }
                if (prepWells.contains(view.model)) {
                    prepWells.remove(view.model);
                    view.model.unset('selected');
                } else {
                    prepWells.push(view.model);
                    this.lastView = view;
                }
                prepWells.each(function (well) {
                    well.set('selected', true);
                });
                prepWells.sort();
                this.trigger('enableButtons', !prepWells.isEmpty());
            },
            'item:shiftClick': function (view) {
                this.model.get('refPcrSetup').set('mode', 'prepWell');
                var prepWells = this.model.get('refPcrSetup').get('prepWellSelected');
                var firstModel = prepWells.first();
                if (firstModel && firstModel.get('refRun') !== view.model.get('refRun')) {
                    prepWells.each(function (well) {
                        well.unset('selected');
                    });
                    prepWells.reset();
                    this.lastView = view;
                    prepWells.push(view.model);
                    prepWells.each(function (well) {
                        well.set('selected', true);
                    });
                } else {
                    prepWells.each(function (well) {
                        well.unset('selected');
                    });
                    prepWells.reset();
                    prepWells.each(function (well) {
                        well.unset('selected');
                    });
                    prepWells.reset();
                    prepWells.push(this.model.findBlock(this.lastView.model, view.model));
                    prepWells.each(function (well) {
                        well.set('selected', true);
                    });
                }
                prepWells.sort();
                this.trigger('enableButtons', !prepWells.isEmpty());
            }
        },
        clearSelection: function () {
            this.start = null;
            _.each(this.children._views, function (view) {
                if (view.unselected) {
                    view.unselected();
                }
            });
        }
    });
});
