define('dynamicListHeaderCollectionView',[
    'module',
    'backbone',
    'backbone.marionette',
    'dynamicListHeaderItemView',
    'template!dynamicListHeaderCollectionView'
], function (
    module,
    Backbone,
    Marionette,
    ItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'col-w-all col-w-100 d-f',
        childView: ItemView
    });
});
