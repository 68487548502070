define('services/caccounts/species',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/species'
], function (
    app,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Specie,
                collection: app.ns.SpecieCollection
            };
        },
        getName: function () {
            return 'specie';
        },
        getUrl: function () {
            return 'rest/v2/species/';
        },
        getDynamicJson: function () {
            return 'SpecieJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refCycler.code']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({'field': 'lisSpecies', 'param': {'type': 'LIST2ENTITY'}});
            return custom;
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({fieldName: 'criterias', values: UserCodeListService.findItemsByCode('FCRIT_SPECIE'), codeListCode: 'FCRIT_SPECIE'});
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});

