
define('template!sampleCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-recalcSampleClass clickable">\n		<span class="mdi mdi-refresh action header" title="' +
((__t = ( _.i18n('sample.recaclSampleClass') )) == null ? '' : __t) +
'">\n		</span>\n</div>\n<div class="js-refreshValidation clickable">\n		<span class="mdi mdi-link action header" title="' +
((__t = ( _.i18n('sample.refreshValidation') )) == null ? '' : __t) +
'">\n		</span>\n</div>';

}
return __p
};});

