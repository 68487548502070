
define('template!pcrWellToolTipDotCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(exist) { ;
__p += '\n<div class="col-w-100 d-f ovar">\n    <div class="col-w-20 m-r-5 col-labelContainer noWrapHidden">\n        ' +
((__t = ( targetCode )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-4 m-r-5 noWrapHidden text-left js-result">\n    </div>\n    <div class="col-w-6 m-r-5 col-labelContainer noWrapHidden">\n        ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-20 m-r-5 col-labelContainer noWrapHidden">\n        ' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-50 m-r-5 col-labelContainer noWrapHidden">\n        ' +
((__t = ( errMessComToDisplayList )) == null ? '' : __t) +
'\n    </div>\n</div>\n';
 } ;
__p += '\n<div class="js-container"></div>';

}
return __p
};});

