/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('pcrWellResultHistogramNormalView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'chart',
    'template!pcrWellResultHistogramNormalView',
    'app',
    'extendChart'
], function (
    module,
    Marionette,
    _,
    $,
    Chart,
    viewTpl,
    App
) {
    'use strict';

    module.exports = Marionette.View.extend({

        template: viewTpl,

        ui: {
            chart: '.chart-canvas'
        },
        
        modelEvents: {
            'change': 'render'
        },

        initialize: function () {
            this.options = this.options || {};

            if (this.options.generateImage) {
                $('body').append('<div id="chart-' + this.cid + '"></div>');
                this.region = App.regions.addRegion('chart', '#chart-' + this.cid);
                this.region.show(this);
            }
        },

        attributes: function () {
            var height = this.options.config.height;
            var width = this.options.config.width;
            if (this.options.generateImage) {
                height = height ? height : 80;
                width = width ? width : 400;
                return {style: 'position: relative;height:' + height + 'px; width:' + width + 'px;'};
            }
            if (this.options.config.max) {
                height = height ? height : 16;
                return {style: 'position: relative;height:' + height + 'px; width:100%;padding-right:5px'};
            }
            return {style: 'position: relative; width:100%; padding-right:5px; height: 17px;'};
        },

        onRender: function () {
            var barPercentage = 0.8;
            if (this.options.data.length > 1 && this.options.data.length < 10) {
                barPercentage = 0.1 * this.options.data.length;
            }
            this.data = {
                labels: _.map(this.options.data, function () {
                    return '';
                }),
                datasets: [{
                    barPercentage: 1,
                    categoryPercentage: barPercentage,
                    axis: 'y',
                    label: '',
                    data: _.pluck(this.options.data, 'value'),
                    fill: false,
                    backgroundColor: _.map(this.options.data, function (data) {
                        return data.color;
                    })
                }]
            };

            var config = {
                type: this.options.config.type,
                data: this.data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    }
                }
            };

            if (this.options.config.type === 'bar') {
                config.options.scales = {
                    xAxes: [{
                        display: false
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true,
                            min: this.options.config.min,
                            max: this.options.config.max,
                            stepSize: 0.1
                        }
                    }]
                };
            } else {
                config.options.scales = {
                    xAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true,
                            min: this.options.config.min,
                            max: this.options.config.max,
                            stepSize: 0.1
                        }
                    }],
                    yAxes: [{
                        display: false
                    }]
                };
            }

            if (this.options.config.lines) {
                config.options.lineAtIndex = this.options.config.lines;
            }

            if (this.options.generateImage) {
                config.options.animation = false;
            }

            this.instance = new Chart(this.ui.chart[0].getContext('2d'), config);
        },

        toBase64Image: function () {
            return this.instance.toBase64Image();
        },

        onDestroy: function () {
            if (this.instance) {
                this.instance.destroy();
            }
            if (this.region) {
                this.region.destroy();
                $('body').find('#chart-' + this.cid).remove();
            }
        }
    });
});
