define('colorCompensationMatriceValueColumnItemManualInput',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        className: 'rounded-5 js-info-input inputBackground t-a-r',
        tagName: 'input',
        attributes: function () {
            var style = this.options.result ? 'width: 60px; font-size: 14px;' : 'width: 40px; font-size: 10px;';
            return {
                style: style,
                type: 'text',
                'data-field-name': 'manualCorrection',
                pattern: '^\\d*(\\.\\d{0,1})?$'
            };
        },
        getTemplate: function () {
            return _.template('');
        },
        events: {
            'change': 'onLocalChange',
            'focus': 'onFocus'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.$el.val(this.model.get('manualCorrection'));
        },
        onFocus: function () {
            if (this.$el.val() === '0') {
                this.$el.val('');
            }
        },
        onLocalChange: function (e) {
            if (e.currentTarget.value === '') {
                e.currentTarget.value = 0;
            }
            var val = parseFloat(e.currentTarget.value, 10);
            if (_.isNaN(parseFloat(e.currentTarget.value, 10))) {
                this.$el.focus();
                e.currentTarget.value = 0;
                return;
            }
            if (val > parseInt(e.currentTarget.value, 10) && val < parseInt(e.currentTarget.value, 10) + 1) {
                val = parseFloat(e.currentTarget.value, 10).toFixed(1);
            }
            e.currentTarget.val = val;
            this.onInputChange(e);
        }
    });
});
