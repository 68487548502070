
define('template!runOperationsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n	<div class="saving-label"></div>\n	<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n		<div class="error-msg"></div>\n	</div>\n	<div class="form-horizontal">\n		<div class="form-inputs-container">\n			<div class="form-group row">\n				<label class="col-xs-3 control-label" for="runName">' +
((__t = ( _.i18n('run.name') )) == null ? '' : __t) +
'</label>\n				<span id="runName" class="col-xs-9">' +
((__t = ( runName )) == null ? '' : __t) +
'</span>\n			</div>\n			<div class="form-group row">\n				<fieldset class="col-xs-12 roundedFieldset p-t-10" style="padding-bottom: 20px !important;">\n					<div class="form-group row">\n						<label class="col-xs-3 control-label" for="wellRange">' +
((__t = ( _.i18n('runOperation.rangeWell')
							)) == null ? '' : __t) +
'</label>\n						<div class="col-xs-9 rangeWell" id="wellRange">\n							<input type="text" class="js-info-input form-control input-well m-r-2" maxlength="3"\n								   size="3"\n								   data-field-name="from"/>\n							<span class="m-r-5 m-l-5">' +
((__t = ( _.i18n('runOperation.rangeWell.to') )) == null ? '' : __t) +
'</span>\n							<input type="text" class="js-info-input form-control input-well m-l-2" maxlength="3"\n								   size="3"\n								   data-field-name="to"/>\n						</div>\n					</div>\n					<div class="form-group row rowRunOperation">\n						<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.wellTest') )) == null ? '' : __t) +
'</label>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-top border-right runOperationAssign">\n								<label class="switch js-checkbox">\n									<input type="checkbox" class="js-info-input" data-field-name="wellTest">\n									<span class="slider classicSliderColors round"></span>\n								</label>\n							</div>\n						</div>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-top border-right runOperationClear">\n								<input type="checkbox" class="form-control js-info-input"\n									   data-field-name="removeWellTest"/>\n							</div>\n						</div>\n					</div>\n					<div class="form-group row rowRunOperation">\n						<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.sample') )) == null ? '' : __t) +
'</label>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right runOperationAssign">\n								<input type="text" class="form-control js-info-input" data-field-name="sid"/>\n							</div>\n						</div>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right runOperationClear">\n							</div>\n						</div>\n					</div>\n					<div class="form-group row rowRunOperation">\n						<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.increment') )) == null ? '' : __t) +
'</label>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right runOperationAssign" style="display: flex;">\n								<label class="col-xs-8">' +
((__t = ( _.i18n('runOperation.increment.by') )) == null ? '' : __t) +
'</label>\n								<input type="text" class="js-info-input form-control input-well"\n									   data-field-name="incrementValue"/>\n							</div>\n						</div>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right runOperationClear">\n							</div>\n						</div>\n					</div>\n					<div class="form-group row rowRunOperation">\n						<label class="col-xs-4">' +
((__t = ( _.i18n('runOperation.assay') )) == null ? '' : __t) +
'</label>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right js-assay runOperationAssign">\n							</div>\n						</div>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right js-assay runOperationClear">\n							</div>\n						</div>\n					</div>\n					<div class="form-group row rowRunOperation">\n						<label class="col-xs-4"></label>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right border-bottom runOperationAssign">\n								<button class="btn btn-primary">' +
((__t = ( _.i18n('runOperation.assign') )) == null ? '' : __t) +
'</button>\n							</div>\n						</div>\n						<div class="col-xs-4 p-l-0 p-r-0">\n							<div class="border-left border-right border-bottom runOperationClear">\n								<button class="btn btn-primary">' +
((__t = ( _.i18n('runOperation.clear') )) == null ? '' : __t) +
'</button>\n							</div>\n						</div>\n					</div>\n				</fieldset>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

