define('suppliersController',[
    'module',
    'underscore',
    'suppliersCreateEditLayoutView'
], function (
    module,
    _,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new CreateEditLayoutView({
                model: param.model,
                service: param.service
            });
            createEditView.show({
                title: _.i18n(param.service.getName() + '.new'),
                className: 'baseTableEditAddPopup suppliersEditAddPopup',
                service: param.service
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        }
    };
});

