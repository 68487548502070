
define('template!emptyDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="no-items">\n	<p>\n		' +
((__t = ( _.i18n("common.noItem") )) == null ? '' : __t) +
'\n	</p>\n</div>';

}
return __p
};});

