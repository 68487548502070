define('runPrepContentWellListView',[
    'module',
    'underscore',
    'jquery',
    'jqgridView',
    'wellUtils'
], function (
    module,
    _,
    $,
    JqGridView,
    WellUtils
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: _.template('<table class="jq-grid-list" id="<%= jqGridId %>" role="grid"></table><div class="jq-grid-pager-list" id="<%= jqGridPagerId %>"></div>'),
        filtersName: 'wellViewTableListFilters',
        className: 'content',

        ui: function () {
            return _.extend({
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager,
                jqgRow: 'tr.jqgrow'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },

        constructor: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
            JqGridView.prototype.constructor.apply(this, arguments);
        },

        initialize: function () {
            this.assays = this.model.getFormattedRunAssayStatus();
        },

        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions = this.gridInitOptions(data),
                options = {
                    colModel: [
                        {
                            label: '',
                            name: 'secId',
                            key: true,
                            hidden: true
                        }, {
                            label: this.getSmpTypeLabelIcon(),
                            name: 'smpType',
                            formatter: _.bind(this.smptypeFormater, this),
                            search: false,
                            sortable: true,
                            index: 'smpType',
                            fixed: true,
                            width: 45
                        }, {
                            label: _.i18n('well.pos'),
                            classes: 'well-list-pos-label',
                            name: 'pos',
                            formatter: this.wellPosFormatter,
                            sortable: true,
                            index: 'pos',
                            fixed: true,
                            width: 55,
                            search: true,
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            }
                        }, {
                            label: _.i18n('well.smpId'),
                            name: 'smpId.code',
                            formatter: _.bind(this.wellSmpidFormatter, this),
                            classes: 'css-grid-cell-accentuatuion-02',
                            search: true,
                            sortable: true,
                            title: false,
                            index: 'smpId.code',
                            searchoptions: {
                                sopt: ['cn'],
                                clearSearch: false
                            },
                            fixed: true,
                            width: 130
                        }, {
                            label: _.i18n('common.empty.placeholder'),
                            name: 'smpId.code',
                            sortable: false,
                            search: false,
                            classes: 'ignore-row-click css-no-leftBorder',
                            formatter: this.copyToClipboardFormatter,
                            fixed: true,
                            width: 30
                        }, {
                            label: this.getRepeatLabelIcon(),
                            name: 'repeatStatus',
                            formatter: _.bind(this.repeatStatusFormater, this),
                            search: false,

                            sortable: true,
                            index: 'repeatStatus',
                            fixed: true,
                            width: 50
                        }, {
                            label: this.assays.assaysIcons.waiting,
                            name: 'assaysWaiting',
                            formatter: this.wellAssayWaitingFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysWaiting',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: this.assays.assaysIcons.running,
                            name: 'assaysRunning',
                            formatter: this.wellAssayRunningFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysRunning',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: this.assays.assaysIcons.done,
                            name: 'assaysDone',
                            formatter: this.wellAssayDoneFormatter,
                            classes: '',
                            search: true,
                            sortable: true,
                            index: 'assaysDone',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }, {
                            label: _.i18n('well.sample.comment'),
                            name: 'wellComment',
                            formatter: _.bind(this.wellCommentFormatter, this),
                            search: true,
                            sortable: true,
                            index: 'wellComment',
                            fixed: true,
                            searchoptions: {clearSearch: false},
                            width: 150
                        }, {
                            label: _.i18n('well.tags'),
                            name: 'tags',
                            formatter: this.defaultFormatter,
                            search: true,
                            sortable: true,
                            index: 'tags',
                            searchoptions: {clearSearch: false},
                            fixed: true,
                            width: 150
                        }
                    ],
                    sortname: this.sortOn ? this.sortOn : 'row',

                    gridComplete: function () {
                        defaultsOptions.gridComplete.apply(this, arguments);

                        var $waiting = that.$('.assayWaitingCell');
                        _.each($waiting, _.bind(function (waiting) {
                            var $waiting = $(waiting);
                            $waiting.parent().addClass('css-prepRun-listView-assayWaiting');
                        }, this));

                        var $running = that.$('.assayRunningCell');
                        _.each($running, _.bind(function (running) {
                            var $running = $(running);
                            $running.parent().addClass('css-prepRun-listView-assayRunning');
                        }, this));

                        var $done = that.$('.assayDoneCell');
                        _.each($done, _.bind(function (done) {
                            var $done = $(done);
                            $done.parent().addClass('css-prepRun-listView-assayDone');
                        }, this));
                    },

                    onSelectRow: _.bind(function (rowId, bool, e) {
                        if (!$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                            var filteredRowList = this.ui.jqGrid.getRowData(null, true);
                            var cSelection = [];
                            _.each(filteredRowList, _.bind(function (wellRow) {
                                cSelection.push(wellRow.pos);
                            }, this));
                            var well = this.model.get('wells').find(function (obj) {
                                return obj.get('secId') === rowId;
                            });
                            var PrepWellService = require('services/caccounts/prepwells');
                            var wellModel = PrepWellService.getModel({secId: well.get('secId')});
                            require(['prepWellController'], _.bind(function (PrepWellController) {
                                PrepWellController.showDetails({
                                    model: wellModel,
                                    cSelection: cSelection,
                                    callBackOnClose: _.bind(function () {
                                        this.onGridLoaded(this.model);
                                    }, this)
                                });
                            }, this));
                        }
                    }, this)
                };
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="right: 5px;">';
            if (cellValue === 'PT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 25px; top: -7px; color: white; font-size: 11px;">c</span>';
            } else if (cellValue === 'XT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            } else if (cellValue === 'XC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 28px; top: -7px; color: white; font-size: 11px;">c</span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellPosFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellPosPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="position: relative;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        onGridLoaded: function (model, rowId) {
            var listViewData = this.getListViewData(this.model.get('wells'));
            this.setGridData(listViewData, rowId);
        },
        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.availableRepeatStatus = wellListViewData.repeatStatusList;
            this.smpTypeList = wellListViewData.smpTypeList;
            this.warningSearch = wellListViewData.warningSearch;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = [];
            wells.each(_.bind(function (well) {
                var assayStatusFormatted = WellUtils.getFormattedAssayStatus(well);
                var comment = well.get('smpId').get('lisComment') ? well.get('smpId').get('lisComment') : '';
                comment += well.get('smpId').get('comment') ? (comment ? ' - ' : '') + well.get('smpId').get('comment') : '';
                var model = {
                    secId: well.get('secId'),
                    smpType: well.get('smpType'),
                    pos: well.get('pos'),
                    smpId: well.get('smpId') ? {
                        name: well.get('smpId').get('name'),
                        code: well.get('smpId').get('code'),
                        lisComment: well.get('smpId').get('lisComment'),
                        comment: well.get('smpId').get('comment')
                    } : {name: '', code: ''},
                    repeatStatus: well.get('repeatStatus'),
                    tags: well.get('tags'),
                    comment: comment
                };
                model.assaysWaiting = assayStatusFormatted.waiting;
                model.assaysRunning = assayStatusFormatted.running;
                model.assaysDone = assayStatusFormatted.done;
                wellsForData.push(model);
            }, this));

            return {
                wellsForData: wellsForData,
                assaysIcons: WellUtils.getPrepWellAssaysListViewIcons()
            };
        },

        setGridData: function (data, rowId) {
            /** */ this.ui.jqGrid.clearGridData(); /** needed in case of refresh */
            /** */ this.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
            /** needed in case of refresh */
            this.gridOptionsObj = this.gridOptions(data);
            this.onRowCountDone(data, 9999);
            this.triggerMethod('checkRowCount', 9999);
            if (rowId) {
                /** */ this.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                /** needed in case of refresh */
                this.ui.jqGrid.setSelection(rowId, false); // highlight selected row
            }
            this.setWellListPopover();
        },
        setWellListPopover: function () {
            this.model.get('wells').each(_.bind(function (well, index) {
                if (well.get('pos')) {

                    if ($('.wellPosPopover-' + well.get('pos')).length > 0 &&
                        $('.wellSmpidPopover-' + well.get('pos')).length > 0 &&
                        $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                        var params = {
                            model: well
                        };

                        params.targets = [
                            $('.wellPosPopover-' + well.get('pos')),
                            $('.wellSmpidPopover-' + well.get('pos')),
                            $('.wellAssayPopover-' + well.get('pos'))
                        ];

                        if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                            (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                            params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                        }
                        var placement = 'right';
                        if (index < 3) {
                            placement = 'bottom';
                        } else if (index > this.model.get('wells').length - 4) {
                            placement = 'top';
                        }
                        params.placement = placement;
                        WellUtils.showPcrWellPopover(params);
                    }
                }
            }, this));
        }
    });
});

