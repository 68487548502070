define('superAdminGodModeView',[
    'module',
    'dialogFormView',
    'template!superAdminGodModeView',
    'underscore',
    'jquery',
    'cookies',
    'bootbox',
    'settings'
], function (
    module,
    DialogFormView,
    Tpl,
    _,
    $,
    Cookies,
    Bootbox,
    Settings
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            displayLabel: '.js-display-label',
            alertCodeList: '.js-alert-code-list',
            clearCache: '.js-clear-cache',
            fixUser: '.js-fix-user'
        },

        events: {
            'change @ui.displayLabel': 'onDisplayLabelChange',
            'change @ui.alertCodeList': 'onAlertCodeListChange',
            'click @ui.clearCache': 'onClearCacheChange',
            'click @ui.fixUser': 'onFixUserClick'
        },

        attributes: {
            style: 'display: flex;justify-content: space-around;padding-top: 10px;flex-direction: column;'
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            this.ui.displayLabel.attr('checked', Cookies.get('gm-displayI18nCode'));
            this.ui.alertCodeList.attr('checked', Cookies.get('gm-autocomplete'));
        },

        onDisplayLabelChange: function (event) {
            if (event.target.checked) {
                Cookies.set('gm-displayI18nCode', true, {expires: 1, path: '/'});
            } else {
                Cookies.remove('gm-displayI18nCode');
            }
        },

        onAlertCodeListChange: function (event) {
            if (event.target.checked) {
                Cookies.set('gm-autocomplete', true, {expires: 1, path: '/'});
            } else {
                Cookies.remove('gm-autocomplete');
            }
        },
        onClearCacheChange: function () {
            var SystemService = require('services/system');
            SystemService.clearCache().done(function () {
                Bootbox.alert('Cache cleared successfully');
            });
        },
        onFixUserClick: function () {
            $('.js-global-loader').show();
            $.ajax({
                url: Settings.url('rest/global/system/database/fixUser/11_ca1b455a-4893-4576-88ea-c40bf35da1f9'),
                type: 'GET',
                success: function () {
                    alert('User fixed successfully');
                },
                complete: function () {
                    $('.js-global-loader').hide();
                }
            });
        }
    });
});

