define('pcrWellView',[
    'module',
    'backbone.marionette',
    'backbone',
    'wellUtils',
    'template!wellTpl',
    'template!wellMultiDisplayTpl',
    'underscore',
    'jquery',
    'colorUtils',
    'runDissolvedView',
    'sampleCommentView',
    'wellDisplaySampleView',
    'wellSmpTypeView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    wellMultiDisplayTpl,
    _,
    $,
    ColorUtils,
    RunDissolvedPopup,
    EditSampleCommentView,
    WellDisplaySampleView,
    WellDisplayTypeView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        ui: {
            elementRight: '.elementRight',
            selectableItem: '.wellSelectableItem',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'
        },

        className: 'cell-well pcr',

        regions: {
            wellDisplaySample: '.js-well-sample',
            wellDisplayType: '.js-well-type',
            result: '.js-display-result'
        },

        events: {
            'click .js-btn-preprun-kitLot': 'onKitLotClick',
            'click .js-btn-comment': 'onCommentClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .triangle-same-assay-plateView': 'onSampleIdClick',
            'click .triangle-other-assay-plateView': 'onSampleIdClick',
            'click .pool-label': 'onClickPool',
            'click .mdi-alert-octagram': 'onClickError'
        },

        modelEvents: {
            'change': 'render'
        },
        attributes: function () {
            return {
                style: 'background-color: ' + this.options.backgroundColor
            };
        },

        onRender: function () {
            this.$el.popover('destroy');
            if (this.model.get('smpType') === 'E') {
                return;
            }

            var shortted = !this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P';

            var viewSample = new WellDisplaySampleView({
                model: this.model,
                shortted: shortted,
                plateView: true
            });
            this.getRegion('wellDisplaySample').show(viewSample);

            var viewSampleType = new WellDisplayTypeView({
                smpType: this.model.get('smpType')
            });
            this.getRegion('wellDisplayType').show(viewSampleType);

            PcrWellResultController.show(this.model, 'Plate', this.getRegion('result'));

            if (this.error) {
                this.$el.addClass('has-error');
            }
            if (this.model.get('smpId').get('verified') === 'IN_PROGRESS') {
                this.$el.addClass('watermark-progress');
            }
            this.renderToolTip();
        },

        renderToolTip: function () {
            if (this.isDestroyed()) {
                return;
            }
            var params = {
                context: this,
                model: this.model,
                target: this.$el
            };

            WellUtils.showPcrWellPopover(params);
        },

        getTemplate: function () {
            if (this.options.isMultiDisplay) {
                return wellMultiDisplayTpl;
            } else {
                return wellTpl;
            }
        },

        onClickPool: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.findDissolveds()
                .done(_.bind(function (wells) {
                    this.dissolvedPopup = new RunDissolvedPopup({
                        model: this.model,
                        wells: wells
                    });
                    this.dissolvedPopup.show({
                        title: _.i18n('preprun.dissolvedPopup'),
                        className: 'dissolvedPopupCss centeredPopup'
                    });
                }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onClickError: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['errorController'], _.bind(function (ErrorController) {
                ErrorController.showE(this.model, this.model.get('entityError'));
            }, this));
        },

        onCommentClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.get('smpId').fetch().done(_.bind(function () {

                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var loader = $('.js-global-loader');
            loader.show();
            this.model.fetch().done(_.bind(function () {
                require(['assayVersionController'], _.bind(function (AssayVersionController) {
                    this.model.getPcrKitLots().done(_.bind(function (response) {
                        AssayVersionController.showDetails({model: this.model.get('assayVersion'), options: {lots: response}});
                    }, this))
                        .always(_.bind(function () {
                            loader.hide();
                            this.$el.trigger('mouseleave');
                        }, this));
                }, this));
            }, this));
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var loader = $('.js-global-loader');
            loader.show();
            this.model.fetch().done(_.bind(function () {

                if (this.model.get('smpType') !== 'PC' &&
                    this.model.get('smpType') !== 'NC' &&
                    this.model.get('smpType') !== 'RC' &&
                    this.model.get('smpType') !== 'OC') {

                    require(['samplesController'], _.bind(function (Controller) {
                        this.model.get('smpId').fetch().done(_.bind(function () {
                            Controller.showDetails({service: require(this.model.get('smpId').service), model: this.model.get('smpId')});
                        }, this)).always(_.bind(function () {
                            loader.hide();
                            this.$el.trigger('mouseleave');
                        }, this));
                    }, this));
                } else {
                    if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('secId')) {
                        return;
                    }
                    require(['kitLotsPcrController'], _.bind(function (KitLotsPcrController) {
                        KitLotsPcrController.showQCFromWell(this.model);
                        loader.hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }
            }, this));
        },

        serializeData: function () {
            this.error = this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' || this.model.get('smpId').get('entityError');
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    repeatStatus: this.model.get('repeatStatus'),
                    exported: this.model.get('exported'),
                    showQC: this.model.get('showQC'),
                    existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                    existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay'),
                    assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : ''
                }
            };

            this.displayResult = !!(this.model.get('refAssay') && this.model.get('refAssay').get('code'));

            templateData.displayKitLot = !!this.model.get('assayVersion');

            templateData.errorWarning = this.model.get('results').some(function (result) {
                return result.get('codeErr');
            });

            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#006fff';
            if (this.model.get('run').get('settings') && this.model.get('run').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('run').get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            var colorsAssay = ['20', '60', '#fff302', '#d06767', '#00FF00', '#285085', '#0012ff'];

            if (this.options.settings && this.options.settings['COLORS-ASSAY']) {
                colorsAssay = this.options.settings['COLORS-ASSAY'].split(';');
            }

            var assayOpacity = colorsAssay[1];
            templateData.assayColor = ColorUtils.defineAlpha(this.options.backgroundColor, assayOpacity);

            var wellRes = this.model.getDisplayGroup();

            if (wellRes && wellRes.length > 0 && _.first(wellRes).get('result')) {
                templateData.wellResColor = _.first(wellRes).get('result').get('color');
            } else {
                templateData.wellResColor = null;
            }

            templateData.expertMode = this.model.get('results').some(function (result) {
                return result.get('valSt') === 3.1;
            });

            templateData.error = this.error;
            templateData.hasComment = this.model.get('smpId').get('lisComment') || this.model.get('smpId').get('comment');
            return templateData;
        }
    });
});

