define('dynamicCreateEditUserCodeList',[
    'module',
    'dialogFormView',
    'underscore',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    _,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-autocomplete"></div>'),

        regions: {
            autocompleteRegion: '.js-autocomplete'
        },

        initialize: function () {
            if (!this.options.config.modelProperty) {
                alert('modelProperty not defined in userCodeList');
            }
            if (!this.options.config.valueProperty) {
                this.options.config.valueProperty = 'code';
            }
            if (!this.options.config.queryProperty) {
                this.options.config.queryProperty = 'code';
            }
            if (!this.options.config.valueKey) {
                this.options.config.valueKey = 'code';
            }
            if (!this.options.config.code) {
                alert('code for userCodeList not defined in userCodeList');
            }
        },
        serializeData: function () {
            var templateData = {};
            templateData.model = this.model.toJSON();
            templateData.fieldName = this.options.fieldName;
            return templateData;
        },

        onRender: function () {
            var config = this.options.config;

            var UserCodeListService = require('services/caccounts/userCodelist');
            this.autocompleteProtocolVariant = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParamForCode({
                        modelProperty: config.modelProperty,
                        codeListCode: config.code,
                        callBackOnChange: this.onChangeAutocomplete
                    })));
            this.getRegion('autocompleteRegion').show(this.autocompleteProtocolVariant);


        },

        onChangeAutocomplete: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
        },

        disabled: function () {
            this.ui.parameters.val(null);
            this.ui.parameters.attr('disabled', 'disabled');
        }
    });
});
