/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemSelect',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<% _.each(values, function(value) { %>\n' +
            '<option value="<%= value.key %>" <%= value.display && value.display.style ? \'style="\' +value.display.style + \'"\' : \'\' %>><%= value.value %></option>\n' +
            '<% }) %>\n'),
        tagName: 'select',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var config = ItemView.prototype.attributes.apply(this, arguments);
            var style = _.findWhere(this.options.field.get('param').config.values, {key: this.model.get(this.options.field.get('field'))});
            if (style && style.display) {
                style = style.display.style;
                if (config.style) {
                    config.style += style;
                } else {
                    config.style = style;
                }
            }
            return config;
        },

        events: {
            'change': 'onChangeSelect'
        },
        initialize: function () {
            ItemView.prototype.initialize.apply(this, arguments);
            this.listenTo(this.model, 'change:' + this.options.field.get('field'), this.renderAttributes);
        },
        serializeData: function () {
            return {values: this.options.field.get('param').config.values};
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            this.$el.val(this.model.get(this.options.field.get('field')));
        },
        onChangeSelect: function (e) {
            this.onChange(this.options.field, e.currentTarget.value);
        },
        renderAttributes: function () {
            _.each(this.attributes(), _.bind(function (attr, index) {
                this.$el.attr(index, attr);
            }, this));
        }
    });
});
