define('services/caccounts/suppliers',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/suppliers'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Supplier,
                collection: app.ns.SupplierCollection
            };
        },
        getName: function () {
            return 'supplier';
        },
        getUrl: function () {
            return 'rest/v2/suppliers/';
        },
        getDynamicJson: function () {
            return 'SupplierJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.city'),
                name: 'city',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'city',
                width: 40
            }, {
                label: _.i18n('common.zipCode'),
                name: 'zipCode',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'zipCode',
                width: 40
            }, {
                label: _.i18n('common.countryCode'),
                name: 'countryCode',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'countryCode',
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name']};
        }
    }, Dynamic);
});

