
define('template!menuGroup', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<button class="btn btn-default dropdown-toggle clickable" type="button" id="' +
((__t = ( idItems )) == null ? '' : __t) +
'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n    ' +
((__t = ( title )) == null ? '' : __t) +
'\n    <span class="mdi mdi-menu-down"></span>\n</button>\n<ul class="dropdown-menu" aria-labelledby="' +
((__t = ( idItems )) == null ? '' : __t) +
'" ></ul>\n';
 if(quickActions) { ;
__p += '\n<div class="quick-action-container">\n</div>\n';
 } ;
__p += '\n';

}
return __p
};});

