/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminGodModeController',[
    'module',
    'superAdminGodModeView'
], function (
    module,
    View
) {
    'use strict';

    module.exports = {
        show: function (region) {
            var view = new View();
            region.show(view);
        }
    };
});
