define('menuRoutingGroupSelect',[
    'module',
    'backbone.marionette',
    'backbone',
    'app',
    'dialogFormView',
    'underscore',
    'template!menuRoutingGroupSelect',
    'menuRoutingGroupCollection'
], function (
    module,
    Marionette,
    Backbone,
    App,
    DialogFormView,
    _,
    Tpl,
    MenuRoutingGroupCollection
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            registration: '.js-registration',
            preProcessing: '.js-preProcessing',
            routing: '.js-routing',
            orp: '.js-orp'
        },

        className: 'container-fluid h-100 selectRouting',

        onRender: function () {

            this.getRegion('registration').show(new MenuRoutingGroupCollection({
                collection: this.options.registrationCollection,
                type: 'registration'
            }));
            this.getRegion('preProcessing').show(new MenuRoutingGroupCollection({
                collection: this.options.preProcessingCollection,
                type: 'preProcessing'
            }));
            this.getRegion('routing').show(new MenuRoutingGroupCollection({
                collection: this.options.routingCollection,
                type: 'routing'
            }));
            this.getRegion('orp').show(new MenuRoutingGroupCollection({
                collection: this.options.orpCollection,
                type: 'orp'
            }));
        }
    });
});

