define('error',['module',
        'backbone.marionette',
        'template!error',
        'dialogFormView'
    ],
    function (module,
              Marionette,
              Tpl,
              DialogFormView
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: Tpl,

            ui: {
                thumbnail: '.routingGroup',
                button: 'button'
            },

            events: {
                'click @ui.button': 'onProcessValidClick'
            },

            className: 'routingGroup state row',

            attributes: function () {
                var color = this.model.get('color');
                if (!color) {
                    color = '#e0fbff';
                }
                return {style: 'background-color: ' + color};
            },

            serializeData: function () {
                return {
                    rackId: this.model.get('rackId'),
                    state: this.model.get('state'),
                    showPopup: this.model.get('showPopup')
                };
            },

            display: function (display) {
                this.$el.css('display', display ? '' : 'none');
                if (display) {
                    return this.ui.button;
                }
                return null;
            },
            onProcessValidClick: function () {
                this.trigger('onProcessValidClick', this.ui.button);
                if (this.options.closePopup) {
                    this.hide();
                }
            }
        });
    }
);
