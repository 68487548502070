define('dynamicExtractionMethodExtractor',[
    'module',
    'formView',
    'template!dynamicExtractionMethodExtractor',
    'dynamicCreateEditCustomListToString'
], function (
    module,
    FormView,
    tpl,
    DynamicCreateEditCustomListToString
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        regions: {
            extractorsRegion: '.js-extractors'
        },

        onRender: function () {
            if (!this.model.get('refExtractors').isEmpty()) {
                this.getRegion('extractorsRegion').show(new DynamicCreateEditCustomListToString({
                    'field': 'refExtractors',
                    'values': this.model.get('refExtractors')
                }));
            }
        },
        serializeData: function () {
            return {manual: this.model.get('refExtractors').isEmpty()};
        }

    });
});
