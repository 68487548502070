define('services/caccounts/routinggroup',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'template!TooltipRoutingGroupCondition',
    'entities/caccounts/routinggroup'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    ConditionTooltips
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.RoutingGroup,
                collection: app.ns.RoutingGroupCollection
            };
        },
        getName: function () {
            return 'routingGroup';
        },
        getUrl: function () {
            return 'rest/v2/routinggroup/';
        },
        getDynamicJson: function () {
            return 'RoutingGroupJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('routingGroup.preProcessing'),
                name: 'preProcessing',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                width: 10
            }, {
                label: _.i18n('validCondition'),
                name: 'validCondition',
                formatter: viewDynamic.booleanFormatter,
                sortable: false,
                search: false,
                width: 10
            }, {
                label: _.i18n('routing.group.condition'),
                name: 'condition',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: false,
                width: 80
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('validCondition');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'condition',
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {tooltip: ConditionTooltips}
                    },
                    'validate': function (model) {
                        return model.validateCondition(btoa(model.get('condition')));
                    }
                }
            );
            return custom;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 150, 'fields': ['name']};
        },

        findByRoutingActionAndSampleId: function (routingActionSecId, sampleId, routingMode) {
            var params = {
                routingActionsecId: routingActionSecId,
                sampleId: sampleId,
                routingMode: routingMode
            };

            var url = Settings.url(this.getUrl() + 'findByRoutingActionAndSampleId', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});

