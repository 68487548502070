
define('template!prepRunCreateLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="createRunFromTemplate">\n    <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="refPrepRunTemplate"\n         style="display: flex;">\n        <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.runTemplate') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-4">\n            <div class="js-select-run-template-region"></div>\n        </div>\n        <label class="col-xs-2 control-label">' +
((__t = ( _.i18n('run.refRespUser') )) == null ? '' : __t) +
'</label>\n        <div class="col-w-all col-w-13 p-r-0 p-l-15">\n            <div class="js-select-respUser-region"></div>\n        </div>\n    </div>\n\n    <div class="form-group dataLabelContainer m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="selectedDate">\n        <label class="col-xs-4 control-label" data-field-name="selectedDate">' +
((__t = ( _.i18n('run.selectedDate') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 js-selectDate-region"></div>\n    </div>\n    <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0" data-field-name="department">\n        <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.department') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8" style="display: flex;">\n            <div class="col-xs-2 p-l-0 js-select-runName-department"></div>\n            <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.runGroup.short')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 p-l-0 js-select-runName-runGroup"></div>\n\n            <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.sequence.short')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 p-l-0 js-select-runName-sequenceFormat"></div>\n\n            <label class="col-xs-1 p-l-0 control-label noWrapHidden">' +
((__t = ( _.i18n('run.suffix.short')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-3 p-l-0 js-runName-suffix">\n                <input class="form-control js-info-input inputBackground"\n                       data-field-name="suffix"\n                       placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                       type="text" value="' +
((__t = ( suffix )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n    </div>\n    <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0"\n         data-field-name="pattern">\n        <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.pattern') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-4 js-runName-pattern">\n        </div>\n    </div>\n    <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0"\n         data-field-name="name">\n        <label class="col-xs-4 control-label" for="name">' +
((__t = ( _.i18n('run.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-5">\n            <input class="form-control floating-label js-info-input" data-field-name="name"\n                   id="name"\n                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'" type="text"\n                   value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-3 p-l-0 p-r-0"\n             style=" padding-left: 299px;\n                             text-align: left;">\n            <label>\n                <input class="js-runNameForced" data-field-name="nameForced"\n                       style="position: relative;\n                                        display: inline-block;\n                                        width: 20px;\n                                        height: 20px;\n                                        border-radius: 2px;\n                                        overflow: hidden;\n                                        z-index: 1;\n                                        color: #11ABC3;"\n                       type="checkbox"\n                       value="false">\n            </label>\n            <label style="padding-left: 13px;">' +
((__t = ( _.i18n('run.name.forced') )) == null ? '' : __t) +
'</label>\n        </div>\n    </div>\n    <div class="form-group js-number-of-run-region m-t-15 m-r-0 m-b-0 m-l-0"\n         data-field-name="numberOfRun"><!--hidden-->\n        <label class="col-xs-4 control-label" for="number-of-run">' +
((__t = ( _.i18n('run.numberOfRun') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n            <input class="form-control floating-label js-info-input number-of-run" data-field-name="numberOfRun" id="number-of-run" max="999"\n                   min="1"\n                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                   style="width: 70px;" type="number"\n                   value="' +
((__t = ( numberOfRun )) == null ? '' : __t) +
'">\n        </div>\n    </div>\n\n    <div class="form-group m-t-15 m-r-0 m-b-0 m-l-0">\n        <label class="col-xs-4 control-label" for="number-of-run">' +
((__t = ( _.i18n('print.runsheet') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n            <input class="generate-starter-sheet" id="printRunSheet"\n                   style="position: relative;\n                                        display: inline-block;\n                                        width: 20px;\n                                        height: 20px;\n                                        border-radius: 2px;\n                                        overflow: hidden;\n                                        z-index: 1;\n                                        color: #11ABC3;"\n                   type="checkbox"\n                   value="false">\n        </div>\n    </div>\n</div>\n<br/>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

