define('runTabItemView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<span class="mdi <%= mdi %>"></span><%= title %>'),
        tagName: 'li',
        className: 'rounded clickable',
        events: {
            'click': 'onClick'
        },
        attributes: function () {
            return {role: this.model.get('item').title};
        },
        serializeData: function () {
            return {
                title: this.model.get('item').title,
                mdi: this.model.get('item').mdi
            };
        },
        onClick: function () {
            this.model.collection.trigger('current', this.model);
        },
        select: function (selected) {
            this.$el.toggleClass('active', selected);
            this.$el.toggleClass('disabled', !selected);
            this.$el.toggleClass('clickable', !selected);
        }
    });
});
