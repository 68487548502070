
define('template!kitLotsActionGenericView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="ui-date">\n    <label for="date">' +
((__t = ( _.i18n('common.date') )) == null ? '' : __t) +
'</label>\n    <div id="date" class="js-date"></div>\n</div>\n<div class="ui-userpid">\n    <label for="userpid">' +
((__t = ( _.i18n('common.user') )) == null ? '' : __t) +
'</label>\n    <input id="userpid" class="js-userpid js-info-input form-control inputBackground disabled" disabled value="' +
((__t = ( user )) == null ? '' : __t) +
'"/>\n</div>\n<div class="ui-comment">\n    <label for="comment"  class="ui-comment">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n    <textarea id="comment" class="ui-comment js-info-input inputBackground form-control">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n</div>\n';

}
return __p
};});

