define('translationsFilterLanguagesLayoutView',[
    'module',
    'template!translationsFilterLanguagesLayoutTpl',
    'backbone.marionette',
    'translationsFilterLanguageListView',
    'translationsFilterLanguageButtonView',
    'backbone'
], function (
    module,
    filterLanguageLayoutTpl,
    Marionette,
    FilterLanguageListView,
    FilterLanguageButtonView,
    Backbone
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: filterLanguageLayoutTpl,

        regions: {
            button: '#translations-filter-button-region',
            list: '#translations-filter-list-region'
        },

        events: {
            'show.bs.dropdown .js-dropdown': '_onShowDropdown',
            'hide.bs.dropdown .js-dropdown': '_onHideDropdown'
        },

        collectionEvents: {
            'change': 'onChangeCollection'
        },

        onChangeCollection: function () {
            if (this.options.jqGrid) {
                this.options.jqGrid.render();
            }
            if (this.options.callBackChange) {
                this.options.callBackChange(this.collection.filter(function (model) {
                    return model.get('checked');
                }));
            }
        },

        initialize: function () {
            if (!this.collection) {
                this.collection = new Backbone.Collection();
            }
        },

        onRender: function () {
            this.getRegion('button').show(new FilterLanguageButtonView({
                collection: this.collection
            }));
            this.getRegion('list').show(new FilterLanguageListView({
                collection: this.collection
            }));
        },

        _onShowDropdown: function () {
            this.trigger('show:dropdown');
        },

        _onHideDropdown: function () {
            this.trigger('hide:dropdown');
            this.getChildView('list').collection.trigger('change');
        }
    });
});

