define('services/common',[
    'jquery',
    'underscore',
    'settings',
    'module'
], function (
    $,
    _,
    Settings,
    module
) {
    'use strict';

    /**
     * Model
     */
    /**
     * REST API
     */
    module.exports = {
        getRowCount: function (urlParams) {
            var params = {},
                defer = $.Deferred();

            _.each(urlParams.filters, function (item, key) {
                if (item) {
                    params[key] = item;
                }
            });

            $.ajax({
                url: Settings.url(urlParams.rowCountUrl, params),
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (result) {
                    defer.reject(result);
                }
            });

            return defer.promise();
        },
        getRowCount2: function (urlParams) {
            var params = {},
                defer = $.Deferred();

            _.each(urlParams.filters, function (item, key) {
                if (item) {
                    params[key] = item;
                }
            });

            $.ajax({
                type: 'POST',
                url: Settings.url(urlParams.rowCountUrl),
                data: $.param(params),
                contentType: 'application/x-www-form-urlencoded',
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (result) {
                    defer.reject(result);
                }
            });

            return defer.promise();
        }
    };
});

