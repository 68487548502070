define('entities/caccounts/kitspcr',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/suppliers',
    'entities/caccounts/assay',
    'entities/caccounts/pcrreagents',
    'entities/caccounts/kitlotpcr'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.KitPcr = Backbone.RelationalModel.extend({
        service: 'services/caccounts/kitspcr',
        fetch: function () {
            this.url = Settings.url('rest/v2/kits/pcr/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'refSupplier': null,
            'reagents': [],
            'comment': '',
            'lots': [],
            'refCurrentLot': undefined,
            'tags': []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'refSupplier',
            relatedModel: 'Supplier',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'PcrReagent',
            collectionType: 'PcrReagentCollection'
        }, {
            type: Backbone.HasMany,
            key: 'lots',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refCurrentLot',
            relatedModel: 'KitLotPcr',
            includeInJSON: ['secId', 'code', 'name']
        }],

        initialize: function () {
            this.listenTo(this.get('lots'), 'add', this.onAddLot);
        },

        onAddLot: function (model) {
            model.set({kit: this}, {silent: true});
        },

        postUrl: function () {
            return Settings.url('rest/v2/kits/pcr/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'PcrKitJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'PcrKit',

        importable: true
    });

    app.ns.KitPcr.role = RolesMixin.KIT_PCR;

    app.ns.KitPcrCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/kits/pcr/');
        },
        model: app.ns.KitPcr
    });
});

