
define('template!imageSelectionPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="selection-logo"></div>\n</div>\n<div class="with-header">' +
((__t = ( _.i18n("logotypeSelection.description") )) == null ? '' : __t) +
'</div>\n<div class="row panel-footer m-t-20">\n  ';
 _.each(radioButtons, function(radioButton, index){ ;
__p += '\n    <div class="col-xs-4">\n      <label class="clickable m-b-0" for="' +
((__t = ( radioButton.id )) == null ? '' : __t) +
'">\n        <div class="image-texture-and-background">\n          <img class="js-image" data-index="' +
((__t = ( index )) == null ? '' : __t) +
'" src=""/>\n        </div>\n      </label>\n      <div class="radio radio-primary">\n        <label class="p-l-30">\n          <input type="radio" name="imageType" id="' +
((__t = ( radioButton.id )) == null ? '' : __t) +
'" value="' +
((__t = ( index )) == null ? '' : __t) +
'" ' +
((__t = ( index === 0 ? 'checked' : '' )) == null ? '' : __t) +
'>\n          ' +
((__t = ( radioButton.label )) == null ? '' : __t) +
'\n        </label>\n      </div>\n    </div>\n  ';
 }) ;
__p += '\n</div>\n<div class="row m-t-10">\n  <div class="pull-right p-r-15">\n    <a class="btn btn-default btn-flat flat-primary js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</a>\n    <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n  </div>\n</div>';

}
return __p
};});

