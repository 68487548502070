define('prepWellPcrWellCollection',[
    'module',
    'backbone.marionette',
    'template!prepWellPcrWellCollection',
    'prepWellPcrWellItem',
    'prepWellEmptyItem',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    Item,
    EmptyItem,
    _) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        className: 'col-w-all rounded',
        childView: Item,
        emptyView: EmptyItem,
        emptyViewOptions: function () {
            return {title: _.i18n('pcrwell.from.preprun')};
        }
    });
});
