
define('template!runPcrHeaderDetailsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-1 p-r-2 p-l-2" style="display:flex; flex-direction: column; justify-content: space-around;">\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-edit-pcrrun mdi-content-create"\n              title="' +
((__t = ( _.i18n('run.pcr.edit.popover') )) == null ? '' : __t) +
'"\n              style="\n                top: 0;\n                position: relative;\n                font-size: 14px;\n                right: -2px;">\n        </span>\n    </div>\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-show-kits mdi mdi-flask-outline"\n              title="' +
((__t = ( _.i18n('run.pcr.kits') )) == null ? '' : __t) +
'"\n              style="\n                top: -2px;\n                position: relative;\n                font-size: 14px;\n                right: -2px;">\n        </span>\n    </div>\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-show-attachments mdi mdi-flip-v mdi-rotate-270 mdi-attachment"\n              title="' +
((__t = ( _.i18n('attachment') )) == null ? '' : __t) +
'"\n              style="top: -1px;\n                position: relative;\n                font-size: 16px;\n                line-height: 19px;">\n        </span>\n    </div>\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-show-properties mdi mdi-format-list-bulleted"\n              title="' +
((__t = ( _.i18n('run.properties') )) == null ? '' : __t) +
'"\n              style="top: -1px;\n                position: relative;\n                font-size: 16px;\n                left: 2px;\n                line-height: 19px;">\n        </span>\n    </div>\n</div>\n<div class="col-xs-9 p-r-2 p-l-2"\n     style="display: flex; flex-direction: column; justify-content: space-around;">\n    <div class="col-xs-12 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox" id="to-clipboard" data-name="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        <div class="js-runName-div-container noWrapHidden"\n             style="\n                    font-size: 20px;\n                    height: 38px;\n                    border: solid 1px #333;\n                    border-radius: 5px;\n                    margin-top: 2px;\n                    padding-top: 6px;\n                    padding-left: 2px;\n                    margin-bottom: 4px;">\n            ' +
((__t = ( code )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="col-xs-12 p-l-5 p-r-5 noWrapHidden">\n        <div class="js-prepruns"></div>\n    </div>\n</div>\n<div class="col-xs-2 p-r-2 p-l-2"\n     style="display: flex; flex-direction: column; justify-content: space-around;">\n    <div style="display: flex; justify-content: space-around;">\n        <div class="buttonCircle">\n                                    <span class="clickable hidden-print js-copyParameters mdi-content-content-copy"\n                                          data-copy="' +
((__t = ( code )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n        </div>\n\n        <div class="buttonCircle">\n                                     <span class="clickable hidden-print js-run-refresh mdi-navigation-refresh"\n                                           title="' +
((__t = ( _.i18n('refresh.run') )) == null ? '' : __t) +
'"\n                                           style="\n                                             top: 0;\n                                             position: relative;\n                                             font-size: 18px;\n                                             right: 0;">\n                                    </span>\n        </div>\n    </div>\n    <div style="display: flex; flex-direction: column;">\n        <div>' +
((__t = ( valStatus )) == null ? '' : __t) +
'</div>\n        <div class="cell-default js-status-tooltip"\n             style="display: flex; position: relative;">\n            ';
 for (var i = 1; i <= 5; i++) {

            var iconColor = '';
            if (status >= i) {
            iconColor = 'statusIcon-upTo';
            }
            if (valStatus.includes(i)) {
            iconColor = 'statusIcon-toDo';
            }
            if (i === 5 && valStatus.includes(i) && status === 5) {
            iconColor = 'statusIcon-upTo';
            }
            ;
__p += '\n            <div class="header jqgrid-valStatusIcon ' +
((__t = ( iconColor )) == null ? '' : __t) +
'"></div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

