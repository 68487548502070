
define('template!dynamicListHeaderItemButtonView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="mdi mdi-plus-circle action clickable"></span>';

}
return __p
};});

