define('menuRoutingGroupItem',['module',
        'backbone.marionette',
        'template!menuRoutingGroupItem',
        'dialogFormView',
        'app'
    ],
    function (module,
              Marionette,
              Tpl,
              DialogFormView,
              App
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: Tpl,

            className: 'col-xs-12 js-select-item p-l-5 p-r-5 p-t-5 p-b-5',
            events: {
                'click .card.clickable': 'onClickItem'
            },

            serializeData: function () {
                return {code: this.model.get('code'), name: this.model.get('name')};
            },

            onClickItem: function () {
                App.navigate('routing/' + this.options.type + '/' + this.model.get('secId'), {trigger: true});
            }
        });
    });
