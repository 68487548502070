define( 'logger',[
  'module',
  'underscore',
  'app',
  'settings'
], function (
  module,
  _,
  App,
  Settings
) {
  'use strict';

  var Logger = {
    startProfiling: function ( target, str ) {
      if (Settings.isDebugMode) {
        if (target && _.has(target, 'id')) {
          console.time( target.id, str );
        } else if (!target) {
          console.time(str);
        }
      }
    },

    stopProfiling: function ( target, str ) {
      if (Settings.isDebugMode) {
        if (target && _.has(target, 'id')) {
          console.timeEnd( target.id, str );
        } else if (!target) {
          console.timeEnd(str);
        }
      }
    },

    log: function ( target, str ) {
      if (Settings.isDebugMode) {
        if (target && _.has(target, 'id')) {
          console.log( target.id, str );
        } else if (!target) {
          console.log(str);
        }
      }
    }
  };

  module.exports = Logger;
} );

