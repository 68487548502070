define('samplePrepWellPcrWellCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'samplePrepWellPcrWellView'
], function (
    module,
    Backbone,
    Marionette,
    view
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({

        childView: view,

        childViewOptions: {
            wellTargetSecID: view.secId
        },

        collection: new Backbone.Collection()
    });
});
