
define('template!contentShowView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="child" id="header-region"></div>\n<div class="main">\n    <nav id="sidebar-region" class="child">\n    </nav>\n    <div class="child content" id="content-region"></div>\n</div>\n<div class="js-global-loader"\n     style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: none; z-index: 1100;">\n    <div class="js-global-loader loader-background"\n         style="position: absolute; top: 0; left: 0; background: #00000059; width: 100%; height: 100%;"></div>\n    <div class="loader" style="top: 45%;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

