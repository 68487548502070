define('services/caccounts/printers',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/printers'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Printer,
                collection: app.ns.PrinterCollection
            };
        },
        getName: function () {
            return 'printer';
        },
        getUrl: function () {
            return 'rest/v2/printers/';
        },
        getDynamicJson: function () {
            return 'PrinterJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('printer.nameService'),
                name: 'nameService',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'nameService',
                width: 40
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            return [
                {
                    'field': 'nameService',
                    'param': {
                        'type': 'AUTOCOMPLETE_SYNC',
                        'config': {'getAutocompleteParam': _.bind(this.getAutocompleteParamService, this)}
                    }
                }
            ];
        },
        getAutocompleteParamService: function (config, context) {
            var defer = $.Deferred();
            config = {
                modelProperty: 'nameService',
                valueProperty: 'code',
                queryProperty: 'code',
                valueKey: 'code',
                url: this.getUrl() + 'service',
                canAddOrEdit: false,
                fieldsToDisplay: ['code', 'name']
            };
            if (!context) {
                context = this;
            }
            config = Dynamic.getAutocompleteParam(config, context);
            var url = Settings.url(this.getUrl() + 'service');

            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (data) {
                    config.data = data;
                    defer.resolve(config);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});

