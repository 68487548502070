define('connectorHl7ViewTracing',[
    'module',
    'dialogFormView',
    'template!connectorHl7ViewTracing',
    'underscore',
    'jquery',
    'settings',
    'dateUtils',
    'bootbox'
], function (
    module,
    DialogFormView,
    Tpl,
    _,
    $,
    Settings,
    DateUtils,
    Bootbox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {},

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-resend': 'onResend'
        },

        regions: {},

        fieldsToValidate: [],

        serializeData: function () {
            var data = {
                analysisDevice: this.model.get('analysisDevice'),
                source: this.model.get('source'),
                sampleId: this.model.get('sampleId'),
                created: DateUtils.toDateTimeFormatStringFromSettings(this.model.get('created')),
                updated: DateUtils.toDateTimeFormatStringFromSettings(this.model.get('updated')),
                lastAction: this.model.get('lastAction'),
                status: this.model.get('status'),
                error: this.model.get('error')
            };

            data.actions = _.map(this.model.get('actions'), function (action) {
                return {
                    createdAt: DateUtils.toDateTimeFormatStringFromSettings(action.date),
                    createdBy: action.user,
                    action: action.action,
                    message: action.message
                };
            });

            data.metadatas = _.map(this.model.get('metaDatas'), function (metadata) {
                return {
                    key: metadata.key,
                    value: metadata.value
                };
            });

            return data;
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },

        onResend: function () {
            Bootbox.confirm('This will resend the HL7 message and create a new request. Are you sure?', _.bind(function (result) {
                if (!result) {
                    return;
                }
                this.model.resend().done(_.bind(function () {
                    this.box.modal('hide');
                }, this));
            }, this));
        },

        onRender: function () {
            var service = require('services/caccounts/connector/connectorHl7Tracings');
            this.setPermissions(service.canAddOrEdit());
        }
    });
});

