define('dynamicCreateEdit',[
    'module',
    'backbone',
    'dialogFormView',
    'template!dynamicCreateEdit',
    'savingBehavior',
    'bootbox',
    'underscore',
    'jquery',
    'settings',
    'dynamicCreateEditComponent',
    'dynamicCreateEditCodeName',
    'dynamicCreateEditCodeNameSequence',
    'dynamicCreateEditDescription',
    'dynamicCreateEditDescriptionComment',
    'dynamicCreateEditDescriptionCommentTags',
    'dynamicCreateEditList',
    'dynamicCreateEditCustomComment',
    'dynamicCreateEditCustomRoles',
    'dynamicCreateEditCustomMbAnaClassifiedValue'
], function (
    module,
    Backbone,
    DialogFormView,
    createEditTpl,
    SavingBehavior,
    bootbox,
    _,
    $,
    Settings,
    ComponentView,
    DynamicCreateEditCodeName,
    DynamicCreateEditCodeNameSequence,
    DynamicCreateEditDescription,
    DynamicCreateEditDescriptionComment,
    DynamicCreateEditDescriptionCommentTags,
    DynamicCreateEditList,
    DynamicCreateEditCustomComment,
    DynamicCreateEditCustomRoles,
    DynamicCreateEditCustomMbAnaClassifiedValue
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            regions: '.dynamic-regions'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'render',
            error: 'onSaveError'
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            var fields = _.filter(this.options.dynamicConfiguration.fields, function (field) {
                return field.field !== 'secId';
            });

            // ignore field
            var ignoreFields = this.options.service.getCreateEditFieldIgnore();
            _.each(ignoreFields, _.bind(function (ignoreField) {
                fields = _.filter(fields, function (field) {
                    return field.field !== ignoreField;
                });
            }, this));

            // custom field
            var customFields = this.options.service.getCreateEditFieldCustom(this.options.model);

            // Code name
            if (_.find(fields, function (field) {
                return field.field === 'code';
            }) && _.find(fields, function (field) {
                return field.field === 'name';
            })) {
                var customFieldCode = _.findWhere(customFields, {'field': 'code'});
                var codeListCode = customFieldCode ? customFieldCode.param.code : null;
                if (_.find(fields, function (field) {
                    return field.field === 'sequence';
                })) {
                    if (!this.options.disableCodeName) {
                        this.addComponent({
                            'type': 'CODENAMESEQUENCE',
                            codeListCode: codeListCode,
                            display: {readOnly: this.options.display ? this.options.display.readOnly : false}
                        }, 'codeNameSequence');
                    }
                    fields = _.filter(fields, function (field) {
                        return field.field !== 'sequence';
                    });
                } else {
                    if (!this.options.disableCodeName) {
                        this.addComponent({'type': 'CODENAME', codeListCode: codeListCode, display: {readOnly: this.options.display ? this.options.display.readOnly : false}}, 'codeName');
                    }
                }
                fields = _.filter(fields, function (field) {
                    return !(field.field === 'code' || field.field === 'name');
                });
            }

            var descCommentTags = false;
            var descComment = false;

            if (_.find(fields, function (field) {
                return field.field === 'description';
            }) && _.find(fields, function (field) {
                return field.field === 'comment';
            })) {
                if (_.find(fields, function (field) {
                    return field.field === 'tags';
                })) {
                    fields = _.filter(fields, function (field) {
                        return field.field !== 'tags';
                    });
                    descCommentTags = true;
                } else {
                    descComment = true;
                }
                fields = _.filter(fields, function (field) {
                    return !(field.field === 'description' || field.field === 'comment');
                });
            }
            var description = _.find(fields, function (field) {
                return field.field === 'description';
            });

            var comment = _.find(fields, function (field) {
                return field.field === 'comment';
            });

            fields = _.filter(fields, function (field) {
                return field.field !== 'description' && field.field !== 'comment';
            });

            // order field
            var fieldOrdered = [];
            // use default field order
            _.each(this.model.defaults, function (ignored, attributeName) {
                var fieldFound = _.first(_.filter(fields, function (field) {
                    return field.field === attributeName;
                }));
                if (fieldFound) {
                    fieldOrdered.push(fieldFound);
                }
            });
            _.each(customFields, function (customField) {
                if (customField.force) {
                    fieldOrdered.push(customField);
                }
            });
            // use model field order not define in default
            _.each(this.model.attributes, function (ignored, attributeName) {
                var found = _.any(fieldOrdered, function (field) {
                    return field.field === attributeName;
                });
                if (found) {
                    return;
                }
                var fieldFound = _.first(_.filter(fields, function (field) {
                    return field.field === attributeName;
                }));
                if (fieldFound) {
                    fieldOrdered.push(fieldFound);
                }
            });

            // generic field
            _.each(fieldOrdered, _.bind(function (field) {
                var custom = _.find(customFields, function (customField) {
                    return field.field === customField.field.substring(0, customField.field.indexOf('.') < 0 ? customField.field.length : customField.field.indexOf('.'));
                });
                if (custom) {
                    field.custom = custom;
                    var display = true;
                    if (custom.display && custom.display.condition) {
                        switch (custom.display.condition.type) {
                            case 'EQUAL':
                                display = this.model.get(custom.display.condition.key) === custom.display.condition.value;
                                break;
                            case 'NOT_EQUAL':
                                display = this.model.get(custom.display.condition.key) !== custom.display.condition.value;
                                break;
                            case 'EMPTY':
                                if (this.model.get(custom.display.condition.key) instanceof Backbone.Collection) {
                                    display = !this.model.get(custom.display.condition.key).isEmpty();
                                } else {
                                    display = _.isEmpty(this.model.get(custom.display.condition.key));
                                }
                                break;
                            case 'NOT_EMPTY':
                                if (this.model.get(custom.display.condition.key) instanceof Backbone.Collection) {
                                    display = this.model.get(custom.display.condition.key).isEmpty();
                                } else {
                                    display = !_.isEmpty(this.model.get(custom.display.condition.key));
                                }
                                break;
                            case 'FUNCTION': {
                                display = custom.display.condition.func(this.model);
                                break;
                            }
                        }
                    }
                    if (custom.dependsOn) {
                        field.dependsOn = custom.dependsOn;
                    }
                    if (display) {
                        field = _.extend(field, custom.param);
                        if (custom.extra) {
                            field.extra = custom.extra;
                        }
                        this.addComponent(field, custom.field);
                    }
                } else {
                    this.addComponent(field, field.field);
                }
            }, this));

            if (description) {
                description.type = 'DESCRIPTION';
                this.addComponent(description, 'description');
            }

            if (comment) {
                comment.type = 'COMMENT';
                this.addComponent(comment, 'comment');
            }
            if (descCommentTags) {
                this.addComponent({'type': 'DESCCOMMENTTAGS'}, 'descriptionCommentTags');
            }
            if (descComment) {
                this.addComponent({'type': 'DESCCOMMENT'}, 'descriptionComment');
            }
            this.setPermissions(this.options.service.canAddOrEdit());
            this.fieldOrdered = fieldOrdered;
        },

        addComponent: function (dynamicConfigurationField, fieldName) {
            var rdm = _.uniqueId();
            this.ui.regions.append('<div class="region-' + fieldName + rdm + '"></div>');
            var region = this.addRegion(fieldName, '.region-' + fieldName + rdm);
            switch (dynamicConfigurationField.type) {
                case 'CODENAME':
                    region.show(new DynamicCreateEditCodeName({
                        'field': fieldName,
                        'model': this.model,
                        'value': this.model.get(fieldName),
                        codeListCode: dynamicConfigurationField.codeListCode,
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'CODENAMESEQUENCE':
                    region.show(new DynamicCreateEditCodeNameSequence({
                        'field': fieldName,
                        'model': this.model,
                        'value': this.model.get(fieldName),
                        codeListCode: dynamicConfigurationField.codeListCode,
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'DESCRIPTION':
                    region.show(new DynamicCreateEditDescription({
                        'field': fieldName,
                        'model': this.model,
                        'value': this.model.get(fieldName),
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'COMMENT':
                    region.show(new DynamicCreateEditCustomComment({
                        'field': fieldName,
                        'model': this.model,
                        'value': this.model.get(fieldName),
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'DESCCOMMENT':
                    region.show(new DynamicCreateEditDescriptionComment({
                        'model': this.model,
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'DESCCOMMENTTAGS':
                    region.show(new DynamicCreateEditDescriptionCommentTags({
                        'model': this.model,
                        service: this.options.service,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false
                    }));
                    break;
                case 'LIST':
                    if (!(this.model.get(fieldName) instanceof Backbone.Collection)) {
                        alert('The field ' + fieldName + ' is not a collection (see console)');
                        throw new Error('The field ' + fieldName + ' is not a collection');
                    }
                    region.show(new DynamicCreateEditList({
                        field: fieldName,
                        model: this.model,
                        service: this.options.service,
                        collection: this.model.get(fieldName),
                        dynamicConfigurationField: dynamicConfigurationField
                    }));
                    break;
                case 'ROLES':
                    region.show(new DynamicCreateEditCustomRoles({
                        'field': fieldName,
                        'model': this.model,
                        service: this.options.service
                    }));
                    break;
                case 'MBANA_CLASSIFIEDVALUES':
                    region.show(new DynamicCreateEditCustomMbAnaClassifiedValue({
                        'field': fieldName,
                        dynamicConfigurationField: dynamicConfigurationField,
                        'model': this.model,
                        service: this.options.service
                    }));
                    break;
                default:
                    region.show(new ComponentView({
                        'model': this.model,
                        service: this.options.service,
                        dynamicConfigurationField: dynamicConfigurationField,
                        'fieldName': fieldName
                    }));
            }
        },

        onConfirm: function () {
            this.internalValidate(this.options.service.getCreateEditFieldValidate()).done(_.bind(function (ret) {
                if (ret) {
                    // remove trigger "sync" event from this.model
                    this.stopListening(this.model, 'sync');
                    this.model.save().done(_.bind(function () {
                        // add trigger "sync" event from this.model
                        this.listenTo(this.model, 'sync', this.onSave);
                        this.hide();
                    }, this));
                }
            }, this));
        },

        internalValidate: function (validateFields) {
            var defer = $.Deferred();
            var defers = [];
            var ret = this.validate(validateFields);

            // for each this.fieldOrdered, check if the field is required
            var map = [];
            _.each(this.fieldOrdered, _.bind(function (field) {
                if (field.custom && field.custom.validate) {
                    defers.push(field.custom.validate(this.model));
                    map.push(field);
                }
            }, this));

            $.when.apply($, defers).done(_.bind(function () {
                _.each(arguments, _.bind(function (arg, index) {
                    if (map[index] && map[index].custom && map[index].custom.validate && map[index].field === 'condition') {
                        var errors = false;
                        var message = '';
                        _.each(arg, function (value, key) {
                            if (!_.isEmpty(value)) {
                                errors = true;
                                message += '<p>' + key + '</p><ul>';
                                _.each(value, function (error) {
                                    message += '<li>' + error + '</li>';
                                });
                                message += '</ul>';
                            }
                        });
                        if (errors) {
                            bootbox.alert({
                                title: _.i18n('error'),
                                message: _.i18n('condition.invalid') + '<br/><br/>' + message
                            });
                            ret = false;
                            this.onError([{name: map[index].field}]);
                        }
                    } else if (!arg) {
                        ret = false;
                        this.onError([{name: map[index].field}]);
                    }
                }, this));
                defer.resolve(ret);
            }, this));
            return defer.promise();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            $('div[data-field-name=code]').addClass('has-error');
            $('div[data-field-name=codeName]').addClass('has-error');
            $('div[data-field-name=codeNameSequence]').addClass('has-error');
        }
    });
});

