
define('template!routingGroupItemCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="rack"\n     style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">' +
((__t = ( rackId )) == null ? '' : __t) +
'\n</div>\n';
 if(name) { ;
__p += '\n<div class="code"\n     style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">' +
((__t = ( code )) == null ? '' : __t) +
'\n</div>\n<div class="name"\n     style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">' +
((__t = ( name )) == null ? '' : __t) +
'\n</div>\n';
 } ;
__p += '\n';
 if(preProcessing) { ;
__p += '\n<div class="watermark"><span>PP</span></div>\n';
 } ;
__p += '\n';
 if(showPopup) { ;
__p += '\n<p class="col-xs-12 text-center m-b-0">\n    <button class="btn btn-danger btn-block" style="display: none;">' +
((__t = (
        _.i18n('routingAction.validProcess') )) == null ? '' : __t) +
'\n    </button>\n</p>\n';
 } ;
__p += '\n';
 if(!empty) { ;
__p += '\n<span class="mdi mdi-alpha-h-circle-outline clickable js-samples"\n      style="position: absolute; top: 5px; right: 5px;"></span>\n';
 } ;
__p += '\n';

}
return __p
};});

