
define('template!exportCreateEditLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="row">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('export.position') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8 p-l-0">\n                    <div class="form-group col-xs-5">\n                        <input class="form-control floating-label js-info-input dataInput inputBackground"\n                               data-placement="top" placeholder="" type="text" style="width: 193px;"\n                               value="' +
((__t = ( namePCRPlate )) == null ? '' : __t) +
'">\n                    </div>\n                    <div class="form-group col-xs-2">\n                        <input class="form-control floating-label js-info-input dataInput inputBackground"\n                               data-placement="top" placeholder="" type="text" style="width: 193px;"\n                               value="' +
((__t = ( position )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('export.sampleId') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <div class="form-group" >\n                        <input class="form-control floating-label js-info-input dataInput inputBackground"\n                               data-placement="top" placeholder="" type="text" value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('export.server') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <div class="form-group" >\n                        <input class="form-control floating-label js-info-input dataInput inputBackground"\n                               data-placement="top" placeholder="" type="text" value="' +
((__t = ( server.uriString )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('export.createdDate') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <div class="form-group">\n                        <input class="form-control floating-label js-info-input dataInput inputBackground"\n                               data-placement="top" placeholder="" style="width: 280px;"\n                               type="text"\n                               value="' +
((__t = ( createdDate )) == null ? '' : __t) +
'">\n                    </div>\n                </div>\n            </div>\n            <div class="row js-queries"></div>\n            <div class="row">\n                <div class="pull-right p-r-15">\n                    <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'\n                    </button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

