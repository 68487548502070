define('jqgridActionsButtonFormatterActions',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = {
    edit: {
      icon: 'mdi-editor-mode-edit',
      text: _.i18n('common.edit'),
      event: 'edit'
    },
    'delete': {
      icon: 'mdi-action-delete',
      text: _.i18n('common.delete'),
      event: 'delete'
    },
    upload: {
      icon: 'mdi-file-file-upload',
      text: _.i18n('common.upload'),
      event: 'upload'
    },
    'import': {
      icon: '',
      text: _.i18n('common.import'),
      event: 'import'
    },
    'export': {
      icon: '',
      text: _.i18n('common.export'),
      event: 'export'
    },
    'duplicate': {
      icon: '',
      text: _.i18n('common.duplicate'),
      event: 'duplicate'
    },
    'proofsheet': {
      icon: '',
      text: _.i18n('common.proofsheet'),
      event: 'proofsheet'
    },
    'copy': {
      icon: '',
      text: _.i18n('common.copy'),
      event: 'copy'
    },
    'cut': {
      icon: '',
      text: _.i18n('common.cut'),
      event: 'cut'
    },
    'paste': {
      icon: '',
      text: _.i18n('common.paste'),
      event: 'paste'
    },
    'unselect': {
      icon: '',
      text: _.i18n('common.unSelectAll'),
      event: 'unselect'
    }
  };
});
