
define('template!connectorHL7TracingCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="clickable" title="' +
((__t = ( _.i18n('hl7.sendResult') )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-invoice-text-send-outline  action header"></span>\n</div>';

}
return __p
};});

