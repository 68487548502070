define('menuGroup',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'template!menuGroup',
    'menuGroupItem',
    'menuGroupQuickActionCollection'
], function (
    module,
    Backbone,
    _,
    Marionette,
    Tpl,
    MenuGroupItem,
    MenuGroupQuickActionCollectionView
) {

    'use strict';

    /**
     * @class MenuGroupView
     * @extends Marionette.View
     * @param {Object} options
     * @param {Object} options.model
     * @param {Object} options.model.items
     *
     * model {
     *    items: Backbone.Collection
     *    type: String (optional) - 'pcr', 'prep', ...
     *    name: String (required)
     *    }
     */
    module.exports = Marionette.View.extend({
        template: Tpl,
        ui: {
            menu: '.dropdown-menu'
        },
        regions: {
            quickActions: '.quick-action-container'
        },
        className: function () {
            return 'menu-group2 dropdown ' + (this.model.get('type') ? ' ' + this.model.get('type') : '');
        },
        initialize: function () {
            this.idItems = _.uniqueId('menu-group-');
            if (this.model.get('disabled')) {
                this.model.get('items').each(_.bind(function (item) {
                    return item.set('disabled', this.model.get('disabled'));
                }, this));
                this.model.get('quickActions').each(_.bind(function (quickAction) {
                    if (quickAction.get('items')) {
                        quickAction.get('items').each(_.bind(function (item) {
                            return item.set('disabled', this.model.get('disabled'));
                        }, this));
                    } else {
                        return quickAction.set('disabled', this.model.get('disabled'));
                    }
                }, this));
            }
        },
        onRender: function () {
            this.ui.menu.html('');
            this.model.get('items').each(_.bind(function (item) {
                if (item.get('type') === 'line') {
                    this.ui.menu.append('<li role="separator" class="divider"></li>');
                } else {
                    var view = new MenuGroupItem({
                        model: item
                    });
                    this.ui.menu.append(view.render().el);
                }
            }, this));
            if (this.model.get('quickActions')) {
                this.showChildView('quickActions', new MenuGroupQuickActionCollectionView({
                    collection: this.model.get('quickActions')
                }));
            }
        },
        serializeData: function () {
            return {
                idItems: this.idItems,
                title: _.i18n(this.model.get('nameCode')),
                quickActions: this.model.get('quickActions') ? !this.model.get('quickActions').isEmpty() : false
            };
        }
    });
});

