
define('template!kitLotsPcrTargetView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row">\n	<div class="form-group">\n		<label class="col-xs-2 control-label t-a-l">' +
((__t = ( _.i18n('lot.kit') )) == null ? '' : __t) +
'</label>\n		<label class="col-xs-2 control-label t-a-l">' +
((__t = ( _.i18n('lot.assay') )) == null ? '' : __t) +
'</label>\n		<label class="col-xs-2 control-label t-a-l">' +
((__t = ( _.i18n('lot.reagent') )) == null ? '' : __t) +
'</label>\n	</div>\n</div>\n<div class="row m-b-10">\n	<div class="form-group">\n		<div class="col-xs-2">\n			<input type="text" class="form-control" value="' +
((__t = ( kit )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off" disabled="disabled"/>\n		</div>\n		<div class="col-xs-2 js-assay"></div>\n		<div class="col-xs-2 js-reagent"></div>\n	</div>\n</div>\n<div class="row m-b-10 d-f col-w-all" style="column-gap: 10px;">\n	<div class="col-w-45 js-current-lot rounded" style="padding: 10px 10px 10px;"></div>\n	<div class="col-w-all p-0 js-left invisible" style="display:flex; align-items: center; justify-content: center; flex-direction: column;">\n		<span class="mdi mdi-transfer-left clickable" style="font-size: 80px; line-height: 80px;"></span>\n	</div>\n	<div class="col-w-45 js-other-lot rounded invisible" style="padding: 10px 10px 10px 10px;"></div>\n</div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>';

}
return __p
};});

