define('qcListListView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'jqgridView2',
    'template!qcListListView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    _,
    $,
    JqGridView,
    viewTpl,
    PcrWellResultController
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'wellQCListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-listQC',
                jqGridPager: '#jq-grid-pager-listQC',
                jqgRow: 'tr.jqgrow',
                blockLoading: '.empty-page-loader'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },
        
        attributes: {
            style: 'width:730px;height:100%;'
        },

        onGridLoaded: function () {
            this.trigger('onGridLoaded', this);
        },

        serializeData: function () {
            return {};
        },

        pagination: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 18,
                currentPage: 1
            };
        },

        gridOptions: function (data) {
            var PcrKitLotService = require('services/caccounts/kitlotpcr');
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('qc.run.date'),
                        name: 'run.period',
                        classes: '',
                        formatter: this.dateFormatterFromSettings,
                        search: false,
                        width: 100
                    }, {
                        label: _.i18n('qc.run'),
                        name: 'run.name',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: false,
                        width: 200
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: false,
                        width: 40
                    }, {
                        label: _.i18n('well.sampleType'),
                        name: 'smpType',
                        classes: '',
                        formatter: this.defaultFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 30,
                        index: 'qualityControl'
                    }, {
                        label: _.i18n('well.assay'),
                        name: 'refAssay',
                        classes: '',
                        formatter: this.codeNameFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 100,
                        index: 'assayCode'
                    }],

                sidx: 'runNameDate',
                sord: 'desc',
                onSelectRow: _.bind(function (rowId) {
                    this.trigger('wellSelected', rowId);
                }, this)
            };

            var maxSequenceDisplay = this.model.get('assayVersion').get('results').max(function (item) {
                return item.get('sequenceDisplay');
            }).get('sequenceDisplay');

            for (var i = 1; i <= maxSequenceDisplay; i++) {
                options.colModel.push({
                    label: _.i18n('well.qc.targetResultIcon') + ' ' + i,
                    name: 'targetResultIcon' + i,
                    classes: '',
                    formatter: _.bind(this.resultIconCodeNumFormatter, this),
                    search: false,
                    width: 80
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        resultIconCodeNumFormatter: function (cellValue, call, object) {
            var PcrWellService = require('services/caccounts/pcrwells');
            var well = PcrWellService.getModel(object);
            var index = parseInt(call.colModel.name.replaceAll('targetResultIcon', ''), 10);
            var result = well.get('results').chain().filter(function (result) {
                return result.get('sequenceDisplay') === index;
            }).first().value();
            if (!result) {
                return '';
            }
            return PcrWellResultController.generateContent(result, 'WellList');
        }
    });
});

