
define('template!connectorHl7ViewTracing', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <h1>' +
((__t = ( _.i18n('connector.hl7.tracing.information') )) == null ? '' : __t) +
'</h1>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (
                    _.i18n('connector.hl7.tracing.analysisDevice') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="analysisDevice"\n                            data-field-name="analysisDevice"\n                            value="' +
((__t = ( analysisDevice )) == null ? '' : __t) +
'" readonly>\n                </div>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="source"\n                            data-field-name="source"\n                            value="' +
((__t = ( source )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.sampleId')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="sampleId"\n                            data-field-name="sampleId"\n                            value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.created')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="created"\n                            data-field-name="created"\n                            value="' +
((__t = ( created )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.updated')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="updated"\n                            data-field-name="updated"\n                            value="' +
((__t = ( updated )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.status')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="status"\n                            data-field-name="status"\n                            value="' +
((__t = ( status )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.error')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="error"\n                            data-field-name="error"\n                            value="' +
((__t = ( error )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('connector.hl7.tracing.lastAction')
                    )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="lastAction"\n                            data-field-name="lastAction"\n                            value="' +
((__t = ( lastAction )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            <hr/>\n            <h1>' +
((__t = ( _.i18n('connector.hl7.tracing.metadata') )) == null ? '' : __t) +
'</h1>\n            ';
 _.each(metadatas, function(metadata) { ;
__p += '\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (metadata.key )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    ';
 if(metadata.key === 'raw-message') { ;
__p += '\n                    <pre>' +
((__t = ( metadata.value )) == null ? '' : __t) +
'</pre>\n                    ';
 } else { ;
__p += '\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="metadata.value"\n                            data-field-name="metadata.value"\n                            value="' +
((__t = ( metadata.value )) == null ? '' : __t) +
'" readonly>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n            ';
 }) ;
__p += '\n            <hr/>\n            <h1>' +
((__t = ( _.i18n('connector.hl7.tracing.action') )) == null ? '' : __t) +
'</h1>\n            <div class="form-group">\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n('connector.hl7.tracing.action.action')
                    )) == null ? '' : __t) +
'</label>\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n('connector.hl7.tracing.action.createdAt')
                    )) == null ? '' : __t) +
'</label>\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n('connector.hl7.tracing.action.createdBy')
                    )) == null ? '' : __t) +
'</label>\n                <label class="col-xs-3 overflow-hidden control-label">' +
((__t = (_.i18n('connector.hl7.tracing.action.message')
                    )) == null ? '' : __t) +
'</label>\n            </div>\n            ';
 _.each(actions, function(action) { ;
__p += '\n            <div class="form-group">\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="action.action"\n                            data-field-name="action.action"\n                            value="' +
((__t = ( action.action )) == null ? '' : __t) +
'" readonly>\n                </div>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="action.createdAt"\n                            data-field-name="action.createdAt"\n                            value="' +
((__t = ( action.createdAt )) == null ? '' : __t) +
'" readonly>\n                </div>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="action.createdBy"\n                            data-field-name="action.createdBy"\n                            value="' +
((__t = ( action.createdBy )) == null ? '' : __t) +
'" readonly>\n                </div>\n                <div class="col-xs-3">\n                    <input\n                            type="text"\n                            class="form-control disabled"\n                            name="action.message"\n                            data-field-name="action.message"\n                            value="' +
((__t = ( action.message )) == null ? '' : __t) +
'" readonly>\n                </div>\n            </div>\n            ';
 }) ;
__p += '\n            <hr/>\n            <div class="cancelConfirmRow">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button class="mdi mdi-invoice-text-send-outline btn popupBtn-action resend-b js-resend"\n                        title="' +
((__t = ( _.i18n('resend') )) == null ? '' : __t) +
'"></button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

