/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouMainRouter',[
    'module',
    'marionetteAppRouter'
], function (
    module,
    AppRouter
) {
    'use strict';

    var Controller = {
        showThankYou: function (target) {
            require(['thankYouShowController'], function (ShowController) {
                ShowController.showThankYou(target);
            });
        }
    };

    var ThankYouRouter = AppRouter.extend({
        appRoutes: {
            'thank_you': 'showThankYou'
        },
        controller: Controller
    });

    module.exports = new ThankYouRouter();
});
