define('prepWellController',[
    'module',
    'underscore',
    'jquery',
    'prepWellDetailsView'
], function (
    module,
    _,
    $,
    PrepWellDetailsView
) {
    'use strict';

    module.exports = {
        _loadDetails: function (param) {
            return param.model.fetch();
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.cSelection - The cSelection object.
         * @param {Object} param.icRefKitsToDiplay - The icRefKitsToDiplay object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the PrepWellDetailsView is closed.
         */
        showDetails: function (param) {
            $('.js-global-loader').show();
            this._loadDetails(param).done(_.bind(function () {
                var view = new PrepWellDetailsView({
                    model: param.model,
                    cSelection: param.cSelection,
                    loadDetails: _.bind(this._loadDetails, this)
                });
                view.show({
                    title: param.model.get('run').get('name'),
                    className: 'popupWellView'
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, this));
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    };
});

