define('entities/caccounts/sampleResults',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'sampleResultIconView',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/sampleResultDefinitions',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/mbanares'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    SampleResultIconView,
    RolesMixin
) {
    'use strict';

    app.ns.SampleResult = Backbone.RelationalModel.extend({
        url: function () {
            return Settings.url('rest/v2/samples/' + this.get('sampleParent').get('secId') + '/results/' + this.get('secId'));
        },

        defaults: {
            'exported': false,
            'exportedDate': null,
            'result': null,
            'sampleResultDefinition': null,
            pcrWellResults: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'sampleResultDefinition',
            relatedModel: 'SampleResultDefinition'
        }, {
            type: Backbone.HasMany,
            key: 'pcrWellResults',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection'
        }, {
            type: Backbone.HasOne,
            key: 'result',
            relatedModel: 'MbAnaRes'
        }],


        getResultIcon: function () {
            var iconResultView = new SampleResultIconView({
                model: this
            });
            iconResultView.render();

            var errMessComToDisplayList = '';
            var commentsList = [];

            if (this.get('comment') && this.get('comment') !== '') {
                commentsList.push(this.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }

            return {
                targetCode: this.get('sampleResultDefinition').get('target').get('code'),
                targetName: this.get('sampleResultDefinition').get('target').get('name'),
                resCode: this.get('result') ? this.get('result').get('code') : null,
                resColor: this.get('result') ? this.get('result').get('color') : 0,
                exported: this.get('exported'),
                iconView: iconResultView,
                iconHtml: iconResultView.$el.html(),
                errMessComToDisplayList: errMessComToDisplayList,
                color: this.get('result') ? this.get('result').get('color') : null
            };
        }
    });

    app.ns.SampleResult.role = RolesMixin.SAMPLES;

    app.ns.SampleResultCollection = Backbone.Collection.extend({
        model: app.ns.SampleResult
    });
});

