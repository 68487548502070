
define('template!menuItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a data-link="' +
((__t = ( link )) == null ? '' : __t) +
'" href="' +
((__t = ( link )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</a>';

}
return __p
};});

