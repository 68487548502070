define('samplePcrWellCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'samplePcrWellView',
    'template!samplePcrWellCollection'
], function (
    module,
    Backbone,
    Marionette,
    view,
    Tpl
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: view,

        childViewOptions: {
            wellTargetSecID: view.secId
        },

        collection: new Backbone.Collection()
    });
});
