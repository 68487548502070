
define('template!pcrSetupAssayItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 d-flex align-items-stretch"><input type="radio" name="well" id="assay-' +
((__t = ( sampleSecId )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleSecId )) == null ? '' : __t) +
'" class="js-info-input" data-field-name="assay"/></div>\n<div class="col-xs-8 d-flex align-items-stretch"><label for="assay-' +
((__t = ( sampleSecId )) == null ? '' : __t) +
'">' +
((__t = ( code )) == null ? '' : __t) +
'</label></div>\n<div class="col-xs-2 d-flex align-items-stretch">' +
((__t = ( wellSize )) == null ? '' : __t) +
'</div>\n';

}
return __p
};});

