define('services/caccounts/pcrwells',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/pcrwells'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.PcrWell,
                collection: app.ns.PcrWellCollection
            };
        },
        getCollection: function (items) {
            return new app.ns.PcrWellCollection(items);
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            var configurations = [];
            var UserCodeListService = require('services/caccounts/userCodelist');
            configurations.push({
                fieldName: 'tags',
                values: UserCodeListService.findItemsByCode('TAG_RESULT'),
                codeListCode: 'TAG_RESULT'
            });
            var configuration = _.findWhere(configurations, {fieldName: fieldName});
            if (configuration) {
                configuration.values.done(function (items) {
                    defer.resolve({items: items});
                });
            } else {
                defer.reject('No found for ' + fieldName);
            }
            return defer.promise();
        },
        getName: function () {
            return 'PcrWell';
        },

        filterWells: function (wells, from, to, step, row, column, filters) {
            console.info('%c check "filterWells" static method in pcrwells', 'color: #00f8ff');
            if (from) {
                var rowFrom = from.substring(0, 1);
                var columnFrom = parseInt(from.substring(1), 10);
            }
            if (to) {
                var rowTo = to.substring(0, 1);
                var columnTo = parseInt(to.substring(1), 10);
            }
            wells = wells.filter(_.bind(function (well) {
                var pos = well.get('pos');
                var rowCurrent = pos.substring(0, 1);
                var columnCurrent = parseInt(pos.substring(1), 10);
                if (from) {
                    if ((rowCurrent < rowFrom) ||
                        (columnCurrent < columnFrom) ||
                        ((columnCurrent - columnFrom) % step !== 0) ||
                        ((rowCurrent.charCodeAt(0) - rowFrom.charCodeAt(0)) % step !== 0)) {
                        return false;
                    }
                }
                if (to) {
                    if ((columnCurrent > columnTo) ||
                        (rowCurrent > rowTo)) {
                        return false;
                    }
                }
                if ((row && rowCurrent !== row) || (column && columnCurrent !== column)) {
                    return false;
                }

                if (filters) {
                    if (!well.get('refAssay')) {
                        return false;
                    }
                    if (filters.assaySecId && well.get('refAssay').get('secId') !== filters.assaySecId) {
                        return false;
                    }
                }
                return true;
            }, this));
            console.log('filter result - ' + wells.length);
            var collection = new app.ns.PcrWellCollection(wells);
            collection.sort();
            return collection.models;
        },
        getErrCodeFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['name']};
        },

        getDataForPcrKitLot: function (model) {
            return {
                url: Settings.url('rest/v2/wells/pcr/' + model.get('secId') + '/controlKitLot/wells'),
                rowCountUrl: 'rest/v2/wells/pcr/' + model.get('secId') + '/controlKitLot/wells/rowCount',
                service: this
            };
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.cSelection - The cSelection object.
         * @param {Object} param.icRefKitsToDisplay - The icRefKitsToDisplay object.
         */
        showDetails: function (param) {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: param.model, cSelection: param.cSelection, icRefKitsToDisplay: param.icRefKitsToDisplay});
            }, this));
        }
    }, Dynamic);
});

