define('extendUnderscore',[
    'underscore',
    'polyglotInstance',
    'cookies',
    'settings',
    'lodash'
], function (
    _,
    Polyglot,
    Cookies,
    Settings,
    Lodash
) {
    'use strict';

    _.i18n = _.bind(function (key, localized, param) {
        var t = Polyglot.t((localized || Settings.get('lang')) + '.' + key);
        if (param) {
            _.each(Object.entries(param), function (entryKeyValue) {
                t = t.replaceAll('[' + entryKeyValue[0] + ']', entryKeyValue[1]);
            });
        }

        if (Cookies.get('gm-displayI18nCode')) {
            return key;
        } else {
            return t;
        }
    }, this);

    Lodash.i18n = _.i18n;
});
