
define('template!dynamicCreateEditTypeParam', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f">\n	<div class="' +
((__t = ( display.type['class'] )) == null ? '' : __t) +
' autocomplete-region">\n	</div>\n\n	<div class="input ' +
((__t = ( display.param.class )) == null ? '' : __t) +
' p-l-5"></div>\n</div>';

}
return __p
};});

