
define('template!preProcessView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-header-region">\n</div>\n\n<div class="row m-b-10">\n    <div class="col-xs-9">\n        <label class="col-xs-4 text-right p-l-0" style="font-size: 25px;">' +
((__t = ( _.i18n('preprocess.printer.select')
            )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1 p-l-0 p-r-0 js-printer-region">\n        </div>\n    </div>\n</div>\n<div class="row m-b-10">\n    <div class="col-xs-9">\n        <label class="col-xs-4 text-right p-l-0">\n            <span class="mdi mdi-barcode-scan" style="font-size: 51px; line-height: 33px;"></span>\n        </label>\n        <div class="col-xs-3 p-l-0 p-r-0">\n            <div class="col-xs-10 p-l-0 p-r-0">\n                <input class="js-scan-sample-input form-control inputBackground" type="text" style="height: 40px; font-size: 30px;"/>\n            </div>\n            <div class="col-xs-2 p-l-0 p-r-0">\n                <button class="btn btn-white js-undo m-b-0 m-t-0 disabled" style="cursor: auto; padding: 1px 10px 3px 10px;"><span class="mdi mdi-undo-variant"></span></button>\n            </div>\n        </div>\n        <div class="col-xs-4 p-r-0 p-l-0 dispatchSampleHeader">\n            <label class="col-xs-5 text-right p-l-0" for="sampleOutput">' +
((__t = ( _.i18n('preprocess.sampleOutput')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-7 p-l-0 p-r-0">\n                <input class="js-sample-output form-control inputBackground disabled" type="text"\n                       id="sampleOutput" disabled value="' +
((__t = ( sampleOutput )) == null ? '' : __t) +
'" style="height: 40px; font-size: 30px;"/>\n            </div>\n        </div>\n    </div>\n    <div class="col-xs-3">\n        <button class="btn btn-warning js-print-barcode m-b-0 m-t-0" disabled>' +
((__t = ( _.i18n('preprocess.reprintBC') )) == null ? '' : __t) +
'\n        </button>\n    </div>\n</div>\n\n<div class="col-xs-12 m-b-20">\n    <div class="col-xs-9 js-sample-detail-region">\n    </div>\n    <div class="col-xs-3 js-error-region">\n    </div>\n</div>\n\n<div class="col-xs-12">\n    <div class="col-xs-7 row">\n        <label class="col-xs-12" for="description" style="min-height: 40px;">' +
((__t = ( _.i18n('preprocess.description') )) == null ? '' : __t) +
'</label>\n        <pre class="col-xs-12 height-100" id="description">' +
((__t = ( description )) == null ? '' : __t) +
'</pre>\n    </div>\n    <div class="col-xs-1"></div>\n\n    <div class="col-xs-4 height-100 row">\n        <label class="col-xs-12">' +
((__t = ( _.i18n('preprocess.history') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-12">\n            <span class="col-xs-4">' +
((__t = ( _.i18n('preprocess.history.primaryTube') )) == null ? '' : __t) +
'</span>\n            <span class="col-xs-4">' +
((__t = ( _.i18n('preprocess.history.secondaryTube') )) == null ? '' : __t) +
'</span>\n            <span class="col-xs-4">' +
((__t = ( _.i18n('preprocess.history.process') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="js-log col-xs-12 row container-border log"></div>\n    </div>\n</div>\n';

}
return __p
};});

