define('entities/admin/codelistelement',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.ns.CodeListElement = Backbone.RelationalModel.extend({
        service: 'services/admin/codelistelement',
        defaults: {
            secId: null,
            code: '',
            name: '',
            description: '',
            seq: null
        },
        idAttribute: 'secId'
    });

    app.ns.CodeListElement.role = RolesMixin.SUPER_USER;

    app.ns.CodeListElementCollection = Backbone.Collection.extend({
        model: app.ns.CodeListElement,
        comparator: function (o1, o2) {
            var seq1 = o1.get('seq');
            var seq2 = o2.get('seq');
            var code1 = o1.get('code');
            var code2 = o2.get('code');

            if ((!seq1 && !seq2) || seq1 === seq2) {
                if (!code1 || !code2) {
                    return 0;
                }
                return code1.localeCompare(code2);
            }
            if (!seq1) {
                return 1;
            }
            if (!seq2) {
                return -1;
            }

            return seq1.localeCompare(seq2);
        }
    });


});

