
define('template!runDissolvedTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group">\n                <div class="row">\n                    <div class="col-xs-12 p-l-45 p-r-45">\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = ( runName )) == null ? '' : __t) +
'</label></div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( wellPool.pos )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-12 p-l-5"><label>' +
((__t = ( wellPool.smpId.name
                            )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label></label></div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label></label></div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-2 p-l-5">\n                            <div class="dissolved-popup-resultIcon"\n                                 style="' +
((__t = ( (wellResColorFormatted? ('background-color:'+ wellResColorFormatted + ';'):'') )) == null ? '' : __t) +
'"></div>\n                        </div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-2 p-l-5"><label>' +
((__t = (
                            wellResToDisplay.result.code
                            )) == null ? '' : __t) +
'</label></div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-10 p-l-5"\n                             title="' +
((__t = ( wellResToDisplay.quantificationFormatted )) == null ? '' : __t) +
'">\n                            <label>' +
((__t = ( wellResToDisplay.quantificationFormatted )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="noWrapHidden col-w-all f-l d-f col-w-32 p-l-5">\n                            ';
 _.each(wellResResults, function(result) { ;
__p += '\n                            ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'' +
((__t = ( result.resFormated )) == null ? '' : __t) +
'\n                            ';
 }); ;
__p += '\n                        </div>\n                    </div>\n                </div>\n                <div class="row">\n                    <div class="col-xs-12">\n                        <h1></h1>\n                    </div>\n                </div>\n                <div class="col-xs-12">\n                    <div class="col-xs-12 popupGridContainer p-l-0 p-r-0">\n                        <div class="col-xs-12" style="padding-bottom: 15px;">\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = ( _.i18n('well.runName')
                                )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( _.i18n('well.pos')
                                )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-12 p-l-5"><label>' +
((__t = ( _.i18n('well.smpId')
                                )) == null ? '' : __t) +
'</label></div>\n                            ';
 if(notDissolved.length < 1){ ;
__p += '\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = ( _.i18n('well.runName')
                                )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( _.i18n('well.pos')
                                )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-46 p-l-5"><label>' +
((__t = ( _.i18n('well.results')
                                )) == null ? '' : __t) +
'</label></div>\n                            ';
 } else { ;
__p += '\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-67 p-l-5"></div>\n                            ';
 } ;
__p += '\n                        </div>\n                        ';
 _.each(wells, function(well) { ;
__p += '\n                        <div class="col-xs-12">\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = (
                                well.wellPooled.runName
                                )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( well.wellPooled.pos
                                )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-12 p-l-5"><label>' +
((__t = ( well.smpId.name
                                )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = ( well.runName
                                )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-2 p-l-5">\n                                <div class="dissolved-popup-resultIcon"\n                                     style="' +
((__t = ( (well.wellResColorFormatted? ('background-color:'+ well.wellResColorFormatted + ';'):'') )) == null ? '' : __t) +
'"></div>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-2 p-l-5"><label>' +
((__t = (
                                well.wellResToDisplay.result.code )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-10 p-l-5"\n                                 title="' +
((__t = ( wellResToDisplay.quantificationFormatted )) == null ? '' : __t) +
'">\n                                <label>' +
((__t = ( well.wellResToDisplay.quantificationFormatted )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-32 p-l-5">\n                                ';
 _.each(well.results, function(result) { ;
__p += '\n                                ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'' +
((__t = ( result.resFormated )) == null ? '' : __t) +
'\n                                ';
 }); ;
__p += '\n                            </div>\n                        </div>\n                        <div class="col-xs-12">\n                            <div class="col-xs-12" style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;">\n                            </div>\n                        </div>\n                        ';
 }); ;
__p += '\n                        ';
 _.each(notDissolved, function(well) { ;
__p += '\n                        <div class="col-xs-12">\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-16 p-l-5"><label>' +
((__t = ( well.runName
                                )) == null ? '' : __t) +
'</label></div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-5 p-l-5"><label>' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-12 p-l-5"><label>' +
((__t = ( well.smpId.name
                                )) == null ? '' : __t) +
'</label>\n                            </div>\n                            <div class="noWrapHidden col-w-all f-l d-f col-w-67 p-l-5"><label></label></div>\n                        </div>\n                        <div class="col-xs-12">\n                            <div class="col-xs-12" style="border-bottom: 1px solid #ccc; padding: 2px 0 0 5px;">\n                            </div>\n                        </div>\n                        ';
 }); ;
__p += '\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="pull-right p-r-15">\n                    <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

