
define('template!standardCurveAssayConfigurationResultContentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="container-fluid m-b-10">\n    <div class="col-xs-4 p-r-0 p-l-0 form-horizontal" style="display: flex; justify-content: center;">\n        <div class="rounded p-2 col-xs-10">\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('run.name')
                    )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( pcrRun.code )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('run.period')
                    )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( pcrRun.period )) == null ? '' : __t) +
'</span>\n            </div>\n        </div>\n    </div>\n    <div class="col-xs-4 p-r-0 p-l-0 form-horizontal" style="display: flex; justify-content: center;">\n        <div class="rounded p-2 col-xs-10">\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('assay')
                    )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( assayCode )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('well.target')
                    )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( targetCode )) == null ? '' : __t) +
'</span>\n            </div>\n        </div>\n    </div>\n\n    <div class="col-xs-4 p-r-0 p-l-0 form-horizontal" style="display: flex; justify-content: center;">\n        <div class="rounded p-2 col-xs-10">\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('standardCurve.assayConfigurationResult.value.slope')
                    )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( slope )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('standardCurve.assayConfigurationResult.value.yIntercept') )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( intercept )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="form-group m-0">\n                <label class="col-xs-4 t-a-r">' +
((__t = (
                    _.i18n('standardCurve.assayConfigurationResult.value.efficiency') )) == null ? '' : __t) +
'</label>\n                <span class="col-xs-8">' +
((__t = ( efficiency )) == null ? '' : __t) +
'</span>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="container-fluid">\n    <div class="col-xs-6">\n        <ul class="nav nav-tabs">\n            ';
 if(displayMelt) { ;
__p += '\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'meltCurvesRaw' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="meltCurvesRaw">\n                <a data-toggle="tab" href="#meltCurvesRaw">MC-Raw</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'meltCurves' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="meltCurves">\n                <a data-toggle="tab" href="#meltCurves">MC</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'meltCurves1' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="meltCurves1">\n                <a data-toggle="tab" href="#meltCurves1">MC-D1</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'meltCurves2' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="meltCurves2">\n                <a data-toggle="tab" href="#meltCurves2">MC-D2</a>\n            </li>\n            ';
 } ;
__p += '\n            ';
 if(displayAmpl) { ;
__p += '\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'amplCurvesRaw' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="amplCurvesRaw">\n                <a data-toggle="tab" href="#amplCurvesRaw">AC-Raw</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'amplCurves' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="amplCurves">\n                <a data-toggle="tab" href="#amplCurves">AC</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'amplCurves1' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="amplCurves1">\n                <a data-toggle="tab" href="#amplCurves1">AC-D1</a>\n            </li>\n            <li class="nav-tab curve ' +
((__t = ( currentGraph === 'amplCurves2' ? 'active': '' )) == null ? '' : __t) +
'"\n                data-graph="amplCurves2">\n                <a data-toggle="tab" href="#amplCurves2">AC-D2</a>\n            </li>\n            ';
 } ;
__p += '\n            ';
 if(displayCC) { ;
__p += '\n            <li class="nav-tab">\n                <div class="css-switch-container" style="\n								display: flex;\n								top: 9px;\n								position: relative;\n								height: 21px;">\n                    <div class="css-switch-label visible" style="\n									padding-left: 20px;\n									width: auto;\n									font-size: 16px;\n									font-weight: 100;\n									color: white;\n									top: 1px;\n									position: relative;">' +
((__t = ( _.i18n('assayConfiguration.colorCompensation') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="css-switch-button" style="width: auto; padding-left: 5px;">\n                        <label class="switch">\n                            <input type="checkbox" class="chart-CC-change" checked>\n                            <span class="slider classicSliderColors round"></span>\n                        </label>\n                    </div>\n                </div>\n            </li>\n            ';
 } ;
__p += '\n            <li class="nav-tab">\n                <div class="css-switch-container" style="\n								display: flex;\n								top: 9px;\n								position: relative;\n								height: 21px;">\n                    <div class="css-switch-label visible" style="\n									padding-left: 20px;\n									width: auto;\n									font-size: 16px;\n									font-weight: 100;\n									color: white;\n									top: 1px;\n									position: relative;">' +
((__t = ( _.i18n('chartview.smooth') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="css-switch-button" style="width: auto; padding-left: 5px;">\n                        <label class="switch">\n                            <input type="checkbox" class="rangeSmooth" ' +
((__t = ( rangeSmooth ?
                            'checked="checked"' : '' )) == null ? '' : __t) +
'>\n                            <span class="slider classicSliderColors round"></span>\n                        </label>\n                    </div>\n                </div>\n            </li>\n        </ul>\n        <div class="row m-b-0 js-graph-curves"></div>\n    </div>\n    <div class="col-xs-6" style="display: flex; flex-direction: column;">\n        <div class="col-xs-12 form-horizontal"\n             style="display: flex;justify-content: center;height: 28px;align-items: baseline;">\n            <label class="col-xs-4 t-a-r">' +
((__t = (
                _.i18n('standardCurve.assayConfigurationResult.value.status')
                )) == null ? '' : __t) +
'</label>\n            <select class="js-status js-info-input" data-field-name="status">\n                <option value="UNKNOWN" style="background-color: lightyellow">' +
((__t = ( _.i18n('standardCurve.assayConfigurationResult.value.status.UNKNOWN')
                    )) == null ? '' : __t) +
'\n                </option>\n                <option value="VALID" style="background-color: darkseagreen">' +
((__t = ( _.i18n('standardCurve.assayConfigurationResult.value.status.VALID')
                    )) == null ? '' : __t) +
'\n                </option>\n                <option value="INVALID" style="background-color: lightcoral">' +
((__t = (
                    _.i18n('standardCurve.assayConfigurationResult.value.status.INVALID') )) == null ? '' : __t) +
'\n                </option>\n            </select>\n        </div>\n        <div class="js-graph-standardCurve"></div>\n    </div>\n</div>';

}
return __p
};});

