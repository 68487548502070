
define('template!prepWellPcrKitLotValidationCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="t-a-l">' +
((__t = ( _.i18n('prep.run.ic.kits') )) == null ? '' : __t) +
'</label>\n<div class="col-w-all col-w-100 d-f">\n    <div class="col-w-all col-w-49 noWrapHidden pcrFromPrepTitleDiv">\n        ' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden pcrFromPrepTitleDiv"></div>\n    <div class="col-w-all col-w-49 noWrapHidden pcrFromPrepTitleDiv">\n        ' +
((__t = ( _.i18n('kitLot') )) == null ? '' : __t) +
'\n    </div>\n</div>';

}
return __p
};});

