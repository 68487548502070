define('runPcrKitLotCollectionView',[
    'module',
    'dialogCollectionView',
    'template!runPcrKitLotCollectionView',
    'runPcrKitLotItemView'
], function (
    module,
    DialogCollectionView,
    Tpl,
    PcrKitLotItemView
) {
    'use strict';

    return DialogCollectionView.extend({
        template: Tpl,
        childView: PcrKitLotItemView,
        childViewContainer: 'tbody',
        tagName: 'table',
        className: 'table table-striped table-hover table-condensed'
    });
});
