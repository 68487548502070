/**
 * Created by OBL on 14/01/2016.
 */
define('adminExportStatusController',[
    'module',
    'underscore',
    'exportCreateEditLayoutView',
    'bootbox'
], function (
    module,
    _,
    View,
    BootBox
) {
    'use strict';

    module.exports = {
        /**
         *
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new View({
                model: param.model
            });
            var LisRecordService = require('services/caccounts/lisRecords');
            createEditView.show({
                title: _.i18n(LisRecordService.getName() + '.new'),
                className: 'baseTableEditAddPopup ' + LisRecordService.getName() + 'EditAddPopup',
                service: LisRecordService
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        },
        showResults: function (param) {
            BootBox.dialog({
                title: _.i18n('exportStatus.title'),
                message: '<pre>' + JSON.stringify(param.model.get('result'), null, 2) + '</pre>'
            });
        },
        showQuery: function (param) {
            BootBox.dialog({
                title: _.i18n('exportStatus.title'),
                message: '<pre>' + param.model.get('query') + '</pre>'
            });
        }
    };
});

