define('adminAttachmentsListView',[
    'module',
    'underscore',
    'jquery',
    'app',
    'jqgridView',
    'template!adminAttachmentsListView',
    'adminAttachmentsCreateEditView'
], function (
    module,
    _,
    $,
    App,
    JqGridView,
    viewTpl,
    CreateEditView
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: viewTpl,
        filtersName: 'attachmentsListFilters',
        triggers: {},

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list-attachment',
                jqGridPager: '#jq-grid-pager-list',
                deleteAttachment: '.delete-link-action',
                exportAttachment: '.export-link-action',
                selectRow: '.select-link-action',
                gridUpperMenu: '.js-grid-upper-menu',
                selectAllRows: '.js-all-rows-selector',
                exportSelected: '.js-all-rows-export',
                tableHeader: 'table.ui-jqgrid-htable',
                menuButton: '#menuAddListButton',
                menuAddList: '#menuAddList',
                menuEntry: '.menuEntry',
                showViewNewAttachment: '.js-showViewNewAttachment',
                attachment: '.attachment-link-action'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({
                'mouseenter @ui.tableHeader': 'showGridUpperMenu',
                'mouseleave @ui.tableHeader': 'HideGridUpperMenu',
                'mouseenter @ui.gridUpperMenu': 'showGridUpperMenu',
                'mouseleave @ui.gridUpperMenu': 'HideGridUpperMenu',
                'click @ui.selectAllRows': 'onCheckAllRows',
                'click @ui.exportSelected': 'onExportElementsList',
                'click @ui.exportAttachment': 'onExportElement',
                'click @ui.selectRow': 'onCheckOneRow',
                'click @ui.deleteAttachment': 'onDelete',
                'click @ui.showViewNewAttachment': 'showViewNewAttachment',
                'click @ui.menuButton': 'showMenu',
                'mouseleave @ui.menuAddList': 'hideMenu',
                'click @ui.menuEntry': 'hideMenu',
                'click @ui.attachment': 'onAttachment',
                'click .js-back-button': 'onHistoryBack',
                'change .js-import': 'onImport'
            }, this.gridEvents);
        },

        showViewNewAttachment: function () {
            var AttachmentService = require('services/caccounts/attachment');
            var view = new CreateEditView({
                'model': AttachmentService.getModel({
                    'context': this.options.context,
                    'contextSecId': this.options.contextSecId
                }),
                'newItem': true
            });
            view.listenTo(view, 'reloadGrid', _.bind(this.reloadGrid, this));
            view.show({
                title: _.i18n('menu.attachments.add'),
                className: 'baseTableEditAddPopup'
            });
        },

        showMenu: function () {
            this.showBaseTableAddMenu($(this.ui.menuAddList));
        },

        hideMenu: function () {
            this.hideBaseTableAddMenu($(this.ui.menuAddList));
        },

        onRender: function () {
            this.hideMenu();
        },

        reloadGrid: function () {
            this.ui.jqGrid.trigger('reloadGrid');
        },

        serializeData: function () {
            this.checkedRowsList = [];
            this.exportCall = 'attachment:export';
            var templateData = {
                data: this.options.data,
                canAdd: this.options.canAdd
            };
            return templateData;
        },
        onHistoryBack: function () {
            this.jqGridHistoryBack();
        },

        onDelete: function (event) {
            var AttachmentService = require('services/caccounts/attachment');
            AttachmentService.deleteEntity($(event.currentTarget).find('span').attr('data-row-id'));
            this.resetCheckedRowList();
        },

        onAttachment: function () { // todo--------------------------------
            this.resetCheckedRowList();
        },

        resetCheckedRowList: function () {
            this.checkedRowsList = [];
            this.resetAllRowSelector();
        },

        showGridUpperMenu: function () {
            this.ui.gridUpperMenu.removeClass('hidden');
        },

        HideGridUpperMenu: function () {
            this.ui.gridUpperMenu.addClass('hidden');
        },

        onExportElementsList: function () {
            if (this.checkedRowsList && this.checkedRowsList.length > 0) {
                this.exportElementsList(this.checkedRowsList, this.exportCall);
            }
        },

        onExportElement: function (event) {
            this.exportElement(event, this.exportCall);
        },

        onCheckAllRows: function (e) {
            this.checkedRowsList = this.checkAllRows(e);
        },

        onCheckOneRow: function (e) {
            this.checkedRowsList = this.checkOneRow(e, this.checkedRowsList);
        },

        gridOptions: function (data) {
            var canAdd = this.options.canAdd;
            var that = this;
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: ' ',
                        name: 'select',
                        sortable: false,
                        search: false,
                        classes: 'select-link-action ignore-row-click',
                        formatter: this.rowSelectorFormatter,
                        fixed: true,
                        width: 25
                    }, {
                        label: ' ',
                        name: 'export',
                        sortable: false,
                        search: false,
                        classes: 'export-link-action ignore-row-click',
                        formatter: this.exportActionIconFormatter,
                        fixed: true,
                        width: 20
                    }, {
                        label: ' ',
                        name: 'delete',
                        sortable: false,
                        search: false,
                        classes: 'delete-link-action ignore-row-click',
                        formatter: this.deleteActionIconFormatter,
                        fixed: true,
                        width: 22
                    }, {
                        label: ' ',
                        name: 'attachment',
                        sortable: false,
                        search: false,
                        classes: 'attachment-link-action ignore-row-click',
                        formatter: this.attachmentActionIconFormatter,
                        fixed: true,
                        width: 20
                    }, {
                        label: ' ',
                        name: 'copy',
                        sortable: false,
                        search: false,
                        classes: 'copy-link-action',
                        formatter: this.copyActionIconFormatter,
                        fixed: true,
                        width: 22
                    }, {
                        label: _.i18n('common.name'),
                        name: 'name',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'name',
                        searchoptions: {size: 10},
                        width: 45
                    }, {
                        label: _.i18n('common.fileName'),
                        name: 'fileName',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'fileName',
                        searchoptions: {size: 10},
                        width: 45
                    }, {
                        label: _.i18n('common.language'),
                        name: 'language',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'language',
                        searchoptions: {size: 10},
                        width: 10
                    }, {
                        label: _.i18n('attachment.context'),
                        name: 'context',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'context',
                        searchoptions: {size: 10},
                        width: 45
                    }, {
                        label: _.i18n('attachment.contextSecId'),
                        name: 'contextSecId',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'contextSecId',
                        searchoptions: {size: 10},
                        width: 45
                    }, {
                        label: _.i18n('common.description'),
                        name: 'comment',
                        classes: 'attachment-link',
                        formatter: this.defaultFormatter,
                        search: true,
                        index: 'comment'
                    }
                ],
                sortname: 'name',

                onSelectRow: function (rowId, bool, e) {
                    if (canAdd && !$(e.target).parent().hasClass('ignore-row-click') && e.type === 'click') {
                        var AttachmentService = require('services/caccounts/attachment');
                        var model = AttachmentService.getModel({secId: rowId});
                        model.fetch().done(function () {
                            if ($(e.target).hasClass('cell-copy')) {
                                model.set('secId', null);
                                model.set('code', 'Copy-' + model.get('code'));
                            }
                            var createEditView = new CreateEditView({'model': model}, false);
                            createEditView.listenTo(createEditView, 'reloadGrid', _.bind(that.reloadGrid, that));
                            createEditView.show({
                                title: _.i18n('menu.admin.attachments'),
                                className: 'baseTableEditAddPopup'
                            });
                        });
                    }
                }
            };
            options = _.defaults(options, this.gridInitOptions(data));
            return options;
        },

        onImport: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                var AttachmentService = require('services/caccounts/attachment');
                var model = AttachmentService.getModel({
                        'context': this.options.context,
                        'contextSecId': this.options.contextSecId
                    }
                );
                AttachmentService.createWithFile(model, e.target.files[0])
                    .done(_.bind(function () {
                        this.ui.jqGrid.trigger('reloadGrid');
                    }, this));
            }
        }
    });
});
