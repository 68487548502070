
define('template!kitLotsActionPopupView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-value status"></div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

