define('assayImportFromResultView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!assayImportFromResultView',
    'underscore',
    'jquery',
    'autocompleteView',
    'fileExplorerController'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    _,
    $,
    AutocompleteView,
    FileExplorerController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            importFileFromCentralLib: '.importFileFromCentralLib',
            selectFileFromComputer: '.js-select-file-from-computer'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click @ui.importFileFromCentralLib': 'onSelectFileFromCentralLib',
            'change @ui.selectFileFromComputer': 'onSelectFileFromComputer',
            'click .js-blank': 'onBlankClick'
        },

        regions: {
            selectCycler: '.js-select-cycler-region'
        },

        fieldsToValidate: [
            {name: 'refCycler', type: 'required'}
        ],

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('refCycler', null);
            this.model.set('accept', '.csv');
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.remoteAvailable = this.options.settingImportResult && this.options.settingImportResult.params && this.options.settingImportResult.params.split(';')[0];
            return templateData;
        },

        onRender: function () {
            this.showselectCyclerView();
        },

        showselectCyclerView: function () {
            if (this.selectCyclerView) {
                this.selectCyclerView.destroy();
            }

            var CyclerPublicService = require('services/caccounts/cyclerpublics');
            CyclerPublicService.findDefault().done(_.bind(function (data) {
                this.model.set('refCycler', data);
                if (data) {
                    this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
                } else {
                    this.$el.find('label#importResultFromLocal').attr('for', 'blank');
                }
                this.displaySelectCycler();
            }, this));
        },
        displaySelectCycler: function () {
            var CyclerPublicService = require('services/caccounts/cyclerpublics');
            this.selectCyclerView = new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerPublicService.getAutocompleteParam({
                    modelProperty: 'refCycler',
                    callBackOnChange: this.onCyclerChange
                }))
            );
            this.getRegion('selectCycler').show(this.selectCyclerView);
        },

        onCyclerChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.$el.find('label#importResultFromLocal').attr('for', 'run-xml-input');
            } else {
                this.$el.find('label#importResultFromLocal').attr('for', 'blank');
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        onBlankClick: function () {
            this.validate(this.fieldsToValidate);
        },

        onSelectFileFromCentralLib: function () {
            if (this.validate(this.fieldsToValidate)) {
                FileExplorerController.show(this.options.settingImportResult.secId, true, _.bind(this.fileUpload, this));
            }
        },

        onSelectFileFromComputer: function (e) {
            if (e && e.target && e.target.files) {
                this.fileUpload(e.target.files);
            }
        },

        fileUpload: function (files) {
            $('.js-global-loader').show();
            if (files && this.validate(this.fieldsToValidate)) {
                $('.js-global-loader').show();
                var cyclerSecId = this.model.get('refCycler').secId;
                this.options.assay.createRunFromResult(files, cyclerSecId)
                    .done(_.bind(function (assay) {
                        this.options.assay.showDetails({model: assay});
                        this.hide();
                    }, this))
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        }
    });
});

