define('tabs',[
    'module',
    'backbone.marionette',
    'tabsCollection',
    'tabsContentCollection',
    'underscore'
], function (
    module,
    Marionette,
    TabCollection,
    TabContentCollection,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-tab-menu"></div><div class="js-tab-content container-fluid" style="padding-left: 0; padding-right: 0; padding-top: 5px;"></div>'),
        regions: {
            tabContent: '.js-tab-content',
            tabMenu: '.js-tab-menu'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.tabContentCollection = new TabContentCollection({collection: this.collection});
            this.getRegion('tabContent').show(this.tabContentCollection);
            this.tabMenu = new TabCollection({collection: this.collection, tabContentCollection: this.tabContentCollection});
            this.getRegion('tabMenu').show(this.tabMenu);
            var view = this.tabMenu.children.first();
            view.trigger('click', view);
        }
    });
});
