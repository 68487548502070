
define('template!prepWellPcrKitLotItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-all col-w-49 noWrapHidden"\n     title="' +
((__t = ( code )) == null ? '' : __t) +
'">\n    ' +
((__t = ( code )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-49 noWrapHidden"\n     title="' +
((__t = ( kitLot )) == null ? '' : __t) +
'">\n    ' +
((__t = ( kitLot )) == null ? '' : __t) +
'\n</div>';

}
return __p
};});

