define('services/caccounts/assayVersion',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayVersion'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.AssayVersion,
                collection: app.ns.AssayVersionCollection
            };
        },
        getName: function () {
            return 'assayversion';
        },
        getDynamicJson: function () {
            return 'AssayVersionJson';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code']};
        }
    }, Dynamic);
});

