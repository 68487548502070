
define('template!assayReagentMMXItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-5"><span class="mdi mdi-delete delete-row js-icon clickable" style="line-height: 26px;"></span></div>\n<div class="col-w-43 js-kit"></div>\n<div class="col-w-15 js-reagent"></div>\n<div class="col-w-15  ">\n    <input autocomplete="off"\n           class="form-control js-info-input-mmx inputBackground t-a-r volumeByTest"\n           data-field-name="volumeByTest" placeholder=""\n           type="number" step="0.01" min="0"\n           value="' +
((__t = ( volumeByTest )) == null ? '' : __t) +
'">\n</div>\n<div class="col-w-15 p-r-2 p-l-2">\n    <input autocomplete="off"\n           class="form-control js-info-input-mmx inputBackground t-a-r reagent"\n           data-field-name="remark" placeholder=""\n           type="text"\n           value="' +
((__t = ( remark )) == null ? '' : __t) +
'">\n</div>\n<div class="col-w-7 p-r-2 p-l-2">\n    <input autocomplete="off"\n           class="form-control js-info-input-mmx inputBackground sequence"\n           data-field-name="sequence" placeholder=""\n           type="text" maxlength="3"\n           value="' +
((__t = ( sequence )) == null ? '' : __t) +
'">\n</div>';

}
return __p
};});

