define('runPcrValidationTargetsView',[
    'module',
    'backbone.marionette',
    'underscore',
    'runPcrValidationTargetsResultCollection'
], function (
    module,
    Marionette,
    _,
    ItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'targets content',
        childView: ItemView,
        initialize: function () {
            this.collection = this.model.get('resultsByAssay');
        },
        childViewOptions: function (model) {
            return {
                collection: model.get('results'),
                validationModel: this.model,
                assays: this.options.assays
            };
        }
    });
});
