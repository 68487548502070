
define('template!runPcrKitLotCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<thead>\n<tr>\n    <th>\n        ' +
((__t = ( _.i18n('reagent.assay.name') )) == null ? '' : __t) +
'\n    </th>\n    <th>\n        ' +
((__t = ( _.i18n('pcrReagents.type') )) == null ? '' : __t) +
'\n    </th>\n    <th>\n        ' +
((__t = ( _.i18n('common.refKit') )) == null ? '' : __t) +
'\n    </th>\n    <th>\n        ' +
((__t = ( _.i18n('kitLot.lotId') )) == null ? '' : __t) +
'\n    </th>\n    <th>\n        ' +
((__t = ( _.i18n('needed.wells.number') )) == null ? '' : __t) +
'\n    </th>\n    <th>\n        ' +
((__t = ( _.i18n('assay.volume.test') )) == null ? '' : __t) +
'\n    </th>\n</tr>\n</thead>\n<tbody></tbody>';

}
return __p
};});

