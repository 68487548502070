define('rolePrivilegeView',[
    'module',
    'backbone.marionette',
    'rolePrivilegeItemView',
    'underscore',
    'settings',
    'rolesMixin'
], function (
    module,
    Marionette,
    child,
    _,
    Settings,
    RolesMixin
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: _.template('<label><%= name %><% if(superuser) { %><span class="mdi mdi-filter js-role clickable"></span><% } %></label><div class="privileges"></div>'),
        className: 'role',
        childView: child,
        childViewContainer: '.privileges',
        childViewOptions: function (model) {
            return {
                model: model,
                parentModel: this.options.parentModel,
                collection: model.get('privileges'),
                readOnly: this.options.readOnly,
                findDepends: this.options.findDepends
            };
        },
        serializeData: function () {
            var superUser = Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER);
            return {
                name: this.model.get('code'),
                superuser: superUser
            };
        },
        events: {
            'click .js-role': 'onRoleClick'
        },
        onRoleClick: function () {
            // open new tab with role url and parameter
            var url = '#superAdmin/routes?role=' + this.model.get('code');
            window.open(url, '_blank');
        }
    });
});

