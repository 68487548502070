
define('template!extractionMethodIcKitsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(displayIcList && displayIcList.length > 0) { ;
__p += '\n<div class="col-xs-12">\n    <div class="col-xs-3"></div>\n    <div class="col-xs-6 p-l-0 p-r-0 m-t-20">\n        <div class="col-xs-12 p-l-0 p-r-0" style="display: flex; text-align: left;">\n            <div class="col-xs-8 p-l-0 p-r-0 noWrapHidden">\n                ' +
((__t = ( _.i18n('pcrKitLot.code') )) == null ? '' : __t) +
'\n            </div>\n            <div class="col-xs-4 p-l-0 p-r-0 noWrapHidden">\n                ' +
((__t = ( _.i18n('pcrKitLot.quantity') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n\n        ';
 _.each(displayIcList, function (obj) { ;
__p += '\n        <div class="col-xs-12 p-l-0 p-r-0" style="display: flex; text-align: left;">\n            <div class="p-l-5 col-xs-8" style="border: 1px solid #333333; background-color: white;">\n                ' +
((__t = ( obj.code )) == null ? '' : __t) +
'\n            </div>\n            <div class="p-l-5 col-xs-4" style="border: 1px solid #333333; background-color: white;">\n                ' +
((__t = ( obj.value )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n    <div class="col-xs-3"></div>\n</div>\n';
 } ;


}
return __p
};});

