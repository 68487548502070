
define('template!sampleCommentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="col-xs-12 p-l-0 p-r-0">\n                <div class="col-xs-12">\n                    <div class="row">\n                        <div class="col-xs-1">\n                            <label>' +
((__t = ( _.i18n('well') )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-4">\n                            <label>' +
((__t = ( _.i18n('sample') )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-7">\n                            <label>' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-1">\n                            <label>' +
((__t = ( pos )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-4">\n                            <label>' +
((__t = ( sample )) == null ? '' : __t) +
'</label>\n                        </div>\n                        <div class="col-xs-7">\n                            <label>' +
((__t = ( commentCombined )) == null ? '' : __t) +
'</label>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="col-xs-5 dataLabelContainer">\n                            <div class="form-group t-a-r" data-field-name="lisComment">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('sampleLisComment') )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-7 p-r-2">\n                            <textarea readonly\n                                      class="form-control floating-label js-comment-input js-info-input"\n                                      style="resize: vertical;"\n                                      placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                      title="' +
((__t = ( lisComment )) == null ? '' : __t) +
'" rows="1" wrap="on"\n                                      data-field-name="lisComment">' +
((__t = ( lisComment )) == null ? '' : __t) +
'</textarea>\n                        </div>\n                    </div>\n\n                    <div class="row">\n                        <div class="col-xs-5 dataLabelContainer">\n                            <div class="form-group t-a-r" data-field-name="comment">\n                                <label class="control-label dataLabel">' +
((__t = ( _.i18n('sampleComment') )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-xs-7 p-r-2">\n                            <textarea class="form-control floating-label js-comment-input js-info-input"\n                                      style="resize: vertical;"\n                                      placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                      title="' +
((__t = ( comment )) == null ? '' : __t) +
'" rows="1" wrap="on"\n                                      data-field-name="comment">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="cancelConfirmRow">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

