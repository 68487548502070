
define('template!kitLotsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <div class="kit">\n        <label>' +
((__t = ( _.i18n('kitLot.refKit') )) == null ? '' : __t) +
'</label>\n        ';
 if(!kitCode) { ;
__p += '\n        <div class="js-kit-region"></div>\n        ';
 } else { ;
__p += '\n        <div>' +
((__t = ( kitCode )) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="status">\n        <label>' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</label>\n        <div>' +
((__t = ( status )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="mdi mdi-target clickable" title="' +
((__t = ( _.i18n('kitLot.target') )) == null ? '' : __t) +
'" style="font-size: 25px; line-height: 25px;"></div>\n    <div class="kitLot">\n        <label for="kitLot">' +
((__t = ( _.i18n('kit.lotId') )) == null ? '' : __t) +
'</label>\n        <input id="kitLot" type="text" class="js-info-input form-control inputBackground" data-field-name="code" value="' +
((__t = ( code )) == null ? '' : __t) +
'"/>\n    </div>\n    <div class="expiration">\n        <label>' +
((__t = ( _.i18n('kit.expiration') )) == null ? '' : __t) +
'</label>\n        <div class="js-expiration"></div>\n    </div>\n</div>\n\n<div class="grid">\n    <div class="js-delivery"></div>\n    <div class="js-test"></div>\n    <div class="js-validated"></div>\n    <div class="js-validated-2"></div>\n    <div class="js-current"></div>\n    <div class="js-paused"></div>\n    <div class="js-exhausted"></div>\n</div>\n<div class="js-description"></div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

