define('entities/menuGroup',[
    'module',
    'backbone',
    'underscore'
], function (
    module,
    Backbone,
    _
) {
    'use strict';

    module.exports = Backbone.Model.extend({
        defaults: {
            name: '',
            type: '',
            items: null,
            quickActions: null
        },
        findItemsBy: function (nameCode, itemInclude, quickActionInclude) {
            itemInclude = itemInclude === undefined ? true : itemInclude;
            quickActionInclude = quickActionInclude === undefined ? true : quickActionInclude;
            var items = [];
            if (itemInclude) {
                items = this.get('items').filter(function (item) {
                    return item.get('nameCode') === nameCode;
                });
            }
            var quickAccessItems = [];
            if (quickActionInclude) {
                quickAccessItems = this.get('quickActions').filter(function (item) {
                    return item.get('nameCode') === nameCode;
                });
            }
            if (!_.isEmpty(quickAccessItems)) {
                items = items.concat(quickAccessItems);
            }
            var quickAccessItemSub = this.get('quickActions').chain().filter(function (item) {
                return item.get('items');
            }).map(function (item) {
                return item.get('items').models;
            }).flatten().filter(function (item) {
                return item.get('nameCode') === nameCode;
            }).value();
            if (!_.isEmpty(quickAccessItemSub)) {
                items = items.concat(quickAccessItemSub);
            }

            return items;
        }
    });
});

