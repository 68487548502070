
define('template!runPcrHeaderPlateRightView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-sample-menu"></div>\n<div class="js-validation-menu"></div>\n<div class="js-repeat-menu"></div>\n<div class="js-other-menu"></div>\n<div class="js-result-menu"></div>\n<div class="js-comment-menu"></div>\n\n<!-- reset filters -->\n<div class="css-grayed-out-header clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n     title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n</div>';

}
return __p
};});

