define('services/caccounts/setting/defaultCheckbox',[
    'entities/ns',
    'module',
    'entities/caccounts/setting/defaultCheckbox'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        getModel: function () {
            return app.ns.defaultCheckbox.findOrCreate({});
        }
    };
});
