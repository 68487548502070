
define('template!kitLotPcrTargetReagentAssayView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-22 p-l-2 p-r-2">\n    <input type="text" class="form-control disabled" value="' +
((__t = ( assay )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off"\n           disabled="disabled"/>\n</div>\n<div class="col-w-10 p-l-2 p-r-2">\n    <input type="text" class="form-control disabled" value="' +
((__t = ( reagent )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off"\n           disabled="disabled"/>\n</div>\n<div class="col-w-29 p-l-2 p-r-2">\n    <input type="text" class="form-control disabled" value="' +
((__t = ( target )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off"\n           disabled="disabled"/>\n</div>\n<div class="col-w-10 js-result p-l-2 p-r-2"></div>\n<div class="col-w-8 p-l-2 p-r-2">\n    <input type="text" class="form-control js-info-input-child t-a-r" value="' +
((__t = ( val )) == null ? '' : __t) +
'" data-placement="top" autocomplete="off"\n           data-field-name="val" ' +
((__t = ( disabled )) == null ? '' : __t) +
'/>\n</div>\n<div class="col-w-8 p-l-2 p-r-2">\n    <input type="text" class="form-control js-info-input-child t-a-r" value="' +
((__t = ( deltaWarning )) == null ? '' : __t) +
'" data-placement="top"\n           autocomplete="off"\n           data-field-name="deltaWarning" ' +
((__t = ( disabled )) == null ? '' : __t) +
'/>\n</div>\n<div class="col-w-8 p-l-2 p-r-2">\n    <input type="text" class="form-control js-info-input-child t-a-r" value="' +
((__t = ( deltaError )) == null ? '' : __t) +
'" data-placement="top"\n           autocomplete="off"\n           data-field-name="deltaError" ' +
((__t = ( disabled )) == null ? '' : __t) +
'/>\n</div>\n<div class="col-w-5 p-l-2 p-r-2">\n    ';
 if (enableQc) { ;
__p += '\n    <span class="mdi mdi-chart-line clickable js-show-qc-graph" data-placement="top" data-toggle="tooltip"\n          title="' +
((__t = ( _.i18n('lot.showQCGraph') )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

