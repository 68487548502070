/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemRadio',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<input type="radio" class="js-info-input js-radio" style="width: 20px;"/>'),
        tagName: 'div',
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            if (attr.style) {
                attr.style = attr.style + 'display: flex;';
            } else {
                attr.style = 'display: flex;';
            }
            return attr;
        },
        ui: {
            'input': '.js-radio'
        },
        events: {
            'change @ui.input': 'onRadioChange',
            'click @ui.input': 'onClick'
        },
        onRadioChange: function (e) {
            this.onChange(this.options.field.get('field'), e.currentTarget.checked);
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            _.each(this.el.attributes, _.bind(function (attr) {
                if (attr.name !== 'class') {
                    this.ui.input.attr(attr.name, attr.value);
                }
            }, this));
            this.ui.input.prop('checked', !!this.model.get(this.options.field.get('field')));
        },
        onClick: function (e) {
            this.model.collection.each(function (model) {
                if (model !== this.model) {
                    model.set(this.options.field.get('field'), false);
                }
            }, this);
            if (e.currentTarget.checked && this.model.get(this.options.field.get('field')) === e.currentTarget.checked) {
                this.ui.input.prop('checked', false);
                this.onChange(this.options.field.get('field'), false);
            }
        }
    });
});
