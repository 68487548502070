define('assayResultCollectionVersion',[
    'module',
    'backbone',
    'backbone.marionette',
    'assayResultVersion'
], function (
    module,
    Backbone,
    Marionette,
    AssayResultVersion
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: AssayResultVersion,
        childViewOptions: function () {
            return {
                cyclerPublicSecId: this.options.cyclerPublicSecId
            };
        }
    });
});
