/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemCodeListView',[
    'module',
    'underscore',
    'dynamicListItemPropertyView',
    'autocompleteView'

], function (
    module,
    _,
    ItemView,
    AutocompleteView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<div class="autocomplete"></div>'),
        tagName: 'div',
        regions: {
            autocomplete: '.autocomplete'
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            var autocompleteParam;
            if (this.options.field.get('param').type === 'CODELIST') {
                var CodeListService = require('services/admin/codelist');
                if (this.options.field.get('param').code) {
                    autocompleteParam = CodeListService.getAutocompleteParamForCode({
                        modelProperty: this.options.field.get('field'),
                        callBackOnChange: _.bind(this.onChangeAutoComplete, this, this.options.field),
                        model: this.model,
                        readOnly: this.options.field.get('param').display ? this.options.field.get('param').display.readOnly : false,
                        codeListCode: this.options.field.get('param').code
                    }, this.options.field.get('param').context, this.options.field.get('dependsOn'));
                } else {
                    autocompleteParam = CodeListService.getAutocompleteParam({
                        modelProperty: this.options.field.get('field'),
                        callBackOnChange: _.bind(this.onChangeAutoComplete, this, this.options.field),
                        model: this.model,
                        readOnly: this.options.field.get('param').display ? this.options.field.get('param').display.readOnly : false
                    }, this.options.field.get('param').context, this.options.field.get('dependsOn'));
                }
            } else if (this.options.field.get('param').type === 'USERCODELIST') {
                var UserCodeListService = require('services/caccounts/userCodelist');
                if (this.options.field.get('param').code) {
                    autocompleteParam = UserCodeListService.getAutocompleteParamForCode({
                        modelProperty: this.options.field.get('field'),
                        callBackOnChange: _.bind(this.onChangeAutoComplete, this, this.options.field),
                        model: this.model,
                        readOnly: this.options.field.get('param').display ? this.options.field.get('param').display.readOnly : false,
                        codeListCode: this.options.field.get('param').code
                    }, this.options.field.get('param').context, this.options.field.get('dependsOn'));
                } else {
                    autocompleteParam = UserCodeListService.getAutocompleteParam({
                        modelProperty: this.options.field.get('field'),
                        callBackOnChange: _.bind(this.onChangeAutoComplete, this, this.options.field),
                        model: this.model,
                        readOnly: this.options.field.get('param').display ? this.options.field.get('param').display.readOnly : false
                    }, this.options.field.get('param').context, this.options.field.get('dependsOn'));
                }
            }
            this.getRegion('autocomplete').show(new AutocompleteView(this._getAutocompleteOptionObject(autocompleteParam)));
        },

        onChangeAutoComplete: function (field, fieldName, model) {
            if (field && field.get('param') && field.get('param').onChange) {
                field.get('param').onChange(fieldName, model, this.model);
            } else {
                if (model) {
                    model.unset('autocompleteValue', {silent: true});
                    if (this.model.get(fieldName) !== model.get('code')) {
                        this.onChange(fieldName, model.get('code'));
                    }
                } else {
                    this.onChange(fieldName, null);
                }
            }
        }
    });
});
