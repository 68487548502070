define('samplePrepWellPcrWellView',[
    'module',
    'backbone.marionette',
    'template!samplePrepWellPcrWellView',
    'wellUtils',
    'underscore',
    'linkNewTabView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    WellTargetTpl,
    WellUtils,
    _,
    LinkNewTabView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: WellTargetTpl,

        modelEvents: {
            'sync': 'renderSafe'
        },

        regions: {
            pcrRun: '.js-pcrrun'
        },

        className: 'col-w-all col-w-100 d-f bottom-border-cell-light',

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        onRender: function () {
            var params = {
                model: this.model,
                target: this.$el,
                placement: 'top'
            };

            WellUtils.showPcrWellPopover(params);
            var PcrRunService = require('services/caccounts/pcrruns');
            this.getRegion('pcrRun').show(new LinkNewTabView(PcrRunService.parameterForLink(this.model.get('run'), PcrRunService)));
        },

        serializeData: function () {
            var templateData = {};
            templateData.secId = this.model.get('secId');
            templateData.runName = this.model.get('run').get('name');
            templateData.runSecId = this.model.get('run').get('secId');
            templateData.pos = this.model.get('pos');
            templateData.assay = this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '-';
            templateData.results = [];
            this.model.get('results').each(_.bind(function (result) {
                var resultForTemplateData = {
                    targetCode: result.get('targetCode'),
                    cts: result.get('cts'),
                    resCode: result.get('resCode')
                };
                resultForTemplateData.iconHtml = '';
                if (result.get('refAssayResult')) {
                    resultForTemplateData.iconHtml = PcrWellResultController.generateContent(result, 'WellCurve');
                }
                templateData.results.push(resultForTemplateData);
            }, this));
            return templateData;
        }
    });
});

