define('dynamicCreateEditTabCollection',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'dynamicCreateEditTabItem',
    'template!dynamicCreateEditTabCollectionTpl'
], function (
    module,
    Backbone,
    _,
    Marionette,
    ItemView,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({

        template: Tpl,
        childViewContainer: '.collection',
        childView: ItemView,
        childViewEvents: {
            'item:changeTab': function (model) {
                this.currentTabId = model.cid;
                this.render();
                this.trigger('item:changeTab', this.currentTabId);
            }
        },
        childViewOptions: function () {
            return {
                currentTabId: this.currentTabId
            };
        },

        initialize: function () {
            this.currentTabId = this.options.currentTabId;
        }
    });
});

