define('entities/caccounts/pcrwells',[
    'entities/ns',
    'backbone',
    'settings',
    'colorUtils',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrruns',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/samples',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/prepwells',
    'entities/caccounts/curves',
    'entities/caccounts/kitlotpcr'
], function (
    app,
    Backbone,
    Settings,
    ColorUtils,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.PcrWell = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrwells',
        fetch: function () {
            this.url = Settings.url('rest/v2/wells/pcr/' + this.get('secId'), {details: true});
            return Backbone.RelationalModel.prototype.fetch.call(this);
        },
        initialize: function () {
            this.set('tags', []);
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection',
            reverseRelation: {
                key: 'refPcrWell'
            }
        }, {
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'run',
            relatedModel: 'PcrRun',
            reverseRelation: {
                includeInJSON: false
            },
            includeInJSON: ['secId', 'pos', 'run.name']
        }, {
            type: Backbone.HasOne,
            key: 'refPrepWell',
            relatedModel: 'PrepWell',
            includeInJSON: ['secId']
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'assayVersion',
            relatedModel: 'AssayVersion',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'curves',
            relatedModel: 'Curve',
            collectionType: 'CurveCollection'
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'referencedWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasOne,
            key: 'wellReference',
            relatedModel: 'PcrWell',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLots',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLotsForValidation',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }],


        getTargetsResultIcons: function () {
            var targetsResultsIcons = [];
            this.get('results').each(_.bind(function (target) {
                targetsResultsIcons.push(target.getResultIcon());
            }, this));

            return targetsResultsIcons;
        },

        getWellRes: function () {
            return this.get('results').chain().filter(function (result) {
                return result.isOvar();
            }).first().value();
        },

        getDisplayGroup: function () {
            return this.get('results').chain().filter(function (result) {
                return result.isOvar();
            }).value();
        },
        postUrl: function (runSecId) {
            return Settings.url('rest/v2/runs/pcr/' + runSecId + '/wells');
        },

        save: function () {
            this.url = this.postUrl(this.get('run').get('secId'));
            return Backbone.Model.prototype.save.call(this);
        },

        saveResults: function (results) {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/results'),
                defer = $.Deferred();

            var body = {
                'tags': this.get('tags'),
                'pcrWellResultRequests': results
            };
            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveHeader: function (requestModel) {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(requestModel),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/sampleId'),
                defer = $.Deferred();

            var body = this.get('smpId').get('name');
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        /**
         * Validation 1 for a well
         * @returns {*|jQuery}
         */
        validation1: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/validation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 2 for a well
         * @returns {*|jQuery}
         */
        validation2: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/validation2');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 12 for a well
         * @returns {*|jQuery}
         */
        validation12: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/validation12');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Cancel the validation process for the PCR well.
         *
         * @returns {Promise} A Promise that resolves when the validation is successfully canceled, and rejects with an error if there was an error canceling the validation.
         */
        cancelValidation: function () {
            var defer = $.Deferred();
            var url;
            if (parseInt(this.get('valWst'), 10) === 3) {
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/cancelValidation1');
            } else {
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/cancelValidation2');
            }


            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        validationExpert: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/validationExpert');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        requireExpertValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/requireExpertValidation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCR: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/repeat/PT');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },


        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCRControl: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/repeat/PC');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtraction: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/repeat/XT', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControl: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/repeat/XC', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @returns {*|jQuery}
         */
        repeatPoolToDissolve: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/repeat/DP');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/controlKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getWellControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/controlKitLot/wells');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(new app.ns.PcrWellCollection(response));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred();

            var ret = this.get('assayVersion').get('reagents').chain().map(_.bind(function (reagent) {
                var pcrKitLot = this.get('pcrKitLots').chain().filter(function (kitLot) {
                    return kitLot.get('kit') === reagent.get('reagent').get('kit');
                }).first().value();

                if (!pcrKitLot) {
                    return null;
                }

                return {
                    kit: reagent.get('reagent').get('kit'),
                    secId: pcrKitLot.get('secId'),
                    code: pcrKitLot.get('code'),
                    volumeByTest: reagent.get('volumeByTest'),
                    reagent: reagent.get('reagent')
                };
            }, this)).filter(function (ret) {
                return ret;
            }).value();

            defer.resolve(ret);
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PcrWellJson.pdf');
        },

        getErrorObject: function () {
            var errorObject = {
                error: false,
                warning: false,
                ok: false
            };

            switch (this.get('errorInd')) {
                case 'E':
                    errorObject.error = true;
                    break;
                case 'W':
                    errorObject.warning = true;
                    break;
            }

            if (!errorObject.warning && !errorObject.error && this.get('results').some({'codeErr': 'W'})) {
                errorObject.warning = true;
            }

            if (!errorObject.error && this.get('results').some({'codeErr': 'E'})) {
                errorObject.warning = false;
                errorObject.error = true;
            }

            if (!errorObject.error && !errorObject.warning) {
                errorObject.ok = true;
            }
            return errorObject;
        },

        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getGraphToDisplay: function () {
            if (this.get('results').isEmpty()) {
                return _.map(_.uniq(this.get('curves').pluck('type')), function (type) {
                    return type.substring(0, 1);
                });
            }
            var type = '';
            this.get('results').chain().filter(function (result) {
                return result instanceof app.ns.PcrWellResultCurve;
            }).each(function (result) {
                if (result.get('curves').isEmpty()) {
                    return;
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'AMP' || curve.get('type') === 'AMP_IC';
                })) {
                    if (!type.includes('A')) {
                        type += 'A';
                    }
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'MELT';
                })) {
                    if (!type.includes('M')) {
                        type += 'M';
                    }
                }
            });
            return type;
        },

        isReadOnly: function (dontCheckResult) {
            return this.get('repeatStatus') !== null || (!dontCheckResult && this.get('results').any(function (result) {
                return result.isReadOnly();
            }));
        },

        canChangeSmpId: function () {
            return (this.get('refPrepWell') === undefined || this.get('refPrepWell') === null || !this.get('refPrepWell')) && (!this.get('valWst') || this.get('valWst') <= 5 || this.get('valWst') === 9);
        },

        hasAlreadyTargetValidated: function () {
            return this.get('results').any(function (result) {
                return result.get('valSt') >= 3 && result.get('valSt') !== 9;
            });
        },

        findResult: function (assayVersionResult) {
            return this.get('results').chain().filter(function (result) {
                return result.get('refAssayResult') === assayVersionResult;
            }).first().value();
        },
        /**
         * Refresh well
         * @returns {*|jQuery}
         */
        refreshWell: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/refresh');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch().done(_.bind(function () {
                        this.fetch().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveDetail: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        getType: function () {
            var types = this.get('results').chain().map(function (model) {
                return model.get('type');
            }).uniq().value();
            if (_.any(types, function (type) {
                return type === 'MAGPIX';
            })) {
                return 'MAGPIX';
            }
            if (_.any(types, function (type) {
                return type === 'HL7';
            })) {
                return 'HL7';
            }
            return 'CURVE';
        },
        changeValidationLot: function (param) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(param),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findPcrKitLotForValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        setTest: function (test) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('secId') + '/test', {test: test});

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.PcrWell.role = RolesMixin.WELL_PCR;

    app.ns.PcrWellCollection = Backbone.Collection.extend({
        fetch: function (params) {
            if (params && params.method === 'POST') {
                // This is a POST request
                this.url = Settings.url('rest/v2/wells/pcr/');
                var defer = $.Deferred();
                $.ajax({
                    url: this.url,
                    type: 'POST',
                    data: JSON.stringify(this.map('secId')),
                    contentType: 'application/json',
                    success: _.bind(function (response) {
                        this.reset(response);
                        defer.resolve();
                    }, this),
                    error: function (err) {
                        console.error(err);
                        defer.reject(err);
                    }
                });
                return defer.promise();
            }
            if (this.run) {
                this.url = Settings.url('rest/v2/wells/pcr/from/' + this.run.get('secId'), params);
            } else {
                this.url = Settings.url('rest/v2/wells/pcr/', params);
            }
            return Backbone.Model.prototype.fetch.call(this);
        },
        model: app.ns.PcrWell,
        comparator: function (m1, m2) {
            if (!m1 || !m1.get('pos')) {
                return -1;
            }
            if (!m2 || !m2.get('pos')) {
                return 1;
            }

            if (m1.get('run') !== m2.get('run') && m1.get('run') instanceof app.ns.PcrRun && m2.get('run') instanceof app.ns.PcrRun) {
                if (m1.get('run').get('period') && m2.get('run').get('period')) {
                    return String(m1.get('run').get('period')).localeCompare(String(m2.get('run').get('period')));
                }
                if (m1.get('run').get('created') && m2.get('run').get('created')) {
                    return String(m1.get('run').get('created')).localeCompare(String(m2.get('run').get('created')));
                }
            }

            var labSetting = m1.get('run') && m1.get('run').get && m1.get('run').get('settings') ? m1.get('run').get('settings')['LAB-SETTINGS'] : 'C';
            if (labSetting === 'R') {
                return m1.get('pos').localeCompare(m2.get('pos'));
            }
            return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
        },

        /**
         *
         * @param assayResult
         * @returns {[]|*|Array}
         */
        search: function (assayResult) {
            var models = _.filter(this.models, function (model) {
                if (!assayResult) {
                    return true;
                }
                return model.get('results').some(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('id') === assayResult.id;
                });
            });
            return new app.ns.PcrWellCollection(models);
        },

        getAssays: function () {
            var assaySecIds = this.chain().filter(function (model) {
                return model.get('refAssay') && !model.get('refAssay').isNew();
            }).map(function (model) {
                return model.get('refAssay');
            }).uniq(false, function (assay) {
                return assay && assay.get('secId');
            }).compact().map(function (model) {
                return model.get('secId');
            }).value();

            if (this.isEmpty()) {
                return null;
            }

            var assayVersions = this.first().get('run').get('assayVersions').filter(function (assayVersion) {
                return assaySecIds.includes(assayVersion.get('assay').get('secId'));
            });
            assayVersions = _.map(assayVersions, function (assayVersion) {
                return assayVersion;
            });
            return assayVersions;
        }
    });
});

