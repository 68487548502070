
define('template!dynamicCreateEditEnumTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<select class="form-control file-import js-info-input" data-field-name="' +
((__t = ( field )) == null ? '' : __t) +
'"\n		style="width: ' +
((__t = ( width )) == null ? '' : __t) +
';">\n	';
 _.each(values, function(val){ ;
__p += '\n	<option value="' +
((__t = ( val )) == null ? '' : __t) +
'"\n	' +
((__t = ( val === value ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( val )) == null ? '' : __t) +
'</option>\n	';
 }); ;
__p += '\n</select>';

}
return __p
};});

