define('exportCreateEditLayoutView',[
    'module',
    'dialogFormView',
    'template!exportCreateEditLayoutView',
    'underscore',
    'jquery',
    'app',
    'settings',
    'moment',
    'dynamicCreateEditList'
], function (
    module,
    DialogFormView,
    Tpl,
    _,
    $,
    App,
    Settings,
    moment,
    DynamicCreateEditList
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            queries: '.js-queries'
        },

        fieldsToValidate: [],

        serializeData: function () {
            var templateData = this.model.toJSON();

            if (templateData.createdDate) {
                templateData.createdDate = moment(templateData.createdDate).format('DD/MM/YYYY HH:mm:ss');
            }
            if (templateData.exportedDate) {
                templateData.exportedDate = moment(templateData.exportedDate).format('DD/MM/YYYY HH:mm:ss');
            }
            if (templateData.failedDate) {
                templateData.failedDate = moment(templateData.failedDate).format('DD/MM/YYYY HH:mm:ss');
            }
            if (templateData.valueDate) {
                templateData.valueDate = moment(templateData.valueDate).format('DD/MM/YYYY HH:mm:ss');
            }
            return templateData;
        },

        onRender: function () {
            var service = require('services/caccounts/lisRecords');
            this.setPermissions(service.canAddOrEdit());
            this.getRegion('queries').show(new DynamicCreateEditList({
                field: 'queries',
                model: this.model,
                collection: this.model.get('queries')
            }));
        },

        hide: function () {
            if (App.getCurrentRoute() === 'admin/export/ASTM/status') {
                App.navigate('admin/export/ASTM/status', {trigger: true});
            } else {
                App.navigate('admin/export/ASTM/status/refresh', {trigger: false});
                App.navigate('admin/export/ASTM/status', {trigger: true, replace: true});
            }
        },

        onCancel: function () {
            this.box.modal('hide');
        }
    });
});

