define('entities/caccounts/extractionMethodReagent',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/kitsextraction',
    'entities/caccounts/extractionreagents'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.ExtractionMethodReagent = Backbone.RelationalModel.extend({
        service: 'services/caccounts/extractionMethodReagent',
        defaults: {
            secId: null,
            remark: '',
            reagent: null,
            kit: null,
            volumeByTest: '',
            volumeDeath: '',
            sequence: ''
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'kit',
            relatedModel: 'KitExtraction'
        }, {
            type: Backbone.HasOne,
            key: 'reagent',
            relatedModel: 'ExtractionReagent'
        }],

        postUrl: function (extractionMethodSecId) {
            return Settings.url('rest/v2/extractionMethods/' + extractionMethodSecId + '/extractionMethodReagents');
        },

        save: function () {
            this.url = this.postUrl(this.get('refExtractionMethod').secId);
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('ExtractionMethodReagentJson.pdf');
        }
    });

    app.ns.ExtractionMethodReagent.role = RolesMixin.EXTRACTIONMETHOD;

    app.ns.ExtractionMethodReagentCollection = Backbone.Collection.extend({
        url: function (extractionMethodSecId) {
            return Settings.url('rest/v2/extractionMethods/' + extractionMethodSecId + '/extractionMethodReagents');
        },
        model: app.ns.ExtractionMethodReagent,
        comparator: 'sequence'
    });

});

