define('prepWellPcrWellItem',[
    'module',
    'backbone.marionette',
    'template!prepWellPcrWellItem',
    'pcrWellResultController',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    PcrWellResultController,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'd-f baseline bottom-border-cell-light',
        events: {
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell'
        },
        serializeData: function () {
            var templateData = {};
            templateData.secId = this.model.get(this.model.idAttribute);
            templateData.pos = this.model.get('pos');
            templateData.runName = this.model.get('run').get('code');
            templateData.assay = this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '';
            templateData.results = this.model.get('results').pluck('secId');
            return templateData;
        },
        onRender: function () {
            this.model.get('results').each(_.bind(function (result) {
                var addRegion = this.addRegion('js-result-' + result.get(result.idAttribute), '.js-result-' + result.get(result.idAttribute));
                PcrWellResultController.show(result, 'Well', addRegion);
            }, this));
        },
        onShowPcrWellFromPrepWell: function () {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: this.model});
            }, this));
        }
    });
});
