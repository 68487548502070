define('entities/caccounts/mbanas',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/assay',
    'entities/caccounts/assaygroup',
    'entities/caccounts/lisana',
    'entities/caccounts/mbanaresgrs',
    'entities/caccounts/classifiedValues'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.MbAna = Backbone.RelationalModel.extend({
        service: 'services/caccounts/mbanas',
        fetch: function () {
            this.url = Settings.url('rest/v2/mbanas/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'tags': [],
            'refMbAnaResGr': null,
            'lisCode': '',
            'lisText': '',
            'classifiedValueType': null,
            'classifiedValues': [],
            'lisVariant': {type: 'NO', param: ''},
            'criterias': [],
            'assays': [],
            'assayGroups': [],
            'lisanas': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'refMbAnaResGr',
            relatedModel: 'MbAnaResGr',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assayGroups',
            relatedModel: 'AssayGroup',
            collectionType: 'AssayGroupCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'lisanas',
            relatedModel: 'LisAna',
            collectionType: 'LisAnaCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'classifiedValues',
            relatedModel: 'ClassifiedValue',
            collectionType: 'ClassifiedValueCollection'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/mbanas/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'MbAnaJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'MbAna',

        importable: true
    });

    app.ns.MbAna.role = RolesMixin.MBANA;

    app.ns.MbAnaCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/mbanas/');
        },
        model: app.ns.MbAna
    });
});

