
define('template!runPcrKitLotItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<td>\n    ' +
((__t = ( assay )) == null ? '' : __t) +
'\n</td>\n<td>\n    ' +
((__t = ( reagent )) == null ? '' : __t) +
' (' +
((__t = ( reagentType )) == null ? '' : __t) +
')\n</td>\n<td>\n    ' +
((__t = ( kit )) == null ? '' : __t) +
'\n</td>\n<td>\n    ' +
((__t = ( kitLot )) == null ? '' : __t) +
'\n</td>\n<td>\n    ' +
((__t = ( count + ' (' + countInPrepWell + ')' )) == null ? '' : __t) +
'\n</td>\n<td>\n    ' +
((__t = ( volume )) == null ? '' : __t) +
'\n</td>';

}
return __p
};});

