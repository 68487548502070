define('runPcrValidationPlateGridItemView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'runPcrValidationPlateWellView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    WellView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-item-region grid-item"></div>'),
        className: 'grid-item',
        regions: {
            item: '.js-grid-item-region'
        },
        onRender: function () {
            var view = new WellView({
                model: this.model,
                validationModel: this.options.validationModel
            });
            this.showChildView('item', view);
            this.listenTo(view, 'well:click', this.onWellClick);
        },
        onWellClick: function (model) {
            this.trigger('click', model);
        }
    });
});

