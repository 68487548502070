define('assayReagentMMXView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'template!assayReagentMMXTpl',
    'assayReagentMMXItemView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Tpl,
    View
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: View,
        childViewContainer: '.collection',
        childViewEvents: {
            'item:delete': function (model) {
                this.collection.remove(model);
            },
            'item:updateReagent': function () {
                var reagentTypes = _.pluck(this.collection.pluck('reagent'), 'type');
                _.each(this.children._views, function (view) {
                    view.options.reagentTypes = reagentTypes;
                });
            },
            'item:updateVolume': function () {
                this.render();
            }
        },
        events: {
            'click .js-add': 'onAdd'
        },
        onAdd: function () {
            var AssayReagentService = require('services/caccounts/assayreagent');
            this.collection.push(AssayReagentService.getModel({refAssay: this.model}));
        },
        serializeData: function () {
            var volumeMMX = _.reduce(this.collection.map(function (model) {
                return parseFloat(model.get('volumeByTest'), 10);
            }), function (memo, num) {
                return memo + num;
            });

            return {volumeMMX: volumeMMX};
        }
    });
});
