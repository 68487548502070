define('entities/caccounts/authorityGroup',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userpids'
], function (
    app,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.AuthorityGroup = Backbone.RelationalModel.extend({
        defaults: {
            privileges: null,
            userpid: null
        },
        initialize: function () {
            if (!this.get('privileges')) {
                this.set('privileges', []);
            }
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'userpid',
            relatedModel: 'UserPid',
            includeInJSON: ['secId', 'firstName', 'lastName']
        }],
        service: 'services/caccounts/authorityGroup',
        fetch: function () {
            this.url = Settings.url('rest/v2/authorityGroups/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/authorityGroups/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('AuthorityGroupJson.pdf');
        }
    });

    app.ns.AuthorityGroup.role = RolesMixin.AUTHORITYGROUP;

    app.ns.AuthorityGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/authorityGroups/');
        },
        model: app.ns.AuthorityGroup
    });
});

