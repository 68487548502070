
define('template!assayConfigurationVersion', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group p-l-0 p-r-0">\n    <label class="col-xs-2 control-label noWrapHidden" style="text-align: right;">' +
((__t = (
        _.i18n('assayConfiguration.code') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-2 js-code">\n        <input type="text"\n               class="form-control inputBackground"\n               placeholder=""\n               value="' +
((__t = ( code )) == null ? '' : __t) +
'"\n               data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n               data-placement="top" autocomplete="off">\n    </div>\n    <label class="col-xs-1 control-label noWrapHidden" style="text-align: right;">' +
((__t = (
        _.i18n('assayConfiguration.name') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-2 js-name">\n        <input type="text"\n               class="form-control inputBackground"\n               placeholder=""\n               value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n               data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n               data-placement="top" autocomplete="off">\n    </div>\n    <label class="col-xs-1 control-label noWrapHidden" style="text-align: right;">' +
((__t = (
        _.i18n('assayConfiguration.colorCompensation') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-2 js-colorCompensation">\n        <input type="text"\n               class="form-control inputBackground"\n               placeholder=""\n               value="' +
((__t = ( colorCompensation ? colorCompensation.code : '' )) == null ? '' : __t) +
'"\n               data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n               data-placement="top" autocomplete="off">\n    </div>\n</div>\n\n<div class="col-xs-12 m-t-5 m-b-5 rounded">\n    <div class="col-w-all col-w-100 p-l-0 p-r-0 tableUnderlineTitle d-f">\n        <div class="col-w-3 noWrapHidden">' +
((__t = ( _.i18n('assayresult.sequence.title') )) == null ? '' : __t) +
'\n        </div>\n        <!-- liaison result - labels -->\n        <div class="col-w-9 noWrapHidden p-l-5"><!--p-l-1-->\n            ' +
((__t = ( _.i18n('assay.kitp.title.target') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-2 p-l-5 noWrapHidden"><!--ic-->\n            ' +
((__t = ( _.i18n('assay.kitp.title.ic') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="col-w-45 p-l-0 p-r-0 d-f">\n            <div class="col-w-100 col-w-all d-f">\n                <div class="col-w-22 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.res') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-all col-w-78 d-f">\n                    <div class="col-w-27 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.dye') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-w-73 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.param') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class="col-w-1"></div>\n\n        <div class="col-w-15 d-b p-l-0 p-r-0">\n            <div class="col-w-all d-f">\n                <div class="col-w-40 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.validation.algo') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-60 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.validation.param') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n\n        <div class="col-w-1"></div>\n\n        <div class="col-w-10 d-b">\n            <div class="col-w-all  d-f">\n                <div class="col-w-20 noWrapHidden p-l-3">' +
((__t = (
                    _.i18n('assayresult.sequenceDisplayGroup.title') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-40 noWrapHidden p-l-3">' +
((__t = (
                    _.i18n('assayresult.sequenceValidation.title') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-40 noWrapHidden p-l-3">' +
((__t = (
                    _.i18n('assayresult.sequenceDisplay.title') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n\n        <div class="col-w-1"></div>\n\n        <div class="col-w-4 noWrapHidden">' +
((__t = ( _.i18n('assay.kitp.title.algotexport') )) == null ? '' : __t) +
'</div>\n        <div class="col-w-11 noWrapHidden p-l-5">' +
((__t = ( _.i18n('assay.kitp.title.export.params') )) == null ? '' : __t) +
'</div>\n    </div>\n    <!-- liaison results - table -->\n    <div class="js-assayResult-region"></div>\n</div>';

}
return __p
};});

