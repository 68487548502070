define('tabsCollection',[
    'module',
    'backbone.marionette',
    'tabsItem',
    'underscore'
], function (
    module,
    Marionette,
    TabChildView,
    _
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        tagName: 'ul',
        className: 'nav nav-pills',
        childView: TabChildView,
        childViewEvents: {
            'click': function (childView) {
                this.children.each(_.bind(function (child) {
                    child.setActive(child === childView);
                }, this));
                this.options.tabContentCollection.changeActive(childView.model);
            }
        },
        serializeData: function () {
            return {};
        }
    });
});
