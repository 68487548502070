define('mbAnaCreateEditLayoutView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!mbAnaCreateEditLayoutView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'jquery',
    'settings',
    'autocompleteView',
    'arrayEncodeEditLayoutView',
    'dynamicCreateEditCustomListToList',
    'dynamicCreateEditDescriptionCommentTags',
    'dynamicCreateEditTypeParam'
], function (
    module,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    _,
    $,
    Settings,
    AutocompleteView,
    ArrayEncodeEditLayoutView,
    DynamicCreateEditCustomListToList,
    DynamicCreateEditDescriptionCommentTags,
    TypeParamView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-select-assay': 'onShowAssay',
            'click .js-select-lisana': 'onShowLisana',
            'click .js-select-assaygroup': 'onShowAssayGroup'
        },

        regions: {
            selectMbAnaResGr: '.js-select-mbanaresgr-region',
            selectListVariantAlgo: '.js-lisVariant',
            criterias: '.js-criterias',
            descriptionRegion: '.js-description'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'}
        ],

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.assaygroups = [];
            _.each(this.model.get('assays'), function (assay) {
                if (assay.refAssayGroup) {
                    templateData.assaygroups.push({
                        'code': assay.refAssayGroup.code,
                        'secId': assay.refAssayGroup.secId
                    });
                }
            });
            if (this.model.get('criterias') && this.model.get('criterias').length > 0) {
                templateData.criterias = this.model.get('criterias').join(', ');
            } else {
                templateData.criterias = null;
            }
            templateData.assaygroups = _.uniq(templateData.assaygroups, 'secId');
            templateData.newItem = this.options.newItem;
            return templateData;
        },

        onEditMbAnaResGr: function (secId) {
            var MbAnaResGrService = require('services/caccounts/mbanaresgrs');
            var model = MbAnaResGrService.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                this.showEditAddMbAnaResGrView(model, false);
            }, this));
        },

        onAddMbAnaResGr: function () {
            var MbAnaResGrService = require('services/caccounts/mbanaresgrs');
            var model = MbAnaResGrService.getModel();
            this.showEditAddMbAnaResGrView(model, true);
        },

        showEditAddMbAnaResGrView: function (model) {
            require(['dynamicController'], _.bind(function (DynamicController) {
                DynamicController.showDetails({
                    model: model, useCreateEditView: _.bind(function () {
                        this.model.set('refMbAnaResGr', model);
                        this.resGroupView.options.value = model.get('code');
                        this.resGroupView.render();
                    }, this)
                });
            }, this));
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save();
            }
        },

        onChangeMbAnaResGr: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model);
            this.triggerMethod('enable:cancel:confirm');
        },

        onShowAssay: function (e) {
            var AssayService = require('services/caccounts/assay');
            this.showView(AssayService.getModel({secId: $(e.target).attr('data-id')}), 'assayCreateEditLayoutView', _.i18n('assay.definition'), 'popupAssayEdit baseTableEditAddPopup');
        },

        onShowLisana: function (e) {
            var LisAnaService = require('services/caccounts/lisana');
            this.showView(LisAnaService.getModel({secId: $(e.target).attr('data-id')}), 'lisanasCreateEditLayoutView', _.i18n('menu.lis.lisana'), 'baseTableEditAddPopup');
        },

        onShowAssayGroup: function (e) {
            var AssayGroupService = require('services/caccounts/assaygroup');
            this.showView(AssayGroupService.getModel({secId: $(e.target).attr('data-id')}), 'assayGroupCreateEditLayoutView', _.i18n('menu.assay.assayGroup'), 'baseTableEditAddPopup');
        },

        showView: function (model, ViewToDisplay, title, className) {
            var Module = require(ViewToDisplay);
            model.fetch().done(function () {
                var createEditView = new Module({
                    model: model,
                    navigateAfterSaving: false
                });
                createEditView.show({
                    title: title,
                    className: className
                });
            });
        },

        updateParameters: function (params) {
            this.model.set('lisVariantParam', params.join(';'));
            $('.js-array-parameters-click').val(this.model.get('lisVariantParam'));
        },

        onSave: function () {
            this.triggerMethod('saved', this.options.model);
            this.box.modal('hide');
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onRender: function () {

            var MbAnaResGrService = require('services/caccounts/mbanaresgrs');
            this.resGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResGrService.getAutocompleteParam({
                    modelProperty: 'refMbAnaResGr',
                    callBackOnChange: this.onChangeMbAnaResGr,
                    callBackAdd: this.onAddMbAnaResGr,
                    callBackEdit: this.onEditMbAnaResGr
                }))
            );
            this.getRegion('selectMbAnaResGr').show(this.resGroupView);

            var CodeListService = require('services/admin/codelist');
            var selectAlgoExportView = new TypeParamView({
                fieldName: 'lisVariant',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-LISVAR'}),
                readOnly: true
            });
            this.getRegion('selectListVariantAlgo').show(selectAlgoExportView);

            var service = require('services/caccounts/mbanas');
            this.setPermissions(service.canAddOrEdit());

            this.getRegion('criterias').show(new DynamicCreateEditCustomListToList({
                'field': 'criterias',
                'model': this.model
            }));
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model
            }));
        }
    });
});

