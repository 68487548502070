
define('template!prepWellPcrWellCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="t-a-l">' +
((__t = ( _.i18n('pcrwell.from.preprun') )) == null ? '' : __t) +
'</label>\n<div class="col-w-all col-w-100 d-f">\n    <div class="col-w-all col-w-20 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.runname') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div>\n    <div class="col-w-all col-w-5 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div>\n    <div class="col-w-all col-w-10 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div>\n    <div class="col-w-all col-w-52 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.results') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div>\n    <div class="col-w-all col-w-4 noWrapHidden"></div>\n</div>';

}
return __p
};});

