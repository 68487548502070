define('runPrepHeaderPlateRightView',[
    'module',
    'backbone',
    'backbone.marionette',
    'template!runPrepHeaderPlateRightView',
    'bootbox',
    'underscore',
    'menuCheck'
], function (
    module,
    Backbone,
    Marionette,
    Tpl,
    bootbox,
    _,
    MenuCheck
) {
    'use strict';

    return Marionette.View.extend({
        template: Tpl,
        className: 'right',

        events: {
            'click .js-reset-filters': 'onResetFilters'
        },
        regions: {
            'sampleMenu': '.js-sample-menu',
            'repeatMenu': '.js-repeat-menu',
            'sampleTypeMenu': '.js-sample-type-menu',
            'sampleIdMenu': '.js-sample-id-menu',
            'assayMenu': '.js-assay-menu',
            'commentMenu': '.js-comment-menu'
        },
        initialize: function () {
            this.filters = [];
            this.filter = {};
        },

        onRender: function () {
            this.sampleMenu = new Backbone.Model({
                nameCode: null,
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.search.on.smpId',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-magnify',
                        attributes: {
                            name: 'select-smpId'
                        }
                    }
                ])
            });

            this.repeatMenu = new Backbone.Model({
                nameCode: 'repeat',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.repeat',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-play mdi-flip-h',
                        attributes: {
                            name: 'select-repeat-prep'
                        }
                    }
                ])
            });

            this.sampleTypeMenu = new Backbone.Model({
                nameCode: 'common.smpType',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.select.qcSample',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-alpha-q-circle-outline',
                        attributes: {
                            name: 'select-qcSample'
                        }
                    }, {
                        nameCode: 'common.select.unKnowSample',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-alpha-u-circle-outline',
                        attributes: {
                            name: 'select-unKnowSample'
                        }
                    }, {
                        nameCode: 'common.select.emptySid',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-alpha-x-circle-outline',
                        attributes: {
                            name: 'select-emptySid'
                        }
                    }
                ])
            });

            this.sampleIdMenu = new Backbone.Model({
                nameCode: 'common.smpId',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.select.wrongSid',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-alert-outline',
                        attributes: {
                            name: 'select-wrongSid'
                        }
                    }, {
                        nameCode: 'common.select.duplicates',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-content-duplicate',
                        attributes: {
                            name: 'select-duplicates'
                        }
                    }
                ])
            });

            this.assayMenu = new Backbone.Model({
                nameCode: 'assay.status',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.select.waiting',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-human-male',
                        attributes: {
                            name: 'select-waiting'
                        },
                        className: 'assay-waiting'
                    }, {
                        nameCode: 'common.select.running',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-run',
                        attributes: {
                            name: 'select-running'
                        },
                        className: 'assay-running'
                    }, {
                        nameCode: 'common.select.done',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-human-handsup',
                        attributes: {
                            name: 'select-done'
                        },
                        className: 'assay-done'
                    }
                ])
            });

            this.commentMenu = new Backbone.Model({
                nameCode: 'common.misc',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.select.comment',
                        click: _.bind(this.onSelectFilters, this),
                        mdi: 'mdi-comment-alert',
                        attributes: {
                            name: 'select-comment'
                        }
                    }
                ])
            });

            this.showChildView('sampleMenu', new MenuCheck({
                model: this.sampleMenu
            }));
            this.showChildView('repeatMenu', new MenuCheck({
                model: this.repeatMenu
            }));
            this.showChildView('sampleTypeMenu', new MenuCheck({
                model: this.sampleTypeMenu
            }));
            this.showChildView('sampleIdMenu', new MenuCheck({
                model: this.sampleIdMenu
            }));
            this.showChildView('assayMenu', new MenuCheck({
                model: this.assayMenu
            }));
            this.showChildView('commentMenu', new MenuCheck({
                model: this.commentMenu
            }));
        },
        serializeData: function () {
            var PrepWellService = require('services/caccounts/prepwells');
            var well = PrepWellService.getModel();
            return {
                assayStatusIcons: well.getAssayStatusFilterIcons()
            };
        },
        onResetFilters: function () {
            this.filters = [];
            this.filter = {sampleId: null};
            this.model.get('wells').trigger('filter', this.model.get('wells').models);
            var items = this.sampleMenu.get('items').models;
            items = items.concat(this.repeatMenu.get('items').models);
            items = items.concat(this.sampleTypeMenu.get('items').models);
            items = items.concat(this.sampleIdMenu.get('items').models);
            items = items.concat(this.assayMenu.get('items').models);
            items = items.concat(this.commentMenu.get('items').models);
            _.each(items, function (item) {
                item.trigger('selected', false);
            });
        },
        onSelectFilters: function (e) {
            this._selectFilters(e).done(_.bind(function () {
                var wells;
                if (_.isEmpty(this.filters)) {
                    wells = this.model.get('wells').models;
                } else {
                    var countBySmpId = this.model.get('wells').countBy(function (well) {
                        return well.get('smpId') ? well.get('smpId').get('name') : '';
                    });
                    wells = this.model.get('wells').chain().filter(_.bind(function (well) {
                        return _.find(this.filters, _.bind(function (filter) {
                            switch (filter) {
                                case 'select-repeat-prep':
                                    return well.get('repeat');
                                case 'select-qcSample':
                                    return well.get('smpType').includes('C');
                                case 'select-unKnowSample':
                                    return well.get('smpType').includes('U');
                                case 'select-emptySid':
                                    return !well.get('smpId').get('name');
                                case 'select-wrongSid':
                                    var settingSID = this.model.get('settings').SID;
                                    if (settingSID) {
                                        var split = settingSID.split(';');
                                        var controlSid = split[4];
                                        var lengthMin = split[2];
                                        var lengthMax = split[3];
                                        if (controlSid && controlSid === 'Y' && lengthMin && lengthMax) {
                                            return well.get('smpId').get('name') && (well.get('smpId').get('name').length < lengthMin || well.get('smpId').get('name').length > lengthMax);
                                        }
                                    }
                                    return false;
                                case 'select-duplicates':
                                    return countBySmpId[well.get('smpId').get('name')] > 1;
                                case 'select-withoutIC':
                                    return well.get('kitLotByKits').length < 1;
                                case 'select-withOneIc':
                                    return well.get('kitLotByKits').length === 1;
                                case 'select-withMoreIcs':
                                    return well.get('kitLotByKits').length > 1;
                                case 'select-waiting':
                                    return well.getAssayStatusCount().waiting > 0;
                                case 'select-running':
                                    return well.getAssayStatusCount().running > 0;
                                case 'select-done':
                                    return well.getAssayStatusCount().done > 0;
                                case 'select-comment':
                                    return well.get('smpId').get('comment') || well.get('smpId').get('lisComment');
                                case 'select-smpId':
                                    return well.get('smpId').get('name').includes(this.filter.sampleId);
                            }
                        }, this));
                    }, this)).value();
                }

                this.model.get('wells').forEach(function (well) {
                    well.trigger('selected', wells.includes(well));
                });
            }, this));
        },
        _selectFilters: function (e) {
            var defer = $.Deferred();

            var attribute = e.currentTarget.attributes.name.value;
            if (this.filters.includes(attribute)) {
                this.filters = _.reject(this.filters, function (filter) {
                    return filter === attribute;
                });
                defer.resolve();
            } else {
                this.filters.push(attribute);
                if (attribute === 'select-smpId') {
                    bootbox.prompt({
                        title: _.i18n('popup.select.smpId'),
                        className: 'actionPopupCss popupFitContent headerAction-bootboxPopup',
                        onEscape: true,
                        buttons: {
                            confirm: {
                                label: '<i></i>',
                                className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm btn-primary'
                            },
                            cancel: {
                                label: '<i></i>',
                                className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                            }
                        },
                        callback: _.bind(function (result) {
                            this.filter.sampleId = result;
                            defer.resolve();
                        }, this)
                    });
                } else {
                    defer.resolve();
                }
            }
            return defer.promise();
        }
    });
});

