define('menuView',[
    'module',
    'backbone.marionette',
    'menuItemView',
    'menuSubMenuView',
    'settings',
    'privileges'
], function (
    module,
    Marionette,
    ItemView,
    SubMenuView,
    Settings,
    Privileges
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: function (model) {
            if (!model.get('subMenu') || model.get('subMenu').isEmpty()) {
                return ItemView;
            }
            return SubMenuView;
        },
        tagName: 'ul',
        className: 'sidebar-nav',
        attributes: function () {
            return {
                id: this.model.get(this.model.idAttribute)
            };
        },
        serializeData: function () {
            return {};
        },
        childViewOptions: function () {
            return {
                menuView: this.options.menuView,
                parent: this,
                settings: this.options.settings
            };
        },
        setActive: function (model) {
            this.children.each(function (childView) {
                childView.setActive(model);
            });
        },
        findByName: function (name) {
            var model = null;
            this.children.each(function (childView) {
                var modelFromView = childView.findByName(name);
                if (modelFromView) {
                    model = modelFromView;
                }
            });
            if (model) {
                return model;
            }
        },
        viewFilter: function (view) {
            if (!view.model.get('role') && view.model.get('subMenu').isEmpty()) {
                return true;
            }
            if (view.model.get('subMenu').isEmpty()) {
                return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(view.model.get('role'), Privileges.READ);
            }
            return view.model.get('subMenu').some(function (subMenu) {
                return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(subMenu.get('role'), Privileges.READ);
            });
        }
    });
});
