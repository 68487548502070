
define('template!menuGroupItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n';
 if(displaySelect) { ;
__p += '\n<select></select>\n';
 } ;
__p += '\n';

}
return __p
};});

