define('runGenerateSampleIdView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runGenerateSampleIdView',
    'commonWellSelectionView',
    'commonCancelActionView',
    'bootbox',
    'wellSmpTypeView',
    'backbone.marionette',
    'runGenerateSampleIdResponseView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView,
    Bootbox,
    SmpTypeView,
    Marionette,
    RunGenerateSampleIdResponseView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            wellSelectionRegion: '.js-well-selection-region',
            cancelActionRegion: '.js-cancel-action-region',
            responseRegion: '.js-response-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView},
                {name: 'sampleId', type: 'required'},
                {name: 'step', type: 'required'},
                {name: 'firstStep', type: 'required'}
            ];
        },

        modelEvents: {
            'change:wellSelection': 'onValidateChange',
            'change:sampleId': 'onValidateChange',
            'change:step': 'onValidateChange',
            'change:firstStep': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model({
                wellSelection: null,
                step: 1,
                firstStep: 1,
                plateSize: this.options.model.get('plateSize'),
                sampleId: null
            });
        },

        onRender: function () {
            this.wellSelectionView = new CommonWellSelectionView({
                direction: this.model.get('wellSelection') ? this.model.get('wellSelection').get('direction') : this.options.model.get('settings')['LAB-SETTINGS'] || 'C',
                start: this.model.get('wellSelection') ? this.model.get('wellSelection').get('start') : 'A01',
                to: this.model.get('wellSelection') ? this.model.get('wellSelection').get('to') : this.options.model.get('plateSize'),
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);
            this.getRegion('responseRegion').show(new RunGenerateSampleIdResponseView({model: this.model}));
            this.commonCancelActionView.enableButtons(false);
        },

        serializeData: function () {
            var templateData = {};
            templateData.sampleId = this.model.get('sampleId');
            templateData.step = this.model.get('step');
            templateData.firstStep = this.model.get('firstStep');
            return templateData;
        },

        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.generateSampleId(this.model.get('response').sampleIds)
                .done(_.bind(function () {
                    this.hide();
                    this.options.model.fetch().always(function () {
                        loading.hide();
                    });
                }, this));
        },

        onValidateChange: function () {
            var validate = this.validate(this.fieldsToValidate());
            if (!validate) {
                return;
            }
            var SampleClassService = require('services/caccounts/sampleClass');
            SampleClassService.generate(this.model).done(_.bind(function (response) {
                this.commonCancelActionView.enableButtons(!!response);
                this.onChange('response', response);
            }, this))
                .fail(_.bind(function () {
                    this.commonCancelActionView.enableButtons(false);
                    this.onChange('response', null);
                }, this));
        }
    });
});
