
define('template!prepWellPcrWellItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-all col-w-20 noWrapHidden"\n     title="' +
((__t = ( runName )) == null ? '' : __t) +
'">\n    ' +
((__t = ( runName )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-5 noWrapHidden"\n     title="' +
((__t = ( pos )) == null ? '' : __t) +
'">\n    ' +
((__t = ( pos )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-10 noWrapHidden"\n     title="' +
((__t = ( assay )) == null ? '' : __t) +
'">\n    ' +
((__t = ( assay )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-52 noWrapHidden">\n    <div class="col-w-all col-w-100 d-f">\n        ';
 _.each(results, function(result) { ;
__p += '\n        <div class="col-w-5 noWrapHidden js-result-' +
((__t = ( result )) == null ? '' : __t) +
'">\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-4 noWrapHidden clickable js-read-click mdi mdi-eye js-show-pcrWell-from-prepWell"\n     data-secId="' +
((__t = ( secId )) == null ? '' : __t) +
'"\n     style="font-size: 22px; position: relative; left: 3px; top: 5px;">\n</div>';

}
return __p
};});

