
define('template!wellTargetPropCPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="saving-label"></div>\n<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n	<div class="error-msg"></div>\n</div>\n<div class="form-horizontal">\n	<div class="form-inputs-container t-a-c">\n		<div class="row m-b-10">\n			<div class="col-xs-12 m-b-0 p-l-0 p-r-0">\n				<div class="col-xs-3 p-l-0 p-r-5">\n					<label>' +
((__t = ( _.i18n('run.run') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					<label>' +
((__t = ( _.i18n('assay.assay') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					<label>' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-5">\n					<label>' +
((__t = ( _.i18n('common.target') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					<label>' +
((__t = ( _.i18n('wellresult.result') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-5">\n					<label>' +
((__t = ( _.i18n('wellresult.num') )) == null ? '' : __t) +
'</label>\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-0">\n					<label>' +
((__t = ( _.i18n('wellresult.error') )) == null ? '' : __t) +
'</label>\n				</div>\n			</div>\n\n			<div class="col-xs-12 p-l-0 p-r-0">\n				<div class="col-xs-3 p-l-0 p-r-5">\n					' +
((__t = ( refPcrWell.runName )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					' +
((__t = ( refPcrWell.assay )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					' +
((__t = ( refPcrWell.pos )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-5">\n					' +
((__t = ( mbAna )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-1 p-l-5 p-r-5">\n					' +
((__t = ( result )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-5">\n					' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n				</div>\n				<div class="col-xs-2 p-l-5 p-r-5">\n					' +
((__t = ( codeErr )) == null ? '' : __t) +
' ' +
((__t = ( commentErrAuto )) == null ? '' : __t) +
'\n				</div>\n			</div>\n		</div>\n		<div class="row">\n			<div class="col-xs-2">\n				<span class="mdi mdi-download clickable rawData" title="' +
((__t = ( _.i18n('curve.rawData.download') )) == null ? '' : __t) +
'"></span>\n				<span class="mdi mdi-download clickable fluorData" title="' +
((__t = ( _.i18n('curve.fluorData.download') )) == null ? '' : __t) +
'"></span>\n			</div>\n			<div class="col-xs-10">\n				' +
((__t = ( propCformated )) == null ? '' : __t) +
'\n			</div>\n		</div>\n		<div class="row">\n			<div class="col-xs-12">\n				<div class="col-w-all col-w-100 d-b p-l-0 p-r-5 m-b-3">\n					<div class="col-w-all col-w-100 d-f">\n						<div class="col-w-all col-w-11 p-l-5 t-a-l"></div>\n						<div class="col-w-all col-w-19 p-l-5 t-a-l">\n							<label style="font-size: 19px;">' +
((__t = ( algoRes )) == null ? '' : __t) +
'</label>\n						</div>\n						<div class="col-w-all col-w-90 p-l-5">\n							<div class="js-algoParam"></div>\n						</div>\n					</div>\n				</div>\n			</div>\n\n		</div>\n		<div class="row">\n			<div>\n				<div id="targetAmplGraphView"></div>\n			</div>\n		</div>\n	</div>\n</div>\n<div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n\n\n\n\n';

}
return __p
};});

