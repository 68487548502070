define('runHeaderMiddleView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!runHeaderMiddleView',
    'app'
], function (
    module,
    Marionette,
    _,
    Tpl,
    App
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'middle',
        ui: {
            previous: '.previous',
            next: '.next'
        },
        regions: {
            'middle': '.js-middle-region'
        },
        events: {
            'click .previous.clickable': 'onChangeRunClick',
            'click .next.clickable': 'onChangeRunClick'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('middle', new this.options.view({model: this.model}));
            var defers = [];
            defers.push(this.model.getPreviousRun());
            defers.push(this.model.getNextRun());
            $.when.apply($, defers).done(_.bind(function (previous, next) {
                if (previous) {
                    this.ui.previous.addClass('clickable');
                    this.ui.previous.removeClass('disabled');
                    this.ui.previous.attr('disabled', false);
                    this.ui.previous.attr('data-secId', previous);
                } else {
                    this.ui.previous.removeClass('clickable');
                    this.ui.previous.addClass('disabled');
                    this.ui.previous.attr('disabled', true);
                    this.ui.previous.attr('data-secId', null);
                }

                if (next) {
                    this.ui.next.addClass('clickable');
                    this.ui.next.removeClass('disabled');
                    this.ui.next.attr('disabled', false);
                    this.ui.next.attr('data-secId', next);
                } else {
                    this.ui.next.removeClass('clickable');
                    this.ui.next.addClass('disabled');
                    this.ui.next.attr('disabled', true);
                    this.ui.next.attr('data-secId', null);
                }
            }, this));
        },
        onChangeRunClick: function (e) {
            var secId = $(e.currentTarget).attr('data-secId');
            if (secId) {
                // retrieve the current route
                var route = App.getCurrentRoute();
                // replace the current run id with the new one
                route = route.replaceAll(this.model.get('secId'), secId);
                // navigate to the new route
                App.navigate(route, {trigger: true});
            }
        }
    });
});

