
define('template!pcrSetupPcrRunSave', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="width: 490px;">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="form-group p-0">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.name"\n                       style="text-align: right;">' +
((__t = (
                    _.i18n('pcrSetupCreated.pcrRunName') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="text" class="disabled" disabled="disabled" id="created.pcrRun.name"\n                           value="' +
((__t = ( name )) == null ? '' : __t) +
'"/>\n                </div>\n            </div>\n            <div class="form-group p-0">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.prepRun.name"\n                       style="text-align: right;">' +
((__t = (
                    _.i18n('pcrSetupCreated.createFromPrepRun') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="text" class="disabled" disabled="disabled" id="created.prepRun.name"\n                           value="' +
((__t = ( prepRuns )) == null ? '' : __t) +
'"/>\n                </div>\n            </div>\n            <div class="form-group p-0" data-field-name="plateIdPC">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.plateIdPC"\n                       data-field-name="plateIdPC" style="text-align: right;">' +
((__t = (
                    _.i18n('pcrSetupCreated.plateId') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="text" class="form-control js-info-input" data-field-name="plateIdPC"\n                           id="created.pcrRun.plateIdPC"\n                           value="' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'" style="width: 49%;"/>\n                </div>\n            </div>\n            <div class="form-group p-0" data-field-name="refCyclerPublic">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label"\n                       data-field-name="refCyclerPublic" style="text-align: right;">' +
((__t = (
                    _.i18n('pcrSetupCreated.cycler') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0 js-select-cycler-region">\n                </div>\n            </div>\n            <div class="form-group p-0">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.plateIdPC"\n                       style="text-align: right;">' +
((__t = (
                    _.i18n('pcrSetupCreated.cyclerProtocol') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="text" class="disabled" disabled="disabled" id="created.pcrRun.cyclerProtocol"\n                           value="' +
((__t = ( protocol )) == null ? '' : __t) +
'"/>\n                </div>\n            </div>\n\n            <div class="col-xs-12 p-0" style="border: solid 1px black; border-radius: 8px;\n        background-color: white;">\n                <div class="form-group m-t-5 m-b-5 m-r-5 m-l-5 col-xs-12 col-w-all d-f">\n                    <div class="col-w-20">' +
((__t = ( _.i18n('pcrSetupCreated.assay') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-32">' +
((__t = ( _.i18n('pcrSetupCreated.wells') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-18">' +
((__t = ( _.i18n('pcrSetupCreated.wells.volumeBySample') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-30">' +
((__t = ( _.i18n('pcrSetupCreated.reagents') )) == null ? '' : __t) +
'</div>\n                </div>\n\n                <div class="form-group m-t-5 m-b-5 m-r-5 m-l-5 col-xs-12 col-w-all d-f ">\n                    <div class="col-w-20"></div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.U') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.D') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.PC') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.NC') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.OC') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sampleType.RC') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-2 bottom-border-cell-dark"></div>\n                    <div class="col-w-4 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.sample') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-5 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.mmx') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-9 bottom-border-cell-dark"></div>\n                    <div class="col-w-15 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.volumeByWell') )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-15 bottom-border-cell-dark">' +
((__t = ( _.i18n('pcrSetupCreated.volumeTotal') )) == null ? '' : __t) +
'</div>\n                </div>\n\n                ';
 _.each(assays, function(line) { ;
__p += '\n                <div class="form-group m-t-5 m-b-5 m-r-5 m-l-5 col-xs-12 col-w-all d-f">\n                    <div class="col-w-20">' +
((__t = ( line.assay.code )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.S )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.D )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.PC )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.NC )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.RC )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-6">' +
((__t = ( line.countByType.OC )) == null ? '' : __t) +
'</div>\n                    <div class="col-w-2"></div>\n                    <div class="col-w-4">' +
((__t = ( line.volumeBySample )) == null ? '' : __t) +
'</div>\n                </div>\n                ';
 }) ;
__p += '\n            </div>\n\n            <div class="form-group col-xs-12 m-t-10">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.exportPlateLayout"\n                       style="text-align: right;">' +
((__t = ( _.i18n('pcrSetupCreated.exportPlateLayout') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-1 p-l-20 p-r-0">\n                    <input type="checkbox" id="created.pcrRun.exportPlateLayout"\n                           class="js-created-pcrRun-exportPlateLayout" ' +
((__t = ( pcrExportPlateLayout ? 'checked="checked"' :
                    ''
                    )) == null ? '' : __t) +
'>\n                </div>\n                ';
 if(displayLocalDownload) { ;
__p += '\n                <div class="col-xs-1 p-l-20 p-r-0">\n                    <input type="checkbox" id="created.pcrRun.exportPlateLayout.local"\n                           class="js-created-pcrRun-exportPlateLayout-local" ' +
((__t = ( pcrExportPlateLayout ? '' :
                    'disabled="disabled"' )) == null ? '' : __t) +
'>\n                </div>\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.exportPlateLayout.local"\n                       style="text-align: right;">' +
((__t = ( _.i18n('pcrSetupCreated.exportPlateLayout.local') )) == null ? '' : __t) +
'</label>\n                ';
 } ;
__p += '\n            </div>\n            <div class="form-group col-xs-12">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.printPcrSetup"\n                       style="text-align: right;">' +
((__t = ( _.i18n('pcrSetupCreated.printPcrSetup') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="checkbox" id="created.pcrRun.printPcrSetup" class="js-created-pcrRun-printPcrSetup"\n                    ' +
((__t = ( printPcrSetup ? 'checked="checked"' : '' )) == null ? '' : __t) +
'>\n                </div>\n            </div>\n            <div class="form-group col-xs-12">\n                <label class="col-xs-5 p-l-0 popupTitleLabel control-label" for="created.pcrRun.printPcrSetupMMX"\n                       style="text-align: right;">' +
((__t = ( _.i18n('pcrSetupCreated.printPcrSetupMMX') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-7 p-l-20 p-r-0">\n                    <input type="checkbox" id="created.pcrRun.printPcrSetupMMX"\n                           class="js-created-pcrRun-printPcrSetupMMX"\n                    ' +
((__t = ( printPcrSetupMMX ? 'checked="checked"' : '' )) == null ? '' : __t) +
'>\n                </div>\n            </div>\n\n            <!-- cancel/confirm row -->\n            <div class="row" style="text-align: center;">\n                <div class=" ">\n                    <button class="mdi-navigation-check popupBtn-confirm btn disabled-grey js-confirm">\n                    </button>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="loader loading-block empty-page-loader" style="top: 200px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

