define('dynamicCreateEditCustomComment',[
    'module',
    'formView',
    'underscore',
    'backbone',
    'template!dynamicCreateEditCustomComment',
    'moment'
], function (
    module,
    FormView,
    _,
    Backbone,
    tpl,
    moment
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        serializeData: function () {
            var templateData = {
                'comment': this.model.get('comment'),
                'created': '-',
                'createdBy': '-',
                'updated': '-',
                'updatedBy': '-'
            };
            if (this.model.get('creatDate')) {
                templateData.created = moment(this.model.get('creatDate')).format('DD-MM-YYYY HH:mm:ss');
            }
            if (this.model.get('creatBy')) {
                if (this.model.get('creatBy') instanceof Backbone.Model) {
                    templateData.createdBy = this.model.get('creatBy').get('nickName');
                }
                if (_.isObject(this.model.get('creatBy'))) {
                    templateData.createdBy = this.model.get('creatBy').nickName;
                }
            }
            if (this.model.get('modDate')) {
                templateData.updated = moment(this.model.get('modDate')).format('DD-MM-YYYY HH:mm:ss');
            }
            if (this.model.get('modBy')) {
                if (this.model.get('modBy') instanceof Backbone.Model) {
                    templateData.updatedBy = this.model.get('modBy').get('nickName');
                }
                if (_.isObject(this.model.get('modBy'))) {
                    templateData.updatedBy = this.model.get('modBy').nickName;
                }
            }

            return templateData;
        }
    });
});
