define('entities/caccounts/fileFormatSampleLists',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.FileFormatSampleList = Backbone.RelationalModel.extend({
        service: 'services/caccounts/fielFormatSampleLists',
        fetch: function () {
            this.url = Settings.url('rest/v2/fileFormat/sampleLists/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'inputSampleListService': 'Custom',
            'outputSampleListService': 'Custom',
            'customInputSampleList': '',
            'customOutputSampleList': ''
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/fileFormat/sampleLists/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'FileFormatSampleListJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'FileFormatSampleList',

        importable: true
    });

    app.ns.FileFormatSampleList.role = RolesMixin.FILEFORMAT;

    app.ns.FileFormatSampleListCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/fileFormat/sampleLists/');
        },
        model: app.ns.FileFormatSampleList
    });
});

