define('pcrWellResultOtherItemView',[
    'module',
    'backbone.marionette',
    'template!pcrWellResultOtherItemView',
    'underscore',
    'jquery',
    'editWellView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    WellResultTpl,
    _,
    $,
    EditWellView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: WellResultTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .btn-validate-result': 'onConfirmResult',
            'click .btn-negative-result': 'onNegativeResult',
            'click .btn-edit-well-target-result': 'onEditWellTargetResult'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        triggers: {
            'click .mdi-format-list-bulleted': 'show:properties'
        },

        regions: {
            result: '.js-result',
            displayMode: '.js-displayMode'
        },
        className: 'result result-other',

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        serializeData: function () {
            var templateData = {
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                comment: this.model.get('comment'),
                codeErr: this.model.get('codeErr'),
                valSt: this.model.get('valSt'),
                needValidation: this.model.get('needValidation'),
                readOnly: (this.options.readOnly !== undefined && this.options.readOnly) || this.model.isReadOnly(),
                propC: this.model.get('propC')
            };

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').get('firstName') && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }

            templateData.commentErrAuto = this.model.get('commentErrAuto');

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ' + templateData.commentErrAuto;
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            templateData.hasProperties = this.model.get('properties') && this.model.get('properties').length > 0;

            return templateData;
        },

        onConfirmResult: function () {
            $('.js-global-loader').show();
            this.model.confirmResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onNegativeResult: function () {
            $('.js-global-loader').show();
            this.model.negativeResult()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onEditWellTargetResult: function () {
            this.showEditWellView();
        },

        showEditWellView: function () {
            this.createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        },

        onRender: function () {
            PcrWellResultController.show(this.model, 'WellTarget', this.getRegion('result'));
            PcrWellResultController.show(this.model, 'Well', this.getRegion('displayMode'));
        }
    });
});

