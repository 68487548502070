define('menuItemView',['module',
        'backbone.marionette',
        'template!menuItemView'
    ],
    function (module,
              Marionette,
              Tpl
    ) {
        'use strict';

        module.exports = Marionette.View.extend({
            template: Tpl,

            events: {
                'click': 'onLinkClick'
            },

            tagName: 'li',
            attributes: function () {
                return {
                    id: this.model.get(this.model.idAttribute)
                };
            },

            initialize: function () {
                this.link = '#' + this.model.get('link')();
            },

            serializeData: function () {
                var templateData = {};
                templateData.title = this.model.get('title');
                templateData.link = this.link;
                return templateData;
            },

            onLinkClick: function (e) {
                e.stopPropagation();
            },
            findByName: function (name) {
                if (this.model.get(this.model.idAttribute) === name) {
                    return this.model;
                }
                return null;
            },
            setActive: function (model) {
                if (model === this.model) {
                    this.$el.addClass('active');
                } else {
                    this.$el.removeClass('active');
                }
            }
        });
    });
