define('runDetailsGraphView',[
    'module',
    'underscore',
    'entities/ns',
    'mobioChartView',
    'colorUtils',
    'fieldUtils',
    'cssUtils',
    'backbone'
], function (
    module,
    _,
    app,
    MobioChartView,
    ColorUtils,
    FieldUtils,
    CssUtils,
    Backbone
) {
    'use strict';

    module.exports = MobioChartView.extend({
        serializeData: function () {
            this.xLabel = _.i18n('well.graph.legend.fluo');
            if (this.deriv1Enable) {
                this.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
            }
            if (this.deriv2Enable) {
                this.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
            }
            if (this.amplGraph) {
                this.yLabel = _.i18n('well.graph.legend.cycle');
            } else {
                this.yLabel = _.i18n('well.graph.legend.temp');
            }

            this.data = {
                datasets: []
            };

            this.pointStyle = this.options.pointStyle;

            var props = [];

            if (this.options.procView) {

                props = this.model.get('propC');

                this.model.get('curves').each(_.bind(function (curveModel) {
                    var displayCurve;
                    if (this.options.showPropC) {
                        displayCurve = curveModel;
                    } else {
                        displayCurve = curveModel.get('type') === this.options.algoType;
                    }

                    if (displayCurve) {
                        var colorTarget1 = ColorUtils.colorTarget(curveModel.get('channel'), this.model.get('refPcrWell').get('run'), this.model, this.options.procView, curveModel);
                        var result = this.addData(
                            curveModel.get('lines'),
                            colorTarget1.r,
                            colorTarget1.g,
                            colorTarget1.b,
                            curveModel.get('secId'),
                            null,
                            colorTarget1.dashed,
                            this.model.get('res1Comment'),
                            this.model,
                            props,
                            this.pointStyle,
                            this.options.rangeSmooth,
                            this.options.smooth
                        );
                        var exponentialData = _.filter(result.data, function (data) {
                            return data.exponential;
                        });
                        result.data = _.reject(result.data, function (data) {
                            return data.exponential;
                        });
                        this.data.datasets.push(result);
                        if (exponentialData.length > 0 && !this.options.hideExtraPoint) {
                            var result2 = JSON.parse(JSON.stringify(result));
                            result2.data = exponentialData;
                            result2.color = 'rgba(0, 0, 0, 0.5)';
                            result2.borderColor = 'rgba(0, 0, 0, 0.5)';
                            result2.dashed = {
                                borderDashOffset: 3,
                                borderDash: [2, 4]
                            };
                            this.data.datasets.push(result2);
                        }
                    }
                }, this));
            } else {
                var resultCurve = new Backbone.Collection();
                this.model.get('results').chain().filter(function(result) {
                    return result instanceof app.ns.PcrWellResultCurve;
                }).each(function(result) {
                    resultCurve.add(result);
                });
                resultCurve.each(function (r) {
                    props[r.get('mbAna').get('code')] = r.get('propC');
                });

                this.model.get('curves').each(_.bind(function (curve) {

                    var displayCurve = curve.get('type') === this.options.algoType;

                    var isInternalControl = resultCurve.isEmpty() || resultCurve.any(function (result) {
                        return result.get('curves').contains(curve) && result.get('internalControl');
                    });
                    var borderWidth = null;
                    if (isInternalControl && this.model.get('run').get('settings')['IC-PARAM']) {
                        var icParamSplitted = this.model.get('run').get('settings')['IC-PARAM'].split(';');
                        if (icParamSplitted.length > 1) {
                            borderWidth = parseInt(icParamSplitted[1], 10);
                        }
                    }

                    if (displayCurve) {
                        var colorTarget1 = ColorUtils.colorTarget(curve.get('channel'), this.model.get('run'), this.model, null, curve);
                        var result = this.addData(
                            curve.get('lines'),
                            colorTarget1.r,
                            colorTarget1.g,
                            colorTarget1.b,
                            curve.get('name'),
                            borderWidth,
                            colorTarget1.dashed,
                            this.model.get('res1Comment'),
                            this.model,
                            props,
                            this.pointStyle,
                            this.options.rangeSmooth,
                            this.options.smooth,
                            this.options.withoutCC
                        );

                        var exponentialData = _.filter(result.data, function (data) {
                            return data.exponential;
                        });
                        result.data = _.reject(result.data, function (data) {
                            return data.exponential;
                        });
                        this.data.datasets.push(result);
                        if (exponentialData.length > 0 && !this.options.hideExtraPoint) {
                            var result2 = JSON.parse(JSON.stringify(result));
                            result2.data = exponentialData;
                            result2.color = 'rgba(0, 0, 0, 0.5)';
                            result2.borderColor = 'rgba(0, 0, 0, 0.5)';
                            result2.dashed = {
                                borderDashOffset: 3,
                                borderDash: [2, 4]
                            };
                            this.data.datasets.push(result2);
                        }
                    }
                }, this));
            }

            var dataToDisplay = this.data.datasets[0];
            var valArray = _.pluck(dataToDisplay.data, 'y');

            var yMinValue = FieldUtils.arrayMin(valArray);
            var yMaxValue = FieldUtils.arrayMax(valArray);

            var addValue = (yMaxValue - yMinValue) * 0.15;
            yMinValue -= addValue;
            yMaxValue += addValue;

            if (this.options.procView && this.data.datasets.length > 0) {
                var prop1Color = CssUtils.getPropertyValue('--css-propC-1');
                var prop2Color = CssUtils.getPropertyValue('--css-propC-2');


                var propC = props;
                var dataLength = dataToDisplay.data.length;

                if (dataLength === 1) {
                    dataLength = 2;
                }

                if (this.resultModel && this.resultModel.get('algoResult').type === 'MUTV') {
                    var params = this.resultModel.get('algoResult').param.split(';');
                    for (var i = 1; i < params.length; i = i + 6) {
                        var result = params[i];
                        if (result) {
                            var ref = parseFloat(params[i + 1]);
                            var expectedMin = ref - parseFloat(params[i + 3]);
                            var expectedMax = ref + parseFloat(params[i + 3]);
                            var errorMin = ref - parseFloat(params[i + 2]);
                            var errorMax = ref + parseFloat(params[i + 2]);
                            var tresholdMin = parseFloat(params[i + 4]);
                            var thresholdMax = parseFloat(params[i + 5]);
                            this.addVerticalLine(yMinValue, yMaxValue, errorMin, '#ff0000');
                            this.addVerticalLine(yMinValue, yMaxValue, errorMax, '#ff0000');
                            this.addVerticalLine(yMinValue, yMaxValue, expectedMin, '#eed862');
                            this.addVerticalLine(yMinValue, yMaxValue, expectedMax, '#eed862');
                            if (this.deriv1Enable) {
                                this.addHorizontalLine(errorMin, errorMax, tresholdMin, '#ff0000');
                                this.addHorizontalLine(errorMin, errorMax, thresholdMax, '#eed862');
                            }
                        }
                    }
                    if (!this.deriv1Enable) {
                        if (propC && propC.C1) {
                            // add C line
                            this.addVerticalLine(yMinValue, yMaxValue, propC.C1, prop1Color);
                        }
                        if (propC && propC.C2) {
                            // add C line
                            this.addVerticalLine(yMinValue, yMaxValue, propC.C2, prop2Color);
                        }
                    }
                }

                if (this.resultModel && this.resultModel.get('algoResult').type === 'AMP') {
                    if (this.deriv2Enable) {
                        var threshold = this.resultModel.get('algoResult').param.split(';')[1];
                        // add Amp line
                        this.addHorizontalLine(1, dataLength, threshold, prop2Color);
                    }
                    if (propC && propC.Amp1 && propC.C1) {
                        // add C line
                        this.addVerticalLine(yMinValue, yMaxValue, propC.C1, '#ff0000');
                        if (!this.deriv2Enable) {
                            // add Amp line
                            this.addHorizontalLine(1, dataLength, propC.Amp1, prop1Color);
                        }
                    }
                    if (propC && propC.Amp2 && propC.C2) {
                        // add C line
                        this.addVerticalLine(yMinValue, yMaxValue, propC.C2, '#ff0000');
                        if (!this.deriv2Enable) {
                            // add Amp line
                            this.addHorizontalLine(1, dataLength, propC.Amp2, prop2Color);
                        }
                    }
                }
            }

            if (this.data.datasets.length) {
                if (this.baseline !== null && this.baseline !== undefined) {
                    this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
                }
                if (this.threshold !== null && this.threshold !== undefined) {
                    this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
                }
            }

            this.generateLegend();
            return this.data;
        }
    });
});
