
define('template!orderCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n	<div class="saving-label"></div>\n	<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n		<div class="error-msg"></div>\n	</div>\n	<div class="form-horizontal">\n		<div class="form-inputs-container">\n\n			<!-- header -->\n			<div class="row">\n				<div class="col-xs-4">\n				</div>\n				<div class="col-xs-4">\n					<div>\n						<div class="col-xs-10" style="border: solid 1px black; border-radius: 5px; background-color: #f5f5dc;\n                     padding: 2px; height: 40px; text-align: center;">\n							<span style="font-size: 24px;">' +
((__t = ( code )) == null ? '' : __t) +
'</span>\n						</div>\n					</div>\n					<div class="row">\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">\n							' +
((__t = ( _.i18n('order.created') )) == null ? '' : __t) +
'\n						</div>\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( created )) == null ? '' : __t) +
'</div>\n					</div>\n					<div class="row">\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = (
							_.i18n('order.untilValidated') )) == null ? '' : __t) +
'\n						</div>\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( untilValidated )) == null ? '' : __t) +
'\n						</div>\n					</div>\n				</div>\n				<div class="col-xs-4">\n					<div class="row">\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">\n							' +
((__t = ( _.i18n('order.specie') )) == null ? '' : __t) +
'\n						</div>\n						<div class="col-xs-6" style="padding-left: 15px; padding-right: 15px;">' +
((__t = ( specieCode )) == null ? '' : __t) +
'</div>\n					</div>\n				</div>\n			</div>\n			<div class="row p-l-15 p-r-15 m-t-20">\n				<label class="col-xs-2 text-right">' +
((__t = ( _.i18n('sample.patientInformation') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-10">' +
((__t = ( patient )) == null ? '' : __t) +
'</div>\n			</div>\n			<!-- results -->\n			<div class="row p-l-15 p-r-15 m-t-20" style="border: solid 1px #c6c6c6; border-radius: 8px;">\n				<div class="col-w-all col-w-100 d-f border-bottom">\n					<div class="col-w-5">\n					</div>\n					<div class="col-w-25">\n						<b>' +
((__t = ( _.i18n('sampleId') )) == null ? '' : __t) +
'</b>\n					</div>\n					<div class="col-w-10">\n						<b>' +
((__t = ( _.i18n('samples.biogroup') )) == null ? '' : __t) +
'</b>\n					</div>\n					<div class="col-w-40">\n						<b>' +
((__t = ( _.i18n('sample.lisAna.open') )) == null ? '' : __t) +
'</b>\n					</div>\n					<div class="col-w-20">\n						<b>' +
((__t = ( _.i18n('sample.gAssay') )) == null ? '' : __t) +
'</b>\n					</div>\n					<div class="col-w-20">\n						<b>' +
((__t = ( _.i18n('sample.wells') )) == null ? '' : __t) +
'</b>\n					</div>\n\n				</div>\n				<div class="js-samples m-t-10 "></div>\n			</div>\n		</div>\n        <div class="cancelConfirmRow">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n        </div>\n	</div>\n	<div class="loader loading-block empty-page-loader" style="top: 147px;">' +
((__t = ( _.i18n('loading') )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

