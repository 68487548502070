
define('template!wellPoolTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="" style="">\n    ';
 wellsPooled.sort((a,b) => (a.runName > b.runName) ? 1 : ((b.runName > a.runName) ? -1 : 0));
__p += '\n    ';
 if (wellsPooled.length < 6) { ;
__p += '\n    ';
 _.each(wellsPooled, function(well) { ;
__p += '\n    <div class="row m-b-0 col-xs-12">\n        <label class="col-xs-5 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n    ';
 for(var i = 0; i < 5; i++) { ;
__p += '\n    ';
 var well = wellsPooled[i]; ;
__p += '\n    <div class="row m-b-0 col-xs-12">\n        <label class="col-xs-5 noWrapHidden">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1 noWrapHidden">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-4 noWrapHidden">' +
((__t = ( well.smpId.name )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 } ;
__p += '\n    <div class="row m-b-0 col-xs-12">\n        <div class="col-xs-12" style="display: flex;">\n            <div class="clickable js-seeMore-pooled ">' +
((__t = ( _.i18n("common.more") )) == null ? '' : __t) +
'</div>\n            <div class="clickable js-seeMore-pooled mdi mdi-chevron-right p-l-5"\n                 style="color: white; font-size: 14px;"></div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

