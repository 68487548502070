define('services/global/caccounttypes',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'entities/global/caccounttypes'
], function (
    app,
    _,
    module,
    Dynamic,
    Settings
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.CAccountType,
                collection: app.ns.CAccountTypeCollection
            };
        },
        getName: function () {
            return 'caccounttype';
        },
        getDynamicJson: function () {
            return 'CAccountTypeJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getCreateEditLine: function () {
            var CAccountService = require('services/global/caccounts');
            return [
                {
                    'field': 'cAccount',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-xs-6'},
                        'config': {
                            'getAutocompleteParam': CAccountService.getAutocompleteParam
                        },
                        'context': CAccountService
                    }
                },
                {'field': 'type', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'USRARIGHTS'}}
            ];
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

