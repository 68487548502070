
define('template!TooltipRoutingGroupCondition', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div style="z-index: 9000;">\n    ' +
((__t = ( _.i18n('assayGroup.condition.keys') )) == null ? '' : __t) +
'\n    <ul>\n        <li>G : ' +
((__t = ( _.i18n('assayGroup.condition.assayGroup') )) == null ? '' : __t) +
'</li>\n        <li>G_C : ' +
((__t = ( _.i18n('assayGroup.condition.assayGroupCriteria') )) == null ? '' : __t) +
'</li>\n        <li>B : ' +
((__t = ( _.i18n('assayGroup.condition.bioGroup') )) == null ? '' : __t) +
'</li>\n        <li>B_C : ' +
((__t = ( _.i18n('assayGroup.condition.bioGroupCriteria') )) == null ? '' : __t) +
'</li>\n        <li>LB : ' +
((__t = ( _.i18n('assayGroup.condition.lisBioGroup') )) == null ? '' : __t) +
'</li>\n        <li>LB_C : ' +
((__t = ( _.i18n('assayGroup.condition.lisBioGroupCriteria') )) == null ? '' : __t) +
'</li>\n        <li>LS : ' +
((__t = ( _.i18n('assayGroup.condition.lisSpecie') )) == null ? '' : __t) +
'</li>\n        <li>LS_C : ' +
((__t = ( _.i18n('assayGroup.condition.lisSpecieCriteria') )) == null ? '' : __t) +
'</li>\n        <li>S : ' +
((__t = ( _.i18n('assayGroup.condition.specie') )) == null ? '' : __t) +
'</li>\n        <li>S_C : ' +
((__t = ( _.i18n('assayGroup.condition.specieCriteria') )) == null ? '' : __t) +
'</li>\n        <li>A : ' +
((__t = ( _.i18n('assayGroup.condition.lisAna') )) == null ? '' : __t) +
'</li>\n        <li>A_C : ' +
((__t = ( _.i18n('assayGroup.condition.lisAnaCriteria') )) == null ? '' : __t) +
'</li>\n        <li>L : ' +
((__t = ( _.i18n('assayGroup.condition.lis') )) == null ? '' : __t) +
'</li>\n        <li>T : ' +
((__t = ( _.i18n('assayGroup.condition.target') )) == null ? '' : __t) +
'</li>\n        <li>T_C : ' +
((__t = ( _.i18n('assayGroup.condition.targetCriteria') )) == null ? '' : __t) +
'</li>\n    </ul>\n    ' +
((__t = ( _.i18n('assayGroup.condition.functions') )) == null ? '' : __t) +
'\n    <ul>\n        <li>size(X) : ' +
((__t = ( _.i18n('assayGroup.condition.size') )) == null ? '' : __t) +
'</li>\n    </ul>\n</div>';

}
return __p
};});

