
define('template!runPcrValidationMainTargetsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="nav navbar-override-css nav-tabs">\n    ';
 if(displayMelt) { ;
__p += '\n    <li class="navbar-override-css nav-tab tab-melt rawData ' +
((__t = ( currentGraph === 'meltCurvesRaw' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="meltCurvesRaw">\n        <a data-toggle="tab" href="#meltCurvesRaw">MC-Raw</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-melt rawData ' +
((__t = ( currentGraph === 'meltCurves' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="meltCurves">\n        <a data-toggle="tab" href="#meltCurves">MC</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-melt rawData ' +
((__t = ( currentGraph === 'meltCurvesS' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="meltCurves">\n        <a data-toggle="tab" href="#meltCurves">MC-S</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-melt rawData ' +
((__t = ( currentGraph === 'meltCurves1' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="meltCurves1">\n        <a data-toggle="tab" href="#meltCurves1">MC-D1</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-melt first-tab ' +
((__t = ( currentGraph === 'meltCurves1S' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="meltCurves1">\n        <a data-toggle="tab" href="#meltCurves1">MC-D1-S</a>\n    </li>\n    ';
 } ;
__p += '\n    ';
 if(displayAmpl) { ;
__p += '\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurvesRaw' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurvesRaw">\n        <a data-toggle="tab" href="#amplCurvesRaw">AC-Raw</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurves' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurves">\n        <a data-toggle="tab" href="#amplCurves">AC</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl first-tab ' +
((__t = ( currentGraph === 'amplCurvesS' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurvesS">\n        <a data-toggle="tab" href="#amplCurvesS">AC-S</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurves1' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurves1">\n        <a data-toggle="tab" href="#amplCurves1">AC-D1</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurves1S' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurves1">\n        <a data-toggle="tab" href="#amplCurves1">AC-D1-S</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurves2' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurves2">\n        <a data-toggle="tab" href="#amplCurves2">AC-D2</a>\n    </li>\n    <li class="navbar-override-css nav-tab tab-ampl rawData ' +
((__t = ( currentGraph === 'amplCurves2S' ? 'active': '' )) == null ? '' : __t) +
'"\n        data-graph="amplCurves2">\n        <a data-toggle="tab" href="#amplCurves2">AC-D2-S</a>\n    </li>\n    ';
 } ;
__p += '\n</ul>\n\n<div class="tab-content" style="display: flex; justify-content: space-between; margin-top: 25px">\n    <div class="js-graph" id="graph" style="flex-grow: 1;"></div>\n    <div class="curve-action" style="max-width: 40px;">\n        ';
 if(displayCC) { ;
__p += '\n        <div class="valViewHeaderFilterButton clickable hidden-print js-colorCompensation-btn ' +
((__t = ( withCC ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi  mdi-palette clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withColorCompensation') )) == null ? '' : __t) +
'"></span>\n        </div>\n        ';
 } ;
__p += '\n        <div class="valViewHeaderFilterButton clickable hidden-print js-chartLegend-btn ' +
((__t = ( legendChecked ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-notification-clear-all clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withLegend') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print js-smoothing-btn ' +
((__t = ( rangeSmooth ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-iron-outline clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withSmoothing') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print js-rawData-btn ' +
((__t = ( rawData ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-raw clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withRawData') )) == null ? '' : __t) +
'"></span>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

