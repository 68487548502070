define('menuGroupQuickActionItemCollection',[
    'module',
    'backbone.marionette',
    'menuGroupQuickActionItem'
], function (
    module,
    Marionette,
    MenuGroupQuickActionItemView
) {
    'use strict';

    /**
     * @class QuickActionCollectionView
     * @extends Marionette.CollectionView
     * @param {Object} options
     * @param {Object} options.collection
     * @param {Object} options.collection.items
     *
     * collection {
     *     items: Backbone.Collection
     * }
     */
    module.exports = Marionette.CollectionView.extend({
        className: 'multiple',
        childView: MenuGroupQuickActionItemView

    });
});
