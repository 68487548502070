define('runPcrControllerConfiguration',[
        'module',
        'underscore',
        'runPcrHeaderDetailsView',
        'runPcrContentPlateView',
        'runPcrContentWellListView',
        'pcrWellView',
        'runPcrHeaderPlateLeftView',
        'runPcrHeaderPlateRightView',
        'runPcrValidationView',
        'runPcrContentTargetListView',
        'runPcrContentContaminationWellView',
        'runPcrHeaderContaminationLeftView'
    ],
    function (
        module,
        _,
        RunPcrHeaderDetailsView,
        RunPcrContentPlateView,
        RunPcrContentWellListView,
        PcrWellView,
        RunPcrHeaderPlateLeftView,
        RunPcrHeaderPlateRightView,
        RunPcrValidationView,
        RunPcrContentTargetListView,
        RunPcrContentContaminationWellView,
        RunPcrHeaderContaminationLeftView
    ) {
        'use strict';

        module.exports = {
            getConfiguration: function () {
                return {
                    header: RunPcrHeaderDetailsView,
                    tabs: [{
                        item: {
                            title: _.i18n('runs.table.tabTitle'),
                            mdi: 'mdi-grid'
                        },
                        content: RunPcrContentPlateView,
                        className: 'plate',
                        options: {
                            childView: PcrWellView
                        },
                        header: {
                            left: RunPcrHeaderPlateLeftView,
                            right: RunPcrHeaderPlateRightView
                        }
                    }, {
                        item: {
                            title: _.i18n('runs.assays.valview.tabTitle'),
                            mdi: 'mdi-chart-areaspline'
                        },
                        content: RunPcrValidationView,
                        className: 'validation'
                    }, {
                        item: {
                            title: _.i18n('runs.details.tabTitle'),
                            mdi: 'mdi-view-list'
                        },
                        content: RunPcrContentWellListView,
                        className: 'well-list'
                    }, {
                        item: {
                            title: _.i18n('runs.targetview.tabTitle'),
                            mdi: 'mdi-reorder-horizontal'
                        },
                        content: RunPcrContentTargetListView,
                        className: 'target-list'
                    }, {
                        item: {
                            title: _.i18n('runs.contaminations.tabTitle'),
                            mdi: 'mdi-biohazard'
                        },
                        content: RunPcrContentPlateView,
                        className: 'contamination',
                        options: {
                            childView: RunPcrContentContaminationWellView
                        },
                        header: {
                            left: RunPcrHeaderContaminationLeftView
                        }
                    }]
                };
            }
        };
    }
);

