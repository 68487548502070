define('runUntestWellView',[
    'module',
    'underscore',
    'jquery',
    'runWellSelectionView'
], function (
    module,
    _,
    $,
    RunPcrCommonWellSelectionView
) {
    'use strict';

    module.exports = RunPcrCommonWellSelectionView.extend({
        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.removeTest(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .always(_.bind(function () {
                    loading.hide();
                }, this));
        }
    });
});
