/**
 * Created by RKL on 26/08/2015.
 */
define('errorItem',[
    'module',
    'backbone.marionette',
    'template!errorItem',
    'underscore'
], function (
    module,
    Marionette,
    viewTpl,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,
        tagName: 'li',
        attributes: function () {
            return {
                style: 'margin: 5px 5px 5px 5px;'
            };
        },
        serializeData: function () {
            return {
                type: this.model.get('type'),
                className: this.model.get('className'),
                identifier: this.model.get('identifier'),
                code: this.model.get('code') ? this.model.get('code') : this.model.get('id'),
                region: 'js-errors-' + this.model.get('type')
            };
        },
        onRender: function () {
            if (!this.model.get('errors').isEmpty()) {
                var region = this.addRegion('errors', '.js-errors-' + this.model.get('type'));
                require(['errorCollection'], _.bind(function (ErrorCollection) {
                    region.show(new ErrorCollection({
                        collection: this.model.get('errors')
                    }));
                }, this));
            }
        }
    });
});
