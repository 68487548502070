define('kitLotsController',[
    'module',
    'underscore',
    'kitLotsView',
    'entities/ns'
], function (
    module,
    _,
    View,
    app
) {
    'use strict';

    module.exports = {
        showDetails: function (param) {
            var service = require('services/caccounts/setting');
            service.findByCode('LOT-VALIDATION').done(_.bind(function (setting) {
                if (!param.model) {
                    alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                    throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
                }
                var numberOfValidation = setting && /^\d+$/.test(setting.params) ? parseInt(setting.params, 10) : 1;
                var role = param.model instanceof app.ns.KitLotPcr ? 'KIT_PCR_LOT' : 'KIT_EXTRACTION_LOT';
                if (!param.options) {
                    param.options = {model: param.model, numberOfValidation: numberOfValidation, role: role};
                } else {
                    param.options = _.defaults({model: param.model, numberOfValidation: numberOfValidation, role: role}, param.options);
                }
                var createEditView = new View(param.options);
                var service = require(param.model.service);
                createEditView.show({
                    title: _.i18n(service.getName() + '.new'),
                    className: 'baseTableEditAddPopup kitLotEditAddPopup' + numberOfValidation
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            }, this));
        }
    };
});

