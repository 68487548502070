
define('template!kitLotActionPrepWellItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<td class="run">' +
((__t = ( runCode )) == null ? '' : __t) +
'</td>\n<td class="sampleId">' +
((__t = ( sampleId )) == null ? '' : __t) +
'</td>\n<td class="pos">' +
((__t = ( refWellPos )) == null ? '' : __t) +
'</td>\n<td class="pos">' +
((__t = ( testWellPos )) == null ? '' : __t) +
'</td>\n<td class="assays js-assay"></td>';

}
return __p
};});

