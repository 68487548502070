/**
 * Created by GSP on 4/08/2015.
 */
define('caccountsMainRouter',[
    'require',
    'underscore',
    'jquery',
    'exports',
    'module',
    'marionetteAppRouter',
    'app',
    'itemPagesNameMixin',
    'cssUtils',
    'sidebarShowController'
], function (
    require,
    _,
    $,
    exports,
    module,
    AppRouter,
    App,
    ItemPagesNameMixin,
    CssUtils,
    SidebarShowController
) {
    'use strict';


    var Controller = {
        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },
        showCurrentAccount: function (secId) {
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'caccountsController'
                ], function (CaccountsController) {
                    CaccountsController.show(App.getView().getRegion('content'), secId);
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.MY_ACCOUNT);
                });
            });
        }
    };

    var CaccountsRouter = AppRouter.extend({
        appRoutes: {
            'caccount/:secId': 'showCurrentAccount'
        },
        controller: Controller
    });
    module.exports = new CaccountsRouter();
});

