define('arrayEncodeEditLayoutView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!arrayEncodeEditLayoutView',
    'arrayCollectionView',
    'savingBehavior',
    'underscore',
    'jquery',
    'fieldUtils',
    'settings',
    'entities/admin/array',
    'colorpicker',
    'colorUtils'
], function (
    module,
    Backbone,
    DialogFormView,
    arrayEncodeEditLayoutTpl,
    ArrayCollectionView,
    SavingBehavior,
    _,
    $,
    FieldUtils,
    Settings,
    Arrays
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: arrayEncodeEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            rawParams: '#rawparams',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-copy-parameters': 'onCopyParameters',
            'click .js-paste-parameters': 'onPasteParameters',
            'click .js-delete-parameters': 'onDeleteParameters',
            'click .js-view-doc': 'onClickViewDoc',
            'change @ui.rawParams': 'onArrayParamInputChange'
        },

        regions: {
            collection: '.js-collection-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },
        initialize: function (options) {
            if (!this.model) {
                if (options.arrayCode !== '') {
                    Arrays.findByCode(options.arrayCode).done(_.bind(function (array) {
                        this.array = array;
                        if (array) {
                            this.trigger('arrayencode:arrayloaded');
                        } else {
                            this.destroy();
                        }
                    }, this));
                } else {
                    this.destroy();
                }
            } else {
                this.array = this.options.array;
            }
            this.arrayParams = this.options.arrayParams ? (_.isArray(this.options.arrayParams) ? this.options.arrayParams : this.options.arrayParams.split(';')) : [];
        },

        serializeData: function () {
            var templateData = {};
            templateData.readOnly = this.options.readOnly;
            templateData.accessToClipboard = navigator.clipboard;

            _.each(this.arrayParams, _.bind(function (value, index) {
                if (this.options.array.get('arrayElements').length > index && this.options.array.get('arrayElements').at(index).get('contentType') === 'BOOL') {
                    this.arrayParams[index] = value && value.toLocaleString() === 'true';
                }
            }, this));

            templateData.arrayParams = this.arrayParams;
            templateData.rawParams = templateData.arrayParams.join(';');

            templateData.array = this.array.toJSON();

            _.each(templateData.array.arrayElements, _.bind(function (arrayEl) {
                if (arrayEl.description) {
                    arrayEl.description = FieldUtils.getHtmlString(arrayEl.description);
                }
            }, this));
            return templateData;
        },

        onRender: function () {
            if (!Array.isArray(this.arrayParams)) {
                this.arrayParams = [];
            }

            this.collection = new Backbone.Collection();
            var defers = [];

            this.array.get('arrayElements').each(_.bind(function (arrayElement, index) {
                var arrayElementModel = new Backbone.Model({
                    index: index,
                    value: this.arrayParams[index],
                    arrayElement: arrayElement,
                    readOnly: this.options.readOnly
                });
                arrayElementModel.getEntity = function () {
                    this.getName = function () {
                        return arrayElement.get('code');
                    };
                    return this;
                };
                this.collection.add(arrayElementModel);
                $('.js-global-loader').show();
                var def = $.Deferred();
                defers.push(def);
                switch (arrayElement.get('contentType')) {
                    case 'L2L-E':
                        var entityName = this.findEntityName(arrayElement.get('contentTypeParam'));
                        require(['services/caccounts/' + entityName], _.bind(function (service) {
                            defers.push(service.findAll().done(_.bind(function (items) {
                                arrayElementModel.set('items', {
                                    items: items.map(function (item) {
                                        return {code: item.get('code'), name: item.get('name')};
                                    })
                                });
                                def.resolve();
                            }, this)));
                        }, this));
                        break;
                    case 'L2L':
                        switch (arrayElement.get('contentTypeParam')) {
                            case 'ASSAY-CONFIGURATION-RESULT-TARGETS':
                                var targets = this.options.referenceModel.get('assayConfiguration').get('results').map(function (result) {
                                    return result.get('refMbAna');
                                });
                                arrayElementModel.set('items', {items: targets});
                                break;
                            default:
                                throw new Error('Unknown contentTypeParam: ' + arrayElement.get('contentTypeParam'));
                        }
                        def.resolve();
                        break;
                    default:
                        def.resolve();
                        break;
                }
            }, this));
            $.when.apply($, defers).done(_.bind(function () {
                var view = new ArrayCollectionView({
                    collection: this.collection,
                    readOnly: this.options.readOnly,
                    service: this.options.service
                });
                this.listenTo(view, 'change', _.bind(this.changeCollection, this));
                this.getRegion('collection').show(view);
                this.triggerMethod('enable:cancel:confirm');
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        changeCollection: function () {
            this.arrayParams = this.collection.map(function (model) {
                if (_.isArray(model.get('value'))) {
                    return model.get('value').map(function (value) {
                        return value.code;
                    }).join(',');
                }
                return model.get('value');
            });
            this.ui.rawParams.val(this.arrayParams.join(';'));
        },
        onConfirm: function () {
            this.hide();
            this.model.set(this.options.fieldName, this.arrayParams.join(';'));
        },

        onArrayParamInputChange: function (e) {
            this.arrayParams = e.currentTarget.value.split(';');
            this.parseParameters();
        },

        onCopyParameters: function () {
            $('.js-copy-parameters').css('color', '#4cc8da');
            if (!navigator.clipboard) {
                //old - document execcommand copy is deprecated
                var range = document.createRange();
                range.selectNode(document.getElementById('rawparams'));
                window.getSelection().removeAllRanges(); // clear current selection
                window.getSelection().addRange(range); // to select text
                document.execCommand('copy');
                window.getSelection().removeAllRanges();// to deselect
            } else {
                var textToCopy = this.ui.rawParams.val();
                navigator.clipboard.writeText(textToCopy);
            }
            setTimeout(function () {
                $('.js-copy-parameters').css('color', 'black');
            }, 100);
        },

        onPasteParameters: function () {
            navigator.clipboard.readText().then(_.bind(function (clipText) {
                $('.js-copy-parameters').css('backgroundColor', 'transparent');
                this.ui.rawParams.val(clipText);
                this.parseParameters();
            }, this));
        },

        parseParameters: function () {
            this.render();
        },

        onDeleteParameters: function () {
            this.ui.rawParams.val('');
            this.arrayParams = [];
            this.parseParameters();
        },
        onClickViewDoc: function () {
            var url = Settings.url('rest/public/document/getfileByName/' + 'AR_' + this.array.get('code') + '.pdf') + '#toolbar=0';
            window.open(url, 'documentation', 'width=' + window.outerWidth * 0.6 + ',height=' + window.outerWidth * 0.7);
        },
        findEntityName: function (contentTypeParam) {
            switch (contentTypeParam) {
                case 'TARGET':
                    return 'mbanas';
            }
            throw new Error('Unknown entity name for contentTypeParam: ' + contentTypeParam);
        }
    });
});

