define('dynamicCreateEditDate',[
    'module',
    'formView',
    'moment',
    'underscore'
], function (
    module,
    FormView,
    moment,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            return 'form-control js-info-input inputBackground ' + (FormView.prototype.className ? ((_.isFunction(FormView.prototype.className) ? FormView.prototype.className.apply(this, arguments) : FormView.prototype.className)) : '');
        },
        attributes: function () {
            var attr = {};
            if (FormView.prototype.attributes) {
                attr = FormView.prototype.attributes.apply(this, arguments);
            }
            this.readOnly = this.options.param && this.options.param.display && this.options.param.display.readOnly;
            var value;
            if (_.isNumber(this.model.get(this.options.field))) {
                value = this.model.get(this.options.field) ? moment(this.model.get(this.options.field)).format('DD-MM-YYYY') : '';
            } else {
                value = this.model.get(this.options.field) ? moment(this.model.get(this.options.field), 'DD-MM-YYYY').format('DD-MM-YYYY') : '';
            }
            if (this.readOnly) {
                return _.extend(attr, {
                    type: 'text',
                    'data-field-name': this.options.field,
                    value: value,
                    disabled: true,
                    readOnly: true
                });
            }
            return _.extend(attr, {
                type: 'datepicker',
                'data-field-name': this.options.field,
                value: value
            });
        },

        events: {
            'change': 'onInputChange'
        },

        initialize: function () {
            this.listenTo(this.model, 'change:' + this.options.field, this.render);
        },
        onInputChange: function (value) {
            this.onChange(this.options.field, value.currentTarget.value ? moment(value.currentTarget.value, 'DD-MM-YYYY').valueOf() : null);
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            if (FormView.prototype.onRender) {
                FormView.prototype.onRender.apply(this, arguments);
            }
            this.initDatePicker(this.$el, this.options.field, true);
            if (_.isNumber(this.model.get(this.options.field))) {
                this.$el.val(this.model.get(this.options.field) ? moment(this.model.get(this.options.field)).format('DD-MM-YYYY') : '');
            } else {
                this.$el.val(this.model.get(this.options.field) ? moment(this.model.get(this.options.field), 'DD-MM-YYYY').format('DD-MM-YYYY') : '');
            }
        }
    });
});

