define('standardCurveAssayConfigurationResultPcrRunCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'standardCurveAssayConfigurationResultPcrRunItem'
], function (
    module,
    Backbone,
    Marionette,
    View
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({

        childView: View,

        childViewEvents: {
            'item:click': 'onChildItemClick'
        },

        onChildItemClick: function (view) {
            this.children.each(function (childView) {
                childView.select(childView === view);
            });
            this.trigger('pcrRun:click', view.model);
        },

        collectionEvents: {
            'change': 'render'
        },
        childViewOptions: function (model) {
            return {
                selected: this.options.model === model
            };
        },
        viewFilter: function (view) {
            if (this.options.pcrRun) {
                return view.model.get('pcrRun') === this.options.pcrRun;
            }
            return true;
        },
        onRender: function () {
            var firstView = this.children.first();
            if (firstView) {
                firstView.onClick();
            }
        }
    });
});
