define('adminAttachmentsPopupListView',[
    'module',
    'adminAttachmentsListView',
    'dialogMixin'
], function (
    module,
    view,
    DialogMixin
) {
    'use strict';

    module.exports = view.extend(DialogMixin);
});
