define('entities/caccounts/pcrSetup/pcrRunLite',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrSetup/pcrWellLite',
    'entities/caccounts/kitprots'
], function (
    app,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.PcrRunLite = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrSetup/pcrRunLite',
        defaults: {
            'name': undefined,
            'period': {},
            'periodFormatted': undefined,
            'group': undefined,
            'wells': [],
            'cyclerPublic': undefined
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PcrWellLite',
            collectionType: 'PcrWellLiteCollection',
            reverseRelation: {
                key: 'refRun',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasOne,
            key: 'pcrProtocol',
            relatedModel: 'KitProt'
        }],


        url: function () {
            return Settings.url('rest/v2/pcrSetup/pcrRun/' + this.get('secId'));
        },

        getColumnNumber: function () {
            return Number.parseInt(this.get('plateSize').substring(1));
        },
        generateColor: function () {
            var assay = null;
            var indexColor = 0;
            this.get('wells').each(function (well) {
                if (!well.get('assay')) {
                    well.set('color', '#FFFFFF');
                } else {
                    if (assay === null) {
                        assay = well.get('assay');
                    }
                    if (assay !== well.get('assay')) {
                        if (indexColor + 1 >= well.get('refRun').get('refPcrSetup').get('colors').length) {
                            indexColor = 0;
                        } else {
                            indexColor++;
                        }
                        assay = null;
                    }
                    well.set('color', well.get('refRun').get('refPcrSetup').get('colors')[indexColor]);
                }
            });
        },
        findBlockStartAt: function (start, size) {
            var wells = new Backbone.Collection();
            var firstWell = this.get('wells').findWhere({pos: start});
            if (!firstWell) {
                return new Backbone.Collection();
            }
            var wellsFiltered = new Backbone.Collection(this.get('wells').filter(function (well) {
                return well.get('assay') === null;
            }));
            var begin = wellsFiltered.indexOf(firstWell);
            wells.push(wellsFiltered.slice(begin, begin + size));

            return wells;
        },
        findBlock: function (start) {
            var delta = parseInt(start.substring(1, 3), 10) - this.get('refPcrSetup').get('prepWellSelected').first().getColumn();
            var wells = new Backbone.Collection();
            this.get('refPcrSetup').get('prepWellSelected').each(_.bind(function (well) {
                if (well.getColumn() + delta <= this.getColumn()) {
                    wells.push(new Backbone.Model({
                        pos: well.getLine() + String(well.getColumn() + delta).padStart(2, '0'),
                        wellSelected: well
                    }));
                }
            }, this));

            return wells;
        },
        getLine: function () {
            return this.get('plateSize').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('plateSize').substring(1), 10);
        },
        canDisplay: function (assay, protocol) {
            var display = true;
            if (display && protocol) {
                display = display && (!this.get('pcrProtocol') || this.get('pcrProtocol').get('secId') === protocol);
            }
            return display;
        },
        containQualityControl: function (assay) {
            var assays = [];
            if (assay === undefined) {
                assays = this.get('wells').chain().map(function (well) {
                    return well.get('assay');
                }).unique().filter(function (assay) {
                    return assay;
                }).value();
            } else if (_.isArray(assay)) {
                assays = assay;
            } else {
                assays.push(assay);
            }
            var ret = [];
            _.each(assays, _.bind(function (assay) {
                var containPC = this.get('wells').any(function (well) {
                    return well.get('assay') === assay && well.get('sampleType') === 'PC';
                });
                var containNC = this.get('wells').any(function (well) {
                    return well.get('assay') === assay && well.get('sampleType') === 'NC';
                });
                ret.push({assay: assay, PC: containPC, NC: containNC});
            }, this));
            return ret;
        }
    });

    app.ns.PcrRunLite.role = RolesMixin.PCR_SETUP;

    app.ns.PcrRunLiteCollection = Backbone.Collection.extend({
        model: app.ns.PcrRunLite,
        type: undefined,
        generateColor: function () {
            this.each(function (model) {
                model.generateColor();
            });
        },
        comparator: function (model1, model2) {
            if (model1.get('period') === model2.get('period')) {
                return 0;
            }
            if (model1.get('period') < model2.get('period')) {
                return 1;
            }
            return -1;
        },
        findWell: function (pcrWell) {
            var well;
            this.each(_.bind(function (model) {
                var w = model.get('wells').findWhere({secId: pcrWell.get('secId') + (this.type ? this.type : '')});
                if (!well) {
                    well = w;
                }
            }, this));
            return well;
        }
    });
});

