define('extendBackbone',[
    'backbone'
], function (
    Backbone
) {
    'use strict';

    Backbone.Collection.prototype.isEqual = function (other) {
        // Vérifier si les collections ont le même nombre d'éléments
        if (this.length !== other.length) {
            return false;
        }

        return this.all(function (model) {
            return other.contains(model);
        });
    };
});
