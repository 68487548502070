
define('template!pcrRunStatus', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul>\n    ';

    var elements = ['0', '1', '2', '3', '4', '5'];
    _.each(elements, function(element) {
    var bold = false;
    if(current) {
    bold = current.toString().includes(element);
    }
    ;
__p += '\n\n    <li>' +
((__t = ( bold ? '<b>' : '' )) == null ? '' : __t) +
'\n        ' +
((__t = ( element )) == null ? '' : __t) +
' - ' +
((__t = ( _.i18n('pcrrun.status.' + element) )) == null ? '' : __t) +
'\n        ' +
((__t = ( bold ? '</b>' : '' )) == null ? '' : __t) +
'\n    </li>\n    ';
 }) ;
__p += '\n</ul>';

}
return __p
};});

