
define('template!arrayCollectionItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-1 p-0" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">' +
((__t = (
    seq )) == null ? '' : __t) +
'\n</div>\n<div class="col-xs-5 p-0" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">\n    ' +
((__t = ( name )) == null ? '' : __t) +
' ' +
((__t = ( code ? '(' + code + ')' : '' )) == null ? '' : __t) +
'\n</div>\n\n<div class="col-xs-1 p-0" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">' +
((__t = (
    contentType )) == null ? '' : __t) +
'\n</div>\n\n<div class="col-xs-1 p-0">\n    ';
 if (description) {;
__p += '\n    <span class="mdi mdi-information-outline js-show-description"\n       style="font-size:20px;top: -5px;position: relative;"></span>\n    ';
 } ;
__p += '\n</div>\n<div class="col-xs-4 p-0 js-item-region">\n\n</div>';

}
return __p
};});

