define('pcrSetupPcrRunSave',[
    'module',
    'dialogFormView',
    'template!pcrSetupPcrRunSave',
    'underscore',
    'jquery',
    'bootbox',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    tpl,
    _,
    $,
    Bootbox,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input',
            checkboxPrintPcrSetup: '.js-created-pcrRun-printPcrSetup',
            checkboxPrintPcrSetupMMX: '.js-created-pcrRun-printPcrSetupMMX',
            checkboxExportPlateLayout: '.js-created-pcrRun-exportPlateLayout',
            checkboxExportPlateLayoutLocal: '.js-created-pcrRun-exportPlateLayout-local'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click @ui.checkboxExportPlateLayout': 'onCheckboxExportPlateLayoutClick'
        },

        regions: {
            cyclerRegion: '.js-select-cycler-region'
        },

        fieldsToValidate: [
            {name: 'plateIdPC', type: 'required'},
            {name: 'refCyclerPublic', type: 'required'}
        ],

        onCheckboxExportPlateLayoutClick: function (e) {
            if (e.currentTarget.checked) {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', false);
            } else {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', true);
                this.ui.checkboxExportPlateLayoutLocal.attr('checked', false);
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            templateData.plateIdPC = this.model.get('plateIdPC');
            templateData.protocol = this.model.get('refKitProt').get('code');
            templateData.prepRuns = this.model.get('prepRuns').pluck('code').join(', ');
            templateData.assays = this.model.findAssayLines().toJSON();
            templateData.displayLocalDownload = this.options.exportPlateLayoutSetted;
            templateData.pcrExportPlateLayout = this.options.pcrExportPlateLayout;
            templateData.printPcrSetup = this.options.printPcrSetup;
            templateData.printPcrSetupMMX = this.options.printPcrSetupMMX;
            return templateData;
        },

        onRender: function () {
            this.triggerMethod('enable:cancel:confirm');
            var CyclerPublicService = require('services/caccounts/cyclerpublics');
            this.getRegion('cyclerRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerPublicService.getAutocompleteParam({
                    modelProperty: 'refCyclerPublic',
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code',
                        'plateSize': this.model.get('plateSize'),
                        'kitProtSecId': this.model.get('pcrProtocol') ? this.model.get('pcrProtocol').get('code') : null
                    },
                    callBackOnChange: _.bind(this.onChangeRefCyclerPublic, this)
                }))
            ));
        },

        onChangeRefCyclerPublic: function (fieldName, model) {
            model = model ? model.toJSON() : null;
            this.onChange(fieldName, model);
        },

        onConfirm: function () {
            if (!this.validate(this.fieldsToValidate)) {
                return;
            }
            $('.js-global-loader').show();
            this.model.saveHeader().done(_.bind(function () {
                if (this.ui.checkboxExportPlateLayout.prop('checked')) {
                    var downloadFile = this.ui.checkboxExportPlateLayoutLocal.prop('checked');
                    this.model.exportPlateLayout({downloadFile: downloadFile}).done(function (isFile, fileName, fileContent) {
                        if (isFile) {
                            var element = document.createElement('a');
                            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
                            element.setAttribute('download', fileName);

                            element.style.display = 'none';
                            document.body.appendChild(element);

                            element.click();

                            document.body.removeChild(element);
                        } else {
                            Bootbox.alert({
                                message: _.i18n('pcrSetupCreated.plateLayoutExported'),
                                backdrop: true,
                                className: 'success'
                            });
                        }
                    });
                }
                var PcrRunService = require('services/caccounts/pcrruns');
                if (this.ui.checkboxPrintPcrSetup.prop('checked')) {
                    PcrRunService.generatePcrSetup(this.model.get('secId'));
                }
                if (this.ui.checkboxPrintPcrSetupMMX.prop('checked')) {
                    PcrRunService.generatePcrSetupMMX(this.model.get('secId'));
                }
                this.hide();
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    });
});

