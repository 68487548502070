define('standardCurve',[
    'module',
    'template!standardCurve',
    'underscore',
    'jquery',
    'dialogFormView',
    'dynamicCreateEditList',
    'dynamicCreateEditDescriptionCommentTags',
    'dynamicCreateEditCodeName',
    'settings',
    'savingBehavior',
    'dynamicCreateEditCustomListToList'
], function (
    module,
    Tpl,
    _,
    $,
    DialogFormView,
    DynamicCreateEditList,
    DynamicCreateEditDescriptionCommentTags,
    DynamicCreateEditCodeName,
    Settings,
    SavingBehavior,
    DynamicCreateEditCustomListToList
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            regionSamples: '.js-samples',
            regionResults: '.js-results',
            regionDescription: '.js-description',
            regionCodeName: '.js-codeName',
            regionBioGroups: '.js-bioGroup-region'
        },
        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        fieldsToValidate: [
            {name: 'fromCt', type: 'required'},
            {name: 'toCt', type: 'required'}
        ],

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },
        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('code');
            templateData.name = this.model.get('name');
            templateData.descriptionKitLot = this.model.get('descriptionKitLot');
            templateData.dilution = this.model.get('dilution');
            templateData.fromCt = this.model.get('fromCt');
            templateData.toCt = this.model.get('toCt');
            templateData.commentRangeBelow = this.model.get('commentRangeBelow');
            templateData.commentRangeAbove = this.model.get('commentRangeAbove');
            templateData.alreadyValues = this.model.get('results').any(function (result) {
                return result.get('values').length > 0;
            });
            return templateData;
        },


        onRender: function () {
            var StandardCurveSampleService = require('services/caccounts/standardCurve/standardCurveSamples');
            this.getRegion('regionSamples').show(new DynamicCreateEditList({
                field: 'samples',
                model: this.model,
                collection: this.model.get('samples'),
                dynamicConfigurationField: {service: StandardCurveSampleService}
            }));
            var StandardCurveAssayConfigurationResultService = require('services/caccounts/standardCurve/standardCurveAssayConfigurationResults');
            this.getRegion('regionResults').show(new DynamicCreateEditList({
                field: 'results',
                model: this.model,
                collection: this.model.get('results'),
                dynamicConfigurationField: {service: StandardCurveAssayConfigurationResultService}
            }));
            var StandardCurveService = require('services/caccounts/standardCurve/standardCurves');
            this.getRegion('regionCodeName').show(new DynamicCreateEditCodeName({
                model: this.model,
                service: StandardCurveService
            }));
            this.getRegion('regionDescription').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model,
                service: StandardCurveService
            }));
            this.showChildView('regionBioGroups', new DynamicCreateEditCustomListToList({
                'field': 'bioGroups',
                'model': this.model
            }));

            var service = require('services/caccounts/standardCurve/standardCurves');
            this.setPermissions(service.canAddOrEdit());
        },
        onConfirm: function () {
            if (this.validate(this.fieldsToValidate) && this.model.get('samples').every(function (model) {
                return model.get('sample');
            })) {
                $('.js-global-loader').show();
                this.model.save().done(_.bind(function () {
                    $('.js-global-loader').hide();
                    this.hide();
                }, this));
            }
        },
        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        }
    });
});

