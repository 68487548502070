
define('template!arrayToolTip', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12 p-l-5 p-r-5" style="width: 400px;">\n    <label>' +
((__t = ( code )) == null ? '' : __t) +
' : ' +
((__t = ( name )) == null ? '' : __t) +
'</label>\n    <hr class="m-b-5 m-t-5"/>\n    ';
 _.each(items, function(item) { ;
__p += '\n    <div class="row m-b-5">\n        <div class="col-xs-8 t-a-r">' +
((__t = ( item.key )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-4">\n            ';
 switch(item.value.contentType) {
            case 'COL': ;
__p += '\n            <div style="width: 100%; height: 100%; background-color: ' +
((__t = ( item.value.value )) == null ? '' : __t) +
'; border: 1px solid #80808066; padding: 5px 5px 5px 5px;" disabled="disabled" readonly="readonly"/>\n            ';
 break;
            default: ;
__p += '\n            ' +
((__t = ( item.value.value )) == null ? '' : __t) +
'\n            ';
 break;
            } ;
__p += '\n        </div>\n    </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

