define('runTabCollectionView',[
    'module',
    'backbone.marionette',
    'runTabItemView'
], function (
    module,
    Marionette,
    TabItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'nav nav-pills center',
        tagName: 'ul',
        childView: TabItemView,
        collectionEvents: {
            'current': 'onChangeCurrent'
        },
        childViewOptions: function () {
            return {
                runModel: this.model
            };
        },
        onChangeCurrent: function (tab) {
            this.children.each(function (child) {
                child.select(child.model === tab);
            });
        }
    });
});
