define('translationsFilterLanguageButtonView',[
    'module',
    'backbone.marionette',
    'template!translationsFilterLanguageButtonTpl'
], function (
    module,
    Marionette,
    filterButtonTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: filterButtonTpl,

        collectionEvents: {
            change: 'render'
        }
    });
});
