define('propertiesController',[
    'module',
    'underscore',
    'bootbox',
    'propertiesView'
], function (
    module,
    _,
    Bootbox,
    PropertiesView
) {
    'use strict';

    module.exports = {
        showProperties: function (model) {
            if (!model.get('properties')) {
                Bootbox.alert(_.i18n('no.properties'));
                return;
            }
            var properties = _.chain(model.get('properties'))
                .map(function (property) {
                    return {
                        name: property.name,
                        value: property.value
                    };
                })
                .sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                })
                .value();
            var view = new PropertiesView({
                data: properties
            });
            view.show({
                title: _.i18n('properties')
            });
        }
    };
});

