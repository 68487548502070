define('entities/caccounts/species',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lisSpecies'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.Specie = Backbone.RelationalModel.extend({
        service: 'services/caccounts/species',
        fetch: function () {
            this.url = Settings.url('rest/v2/species/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'lisSpecies': [],
            'tags': [],
            'criterias': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lisSpecies',
            relatedModel: 'LisSpecie',
            collectionType: 'LisSpecieCollection',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/species/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SpecieJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Specie',

        importable: true
    });

    app.ns.Specie.role = RolesMixin.SPECIE;
});

