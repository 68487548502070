define('services/global/text',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'textCustomJqGridView',
    'translationsFilterLanguagesLayoutView',
    'bootbox',
    'entities/global/text'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    TextCustomJqGridView,
    FilterLanguageLayoutView,
    Bootbox
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Text,
                collection: app.ns.TextCollection
            };
        },
        getName: function () {
            return 'text';
        },
        getUrl: function () {
            return 'rest/public/texts/';
        },
        getDynamicJson: function () {
            return 'TextJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.duplicable = false;
            config.selectable = false;
            config.attachmentable = false;
            config.customLeft = new TextCustomJqGridView({translation: this});
            config.customRight = new FilterLanguageLayoutView({translation: this});
            return config;
        },
        getJqGridColumn: function (viewDynamic, options) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            });

            var languages = options.collection.filter(function (language) {
                return language.get('checked');
            });
            _.each(languages, _.bind(function (language) {
                columns.push({
                    label: language.get('name'),
                    name: language.get('abbreviation'),
                    formatter: _.bind(this.languageFormatter, this),
                    search: true,
                    index: 'text',
                    width: 40
                });
            }, this));

            return columns;
        },

        languageFormatter: function (cellValue, options, rowObject) {
            var model = _.findWhere(rowObject.values, {locale: options.colModel.name});
            if (model) {
                return model.value;
            }
            return '';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['app']};
        },
        upload: function (fileInfo) {
            var formData = new FormData();
            formData.append('file', fileInfo);
            var defer = $.Deferred();

            defer.resolve($.ajax({
                type: 'POST',
                url: Settings.url('rest/v2/i18n.json/excel'),
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false
            }));

            return defer.promise();
        },
        download: function () {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', Settings.url('rest/v2/i18n.json/excel'), true);
            xhr.responseType = 'blob';

            xhr.onload = _.bind(function () {
                if (xhr.status === 200) {
                    var currentDate = new Date();
                    var formattedDate = currentDate.toISOString().slice(0, 10) + '_' +
                        currentDate.toISOString().slice(11, 19).replace(/:/g, '-');
                    // Obtenir l'URL de la page actuelle
                    var currentUrl = window.location.href;
                    var urlObject = new URL(currentUrl);
                    var fileName = 'i18n_' + urlObject.hostname + '_' + formattedDate + '.xlsx';

                    var blob = xhr.response;

                    // Créer une URL à partir de l'objet Blob
                    var blobUrl = URL.createObjectURL(blob);

                    // Créer un lien <a> avec l'URL Blob
                    var downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;

                    // Spécifier le nom du fichier à télécharger
                    downloadLink.download = fileName;

                    // Ajouter le lien à la page
                    document.body.appendChild(downloadLink);

                    // Simuler un clic sur le lien pour déclencher le téléchargement
                    downloadLink.click();

                    // Supprimer le lien après le téléchargement
                    document.body.removeChild(downloadLink);

                    // Libérer l'URL Blob après le téléchargement
                    URL.revokeObjectURL(blobUrl);
                }
                Bootbox.alert({
                    title: _.i18n('common.success'),
                    message: _.i18n('common.translationsDownloaded'),
                    className: 'success'
                });

            }, this);
            xhr.send();
        },
        showEditAdd: function (model, callBack) {
            if (!model) {
                model = this.getModel({});
            }
            this.showDetails({model: model, callBackRefresh: callBack});
        },
        showDetails: function (param) {
            require(['translationsTableController'], function (controller) {
                controller.showDetails({service: param.model.service, model: param.model, callBackOnClose: param.callBackOnClose});
            });
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

