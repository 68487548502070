
define('template!pcrWellResultHistogramNormalView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<canvas class="chart-canvas"></canvas>';

}
return __p
};});

