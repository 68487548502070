define('services/caccounts/kitlotextraction',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitlotextraction'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.KitLotExtraction,
                collection: app.ns.KitLotExtractionCollection
            };
        },
        getName: function () {
            return 'kitlotsextraction';
        },
        getUrl: function () {
            return 'rest/v2/kitlots/extraction/';
        },
        getDynamicJson: function () {
            return 'ExtractionKitLotJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                    label: _.i18n('kitLot.refKit'),
                    name: 'kit.code',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'kit.code',
                    searchoptions: {clearSearch: false},
                    width: 60
                },
                {
                    label: _.i18n('kitLot.refKitName'),
                    name: 'kit.name',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'kit.name',
                    searchoptions: {clearSearch: false},
                    width: 60
                },
                {
                    label: _.i18n('kitLot.lotId'),
                    name: 'code',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'code',
                    width: 80
                }, {
                    label: _.i18n('kitLot.status'),
                    name: 'status',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'status',
                    width: 60
                }, {
                    label: _.i18n('kitLot.quantity'),
                    name: 'deliveryQuantity',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'deliveryQuantity',
                    width: 40
                }, {
                    label: _.i18n('kitLot.deliveryDate'),
                    name: 'deliveryDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'deliveryDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.testDate'),
                    name: 'testDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'testDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.validatedDate'),
                    name: 'validatedDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'validatedDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.currentDate'),
                    name: 'currentDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'currentDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.pausedDate'),
                    name: 'pausedDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'pausedDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('kitLot.exhaustedDate'),
                    name: 'exhaustedDate',
                    formatter: viewDynamic.dateFormatterFromSettings,
                    search: true,
                    index: 'exhaustedDate',
                    width: 40,
                    sorttype: 'date',
                    searchoptions: {
                        autocomplete: 'off',
                        dataInit: function (element) {
                            $(element).datepicker({
                                dateFormat: 'dd/mm/yy',
                                changeYear: true,
                                changeMonth: true,
                                showOn: 'focus',
                                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                            });
                        }
                    }
                }, {
                    label: _.i18n('tags'),
                    name: 'tags',
                    formatter: viewDynamic.defaultFormatter,
                    sortable: false,
                    search: true,
                    index: 'tags',
                    width: 40
                }, {
                    label: _.i18n('common.description'),
                    name: 'description',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'description'
                });
            return columns;
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            };
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getJqGridSidx: function () {
            return 'kit.code';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.KitLotExtractionCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 200, 'fields': ['refKit.code']}; //'delivery', 'expiration'
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldCustom();
            ignored.push('comment');
            return ignored;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'current',
                    'param': {
                        'type': 'RADIO',
                        'display': {
                            'class': 'col-w-3',
                            'readOnly': true
                        }
                    }
                },
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'deliveryDate', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'expiration', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'deliveryQuantity', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-8', 'readOnly': true}}},
                {'field': 'status', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'statusDate', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'statusComment', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {
                    'field': 'edit',
                    'param': {
                        'type': 'POPUP',
                        'needExist': true,
                        'config': this.showDetails,
                        'display': {'class': 'col-w-7', 'mdi': 'mdi-pencil'}
                    }
                }
            ];
        },
        showDetails: function (param) {
            require(['kitLotsController'], function (Controller) {
                param.callBackOnClose = function () {
                    param.model.get('kit').fetch();
                };
                Controller.showDetails(param);
            });
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('KIT');
            return config;
        }
    }, Dynamic);
})
;

