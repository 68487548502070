define('entities/caccounts/standardCurve/standardCurveSamples',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.ns.StandardCurveSample = Backbone.RelationalModel.extend({
        service: 'services/caccounts/standardCurve/standardCurveSamples',
        defaults: {
            'sample': '',
            'dillution': '',
            mandatory: true
        },
        idAttribute: 'secId',
        importable: true
    });

    app.ns.StandardCurveSample.role = RolesMixin.STANDARDCURVE;

    app.ns.StandardCurveSampleCollection = Backbone.Collection.extend({
        model: app.ns.StandardCurveSample
    });

});

