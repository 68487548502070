
define('template!runPlateLayoutExportView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group" data-field-name="fileFormat">\n    <label class="col-xs-4" data-field-name="fileFormat">' +
((__t = ( _.i18n('import.refFileFormat') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-8 js-select-fileFormat-region"></div>\n</div>\n<div class="form-group js-all-assays-region d-f baseline" data-field-name="allAssays">\n    <label class="col-xs-4" data-field-name="allAssays">' +
((__t = ( _.i18n('export.prep.allAssays') )) == null ? '' : __t) +
'</label>\n    <input type="checkbox" class="js-info-input inputBackground form-control col-xs-1" data-field-name="allAssays" style="margin-left: 15px !important; width: 20px;"/>\n</div>\n<div class="js-cancel-export-region"></div>';

}
return __p
};});

