define('standardCurveController',[
    'module',
    'underscore',
    'backbone',
    'standardCurve',
    'standardCurveAssayConfigurationResultView',
    'bootbox',
    'jquery'
], function (
    module,
    _,
    Backbone,
    View,
    ViewDetails,
    Bootbox,
    $
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Object} param.options - The options object.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            param.options = _.defaults({model: param.model}, param.options);
            var createEditView = new View(param.options);
            createEditView.show({
                title: _.i18n(param.service.getName() + '.new'),
                className: 'baseTableEditAddPopup ' + param.service.getName() + 'EditAddPopup',
                service: param.service
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        },
        showResults: function (service, model, callBackOnClose, options) {
            if (!(model instanceof Backbone.Model) && typeof model === 'object') {
                var param = model;
                model = param.model;
                callBackOnClose = param.callBackOnClose;
                options = param.options;
            }
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            if (model.get('values').isEmpty()) {
                Bootbox.alert(_.i18n('standardCurve.result.noValues'));
                return;
            }
            options = _.defaults({model: model}, options);
            $('.js-global-loader').show();
            model.loadCurve().done(_.bind(function () {
                $('.js-global-loader').hide();
                var createEditView = new ViewDetails(options);
                createEditView.show({
                    title: _.i18n(service.getName() + '.new'),
                    className: 'baseTableEditAddPopup ' + service.getName() + 'EditAddPopup'
                }, _.bind(function () {
                    if (callBackOnClose) {
                        callBackOnClose();
                    }
                }, callBackOnClose ? callBackOnClose.context : undefined));
            }, this));
        }
    };
});

