define('services/caccounts/lis',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lis'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Lis,
                collection: app.ns.LisCollection
            };
        },
        getName: function () {
            return 'lis';
        },
        getUrl: function () {
            return 'rest/v2/lis/';
        },
        getDynamicJson: function () {
            return 'LISJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lis.sampleClasses'),
                name: 'sampleClasses',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: false,
                width: 45
            }, {
                label: _.i18n('lis.followeds'),
                name: 'lisFollowed',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameFormatter,
                sortable: false,
                search: false,
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.duplicable = false;
            return config;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'sampleClasses',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                },
                {'field': 'parameter', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'LIS_PARAM'}},
                {'field': 'lisFollowed', 'param': {'display': {'class': 'col-xs-3'}}}
            );
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'sampleClasses',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            ];
        },
        findDefault: function () {
            var defer = $.Deferred();
            var defaultValue;
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('DEFAULT-VALUES')
                .done(function (value) {
                    if (value && value.params) {
                        var paramSplitted = value.params.split(';');
                        if (paramSplitted.length > 3)
                            defaultValue = value.params.split(';')[3];
                    }
                })
                .always(_.bind(function () {
                    if (defaultValue) {
                        var url = Settings.url(this.getUrl() + '?' + Settings.generateParamUrl({
                            'sord': 'asc',
                            'sidx': 'code',
                            'code': defaultValue
                        }));

                        $.ajax({
                            type: 'GET',
                            url: url,
                            success: _.bind(function (data) {
                                defer.resolve(this.getModel(data[0]));
                            }, this)
                        });
                    } else {
                        defer.resolve(undefined);
                    }
                }, this));
            return defer.promise();
        }
    }, Dynamic);
});

