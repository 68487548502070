
define('template!arrayCustomFromTypeView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-autocomplete col-w-20 inputBackground"></div>\n<div class="js-input lightGrey-input-border col-w-80 p-l-5"></div>';

}
return __p
};});

