define('indexController',[
        'module',
        'settings',
        'menuItemsUtils',
        'app',
        'underscore'
    ],
    function (
        module,
        Settings,
        MenuItemUtils,
        App,
        _
    ) {
        'use strict';

        module.exports = {
            show: function () {
                var menuItems = MenuItemUtils.getMenuItems();
                var lastUrlPageCookie = Settings.get('lastUrlPageCookie');
                if (lastUrlPageCookie) {
                    Settings.set('lastUrlPageCookie', null);
                    App.navigate(lastUrlPageCookie, {trigger: true});
                } else if (menuItems) {
                    var menuItem = menuItems[0];
                    if (menuItem) {
                        if (_.isEmpty(menuItem.subMenu) === false) {
                            menuItem = menuItem.subMenu[0];
                        }
                        if (menuItem.link) {
                            App.navigate('#' + menuItem.link(), {trigger: true});
                        }
                    }
                }
            }
        };
    });

