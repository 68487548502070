
define('template!roleMissingView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h3>' +
((__t = ( _.i18n('role.missing.found.label') )) == null ? '' : __t) +
'</h3>\n<ul class="js-collection"></ul>\n';

}
return __p
};});

