define('dynamicListItemPropertyView',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        initialize: function () {
            if (this.options.field.get('field')) {
                this.options.field.set('fieldName', this.options.field.get('field').replaceAll('.', '-'));
            }
            if (this.options.field.get('dependsOn')) {
                this.listenTo(this.options.field.get('dependsOn').model, 'change:' + this.options.field.get('dependsOn').field, _.bind(function () {
                    setTimeout(_.bind(function () {
                        this.options.field.collection.trigger('renderModel', this.model, this.options.dynamicConfigurationField);
                    }, this), 300);
                }, this));
            }
        },
        triggers: {
            'change': 'item:change'
        },
        modelEvents: {
            'change': 'render'
        },
        className: function () {
            return 'js-info-input-' + this.options.field.get('field') + ' m-l-2 m-r-2 ' + (this.options.field && this.options.field.get('param') && this.options.field.get('param').display ? this.options.field.get('param').display['class'] : '');
        },
        attributes: function () {
            var attr = {
                'data-field-name': this.options.field.get('field'),
                'disabled': this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.readOnly,
                'readonly': this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.readOnly
            };
            if (this.model.get('param') && this.model.get('param').display && this.model.get('param').display.css) {
                attr.style = this.model.get('param').display.css;
            }
            if (this.options.field.get('dependsOn') && this.options.field.get('dependsOn').enable) {
                attr.disabled = !this.options.field.get('dependsOn').enable(this.model);
            }
            return attr;
        },

        serializeData: function () {
            return {};
        },
        onRender: function () {
            if (this.options.field.get && this.options.field.get('field')) {
                this.options.field.set(_.findWhere(this.options.dynamicConfigurationField.service.getCreateEditLine(this.model), {field: this.options.field.get('field')}));
            }
            _.each(this.attributes(), _.bind(function (attr, index) {
                this.$el.attr(index, attr);
            }, this));
        }
    });
});

