
define('template!runRemoveAssayView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group" data-field-name="assay">\n    <label class="col-xs-4" data-field-name="assay">' +
((__t = ( _.i18n('run.removeAssay') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-8 js-assay-region"></div>\n</div>\n<div class="form-group js-well-selection-region"></div>\n<div class="js-cancel-action-region"></div>';

}
return __p
};});

