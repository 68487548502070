define('jqgridMbl',[
        'jquery',
        'underscore',
        'grid.postext'],
    function (
        $,
        _) {
        'use strict';

        if (!$.jgrid.hasOwnProperty('regional')) {
            $.jgrid.regional = {};
        }

        $.jgrid.regional.labels = {
            defaults: {
                recordtext: _.i18n('jqgrid.view') + ' {0} - {1} ' + _.i18n('jqgrid.of') + ' {2}',
                emptyrecords: _.i18n('jqgrid.emptyrecords'),
                loadtext: _.i18n('jqgrid.loadtext'),
                pgtext: _.i18n('jqgrid.pgtext') + ' {0} ' + _.i18n('jqgrid.of') + ' {1}',
                savetext: _.i18n('jqgrid.savetext'),
                pgfirst: _.i18n('jqgrid.pgfirst'),
                pglast: _.i18n('jqgrid.pglast'),
                pgnext: _.i18n('jqgrid.pgnext'),
                pgprev: _.i18n('jqgrid.pgprev'),
                pgrecs: _.i18n('jqgrid.pgrecs')
            },
            search: {
                resetTitle: _.i18n('jqgrid.resetTitle')
            },
            formatter: {
                integer: {thousandsSeparator: ',', defaultValue: '0'}
            }
        };

        $.extend($.jgrid, $.jgrid.regional.labels);
    });
