define('prepWellContentView',[
    'module',
    'template!prepWellContentView',
    'underscore',
    'backbone.marionette',
    'wellUtils',
    'runController',
    'prepWellPcrWellCollection',
    'prepWellPcrKitLotCollection',
    'prepWellPcrKitLotValidationCollection',
    'prepWellPoolCollection',
    'prepWellPooledCollection'
], function (
    module,
    Tpl,
    _,
    Marionette,
    WellUtils,
    RunController,
    PcrWellCollectionView,
    PcrKitLotCollectionView,
    PcrKitLotValidationCollectionView,
    PrepWellPoolCollectionView,
    PrepWellPooledCollectionView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            assayPlus: '.assayWaiting .mdi-plus-box-outline',
            assayMinus: '.assayWaiting .mdi-minus-box-outline',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click @ui.assayPlus': 'onAddAssay',
            'click @ui.assayMinus': 'onRemoveAssay'
        },

        modelEvents: {
            'change': 'render',
            'change:smpId': 'render'
        },

        regions: {
            'pcrWells': '.js-pcrWells',
            'pcrKitLots': '.js-pcrKitLots',
            'pcrKitLotsValidation': '.js-pcrKitLotsValidation',
            'wellsPool': '.js-wellsPool',
            'wellsPooled': '.js-wellsPooled'
        },

        className: 'd-f column',

        initialize: function (options) {
            this.cSelection = options.cSelection;
            this.pcrWellsFromPrep = this.options.pcrWellsFromPrep;
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                smpId: this.model.get('smpId') ? this.model.get('smpId').get('code') : '',
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution')
            };

            templateData.otherWell = null;
            if (_.any(this.model.get('otherPrepWells'), function (well) {
                return well.repeatStatus;
            })) {
                templateData.otherWell = 'repeat';
            }
            if (templateData.otherWell === null && _.any(this.model.get('otherPrepWells'), function (well) {
                return !well.repeatStatus;
            })) {
                templateData.otherWell = 'other';
            }

            templateData.refLmbExtractor = this.model.get('run').get('extractionDetails').get('refLmbExtractor') && this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') ?
                this.model.get('run').get('extractionDetails').get('refLmbExtractor').get('code') : '-';

            templateData.refExtractionMethod = this.model.get('run').get('extractionDetails').get('refExtractionMethod') && this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') ?
                this.model.get('run').get('extractionDetails').get('refExtractionMethod').get('code') : '-';

            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'wellEditPopup');
            templateData.displayPool = !this.model.get('wellsPool').isEmpty();
            templateData.displayPooled = !this.model.get('wellsPooled').isEmpty();
            templateData.pcrKitsLotsForValidation = !this.model.get('pcrKitLotsForValidation').isEmpty();
            return templateData;
        },

        getWellResColor: function (wellRes) {
            var wellResColor;
            if (wellRes && wellRes.get('result')) {
                wellResColor = wellRes.get('result').get('color');
            } else {
                wellResColor = 'transparent';
            }
            return wellResColor;
        },

        onRender: function () {
            this.$el.find('[data-toggle="tooltip"]').tooltip();
            this.showChildView('pcrWells', new PcrWellCollectionView({
                collection: this.model.get('pcrWells')
            }));
            this.showChildView('pcrKitLots', new PcrKitLotCollectionView({
                collection: this.model.get('pcrKitLots')
            }));
            if (!this.model.get('pcrKitLotsForValidation').isEmpty()) {
                this.showChildView('pcrKitLotsValidation', new PcrKitLotValidationCollectionView({
                    collection: this.model.get('pcrKitLotsForValidation')
                }));
            }
            if (!this.model.get('wellsPool').isEmpty()) {
                this.showChildView('wellsPool', new PrepWellPoolCollectionView({
                    collection: this.model.get('wellsPool')
                }));
            }
            if (!this.model.get('wellsPooled').isEmpty()) {
                this.showChildView('wellsPooled', new PrepWellPooledCollectionView({
                    collection: this.model.get('wellsPooled')
                }));
            }
        },

        onAddAssay: function () {
            RunController.assignAssay(this.model.get('run'), this.model.get('pos'), this.model.get('pos'));
        },
        onRemoveAssay: function () {
            RunController.removeAssay(this.model.get('run'), this.model.get('pos'), this.model.get('pos'));
        }
    });
});

