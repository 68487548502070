define('runControlSampleView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runControlSampleView',
    'commonCancelActionView',
    'customBootboxMessage'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelActionView,
    CustomBootboxMessage
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            controlSidCheckBox: 'input[data-field-name="controlDuplicateSidCheckbox"]',
            controlSidLengthCheckbox: 'input[data-field-name="controlSidLengthCheckbox"]'
        },

        regions: {
            cancelActionRegion: '.js-cancel-action-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        initialize: function () {
            this.model = new Backbone.Model({controlSidCheckBox: true, controlSidLengthCheckbox: true});
        },

        onRender: function () {
            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);
            this.commonCancelActionView.enableButtons(true);
            this.ui.controlSidCheckBox.attr('checked', this.model.get('controlSidCheckBox'));
            this.ui.controlSidLengthCheckbox.attr('checked', this.model.get('controlSidLengthCheckbox'));
        },

        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.controlSample()
                .done(_.bind(function (controlSampleResponse) {
                    if ((controlSampleResponse.duplicateSampleId && Object.getOwnPropertyNames(controlSampleResponse.duplicateSampleId).length > 0) ||
                        (controlSampleResponse.sampleClassWithoutLIS && Object.getOwnPropertyNames(controlSampleResponse.sampleClassWithoutLIS).length > 0) ||
                        controlSampleResponse.sampleDiversCount > 0) {
                        var text = '';
                        var textSet = false;

                        var wrongSmpIdCount = 0;
                        _.each(controlSampleResponse.sampleClassWithoutLIS, function () {
                            wrongSmpIdCount++;
                        });

                        if (this.model.get('controlSidLengthCheckbox') && wrongSmpIdCount > 0) {
                            textSet = true;
                            text = _.i18n('plateLayout.sampleId.sampleClassWithoutLis') + '<br/><div class="row">';

                            text = text + '<div class="col-xs-4">' + _.i18n('sampleClass') + '</div>';
                            text = text + '<div class="col-xs-4">' + _.i18n('well') + '</div>';
                            text = text + '<div class="col-xs-4">' + _.i18n('sampleId') + '</div>';
                            _.each(controlSampleResponse.sampleClassWithoutLIS, function (wells, p) {
                                _.each(wells, function (pos) {
                                    text = text + '<div class="col-xs-4" style="font-weight: normal;">' + p + '</div>';
                                    text = text + '<div class="col-xs-4" style="font-weight: normal;">' + pos + '</div>';
                                    var wellModel = this.get('wells').findWhere({pos: pos});
                                    text = text + '<div class="col-xs-4" style="font-weight: normal;">' + (wellModel ? wellModel.get('smpId').get('code') : '') + '</div>';
                                });
                            });
                            text = text + '</div>';
                        }

                        var duplicateCount = 0;
                        _.each(controlSampleResponse.duplicateSampleId, function () {
                            duplicateCount++;
                        });

                        if (this.model.get('controlSidCheckBox') && duplicateCount > 0) {
                            textSet = true;
                            text = text + _.i18n('plateLayout.sampleId.duplicate') + '<br/><div class="row">';

                            for (var i in controlSampleResponse.duplicateSampleId) {
                                text = text + '<div>' + i + '</div>' +
                                    '<div><ul>';
                                for (var j in controlSampleResponse.duplicateSampleId[i]) {
                                    text = text + '<li>' + controlSampleResponse.duplicateSampleId[i][j] + '</li>';
                                }
                                text = text + '</ul></div>';
                            }
                            text = text + '</div><br/>';
                        }

                        if (controlSampleResponse.sampleDiversCount > 0) {
                            textSet = true;
                            text = text + _.i18n('plateLayout.sampleId.divers') + ' : ' + controlSampleResponse.sampleDiversCount;
                        }

                        if (textSet) {
                            var params = {
                                message: text,
                                type: 'info'
                            };
                            CustomBootboxMessage.customAlert(params);
                        }
                    } else {
                        CustomBootboxMessage.customThumbUpNotification();
                    }
                    this.hide();
                }, this))
                .always(_.bind(function () {
                    loading.hide();
                }, this));
        }
    });
});
