/**
 * Created by OLD on 06/08/2015.
 */
define('thankYouShowView',[
    'module',
    'backbone.marionette',
    'app',
    'template!thankYouViewTpl'
], function (
    module,
    Marionette,
    App,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,

        ui: {
            message: '.thank-you-message'
        },

        className: 'login-form-container',

        onRender: function () {
            this.ui.message.text(this.options.target);

            setTimeout(function () {
                App.navigate('/');
            }, 5000);
        }
    });
});
