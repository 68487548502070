
define('template!pcrSetupPcrRunEmpty', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12 m-0 p-0" style="display: flex; align-items: center; flex-direction: column;">\n    <span class="mdi mdi-plus" style="color: lightgrey; font-size: 100px; line-height: 100px;"></span>\n    <label style="color: lightgrey; font-size: 50px; line-height: 50px; cursor: pointer;">' +
((__t = (
        _.i18n('pcrSetup.pcrRun.add') )) == null ? '' : __t) +
'</label>\n</div>';

}
return __p
};});

