define('propertiesGridView',[
    'module',
    'underscore',
    'settings',
    'jqgridView2'
], function (
    module,
    _,
    Settings,
    JqGridView
) {
    'use strict';

    module.exports = JqGridView.extend({
        template: _.template('<table id="jq-grid-list-properties" role="grid"></table>'),
        filtersName: 'pcrRunTracsFilters',

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 999,
                currentPage: 1
            };
        },

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list-properties',
                jqgRow: 'tr.jqgrow'
            }, this.gridUi);
        },

        events: function () {
            return _.extend({}, this.gridEvents);
        },

        serializeData: function () {
            return {};
        },

        gridOptions: function (data) {
            var defaultsOptions, options,
                _colModel = [];

            _colModel.push({
                key: true,
                label: _.i18n('property.name'),
                name: 'name',
                formatter: this.defaultFormatter,
                search: true,
                searchoptions: {sopt: ['cn'], clearSearch: false},
                sortable: true,
                index: 'name',
                width: 400
            });
            _colModel.push({
                label: _.i18n('property.value'),
                name: 'value',
                formatter: this.defaultFormatter,
                search: true,
                searchoptions: {sopt: ['cn'], clearSearch: false},
                sortable: true,
                index: 'value',
                width: 260
            });
            defaultsOptions = this.gridInitOptions(data);
            options = {
                colModel: _colModel,
                autowidth: false
            };

            options.sortorder = 'ASC';
            options = _.defaults(options, defaultsOptions);
            return options;
        },

        onGridLoaded: function () {
            var data = {
                data: this.options.data
            };
            this.displayGrid(data);
        }
    });
});

