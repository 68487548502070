define('prepRunCreateLayoutView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!prepRunCreateLayoutView',
    'savingBehavior',
    'underscore',
    'jquery',
    'bootbox',
    'app',
    'settings',
    'autocompleteView',
    'moment',
    'dynamicCreateEditDate'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    bootbox,
    App,
    Settings,
    AutocompleteView,
    moment,
    DynamicDate
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            numberOfRunRegion: '.js-number-of-run-region',
            numberOfRun: '.number-of-run',
            generateStarterSheet: '.generate-starter-sheet',
            selectedDate: '.js-selectedDate',
            forceRunName: '.js-runNameForced'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'change @ui.generateStarterSheet': 'onChangeGenerateStarterSheet',
            'change @ui.forceRunName': 'onChangeForceRunName'
        },

        regions: {
            selectRespUser: '.js-select-respUser-region',
            selectRunPattern: '.js-runName-pattern',
            selectRunNameDepartment: '.js-select-runName-department',
            selectRunNameGroup: '.js-select-runName-runGroup',
            selectRunNameSequenceFormat: '.js-select-runName-sequenceFormat',
            selectRunTemplate: '.js-select-run-template-region',
            selectDate: '.js-selectDate-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [
            {name: 'selectedDate', type: 'required'}
        ],

        currentMode: 'fromTemplate',
        generateStarterSheet: false,


        initialize: function () {
            this.model = new Backbone.Model();
            this.currentMode = 'fromTemplate';
            this.model.set('name', '');
            this.model.set('refRespUser', '');
            this.model.set('suffix', '');
            this.model.set('generateStarterSheet', false);
            this.model.set('numberOfRun', 1);
            this.model.set('nameForced', false);
            this.model.set('selectedDate', parseInt(moment().format('x'), 10));
            this.nameForced = false;
            if (!this.model.get('pattern') || this.model.get('pattern') === '') {
                this.model.set('pattern', '');
            }
        },

        onChangeForceRunName: function (event) {
            this.nameForced = event.target.checked;
            this.model.set('nameForced', this.nameForced);
            if (this.nameForced) {
                this.model.set('numberOfRun', 1);
                $('.js-number-of-run-region').css('display', 'none');
            } else {
                $('.js-number-of-run-region').css('display', 'block');
            }
        },

        onDepartmentChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onRunGroupChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onRunSequenceFormatChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        onPatternChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
                this.model.set(fieldName, model.get('code'));
            } else {
                this.model.set(fieldName, null);
            }
        },

        showSelectRunTemplateView: function () {
            if (this.selectRunTemplateView) {
                this.selectRunTemplateView.destroy();
            }
            var PrepRunTemplateService = require('services/caccounts/prepruntemplates');
            this.selectRunTemplateView = new AutocompleteView(
                this._getAutocompleteOptionObject(PrepRunTemplateService.getAutocompleteParam({
                    modelProperty: 'prepRunTemplate',
                    callBackOnChange: _.bind(this.onTemplateChange, this)
                }))
            );
            this.getRegion('selectRunTemplate').show(this.selectRunTemplateView);
            this.triggerMethod('enable:cancel:confirm');
        },

        showPatternDropDown: function () {
            if (this.selectPatternView) {
                this.selectPatternView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectPatternView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'pattern',
                        codeListCode: 'PATTERN_PREP',
                        callBackOnChange: this.onPatternChange
                    })));
            this.getRegion('selectRunPattern').show(this.selectPatternView);
        },

        showDepartmentDropDown: function () {
            if (this.selectDepartmentView) {
                this.selectDepartmentView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectDepartmentView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'department',
                        codeListCode: 'DEPT',
                        callBackOnChange: this.onDepartmentChange
                    })));
            this.getRegion('selectRunNameDepartment').show(this.selectDepartmentView);
        },

        showRunGroupDropDown: function () {
            if (this.selectRunGroupView) {
                this.selectRunGroupView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectRunGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'group',
                        codeListCode: 'RUNGROUP_PREP',
                        callBackOnChange: this.onRunGroupChange
                    })));
            this.getRegion('selectRunNameGroup').show(this.selectRunGroupView);
        },

        showSequenceFormatDropDown: function () {
            if (this.selectRunSequenceFormatView) {
                this.selectRunSequenceFormatView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectRunSequenceFormatView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'sequenceFormat',
                        codeListCode: 'SEQUENCE',
                        callBackOnChange: this.onRunSequenceFormatChange
                    })));
            this.getRegion('selectRunNameSequenceFormat').show(this.selectRunSequenceFormatView);
        },

        onChangeGenerateStarterSheet: function (event) {
            this.model.set('generateStarterSheet', event.target.checked);
        },

        onRender: function () {
            this.showPatternDropDown();
            this.showDepartmentDropDown();
            this.showRunGroupDropDown();
            this.showSequenceFormatDropDown();
            this.getRegion('selectRunTemplate').options.allowMissingEl = true;
            this.showSelectRunTemplateView();
            this.triggerMethod('enable:cancel:confirm');
            this.showRefRespUserDropDown();
            this.showChildView('selectDate', new DynamicDate({
                model: this.model,
                field: 'selectedDate',
                value: this.model.get('selectedDate')
            }));
        },

        showRefRespUserDropDown: function () {
            if (this.respUserView) {
                this.respUserView.destroy();
            }
            var UserpidService = require('services/caccounts/userpids');
            this.respUserView = new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'refRespUser',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            );
            this.getRegion('selectRespUser').show(this.respUserView);
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model : null);
        },

        onTemplateChange: function (fieldName, model) {
            this.model.set('prepRunTemplate', model);

            this.model.set('generateStarterSheet', false);
            if (model) {

                if (model.get('pattern') && model.get('pattern') !== '') {
                    this.model.set('pattern', model.get('pattern'));
                    this.showPatternDropDown();
                }
                if (model.get('department') && model.get('department') !== '') {
                    this.model.set('department', model.get('department'));
                    this.showDepartmentDropDown();
                }
                if (model.get('group') && model.get('group') !== '') {
                    this.model.set('group', model.get('group'));
                    this.showRunGroupDropDown();
                }
                if (model.get('sequenceFormat') && model.get('sequenceFormat') !== '') {
                    this.model.set('sequenceFormat', model.get('sequenceFormat'));
                    this.showSequenceFormatDropDown();
                }
                if (model.get('suffix') && model.get('suffix') !== '') {
                    this.model.set('suffix', model.get('suffix'));
                    $('.js-runName-suffix').find('input').val(model.get('suffix'));
                }
                if (model.get('pattern') && model.get('pattern') !== '') {
                    this.model.set('pattern', model.get('pattern'));
                    $('.js-runName-pattern').find('input').val(model.get('pattern'));
                }
                this.model.set('generateStarterSheet', model.get('printRunSheet'));
            }
            this.ui.generateStarterSheet.attr('checked', this.model.get('generateStarterSheet'));

            this.ui.selectedDate.removeClass('invalid');
            this.ui.numberOfRun.removeClass('invalid');
            this.ui.numberOfRunRegion.closest('div[data-field-name=numberOfRun]').removeClass('has-error');

            this.model.set('numberOfRun', 1);
        },

        onConfirm: function () {
            var CreateRunFromTemplate = Backbone.Model.extend({
                defaults: {
                    'prepRunTemplate': '',
                    'selectedDate': '',
                    'numberOfRun': ''
                },
                idAttribute: 'secId',

                postUrl: function () {
                    return Settings.url('rest/v2/runs/prep/fromTemplate');
                },

                save: function () {
                    this.url = this.postUrl();
                    return Backbone.Model.prototype.save.call(this);
                }
            });
            var createRunFromTemplate = new CreateRunFromTemplate();
            createRunFromTemplate.set('prepRunTemplate', (this.model.get('prepRunTemplate') && this.model.get('prepRunTemplate').get('secId') ? this.model.get('prepRunTemplate').get('secId') : ''));
            createRunFromTemplate.set('suffix', (this.model.get('suffix') ? this.model.get('suffix') : ''));
            createRunFromTemplate.set('nameForced', (this.model.get('nameForced') ? this.model.get('nameForced') : false));
            createRunFromTemplate.set('name', (this.model.get('name') ? this.model.get('name') : ''));
            createRunFromTemplate.set('pattern', (this.model.get('pattern') ? this.model.get('pattern') : ''));
            createRunFromTemplate.set('department', (this.model.get('department') ? this.model.get('department') : ''));
            createRunFromTemplate.set('group', (this.model.get('group') ? this.model.get('group') : ''));
            createRunFromTemplate.set('sequenceFormat', (this.model.get('sequenceFormat') ? this.model.get('sequenceFormat') : ''));
            createRunFromTemplate.set('respUserSecId', (this.model.get('refRespUser') && this.model.get('refRespUser').get('secId') ?
                this.model.get('refRespUser').get('secId') : ''));

            var needToCheckNumberOfRuns = true;
            if (this.model.get('nameForced')) {
                needToCheckNumberOfRuns = false;
                createRunFromTemplate.set('numberOfRun', this.model.get('numberOfRun'));
            }

            if (!this.validate([{name: 'prepRunTemplate', type: 'required'}])) {
                bootbox.alert(_.i18n('run.prep.template.required'));
                return;
            }

            if (!this.validate([{name: 'selectedDate', type: 'required'}]) ||
                !this.validate([{name: 'selectedDate', type: 'datePicker'}])) {
                bootbox.alert(_.i18n('run.prep.date.required'));
                return;
            }
            createRunFromTemplate.set('selectedDate', moment(this.model.get('selectedDate')).format());

            if (needToCheckNumberOfRuns) {
                if (!this.validate([{name: 'numberOfRun', type: 'required'}])) {
                    bootbox.alert(_.i18n('run.prep.numberofrun.required'));
                    return;
                }
                var numberOfRun = parseInt(this.model.get('numberOfRun'), 10);
                var maxNumberOfRun = parseInt(this.$el.find(this.ui.numberOfRun).attr('max'), 10);
                if (numberOfRun > maxNumberOfRun) {
                    bootbox.alert(_.i18n('run.prep.numberofrun.max', maxNumberOfRun));
                    return;
                }
                createRunFromTemplate.set('numberOfRun', numberOfRun);
            } else {
                createRunFromTemplate.set('numberOfRun', 1);
            }

            var loader = $('.js-global-loader');
            loader.show();

            createRunFromTemplate.save().done(
                _.bind(function (result) {
                    this.options.callBackOnClose(result);
                    if (this.model.get('generateStarterSheet')) {
                        var runSecIds = result.map(function (run) {
                            return run.secId;
                        });
                        this.options.service.generateRunSheet(runSecIds);
                    }
                    this.hide();
                }, this)
            ).fail(
                _.bind(function (response) {
                    if (response.status === 409) {
                        this.ui.selectedDate.addClass('invalid');
                    }
                }, this)
            ).always(function () {
                loader.hide();
            });
        },

        onSave: function () {
            this.hide();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onFocusOut: function () {
            var $target,
                datepicker;

            setTimeout(_.bind(function () {
                $target = $(document.activeElement);
                datepicker = $target.parents('.datepicker-element').length > 0 ||
                    $target.parents('.ui-datepicker').length > 0 ||
                    $target.hasClass('ui-datepicker') ||
                    $target.get(0) === this.$el.get(0) ||
                    $target.hasClass('popover-shown');

                if (!datepicker) this.trigger('close:popover');
            }, this), 0);
        }
    });
});

