define('services/caccounts/colorCompensations',[
    'entities/ns',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/colorCompensations'
], function (
    app,
    _,
    Dynamic,
    module
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.ColorCompensation,
                collection: app.ns.ColorCompensationCollection
            };
        },
        getName: function () {
            return 'colorCompensation';
        },
        getUrl: function () {
            return 'rest/v2/colorCompensations/';
        },
        getDynamicJson: function () {
            return 'ColorCompensationJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 30
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 50
            }, {
                label: _.i18n('colorCompensation.temperature'),
                name: 'temperature',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'temperature',
                width: 10
            }, {
                label: _.i18n('colorCompensation.sampleIdWater'),
                name: 'sampleIdWater',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'sampleIdWater',
                width: 30
            }, {
                label: _.i18n('colorCompensation.sampleIds'),
                name: 'sampleIds',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: false,
                index: 'sampleIds',
                width: 100
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'sampleIds', 'param': {'type': 'ARRAYLIST', 'arrayCode': 'CC_SAMPLE'}},
                {'field': 'temperature', 'param': {'type': 'INTEGER', 'display': {'class': 'col-xs-1'}}},
                {'field': 'sampleIdWater', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-2'}}},
                {'field': 'lmbCyclers', 'param': {'type': 'LISTTOLIST'}},
                {
                    'field': 'matrices', 'param': {'type': 'LIST'}
                },
                {
                    'field': 'assays', 'param': {'type': 'LIST2ENTITY'}
                }
            );
            return custom;
        }
    }, Dynamic);
});

