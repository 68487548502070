define('entities/languages',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    _
) {
    'use strict';

    var languageSettingsKey = 'translationLanguages';

    app.ns.Language = Backbone.RelationalModel.extend({
        service: 'services/global/languages',
        idAttribute: 'abbreviation'
    });

    app.ns.LanguageCollection = Backbone.Collection.extend({
        model: app.ns.Language,

        comparator: 'abbreviation',

        labels: [
            'label-primary',
            'label-success',
            'label-info',
            'label-warning',
            'label-danger',
            'label-violet-bg',
            'label-blue-bg',
            'label-yellow-bg',
            'label-purple-bg',
            'label-deep-purple-bg',
            'label-indigo-bg',
            'label-cyan-bg',
            'label-green-bg',
            'label-red-bg',
            'label-pink-bg',
            'label-brown-bg',
            'label-deep-orange-bg',
            'label-amber-bg',
            'label-orange-bg',
            'label-lime-bg',
            'label-light-green-bg',
            'label-blue-grey-bg',
            'label-grey-bg'
        ],

        /**
         * Fetches languages from the server
         * @param {boolean} admin - Whether the user is an admin
         * @param {boolean} autoTranslate - Whether to auto translate
         * @returns {Promise} A promise that resolves with the fetched languages
         */
        getLanguages: function (admin, autoTranslate) {
            var defer = $.Deferred(),
                languages = [];
            if (autoTranslate) {
                $.ajax({
                    url: Settings.url('rest/v2/languages/', {admin: admin, autoTranslate: autoTranslate}),
                    success: _.bind(function (response) {
                        var collection = [];
                        _.each(response, _.bind(function (language, index) {
                            collection.push({
                                abbreviation: language.code,
                                name: language.name,
                                label: this.labels[index] ? this.labels[index] : this.labels[this.labels.length - 1]
                            });
                        }, this));
                        this.reset(collection);
                        defer.resolve(this);
                    }, this)
                });
            } else {
                if (Settings.get(languageSettingsKey) && !admin) {
                    this.reset(Settings.get(languageSettingsKey));
                    defer.resolve(this);
                } else {
                    $.ajax({
                        url: Settings.url('rest/v2/languages/', {admin: admin, autoTranslate: autoTranslate}),
                        success: _.bind(function (response) {
                            _.each(response, _.bind(function (language, index) {
                                languages.push({
                                    abbreviation: language.code,
                                    name: language.name,
                                    label: this.labels[index] ? this.labels[index] : this.labels[this.labels.length - 1]
                                });
                            }, this));
                            Settings.set(languageSettingsKey, languages);
                            this.reset(languages);

                            defer.resolve(this);
                        }, this)
                    });
                }
            }

            return defer.promise();
        }
    });
});

