
define('template!commonCancelActionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<button class="btn popupBtn-cancel  cancel-b js-cancel-popup">\n    <span class="mdi mdi-close" style="line-height: 40px; font-size: 50px;"></span>\n</button>\n<button class="btn btn-success popupBtn-confirm disabled-grey js-action" disabled>\n    <span class="mdi mdi-send" style="line-height: 40px; font-size: 50px;"></span>\n</button>';

}
return __p
};});

