define('colorCompensationMatriceValueLineCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'colorCompensationMatriceValueLineItem'
], function (
    module,
    Backbone,
    Marionette,
    View
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: View,
        childViewOptions: function () {
            return {
                manual: this.options.manual,
                result: this.options.result
            };
        },
        attributes: function () {
            return {style: 'margin: auto;'};
        }
    });
});
