
define('template!dynamicCreateEditDescriptionComment', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="description">\n    <textarea class="form-control js-info-input inputBackground description"\n              wrap="on" data-field-name="description" id="description"\n              placeholder="' +
((__t = ( _.i18n('description') )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n</div>\n<div class="commentTag">\n    <textarea class="form-control js-info-input inputBackground col-xs-11"\n              data-field-name="comment" id="comment"\n              placeholder="' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'" style="margin-bottom: 10px;">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n</div>\n<div class="information">\n    <div>\n        <span class="mdi mdi-database-plus" title="' +
((__t = ( _.i18n('created') )) == null ? '' : __t) +
'"></span>\n        <p id="created" >' +
((__t = ( created )) == null ? '' : __t) +
'<br/><span title="' +
((__t = ( createdByTitle )) == null ? '' : __t) +
'"> ' +
((__t = ( createdBy )) == null ? '' : __t) +
'</span></p>\n    </div>\n    <div>\n        <span class="mdi mdi-database-edit" title="' +
((__t = ( _.i18n('updated') )) == null ? '' : __t) +
'"></span>\n        <p id="updated">' +
((__t = ( updated )) == null ? '' : __t) +
'<br/><span title="' +
((__t = ( updatedByTitle )) == null ? '' : __t) +
'"> ' +
((__t = ( updatedBy )) == null ? '' : __t) +
'</span></p>\n    </div>\n</div>';

}
return __p
};});

