define('dynamicCreateEditTypeParam',[
    'module',
    'dialogFormView',
    'backbone',
    'underscore',
    'template!dynamicCreateEditTypeParam',
    'autocompleteView',
    'arrayInputView'
], function (
    module,
    DialogFormView,
    Backbone,
    _,
    tpl,
    AutocompleteView,
    ArrayInputView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        regions: {
            autocompleteRegion: '.autocomplete-region',
            input: '.input'
        },

        className: 'col-w-all col-w-100 d-b',

        modelEvents: {
            'change': 'render',
            'change:param': 'updateModel'
        },

        initialize: function () {
            this.model = new Backbone.Model(this.options.model.get(this.options.fieldName));
        },

        serializeData: function () {
            this.options.display = {
                type: {
                    'class': 'col-w-20'
                },
                param: {
                    'class': 'col-w-80'
                }
            };
            return this.options;
        },

        onRender: function () {
            var config = this.options.getAutocompleteParam;
            config.callback = this.onChangeValue;
            config.modelProperty = 'type';
            config.readOnly = this.options.readOnly;
            var autocomplete = new AutocompleteView(this._getAutocompleteOptionObject(config));
            this.getRegion('autocompleteRegion').show(autocomplete);

            if (this.options.customInput) {
                this.getRegion('input').show(this.options.customInput());
            } else {
                this.getRegion('input').show(new ArrayInputView({
                    model: this.model,
                    field: new Backbone.Model({
                        field: 'param',
                        param: {
                            arrayCode: this.model.get('type'),
                            readOnly: this.options.readOnly
                        }
                    })
                }));
            }
        },

        onChangeValue: function (fieldName, model) {
            var value = model ? model.get('code') : null;
            var triggerChange = value !== this.model.get(fieldName);
            this.onChange(fieldName, value);
            this.model.set({'param': null}, {silent: true});
            this.updateModel(triggerChange);
            if (triggerChange) {
                this.model.trigger('change');
            }
        },

        updateModel: function (triggerChange) {
            var data = {};
            data[this.options.fieldName] = this.model.toJSON();
            this.options.model.set(data, {silent: true});
            if (triggerChange) {
                this.options.model.trigger('change:' + this.options.fieldName);
            }
        }
    });
});
