
define('template!editWellTargetView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row m-t-5 m-b-5">\n    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <label class="m-b-0 clickable" data-html="true"\n                   data-toggle="tooltip"\n                   title="' +
((__t = ( propC )) == null ? '' : __t) +
'">' +
((__t = ( mbAnaCode )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-2 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-2 js-result" style="display: flex; align-items: flex-end;"></div>\n        <div class="col-w-all col-w-7 p-t-5 p-b-0">\n            ';
 if(canEdit) { ;
__p += '\n            <div class="js-mbAnaRes"></div>\n            ';
 } else { ;
__p += '\n            <div class="css-readOnlyField"\n                 style="height: 28px; border: solid 1px #c6c6c6; font-size: 18px;">\n                ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n            </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <input autocomplete="off"\n                   class="' +
((__t = ( canEdit ? '' : 'css-readOnlyField' )) == null ? '' : __t) +
' js-ui-cts\n                   form-control floating-label js-code dataNameIdInput noWrapHidden"\n                   data-field-name="cts" data-placement="top"\n                   id="' +
((__t = ( cts )) == null ? '' : __t) +
'"\n                   placeholder=""\n                   type="text" title="' +
((__t = ( cts)) == null ? '' : __t) +
'" value="' +
((__t = ( cts)) == null ? '' : __t) +
'" ' +
((__t = (!canEdit ? 'readonly' : '' )) == null ? '' : __t) +
'>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <input autocomplete="off"\n                   class="' +
((__t = ( canEdit ? '' : 'css-readOnlyField' )) == null ? '' : __t) +
' js-ui-quantifications\n                   form-control floating-label js-code dataNameIdInput noWrapHidden"\n                   data-field-name="quantifications" data-placement="top"\n                   id="' +
((__t = ( quantifications )) == null ? '' : __t) +
'"\n                   placeholder=""\n                   type="text" title="' +
((__t = ( quantifications)) == null ? '' : __t) +
'" value="' +
((__t = ( quantifications)) == null ? '' : __t) +
'" ' +
((__t = (!canEdit ? 'readonly' : ''
            )) == null ? '' : __t) +
'>\n        </div>\n\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-30 p-t-5 p-b-0">\n            <input autocomplete="off" class="' +
((__t = ( canEdit ? '' : 'css-readOnlyField' )) == null ? '' : __t) +
'\n            form-control floating-label js-info-input dataNameIdInput"\n                   data-field-name="comment"\n                   data-placement="top"\n                   placeholder=""\n                   type="text" value="' +
((__t = ( comment)) == null ? '' : __t) +
'" ' +
((__t = (!canEdit ? 'readonly' : '' )) == null ? '' : __t) +
'>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n        <div class="col-w-all col-w-4 p-t-5 p-b-0">\n            ';
 if(canEdit && !isOVAR) { ;
__p += '\n            <div class="js-select-codeErr-region"></div>\n            ';
 } else { ;
__p += '\n            <div class="css-readOnlyField" style="height: 28px; border: solid 1px #c6c6c6; font-size: 18px;">\n                ' +
((__t = ( codeErr )) == null ? '' : __t) +
'\n            </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-30 p-t-5 p-b-0">\n            <input autocomplete="off" class="css-readOnlyField\n            form-control floating-label js-info-input js-code dataNameIdInput"\n                   data-field-name="justificatory"\n                   data-placement="top"\n                   placeholder=""\n                   type="text" value="' +
((__t = ( justificatory )) == null ? '' : __t) +
'" readonly>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

