/**
 * Created by OBL on 14/01/2016.
 */
define('runPrepController',[
    'require',
    'module',
    'app',
    'underscore',
    'prepRunCreateLayoutView',
    'runController'
], function (
    require,
    module,
    App,
    _,
    CreateEditLayoutView,
    RunController
) {
    'use strict';


    module.exports = _.extend({}, RunController, {

        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         */
        showDetails: function (param) {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('DEF_DEPT').done(function (defDept) {
                var createEditView = new CreateEditLayoutView({
                    newItem: true,
                    defDept: defDept,
                    runModel: param.model,
                    callBackOnClose: function (result) {
                        if (!param.callBackOnClose && result.length === 1) {
                            App.navigate('runs/prep/' + result[0].secId, {trigger: true});
                        } else if (param.callBackOnClose) {
                            param.callBackOnClose(result);
                        } else {
                            App.navigate('runs/prep', {trigger: true});
                        }
                    },
                    service: param.service
                });
                createEditView.show({
                    title: _.i18n('run.prep.new'),
                    className: 'actionPopupCss900 actionPopupCss popupFitContent',
                    service: param.service
                });
            });
        },


        importPlateLayout: function (model) {
            RunController.importPlateLayout(model, 'IMPORT-PLATELAYOUT-PREP');
        },
        importSampleList: function (model) {
            RunController.importSampleList(model, 'IMPORT-SAMPLELIST-PREP');
        },
        createFromPlateLayoutFile: function () {
            RunController.createFromPlateLayoutFile('IMPORT-PLATELAYOUT-PREP', require('services/caccounts/prepruns'), 'runs/prep');
        },
        createFromSampleListFile: function () {
            RunController.createFromSampleListFile('IMPORT-SAMPLELIST-PREP', require('services/caccounts/prepruns'), 'runs/prep');
        }
    });
});

