define('services/caccounts/connector/connectorHl7Tracings',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'connectorHL7TracingCustomJqGridView',
    'settings',
    'entities/caccounts/connector/connectorHl7Tracings'
], function (
    app,
    _,
    module,
    Dynamic,
    ConnectorHL7TracingCustomJqGridView,
    Settings
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.ConnectorHL7Tracing,
                collection: app.ns.ConnectorHL7TracingCollection
            };
        },
        getName: function () {
            return 'connectorHl7Tracing';
        },
        getUrl: function () {
            return 'rest/v2/connector/hl7/tracing/';
        },
        getDynamicJson: function () {
            return 'Request';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('connector.hl7.analysisDevice'),
                name: 'analysisDevice',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'analysisDevice',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.created'),
                name: 'created',
                classes: 'dynamic-link',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'created',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('connector.hl7.updated'),
                name: 'updated',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'updated',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.sampleId'),
                name: 'sampleId',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'sampleId',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('connector.hl7.status'),
                name: 'status',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                searchoptions: {size: 10},
                index: 'status',
                width: 40
            }, {
                label: _.i18n('connector.hl7.error'),
                name: 'error',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'error'
            }, {
                label: _.i18n('connector.hl7.lastAction'),
                name: 'lastAction',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'lastAction'
            });
            return columns;
        },

        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.creatable = false;
            config.duplicable = false;
            config.customLeft = new ConnectorHL7TracingCustomJqGridView({service: this});
            return config;
        },
        getJqGridSidx: function () {
            return 'created';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        resend: function (secIds) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/connector/hl7/tracing/resend');

            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(secIds),
                contentType: 'application/json',
                success: function () {
                    defer.resolve();
                },
                error: _.bind(function (xhr) {
                    defer.reject(xhr);
                }, this)
            });

            return defer.promise();
        }
    }, Dynamic);
});

