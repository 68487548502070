define('entities/caccounts/assayVersion',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assayReagentVersion',
    'entities/caccounts/assayResultVersion',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup',
    'entities/caccounts/assay',
    'entities/caccounts/colorCompensations'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.AssayVersion = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayVersion',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayVersions/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'algoDeathVolume': {type: 'NO', param: ''}
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayResultVersion',
            collectionType: 'AssayResultVersionCollection',
            reverseRelation: {
                key: 'assayVersion',
                includeInJSON: ['secId', 'code', 'name']
            }
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'AssayReagentVersion',
            collectionType: 'AssayReagentVersionCollection',
            reverseRelation: {
                key: 'assayVersion',
                includeInJSON: ['secId', 'code', 'name']
            }
        }, {
            type: Backbone.HasOne,
            key: 'kitProt',
            relatedModel: 'KitProt'
        }, {
            type: Backbone.HasOne,
            key: 'assay',
            relatedModel: 'Assay'
        }, {
            type: Backbone.HasOne,
            key: 'assayGroup',
            relatedModel: 'AssayGroup'
        }, {
            type: Backbone.HasOne,
            key: 'colorCompensation',
            relatedModel: 'ColorCompensation',
            includeInJSON: ['secId', 'code', 'name']
        }],

        jsonObjectName: 'AssayVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayVersion',

        importable: true,
        getReagentsQC: function () {
            return new app.ns.AssayReagentVersionCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && assayReagent.get('reagent').get('type') === 'QC';
            }));
        },
        getReagentIC: function () {
            var model = _.first(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && ['IC'].includes(assayReagent.get('reagent').get('type'));
            }));
            if (!model) {
                model = new app.ns.AssayReagentVersion({assayVersion: this});
            }
            return model;
        },
        getReagentsMMX: function () {
            return new app.ns.AssayReagentVersionCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && !['QC', 'IC'].includes(assayReagent.get('reagent').get('type')) && assayReagent.get('reagent').get('type');
            }));
        }
    });

    app.ns.AssayVersion.role = [RolesMixin.ASSAY, RolesMixin.RUN_PCR];

    app.ns.AssayVersionCollection = Backbone.Collection.extend({
        model: app.ns.AssayVersion
    });
});

