define('systemModuleController',[
    'module',
    'systemModuleView'
], function (
    module,
    SystemModuleView
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.region - The region where the view is to be shown.
         */
        showDetails: function (param) {
            var systemService = require('services/system');
            systemService.findModules().done(function (data) {
                var view = new SystemModuleView({
                    collection: data
                });
                param.region.show(view);
            });
        }
    };

});

