define('arrayCriCurveView',[
	'module',
	'underscore',
	'autocompleteView',
	'dialogFormView',
	'template!arrayCustomCurveView',
	'app',
	'entities/admin/dye',
	'arrayEncodeEditLayoutController',
	'backbone'
], function (
	module,
	_,
	AutocompleteView,
	DialogFormView,
	Tpl,
	App,
	Dyes,
	ArrayEncodeEditLayoutController,
	Backbone
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		regions: {
			autocomplete: '.js-autocomplete'
		},

		modelEvents: {
			'change:param': 'updateModel'
		},

		initialize: function () {
			this.model = new Backbone.Model(this.options.model.get('algoResult'));
			var split = this.model.get('param') ? this.model.get('param').split(';') : [];
			this.model.set('dyeName', null);
			if (split.length > 0) {
				this.model.set('dyeName', split[0]);
			}
			if (split.length > 1) {
				this.model.set('param', _.rest(split).join(';'));
			}
		},
		serializeData: function () {
			return {dyeName: this.model.get('dyeName')};
		},
		onRender: function () {
			var view = new AutocompleteView(
				this._getAutocompleteOptionObject(Dyes.getAutocompleteParam({
						modelProperty: 'dyeName',
						callBackOnChange: this.onChangeDye,
						valueProperty: 'shortName',
						queryProperty: 'shortName',
						paramUrl: {
							'sord': 'asc',
							'sidx': 'channel',
							'cyclerSecId': this.options.cyclerPublicSecId
						}
					})
				));
			this.getRegion('autocomplete').show(view);
		},
		onChangeDye: function (fieldName, model) {
			this.onChange(fieldName, model ? model.get('shortName') : null);
			this.updateModel();
		},
		updateModel: function () {
			var data = {};
			data.algoResult = {
				type: this.model.get('type'),
				param: this.model.get('dyeName') + (this.model.get('param') ? ';' + this.model.get('param') : '')
			};
			this.options.model.set(data, {silent: true});
			this.options.model.trigger('change:algoResult');
		}
	});
});
