
define('template!fileExplorer', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12">\n	<label class="col-xs-4" for="fileExplorerFilter">' +
((__t = ( _.i18n('fileExplorer.filter') )) == null ? '' : __t) +
'</label>\n	<input id="fileExplorerFilter" type="text" value="" class="input">\n</div>\n<div class="col-xs-12" id="file-tree"></div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>';

}
return __p
};});

