
define('template!pcrKitLotForValidationItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td class="pcrReagent">' +
((__t = ( pcrReagent )) == null ? '' : __t) +
'</td>\n<td class="pcrKit" title="' +
((__t = ( kit.name )) == null ? '' : __t) +
'">' +
((__t = ( kit.code )) == null ? '' : __t) +
' (' +
((__t = ( currentKitLotCode ? currentKitLotCode : '-' )) == null ? '' : __t) +
')</td>\n<td><select class="form-control pcrKitLot">\n    <option value="" selected></option>\n    ';
 _.each(pcrKitLots, function(pcrKitLot) { ;
__p += '\n    <option value="' +
((__t = ( pcrKitLot.secId )) == null ? '' : __t) +
'">' +
((__t = ( pcrKitLot.code )) == null ? '' : __t) +
'</option>\n    ';
 }); ;
__p += '\n</select>\n</td>\n<td class="action">\n    ';
 if(!canUpdate) { ;
__p += '\n    ' +
((__t = ( _.i18n('well.pcrKitValidation.alreadyDefined') )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</td>\n';

}
return __p
};});

