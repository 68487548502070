
define('template!sampleCreateEditLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="js-header-region"></div>\n<div class="row p-l-15 p-r-15 m-t-20">\n    <label class="col-xs-2 text-right">' +
((__t = ( _.i18n('sample.patientInformation') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-10">' +
((__t = ( patient )) == null ? '' : __t) +
'</div>\n</div>\n\n<!-- lisAna -->\n<div class="row p-l-15 p-r-15 m-t-20">\n    <div class="col-xs-12 p-t-10 p-b-10 rounded">\n        <div class="col-xs-4">\n            <label for="lisAna">' +
((__t = ( _.i18n('sample.lisAna.open') )) == null ? '' : __t) +
'<span class="m-l-5 text-muted">' +
((__t = ( lisAnaOpenedUpdated ? '(' + lisAnaOpenedUpdated + ')' : '' )) == null ? '' : __t) +
'</span></label>\n            <span class="mdi mdi-playlist-edit clickable js-edit-lisana"\n                  style="float: right; line-height: 20px; font-size: 19px;"></span>\n            <select multiple class="form-control" id="lisAna">\n                ';
 _.each(lisAnas, function(lisAna) { ;
__p += '\n                <option\n                ' +
((__t = ( lisAna.entityError ? 'class="invalid"' : '' )) == null ? '' : __t) +
'>' +
((__t = ( lisAna.code )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </div>\n        <div class="col-xs-4">\n            <label for="target">' +
((__t = ( _.i18n('sample.target') )) == null ? '' : __t) +
'</label>\n            <select multiple class="form-control" id="target">\n                ';
 _.each(targets, function(target) { ;
__p += '\n                <option>' +
((__t = ( target )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </div>\n        <div class="col-xs-4">\n            <label for="gAssay">' +
((__t = ( _.i18n('sample.gAssay') )) == null ? '' : __t) +
'</label>\n            <select multiple class="form-control" id="gAssay">\n                ';
 _.each(assayGroups, function(assayGroup) { ;
__p += '\n                <option>' +
((__t = ( assayGroup )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </div>\n    </div>\n</div>\n\n<!-- results -->\n<div class="row p-l-15 p-r-15 m-t-20 js-pcrwell-region-display">\n    <div class="col-xs-12 rounded">\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-100">\n                <div class="col-w-all col-w-100 d-f m-t-6">\n                    <label>' +
((__t = ( _.i18n('pcrrun') )) == null ? '' : __t) +
'</label>\n                </div>\n\n                <div class="js-pcrwell-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<!-- results -->\n<div class="row p-l-15 p-r-15 m-t-20 js-prepwell-region-display">\n    <div class="col-xs-12 rounded">\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-100">\n                <div class="col-w-all col-w-100 d-f m-t-6">\n                    <label>' +
((__t = ( _.i18n('preprun') )) == null ? '' : __t) +
'</label>\n                </div>\n\n                <div class="js-prepwell-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<!-- results -->\n<div class="row p-l-15 p-r-15 m-t-20 js-results-region-display">\n    <div class="col-xs-12 rounded">\n        <div class="col-w-all col-w-100 d-f">\n            <div class="col-w-all col-w-100">\n                <div class="col-w-all col-w-100 d-f m-t-6">\n                    <label>' +
((__t = ( _.i18n('result') )) == null ? '' : __t) +
'</label>\n                </div>\n\n                <div class="js-results-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="row p-l-15 p-r-15 m-t-20">\n    <div class="col-xs-12 rounded">\n        <div class="row p-l-15 p-r-15 m-b-10">\n            <label class="col-xs-2 p-0 border-bottom">' +
((__t = ( _.i18n('sample.tracing.date') )) == null ? '' : __t) +
'</label>\n            <label class="col-xs-1 p-0 border-bottom">' +
((__t = ( _.i18n('sample.tracing.user') )) == null ? '' : __t) +
'</label>\n            <label class="col-xs-3 p-0 border-bottom">' +
((__t = ( _.i18n('sample.tracing.routingActionRoutingGroup')
                )) == null ? '' : __t) +
'</label>\n        </div>\n        ';
 _.each(tracing, function(trac) { ;
__p += '\n        <div class="row p-l-15 p-r-15 m-b-10">\n            <div class="col-xs-2 p-0 border-bottom">' +
((__t = ( trac.date )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-1 p-0 border-bottom" title="' +
((__t = ( trac.title )) == null ? '' : __t) +
'">' +
((__t = ( trac.user )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-3 p-0 border-bottom">' +
((__t = ( trac.routingActionRoutingGroup )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 }) ;
__p += '\n    </div>\n</div>\n\n\n<div class="row p-l-15 p-r-15 m-t-20 js-responseQuery">\n    <div class="col-xs-12 rounded">\n        <label for="responseQuery">' +
((__t = ( _.i18n('sample.responseQuery') )) == null ? '' : __t) +
'</label>\n        <pre id="responseQuery">' +
((__t = ( responseQuery )) == null ? '' : __t) +
'</pre>\n    </div>\n</div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

