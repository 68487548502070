define('entities/caccounts/pcrruntracs',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.PcrRunTrac = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrruntracs',
        fetch: function () {
            this.url = Settings.url('rest/v2/runtracs/pcr/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'creatDate': null,
            'refUserpid': {'nickname': ''},
            'action': '',
            'level': '',
            'comment': '',
            'target': 0,
            'wells': [''],
            'refRun': {'name': ''}
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/runtracs/pcr/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PcrRunTracJson.pdf');
        }
    });

    app.ns.PcrRunTrac.role = RolesMixin.RUN_PCR;

    app.ns.PcrRunTracCollection = Backbone.Collection.extend({
        model: app.ns.PcrRunTrac
    });
});

