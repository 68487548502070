define('entities/caccounts/lmbsamplehandlers',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.LmbSampleHandler = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lmbsamplehandlers',
        fetch: function () {
            this.url = Settings.url('rest/v2/lmbsamplehandlers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'identifier': '',
            'location': '',
            'refSampleHandler': {}
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/lmbsamplehandlers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LmbSampleHandlerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LmbSampleHandler',

        importable: true
    });

    app.ns.LmbSampleHandler.role = RolesMixin.LMBSAMPLEHANDLER;

    app.ns.LmbSampleHandlerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lmbsamplehandlers');
        },
        model: app.ns.LmbSampleHandler
    });

});

