/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemNumber',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            return _.extend(attr, {
                'type': 'number',
                'value': this.model.get(this.options.field.get('field'))
            });
        }
    });
});
