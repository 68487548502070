
define('template!runGenerateSampleIdResponseView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group">\n    <label class="col-xs-3 p-r-0">' +
((__t = ( _.i18n('run.sampleId.generate.sampleClass') )) == null ? '' : __t) +
'</label>\n    <input class="form-control disabled readonly"\n           disabled\n           readonly type="text" value="' +
((__t = ( sampleClass.code )) == null ? '' : __t) +
'">\n    <div class="col-xs-1 p-l-0 js-select-smpType" style="line-height: 25px;"></div>\n</div>\n<div class="form-group">\n    <label class="col-xs-3 p-r-0">' +
((__t = ( _.i18n('run.sampleId.generate.sampleClass.order') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-7 p-r-1">\n        <input class="form-control disabled readonly t-a-r"\n               disabled\n               readonly type="text" value="' +
((__t = ( sampleClass.orderPrefix )) == null ? '' : __t) +
'">\n    </div>\n    <div class="col-xs-2 p-l-1">\n        <input class="form-control  disabled readonly"\n               disabled\n               readonly type="text" value="' +
((__t = ( sampleClass.orderIncrement )) == null ? '' : __t) +
'">\n    </div>\n</div>\n\n<div class="form-group">\n    <label class="col-xs-3 p-r-0">' +
((__t = ( _.i18n('run.sampleId.generate.sampleClass.order') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-7 js-collection">\n    </div>\n</div>';

}
return __p
};});

