
define('template!prepRunCreateBeadPoolingItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group">\n    <label class="col-xs-3 popupTitleLabel" style="text-align: right;">' +
((__t = ( _.i18n('run.run') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-8">\n        <div class="js-select-run-region"></div>\n    </div>\n    <div class="col-xs-1">\n        <span class="mdi mdi-delete clickable" style="line-height: 20px; font-size: 26px;"></span>\n    </div>\n</div>';

}
return __p
};});

