
define('template!commonWellSelectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-3 form-group m-r-0 m-l-0 js-start" data-field-name="start" style="display: flex; flex-direction: row; align-items: baseline;">\n    <label class="m-r-5" data-field-name="start">' +
((__t = ( _.i18n('run.assignAssay.from') )) == null ? '' : __t) +
'</label>\n    <input class="js-info-input form-control" data-field-name="start" data-placement="top"\n           onkeyup="this.value = this.value.toUpperCase();"\n           style="width: 45px;"\n           type="text" value="' +
((__t = ( start )) == null ? '' : __t) +
'">\n</div>\n<div class="col-xs-3 form-group m-r-0 m-l-0 js-to" data-field-name="to" style="display: flex; flex-direction: row; align-items: baseline;">\n    <label class="m-r-5" data-field-name="to">' +
((__t = (
        _.i18n('run.assignAssay.to') )) == null ? '' : __t) +
'</label>\n    <input class="js-info-input form-control" data-field-name="to" data-placement="top"\n           onkeyup="this.value = this.value.toUpperCase();"\n           style="width: 45px;"\n           type="text" value="' +
((__t = ( to )) == null ? '' : __t) +
'">\n</div>\n<div class="col-xs-6 form-group m-r-0 m-l-0" data-field-name="direction" style="display: flex; flex-direction: row; align-items: baseline;">\n    <label class="m-r-5" data-field-name="direction" style="text-wrap: none">' +
((__t = ( _.i18n('run.pcr.importPlateLayoutFrom.sourceDirection') )) == null ? '' : __t) +
'</label>\n    <div class="js-plate-fill-direction-region" data-field-name="direction" style="width: 45px;"></div>\n</div>';

}
return __p
};});

