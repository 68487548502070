
define('template!pcrSetupPrepWellItemHeader', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="header">' +
((__t = ( name )) == null ? '' : __t) +
'</div>';

}
return __p
};});

