define('colorCompensationMatriceValueColumnItemHeader',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        attributes: function () {
            var width = this.options.result ? 'width: 60px; font-size: 18px;': 'width: 40px; font-size: 14px;';
            return {style: width + 'font-weight: bold; display: flex; justify-content: center; align-items: center;'};
        },
        template: _.template('<%= value %>'),
        serializeData: function () {
            return {value: this.model.get('value')};
        }

    });
});
