/**
 * Created by OLD on 20/08/2015.
 */
define('dateUtils',[
	'module',
	'moment'
], function (module, moment) {
	'use strict';

	module.exports = {
		toDateFormatStringFromSettings: function (inputValue, settings) {
			if (inputValue === '' || inputValue === null || inputValue === undefined) {
				console.error('invalid date or missing');
				return '';
			}
			var format = 'DD/MM/YYYY';
			if (settings && settings.DATEFMT &&
				settings.DATEFMT.split(';') &&
				settings.DATEFMT.split(';').length > 0 &&
				settings.DATEFMT.split(';')[0] !== '' &&
				settings.DATEFMT.split(';')[0] !== null &&
				settings.DATEFMT.split(';')[0] !== undefined) {
				format = settings.DATEFMT.split(';')[0];
			}
			return moment(inputValue).format(format);
		},

		toDateTimeFormatStringFromSettings: function (inputValue, settings) {
			var format = 'DD/MM/YYYY HH:mm:ss';
			if (settings && settings.DATEFMT &&
				settings.DATEFMT.split(';') &&
				settings.DATEFMT.split(';').length > 3 &&
				settings.DATEFMT.split(';')[3] !== '' &&
				settings.DATEFMT.split(';')[3] !== null &&
				settings.DATEFMT.split(';')[3] !== undefined) {
				format = settings.DATEFMT.split(';')[3];
			}
			return moment(inputValue).format(format);
		},

		toMDYFormat: function (inputValue) {
			var format = new RegExp('^(0?[1-9]|[12][0-9]|3[01])/' +
					'(0?[1-9]|1[012])/((19|20)\\d\\d)$'),
				dateArr,
				formattedDate;

			if (format.test(inputValue)) {
				dateArr = inputValue.split('/');
				formattedDate = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
			}

			return formattedDate ? formattedDate : undefined;
		},

		toDateFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY');
		},

		toDateTimeFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY HH:mm');
		},

		toDateTimeShortYearFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YY HH:mm');
		},

		toDateTimeSecondeFormatString: function (inputValue) {
			return moment(inputValue).format('DD/MM/YYYY HH:mm:ss');
		},

		// hh:mm
		toTimeFormatString: function (inputValue) {
			return moment(inputValue).format('HH:mm');
		},

		getDateFormat: function () {
			return 'dd-mm-yy';
		}
	};
});

