define('dynamicCreateEditCustomListToString',[
    'module',
    'formView',
    'backbone',
    'underscore'
], function (
    module,
    FormView,
    Backbone,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= values.join(\', \') %>'),

        serializeData: function () {
            var templateData = this.options;
            if (templateData.values instanceof Backbone.Collection) {
                templateData.values = templateData.values.toJSON();
            }
            if (templateData.values && templateData.values.length > 0) {
                if (templateData.values[0].code) {
                    templateData.values = _.pluck(templateData.values, 'code');
                }
            } else {
                templateData.values = [];
            }
            return templateData;
        }
    });
});
