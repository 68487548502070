/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemEmpty',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        tagName: 'div'
    });
});
