
define('template!runPcrTooltip', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="run-pcr-tooltip">\n    <div class="title">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n\n    <div>\n        <div class="status">\n            <div class="text">' +
((__t = ( valStatus )) == null ? '' : __t) +
'</div>\n            <div class="bar">\n                ';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n                <div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( valStatus.includes(i) ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="plateSize">\n            <div>' +
((__t = ( wellsSizePlateIcon )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="information">\n            <div class="header">\n                <div class="departement">' +
((__t = ( _.i18n('department') )) == null ? '' : __t) +
'</div>\n                <div class="period">' +
((__t = ( _.i18n('period') )) == null ? '' : __t) +
'</div>\n                <div class="group">' +
((__t = ( _.i18n('group') )) == null ? '' : __t) +
'</div>\n                <div class="seq">' +
((__t = ( _.i18n('seq') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="content">\n                <div class="departement">' +
((__t = ( department ? department : '-' )) == null ? '' : __t) +
'</div>\n                <div class="period">' +
((__t = ( periodFormatted ? periodFormatted : '-' )) == null ? '' : __t) +
'</div>\n                <div class="group">' +
((__t = ( group ? group : '-' )) == null ? '' : __t) +
'</div>\n                <div class="seq">' +
((__t = ( sequenceFormatted )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n\n    <div>\n        <div class="information">\n            <div class="header">\n                <div class="kitProt">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n                <div class="lmbCycler">' +
((__t = ( _.i18n('run.refLmbCycler') )) == null ? '' : __t) +
'</div>\n                <div class="respUser">' +
((__t = ( _.i18n('run.refRespUser') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="content">\n                <div class="kitProt">' +
((__t = ( kitProt )) == null ? '' : __t) +
'</div>\n                <div class="lmbCycler">' +
((__t = ( lmbCycler )) == null ? '' : __t) +
'</div>\n                <div class="respUser">' +
((__t = ( respUser )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n\n    ';
 if (assaysToDisplay) { ;
__p += '\n    <div>\n        <div class="information">\n            <div class="header">\n                <div class="assays">' +
((__t = ( _.i18n('run.assays') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="content">\n                <div class="assays">' +
((__t = ( assaysToDisplay )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n\n    ';
 if (formattedPcrRuns) { ;
__p += '\n    <div>\n        <div class="information">\n            <div class="header">\n                <div class="pcrPrepRuns">' +
((__t = ( _.i18n('run.pcr.prepRuns') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="content">\n                <div class="pcrPrepRuns">' +
((__t = ( formattedPcrRuns )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n\n    ';
 if (comment) { ;
__p += '\n    <div>\n        <div class="information">\n            <div class="header">\n                <div class="comment">' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="content">\n                <div class="comment">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

