define('textCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!textCustomJqGridView'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .mdi-file-upload': 'onFileUploadClick',
            'change #translations-upload': 'onUploadTranslations',
            'click .mdi-file-download': 'onDownloadTranslations',
            'click .mdi-magnify': 'onSearchTranslations'
        },

        ui: {
            searchInput: '.js-text',
            uploadInput: '#translations-upload'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        onFileUploadClick: function () {
            this.ui.uploadInput.click();
        },
        onUploadTranslations: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.options.translation.upload(e.target.files[0]);
            }
        },
        onDownloadTranslations: function () {
            this.options.translation.download();
        },
        onSearchTranslations: function () {
            var inputs = $('input[name="text"]');
            inputs.val(this.ui.searchInput.val());
            inputs.change();
        }
    });
});
