define('jqgridFormatters',[
  'module',
  'underscore',
  'jqgridActionsButtonFormatterView',
  'jqgridActionsButtonFormatterActions',
  'backbone'
], function(
  module,
  _,
  ActionsButtonView,
  availableActions,
  Backbone
) {
  'use strict';

  module.exports = {
    actionsButtonViews: [],

    actionsButtonFormatter: function (actions) {
      var self = this;
      return function (cellvalue, colModel) {
        var that = this,
            actionsCollection = new Backbone.Collection(),
            actionsButtonView;
        if (!that.$el) {
          throw 'Method should be run in view\'s context. Please use _.bind()';
        }

        _.each(actions, function (action) {
          actionsCollection.push(availableActions[action]);
        });
        actionsButtonView = new ActionsButtonView({
          collection: actionsCollection,
          colModel: colModel
        });
        self.actionsButtonViews.push(actionsButtonView);
        this.listenTo(actionsButtonView, 'action', this.onEdit);
        actionsButtonView.render();

        return actionsButtonView.el.innerHTML;
      };
    },

    destroyActionsButtonViews: function () {
      _.each(this.actionsButtonViews, function (item) {
        item.destroy();
      });
      this.actionsButtonViews = [];
    }
  };
});
