
define('template!runControlSampleView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container controlSidPopup t-a-c">\n        <label class="popupTitleLabel" for="controlSidLengthCheckbox">' +
((__t = ( _.i18n('platelayout.ctrl.sid.length') )) == null ? '' : __t) +
'</label>\n        <div class="row m-b-15 m-t-5">\n            <input class="controlSidCheckBox js-info-input" data-field-name="controlSidLengthCheckbox"\n                   id="controlSidLengthCheckbox" name="controlSidLengthCheckbox"\n                   style="transform: scale(2);" type="checkbox"/>\n        </div>\n\n        <label class="popupTitleLabel" for="controlDuplicateSidCheckbox">' +
((__t = ( _.i18n('platelayout.ctrl.sid.duplicate') )) == null ? '' : __t) +
'</label>\n        <div class="row m-b-15 m-t-5">\n            <input class="controlSidCheckBox js-info-input" data-field-name="controlDuplicateSidCheckbox"\n                   id="controlDuplicateSidCheckbox" name="controlDuplicateSidCheckbox"\n                   style="transform: scale(2);" type="checkbox"/>\n        </div>\n\n        <div class="js-cancel-action-region"></div>\n    </div>\n</div>';

}
return __p
};});

