define('runPcrValidationTargetsResultItem',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<span class="mdi mdi-menu-right"></span><div class="sequence"><%= sequence %> : </div><div><%= code %></div>'),
        className: 'target',
        serializeData: function () {
            return {
                sequence: this.model.get('sequenceValidation'),
                code: this.model.get('target').get('code')
            };
        },
        ui: {
            carret: '.mdi-menu-right'
        },
        events: {
            'click': 'onClick'
        },
        initialize: function () {
            this.listenTo(this.options.validationModel, 'change:assayResultVersion', this.onRender);
        },
        onRender: function () {
            var current = this.options.validationModel.get('assayResultVersion') === this.model;
            this.$el.toggleClass('current', current);
            this.$el.toggleClass('clickable', !current);
        },
        onClick: function () {
            this.options.validationModel.set('assayResultVersion', this.model);
        }
    });
});

