define('pcrRunTracsView',[
    'module',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!pcrRunTracsTpl',
    'tracsView'
], function (
    module,
    _,
    $,
    DialogFormView,
    PcrRunTracsTpl,
    TracsView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: PcrRunTracsTpl,

        regions: {
            pcrRunTracsRegion: '.js-pcrRun-tracs-region'
        },

        onRender: function () {
            this.view = new TracsView({
                model: this.options.model
            });
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.getRegion('pcrRunTracsRegion').show(this.view);
        },

        onGridLoaded: function () {
            var data;
            var PcrRunTracService = require('services/caccounts/pcrruntracs');
            var url = PcrRunTracService.getCollectionUrl();
            var rowCountUrl = PcrRunTracService.getCountUrl();
            data = {
                url: url,
                rowCountUrl: rowCountUrl
            };

            var filters = {};
            filters.runSecId = this.model.get('secId');
            if (this.options.type === 'wellTracks') {
                filters.runSecId = this.model.get('run').get('secId');
                filters.position = this.model.get('pos');
            }
            this.view.setFilter(filters);
            this.view.displayGrid(data);
        },

        serializeData: function () {
            return {};
        }
    });
});

