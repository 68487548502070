/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemBoolean',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<input type="checkbox" class="js-info-input js-checkbox" style="width: 20px;"/>'),
        tagName: 'div',
        events: {
            'change .js-checkbox': 'onInputChange'
        },
        ui: {
            'input': '.js-checkbox'
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            if (attr.style) {
                attr.style = attr.style + 'display: flex;';
            } else {
                attr.style = 'display: flex;';
            }
            return attr;
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            _.each(this.el.attributes, _.bind(function (attr) {
                if (attr.name !== 'class') {
                    this.ui.input.attr(attr.name, attr.value);
                }
            }, this));
            this.ui.input.prop('checked', !!this.model.get(this.options.field.get('field')));
        },
        serializeData: function () {
            return {};
        }
    });
});
