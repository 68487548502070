
define('template!arrayEncodeEditLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-10 m-t-10" style="font-size: 20px;font-weight: 600;">\n        ' +
((__t = (array.code)) == null ? '' : __t) +
' : ' +
((__t = (array.name)) == null ? '' : __t) +
'\n    </div>\n    <div class="col-xs-2 m-t-10">\n        <span class="mdi-action-info-outline  js-view-doc"></span>\n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-9 m-t-10">\n        <label class="control-label">' +
((__t = ( _.i18n('setting.oldparams') )) == null ? '' : __t) +
'</label>\n        <textarea\n                id="rawparams" style="resize: vertical;width:100%;"\n                data-field-name="params"\n                placeholder="' +
((__t = ( _.i18n('setting.params') )) == null ? '' : __t) +
'"\n        ' +
((__t = ( readOnly? 'readonly' : '' )) == null ? '' : __t) +
'>' +
((__t = ( rawParams )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="col-xs-3 m-t-10" style="margin-top:30px;">\n        <span class="mdi mdi-content-copy js-copy-parameters clickable" ></span>\n        ';
 if(!readOnly) { ;
__p += '\n        ';
 if(accessToClipboard) { ;
__p += '\n        <span class="mdi mdi-content-paste js-paste-parameters clickable"></span>\n        ';
 } ;
__p += '\n        <span class="mdi mdi-delete js-delete-parameters clickable"></span>\n        ';
 } ;
__p += '\n    </div>\n</div>\n<label class="control-label">' +
((__t = ( _.i18n('setting.params') )) == null ? '' : __t) +
'</label>\n<div class="form-group js-collection-region">\n</div>\n\n<div class="row">\n    ';
 if(!readOnly){ ;
__p += '\n    <div class="pull-right p-r-15">\n        <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n        <button class="btn btn-primary disabled-grey js-confirm" disabled>' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n    </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

