
define('template!exportResultStatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="font16">\n	';
 if(success) { ;
__p += '\n	<div class="row m-l-0 m-r-0" style="border: solid 1px black; border-radius: 5px;">\n		<div class="col-xs-12 p-l-0 p-r-0">\n			<div class="col-xs-4 p-l-0 p-r-0">\n				<div class="col-xs-1 p-l-5 p-r-0"></div>\n				<div class="col-xs-7 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.assay') )) == null ? '' : __t) +
'</div>\n				<div class="col-xs-2 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.QC') )) == null ? '' : __t) +
'</div>\n				<div class="col-xs-2 p-l-0 p-r-0">' +
((__t = ( _.i18n('export.numberOfWells') )) == null ? '' : __t) +
'</div>\n			</div>\n			<div class="col-xs-8 p-l-0 p-r-0">\n				<div class="col-xs-4 p-l-5 p-r-0 leftBorder rightBorder redCell">' +
((__t = ( _.i18n('export.notReady') )) == null ? '' : __t) +
'</div>\n				<div class="col-xs-4 p-l-5 p-r-0 rightBorder greenCell">' +
((__t = ( _.i18n('export.toExport') )) == null ? '' : __t) +
'</div>\n				<div class="col-xs-4 p-l-5 p-r-0 blueCell">' +
((__t = ( _.i18n('export.exported') )) == null ? '' : __t) +
'</div>\n			</div>\n		</div>\n		<div class="col-xs-12 p-l-0 p-r-0" style="height: 21px;">\n			<div class="col-xs-4 p-l-5 p-r-0"></div>\n			<div class="col-xs-8 p-l-0 p-r-0">\n				<div class="col-xs-4 p-l-0 p-r-0 p-t-1 leftBorder rightBorder topBorder redCell" style="display: flex;">\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-xs-4 p-l-0 p-r-0 rightBorder topBorder greenCell" style="display: flex;">\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-xs-4 p-l-0 p-r-0 topBorder blueCell" style="display: flex;">\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('res') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('resTest') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">' +
((__t = ( _.i18n('qc') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-3 p-l-0 p-r-0 centeredText">' +
((__t = ( _.i18n('rep') )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n		</div>\n		';
 _.each(exportResultStats, function(exportResultStat) { ;
__p += '\n		<label class="checkBoxLabel" for="line-' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'">\n			<!--checkBoxLabel is use for extend checkbox click range-->\n			<div class="col-xs-12 p-l-0 p-r-0 topBorder ' +
((__t = ( !exportResultStat.validated ? 'redCell': '' )) == null ? '' : __t) +
'"\n				 style="height: 25px;">\n				<div class="col-xs-4 p-l-0 p-r-0">\n					<div class="col-xs-1 p-l-5 p-r-0 p-t-1">\n						<input class="assayRow" data-lineSecId="' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'"\n							   id="line-' +
((__t = ( exportResultStat.line.secId )) == null ? '' : __t) +
'"\n							   type="checkbox"\n						' +
((__t = ( exportResultStat.validated ? "checked" : "hidden" )) == null ? '' : __t) +
'/>\n					</div>\n					<div class="col-xs-7 p-l-0 p-r-0 p-t-1" style="font-size: 16px;">' +
((__t = (
						exportResultStat.line.assay.code )) == null ? '' : __t) +
'\n					</div>\n					<div class="col-xs-2 p-l-0 p-r-0 p-t-1">';
 if(exportResultStat.validated){ ;
__p += '\n						<span class="mdi mdi-check exportStatQc qcValidated"></span>\n						';
 } else { ;
__p += '\n						<span class="mdi mdi-close exportStatQc qcNotValidated"></span>\n						';
 } ;
__p += '\n					</div>\n					<div class="col-xs-2 p-l-0 p-r-0 p-t-1">' +
((__t = ( exportResultStat.total )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-xs-8 p-l-0 p-r-0">\n					<div class="col-xs-4 p-l-0 p-r-0 p-t-1 leftBorder rightBorder" style="display: flex;">\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( (exportResultStat.notReady.result.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.notReady.result.length === 0 ? '-' :
								exportResultStat.notReady.result.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( (exportResultStat.notReady.resultTest.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.notReady.resultTest.length === 0 ? '-' :
								exportResultStat.notReady.resultTest.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.notReady.qualityControl.length> 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.notReady.qualityControl.length === 0 ? '-' :
								exportResultStat.notReady.qualityControl.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.notReady.repeat.length> 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.notReady.repeat.length === 0 ? '-' :
								exportResultStat.notReady.repeat.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					</div>\n					<div class="col-xs-4 p-l-0 p-r-0 p-t-1 rightBorder ' +
((__t = ( exportResultStat.readyToExport ? 'greenCell': '' )) == null ? '' : __t) +
'"\n						 style="display: flex;">\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.result.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.toExport.result.length === 0 ? '-' :
								exportResultStat.toExport.result.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.resultTest.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.toExport.resultTest.length === 0 ? '-' :
								exportResultStat.toExport.resultTest.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.qualityControl.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.toExport.qualityControl.length === 0 ? '-' :
								exportResultStat.toExport.qualityControl.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n							<div class="nbStat resultStat ' +
((__t = ( ( exportResultStat.toExport.repeat.length > 0)? 'nbHightlight': '' )) == null ? '' : __t) +
'">\n								' +
((__t = ( exportResultStat.toExport.repeat.length === 0 ? '-' :
								exportResultStat.toExport.repeat.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					</div>\n					<div class="col-xs-4 p-l-0 p-r-0 p-t-1 ' +
((__t = ( exportResultStat.readyToReExport ? 'blueCell': '' )) == null ? '' : __t) +
'"\n						 style="display: flex;">\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat">\n								' +
((__t = ( exportResultStat.reExport.result.length === 0 ? '-' :
								exportResultStat.reExport.result.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat">\n								' +
((__t = ( exportResultStat.reExport.resultTest.length === 0 ? '-' :
								exportResultStat.reExport.resultTest.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 rightBorder centeredText">\n							<div class="nbStat resultStat">\n								' +
((__t = ( exportResultStat.reExport.qualityControl.length === 0 ? '-' :
								exportResultStat.reExport.qualityControl.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n						<div class="col-xs-3 p-l-0 p-r-0 centeredText">\n							<div class="nbStat resultStat">\n								' +
((__t = ( exportResultStat.reExport.repeat.length === 0 ? '-' :
								exportResultStat.reExport.qualityControl.length )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</label>\n\n		';
 }) ;
__p += '\n	</div>\n	<div class="row js-lis"></div>\n	<div class="row m-t-10">\n		<div class="col-xs-12">\n			<div class="col-xs-12 p-l-0 p-r-0">\n				<div class="col-xs-4 p-l-0 p-r-0 "></div>\n				<div class="col-xs-8 p-l-0 p-r-0">\n					<div class="col-xs-4 p-l-0 p-r-0 "></div>\n					<div class="col-xs-4 p-l-5 p-r-5 p-t-0 p-b-0 button ">\n						<button class="btn greenCell js-export btn-block invisible"\n								style="padding: 5px 5px 5px 5px;"\n						' +
((__t = ( enabledExport ? "" : "disabled" )) == null ? '' : __t) +
'>\n						' +
((__t = ( _.i18n("export") )) == null ? '' : __t) +
'\n						</button>\n					</div>\n					<div class="col-xs-4 p-l-5 p-r-5 p-t-0 p-b-0 button ">\n						<button class="btn blueCell js-reexport btn-block invisible"\n								style="padding: 5px 5px 5px 5px;"\n						' +
((__t = ( enabledReExport ? "" : "disabled" )) == null ? '' : __t) +
'>\n						' +
((__t = ( _.i18n("re.export") )) == null ? '' : __t) +
'\n						</button>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n';
 } else { ;
__p += '\nError : ' +
((__t = ( error )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n</div>';

}
return __p
};});

