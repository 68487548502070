define('runDetailsTabController',[
    'module',
    'app',
    'underscore',
    'jquery',
    'backbone',
    'runDetailsTabView',
    'emptyView',
    'wellUtils',
    'wellTableView'
], function (
    module,
    App,
    _,
    $,
    Backbone,
    DetailsTabView,
    EmptyView,
    WellUtils,
    WellTableView
) {
    'use strict';

    module.exports = {

        showDetailsTab: function (region, runModel, from, step, to, row, column, filters) {
            this.model = runModel;

            // refresh the well_table_view (details) after a plate layout action because de wellView use a local gridData
            App.on('well:table:pcr:refresh:jqGrid', _.bind(function () {
                this.onShowWellTable();
            }, this));

            var otherAssayInd = this.model.get('settings')['OTHER-ASSAY-IND'] ? this.model.get('settings')['OTHER-ASSAY-IND'].split(';') : [];

            if (otherAssayInd.length === 1) {
                this.model.sameAssayColor = otherAssayInd[0];
            } else if (otherAssayInd.length === 2) {
                this.model.otherAssayColor = otherAssayInd[1];
            }

            var PcrWellService = require('services/caccounts/pcrwells');
            this.wells = PcrWellService.filterWells(this.model.get('wells'), from, to, parseInt(step, 10));

            this.view = new DetailsTabView({
                model: runModel,
                wells: this.wells
            });

            this.row = row;
            this.column = column;
            this.multiRes = null;
            this.wellRes = null;
            this.filters = filters;

            if (this.model.get('settings')['LAB-SETTINGS'] && this.model.get('settings')['LAB-SETTINGS'] === 'C') {
                this.sortOn = 'column';
            } else {
                this.sortOn = 'row';
            }
            if (this.filters && this.filters.targetSecId) {
                this.target = this.model.getTarget(this.filters.targetSecId);
                this.channelToDisplay = this.target.channelNb;
                this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
                this.target = null;
                this.channelToDisplay = null;
                this.runAt = null;
            }
            this.from = from;
            this.to = to;
            this.step = step;

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            this.onShowWellTable();
        },

        onShowWellTable: function () {
            this.wellView = new WellTableView({
                tabName: this.tabName,
                model: this.model
            });

            this.wellView.channelToDisplay = this.channelToDisplay;
            this.wellView.sortOn = this.sortOn;
            this.wellView.multiRes = this.multiRes;
            this.wellView.wellRes = this.wellRes;
            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));

            // refresh the well_table_view (details) after a wellEditPopup action because de wellView use a local gridData
            this.wellView.listenTo(App, 'well:refresh:jqGrid', _.bind(this.onGridLoaded, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onGridLoaded: function (model, rowId) {
            this.wellView.ui.blockLoading.show();

            this.getDetailsFilteredWells()
                .done(_.bind(function (filteredWells) {
                    if (filteredWells.length < 1) {
                        $('.jq-grid-table-page').append('<div class="alert-info-grid">' + _.i18n('jqgrid.emptyrecords') + '</div>');
                        return;
                    }
                    var listViewData = this.getListViewData(filteredWells);
                    this.setGridData(listViewData, rowId);
                }, this));
        },

        getDetailsFilteredWells: function () {
            var defer = $.Deferred();
            require([
                'runTabsLayoutController'
            ], _.bind(function (RunTabsLayoutController) {
                this.wellFiltersList = [];
                this.wellFiltersList = RunTabsLayoutController.getWellFiltersList();

                var wells = this.model.get('wells');
                var filteredWells = false;
                var PcrWellService = require('services/caccounts/pcrwells');
                if (this.wellFiltersList && this.wellFiltersList.length && this.wellFiltersList.length > 0 && App.getCurrentRoute().includes('select')) {
                    wells = wells.filter(function (well) {
                        return _.contains(RunTabsLayoutController.getFilteredWells(), well.get('pos'));
                    });
                    filteredWells = PcrWellService.getCollection();
                    filteredWells.set(wells);
                }
                wells = PcrWellService.filterWells((!filteredWells ? wells : filteredWells), this.from, this.to, this.step, this.row, this.column, this.filters, this.multiRes, (this.wellRes && this.wellRes !== 'all') ? this.wellRes : null);
                defer.resolve(wells);
            }, this));
            return defer.promise();
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.wellView.availableRepeatStatus = wellListViewData.repeatStatusList;
            this.wellView.smpTypeList = wellListViewData.smpTypeList;
            this.wellView.warningSearch = wellListViewData.warningSearch;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },

        setGridData: function (data, rowId) {
            setTimeout(_.bind(function () {
                /** */ this.wellView.ui.jqGrid.clearGridData(); /** needed in case of refresh */
                /** */ this.wellView.ui.jqGrid.setGridParam(data, true); //overwrite param is needed true to avoid maximum call stack error on grid refresh
                /** needed in case of refresh */
                this.wellView.gridOptionsObj = this.wellView.gridOptions(data);
                this.wellView.onRowCountDone(data, 9999);
                this.wellView.triggerMethod('checkRowCount', 9999);
                if (rowId) {
                    /** */ this.wellView.ui.jqGrid.trigger('reloadGrid', [{current: true}]);
                    /** needed in case of refresh */
                    this.wellView.ui.jqGrid.setSelection(rowId, false); // highlight selected row
                }
                this.setWellListPopover();

                this.wellView.ui.blockLoading.hide();
            }, this), 500);
        },

        setWellListPopover: function () {
            this.model.get('wells').each(_.bind(function (well, index) {
                if (well.get('pos')) {

                    if ($('.wellPosPopover-' + well.get('pos')).length > 0 &&
                        $('.wellSmpidPopover-' + well.get('pos')).length > 0 &&
                        $('.wellAssayPopover-' + well.get('pos')).length > 0) {

                        var params = {
                            model: well
                        };

                        params.targets = [
                            $('.wellPosPopover-' + well.get('pos')),
                            $('.wellSmpidPopover-' + well.get('pos')),
                            $('.wellAssayPopover-' + well.get('pos'))
                        ];

                        if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                            (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                            params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                        }
                        var placement = 'right';
                        if (index < 3) {
                            placement = 'bottom';
                        } else if (index > this.model.get('wells').length - 4) {
                            placement = 'top';
                        }
                        params.placement = placement;
                        WellUtils.showPcrWellPopover(params);
                    }
                }
            }, this));
        },

        getWellListViewData: function (wells) {
            var availableRepeatStatus = [];
            var availableSmpTypes = [];
            var wellsForData = [];
            var warningFound = {empty: 0, warning: 0, expert: 0};

            _.each(wells, _.bind(function (well) {
                var counter = 0;
                if (well.get('repeatStatus')) {
                    availableRepeatStatus.push(well.get('repeatStatus'));
                }
                if (well.get('smpType')) {
                    availableSmpTypes.push(well.get('smpType'));
                }
                var model = {
                    secId: well.get('secId'),
                    wellRes: well.get('wellRes'),
                    pos: well.get('pos'),
                    codeErr: well.get('codeErr'),
                    repeatStatus: well.get('repeatStatus'),
                    existOtherWellSameAssay: well.get('existOtherWellSameAssay'),
                    smpType: well.get('smpType'),
                    wellResSearchValue: well.getWellRes()
                };

                if (well.get('smpId')) {
                    model.smpId = {
                        secId: well.get('smpId').get('secId'),
                        name: well.get('smpId').get('name'),
                        code: well.get('smpId').get('code'),
                        lisComment: well.get('smpId').get('lisComment'),
                        comment: well.get('smpId').get('comment')
                    };
                }
                if (well.get('refAssay')) {
                    model.refAssay = {
                        secId: well.get('refAssay').get('secId'),
                        code: well.get('refAssay').get('code'),
                        name: well.get('refAssay').get('name')
                    };
                }

                model.wellRes = _.first(well.getDisplayGroup());

                var wellResCode = well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code') ?
                    well.get('wellRes').get('result').get('code') : '';

                var wellResQuantification = well.get('wellRes') && well.get('wellRes').get('quantificationFormatted') ?
                    well.get('wellRes').get('quantificationFormatted') : '';

                model.wellResSearchValue = wellResCode + wellResQuantification;

                well.get('results').each(_.bind(function (target) {
                    if (target.get('codeErr')) {
                        model.codeErr = 'warning';
                        warningFound.warning++;
                        counter++;
                    }
                }, this));

                if (well.get('coErr') !== 'warning' && well.get('valWst') === 3.1) {
                    model.codeErr = 'expert';
                    warningFound.expert++;
                    counter++;
                }

                if (counter === 0) {
                    warningFound.empty++;
                    model.codeErr = 'empty';
                }

                wellsForData.push(model);
            }, this));

            availableSmpTypes = _.uniq(availableSmpTypes);
            availableRepeatStatus = _.uniq(availableRepeatStatus);

            return {
                repeatStatusList: this.getGridSearchField(availableRepeatStatus),
                smpTypeList: this.getGridSearchField(availableSmpTypes),
                warningSearch: this.getGridWarningSearchField(warningFound),
                wellsForData: wellsForData
            };
        },

        getGridSearchField: function (list) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            _.each(list, function (l) {
                retVal += l + ':' + l + ';';
            });
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        getGridWarningSearchField: function (object) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            if (object.empty > 0) {
                retVal += 'empty:' + _.i18n('empty') + ';';
            }
            if (object.expert > 0) {
                retVal += 'expert:' + _.i18n('expert') + ';';
            }
            if (object.warning > 0) {
                retVal += 'warning:' + _.i18n('warning') + ';';
            }
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        showWell: function (model) {
            $('.js-global-loader').show();
            var PcrRunService = require('services/caccounts/pcrruns');
            var pcrRunModel = PcrRunService.getModel({secId: model.get('run').get('secId')});
            pcrRunModel.fetch().done(_.bind(function () {
                var pcrWellModel = pcrRunModel.get('wells').findWhere({secId: model.get('secId')});
                require(['pcrWellController'], _.bind(function (PcrWellController) {
                    PcrWellController.showDetails({model: pcrWellModel});
                }, this));
            }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        }
    };
});

