define('runPcrKitLotItemView',[
    'module',
    'backbone.marionette',
    'template!runPcrKitLotItemView'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    return Marionette.View.extend({
        template: Tpl,
        tagName: 'tr'
    });
});
