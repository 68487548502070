// define([
//     'module',
//     'underscore',
//     'rolesMixin'
// ], function (
//     module,
//     _,
//     RolesMixin
// ) {
//     'use strict';
//
//     module.exports = [
//         {
//             name: 'table',
//             title: _.i18n('runs.table.tabTitle')
//         },
//         {
//             name: 'details',
//             title: _.i18n('runs.details.tabTitle')
//         }
//     ];
// });
define("prepRunTabsData", function(){});

