define('prepRunExtractionStart',[
    'module',
    'dialogFormView',
    'template!prepRunExtractionStart',
    'savingBehavior',
    'underscore',
    'jquery',
    'autocompleteView',
    'backbone',
    'extractionMethodsReagentsView',
    'extractionMethodIcKitsView'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    AutocompleteView,
    Backbone,
    ExtractionMethodsReagentsView,
    ExtractionMethodIcKitsView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            selectRespUser: '.js-select-respUser-region',
            selectExtractionMethod: '.js-select-extractionMethod-region',
            extractionMethodsReagentsView: '.js-show-extractionMethReagents',
            extractionMethodIcKitsView: '.js-show-extractionMethod-icKits'
        },

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        fieldsToValidate: [
            {name: 'refExtractionMethod', type: 'required'},
            {name: 'startUserpid', type: 'required'}
        ],

        initialize: function (options) {
            this.extractionDetails = options.runModel.get('extractionDetails');
            this.model = new Backbone.Model();
            this.model.set('refExtractionMethod', null);
            this.model.set('startUserpid', options.currentUser);
            this.model.set('comment', null);
            this.model.set('printExtractionSetup', this.options.printExtractionSetup);
        },

        onChangeExtractionMethod: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.triggerMethod('enable:cancel:confirm');

            var countWell = 0;
            this.options.runModel.get('wells').each(function (well) {
                if (well && well.get('smpId') && well.get('smpId').get('code')) {
                    countWell++;
                }
            });
            var reagentRegion = this.getRegion('extractionMethodsReagentsView');
            if (this.model.get(fieldName)) {
                this.extractionMethodsReagentsView = new ExtractionMethodsReagentsView({
                    model: model,
                    wellsNumber: countWell,
                    className: 'm-b-20'
                });
                reagentRegion.show(this.extractionMethodsReagentsView);
            } else {
                reagentRegion.empty();
            }
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        onRender: function () {
            this.showIcKitsLotsView();

            var ExtractionMethodService = require('services/caccounts/extractionMethod');
            this.extractionMethodView = new AutocompleteView(
                this._getAutocompleteOptionObject(ExtractionMethodService.getAutocompleteParam({
                    modelProperty: 'refExtractionMethod',
                    callBackOnChange: this.onChangeExtractionMethod
                }))
            );
            this.getRegion('selectExtractionMethod').show(this.extractionMethodView);

            var UserpidService = require('services/caccounts/userpids');
            this.respUserView = new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'startUserpid',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            );
            this.getRegion('selectRespUser').show(this.respUserView);

            this.triggerMethod('enable:cancel:confirm');
        },

        showIcKitsLotsView: function () {
            this.options.runModel.getKitLots().done(_.bind(function (prepRunKitLot) {
                this.extractionMethodIcKitsView = new ExtractionMethodIcKitsView({
                    model: this.options.runModel,
                    prepRunKitLot: prepRunKitLot
                });
                var icKitRegion = this.getRegion('extractionMethodIcKitsView');
                if (icKitRegion) {
                    icKitRegion.show(this.extractionMethodIcKitsView);
                }
            }, this));
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                var loader = $('.js-global-loader');
                loader.show();
                this.options.runModel.startExtraction(this.model)
                    .done(_.bind(function () {
                        if (this.model.get('printExtractionSetup')) {
                            require(this.options.runModel.service).generateRunExtractSetup();
                        }
                        this.hide();
                    }, this))
                    .fail(_.bind(function () {
                        this.options.runModel.fetch()
                            .done(_.bind(function () {
                                this.hide();
                            }, this));
                    }, this))
                    .always(function () {
                        loader.hide();
                    });
            }
        }
    });
});

