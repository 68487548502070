define('translationsPopupFormView',[
    'module',
    'backbone.marionette',
    'template!translationsPopupFormTpl',
    'dialogFormView',
    'translationsFilterLanguagesLayoutView',
    'translationsPopupTextsView',
    'backbone',
    'stateMessages',
    'underscore',
    'entities/languages',
    'entities/admin/translations'
], function (
    module,
    Marionette,
    formTpl,
    DialogFormView,
    FilterLanguagesLayoutView,
    TextsView,
    Backbone,
    StateMessagesBehavior,
    _,
    Languages,
    Translations
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: formTpl,

        regions: {
            languages: '.js-languages-region',
            translations: '.js-translations-region'
        },

        ui: {
            save: '.js-save',
            cancel: '.js-cancel',
            inputs: 'input'
        },

        events: {
            'click @ui.save': '_onSave',
            'click @ui.cancel': '_onCancel',
            'change @ui.inputs': 'onInputChange'
        },

        modelEvents: {
            request: '_onLoading',
            sync: 'hide',
            saved: '_onSaved',
            error: '_onSaveError'
        },

        behaviors: {
            StateMessages: {
                behaviorClass: StateMessagesBehavior
            }
        },

        initialize: function (options) {
            if (!options.model) {
                this.model = Translations.getModel();
            }
        },

        onRender: function () {
            var textView = new TextsView({
                collection: this.model.get('values'),
                languages: this.options.languages
            });
            this.getRegion('translations').show(textView);

            var filterLanguagesView = new FilterLanguagesLayoutView({
                collection: this.options.languages,
                callBackChange: _.bind(textView.changeLanguages, textView)
            });
            this.getRegion('languages').show(filterLanguagesView);
        },

        _onLoading: function () {
            this.triggerMethod('show:message', _.i18n('common.loading'));
        },

        _onSave: function () {
            this.model.save();
        },

        _onCancel: function () {
            this.hide();
        },

        _onSaved: function () {
            this.hide();
        },

        _onSaveError: function (model, resp) {
            this.triggerMethod('error:message', resp.statusText);
        }
    });
});
