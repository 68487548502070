define('entities/caccounts/extractors',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.Extractor = Backbone.RelationalModel.extend({
        service: 'services/caccounts/extractors',
        fetch: function () {
            this.url = Settings.url('rest/v2/extractors/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'lmbExtractors': [],
            'extractionMethods': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lmbExtractors',
            relatedModel: 'LmbExtractor',
            collectionType: 'LmbExtractorCollection'
        }, {
            type: Backbone.HasMany,
            key: 'extractionMethods',
            relatedModel: 'ExtractionMethod',
            collectionType: 'ExtractionMethodCollection'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/extractors/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ExtractorJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Extractor',

        importable: true
    });

    app.ns.Extractor.role = RolesMixin.EXTRACTOR;

    app.ns.ExtractorCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/extractors/');
        },
        model: app.ns.Extractor
    });
});

