/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowView',[
    'module',
    'backbone.marionette',
    'app',
    'template!contentShowView'
], function (
    module,
    Marionette,
    App,
    layoutTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: layoutTpl,

        regions: {
            header: '#header-region',
            sidebar: '#sidebar-region',
            content: '#content-region'
        },

        ui: {
            aside: '.aside-md'
        },

        className: 'app'
    });
});
