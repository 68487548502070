define('prepRunCreatePrimaryPooling',[
    'module',
    'dialogFormView',
    'template!prepRunCreatePrimaryPooling',
    'savingBehavior',
    'underscore',
    'autocompleteView',
    'backbone',
    'jquery',
    'dynamicCreateEditCodeList',
    'dynamicCreateEditCustomListToList'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    AutocompleteView,
    Backbone,
    $,
    CodeListView,
    DynamicCreateEditCustomListToList
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'hide',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            prepRunSource: '.js-select-prepRunSource-region',
            plateDirection: '.js-plate-direction-region',
            assayRegion: '.js-edit-assay'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        fieldsToValidate: [
            {name: 'prepRunSecId', type: 'required'},
            {name: 'posFrom', type: 'required'},
            {name: 'posTo', type: 'required'},
            {name: 'depth', type: 'required'},
            {name: 'posStart', type: 'required'},
            {name: 'assays', type: 'required'}
        ],

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('plateFillDirection', this.options.model.get('settings')['LAB-SETTINGS']);
        },

        onRender: function () {
            this.getRegion('plateDirection').show(new CodeListView({
                model: this.model,
                config: {
                    modelProperty: 'plateFillDirection',
                    code: 'PLATE_FILL_DIRECTION'
                }
            }));

            this.getRegion('prepRunSource').show(new AutocompleteView(
                this._getAutocompleteOptions('prepRun', 'name', 'name', 'rest/v2/runs/prep?status=1&toBePooled=true',
                    'selectrun.placeholder', 'name', this.onRunChange)
            ));

            this.getRegion('assayRegion').show(new DynamicCreateEditCustomListToList({
                'field': 'assays',
                'model': this.model,
                'items': {items: this.options.assays, codeListCode: ''}
            }));
        },

        onRunChange: function (fieldName, model) {
            this.onChange('prepRunSecId', model ? model.get('secId') : null);
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                $('.js-global-loader').show();
                this.options.model.createPrimaryPooling(this.model.toJSON())
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                        this.hide();
                    }, this));
            }
        }
    });
});

