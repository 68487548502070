define('entities/caccounts/lisana',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lis',
    'entities/caccounts/assay',
    'entities/caccounts/assaygroup'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.LisAna = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisana',
        fetch: function () {
            this.url = Settings.url('rest/v2/lisAnas/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'lis': null,
            'refMbAna': null,
            'code': '',
            'name': '',
            'description': '',
            'tags': [],
            comment: '',
            'criterias': [],
            'jsonobjectdocumentation': 'TB_LisAnaJson.pdf',
            'assayFromResult': [],
            'assayGroups': []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'lis',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasMany,
            key: 'assayFromResult',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assayGroups',
            relatedModel: 'AssayGroup',
            collectionType: 'AssayGroupCollection'
        }, {
            type: Backbone.HasOne,
            key: 'refMbAna',
            relatedModel: 'MbAna',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/lisAnas/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('LisAnaJson.pdf');
        }
    });

    app.ns.LisAna.role = RolesMixin.LISANA;

    app.ns.LisAnaCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lisAnas/');
        },
        model: app.ns.LisAna
    });
});

