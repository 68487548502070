define('connectorHL7TracingCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'template!connectorHL7TracingCustomJqGridView',
    'bootbox'
], function (
    module,
    DialogFormView,
    _,
    tpl,
    Bootbox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click': 'resend'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        resend: function () {
            var selectedRows = this.options.jqGrid.getCheckedRowsList();
            var secIds = _.pluck(selectedRows, 'secId');
            if(_.isEmpty(secIds)) {
                Bootbox.alert(_.i18n('connector.hl7.tracing.noRowsSelected'));
                return;
            }
            this.options.service.resend(secIds)
                .done(_.bind(function () {
                    Bootbox.dialog({
                        className: 'success',
                        message: _.i18n('connector.hl7.tracing.resendSuccess')
                    });
                    setTimeout(_.bind(function () {
                        $(this.options.jqGrid.ui.jqGrid).trigger('reloadGrid');
                    }, this), 1000);
                }, this))
                .fail(_.bind(function (xhr) {
                    Bootbox.alert(xhr.responseText);
                }, this));
        }
    });
});
