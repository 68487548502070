define('assayVersionLotItem',[
    'module',
    'backbone.marionette',
    'template!assayVersionLotItem',
    'dialogFormView'
], function (
    module,
    Marionette,
    Tpl,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {},

        events: {},

        regions: {},

        className: 'col-xs-12 p-0',

        serializeData: function () {
            var templateData = {};

            templateData.reagentType = this.model.get('reagent').get('type');
            templateData.reagentCode = this.model.get('reagent').get('code');
            templateData.kit = this.model.get('reagent').get('kit').get('code');
            templateData.lot = this.model.get('code');
            templateData.volumeByTest = this.model.get('volumeByTest');
            templateData.volumeDeath = this.model.get('volumeDeath');

            return templateData;
        }

    });
});
