
define('template!prepWellPooledCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f p-b-5">\n    <div class="col-w-all col-w-100 noWrapHidden">\n        <label>' +
((__t = ( _.i18n('prepwell.pooleds') )) == null ? '' : __t) +
'</label>\n    </div>\n</div>\n<div class="col-w-all col-w-100 d-f">\n    <div class="col-w-all col-w-20 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('prepWell.runname') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div><!--separator-->\n    <div class="col-w-all col-w-5 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div><!--separator-->\n    <div class="col-w-all col-w-25 noWrapHidden pcrFromPrepTitleDiv ">\n        ' +
((__t = ( _.i18n('well.sample') )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-2 noWrapHidden"></div><!--separator-->\n    <div class="col-w-all col-w-4 noWrapHidden"></div><!--separator-->\n</div>';

}
return __p
};});

