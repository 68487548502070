define('services/caccounts/colorCompensationMatrices',[
    'entities/ns',
    'jquery',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/colorCompensationMatrices'
], function (
    app,
    $,
    _,
    Dynamic,
    module
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.ColorCompensationMatrice,
                collection: app.ns.ColorCompensationMatriceCollection
            };
        },
        getName: function () {
            return 'ColorCompensationMatrice';
        },
        getDynamicJson: function () {
            return 'ColorCompensationMatriceJson';
        },
        getCreateEditLine: function (model) {
            var PcrRunService = require('services/caccounts/pcrruns');
            var LmbCyclerService = require('services/caccounts/lmbcyclers');
            return [
                {
                    'field': 'pcrRun',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': PcrRunService.getAutocompleteParamForColorCompensation},
                        'context': PcrRunService,
                        'display': {'class': 'col-w-35'},
                        onChange: function (fieldName, modelFieldName, model) {
                            var data = {lmbCycler: null, createdDate: new Date()};
                            if (modelFieldName !== null) {
                                data[fieldName] = modelFieldName.toJSON ? modelFieldName.toJSON() : modelFieldName;
                                data.lmbCycler = model.getRelation('lmbCycler').relatedModel.findOrCreate(data[fieldName].refLmbCycler);
                                data.createdDate = data[fieldName].creatDate;
                            }
                            model.set(data, {silent: true});
                            model.trigger('change:pcrRun');
                            model.trigger('change:lmbCycler');
                            model.trigger('change:createdDate');
                        }
                    }
                },
                {
                    'field': 'createdDate',
                    'param': {
                        'type': 'DATE',
                        'display': {
                            'class': 'col-w-10',
                            'readOnly': true
                        }
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'pcrRun'
                    }
                },
                {
                    'field': 'lmbCycler',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-w-20'},
                        'config': {
                            'getAutocompleteParam': LmbCyclerService.getAutocompleteParamWithColorCompensation
                        },
                        'context': LmbCyclerService
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'pcrRun',
                        'allowEmpty': true,
                        clearValue: false,
                        'readOnly': function (model) {
                            return !!model.get('pcrRun');
                        },
                        disableEvent: true
                    }
                },
                {
                    'field': 'status',
                    'param': {
                        'type': 'SELECT',
                        'display': {'class': 'col-w-20'},
                        'config': {
                            'values': [
                                {
                                    'key': 'UNKNOWN', 'value': _.i18n('status.unknown'),
                                    'display': {'style': 'background-color: #f5f5f5 !important;'}
                                },
                                {
                                    'key': 'VALID',
                                    'value': _.i18n('status.valid'),
                                    'display': {'style': 'background-color: #0080008c !important;'}
                                },
                                {
                                    'key': 'INVALID',
                                    'value': _.i18n('status.invalid'),
                                    'display': {'style': 'background-color: #ff00008c !important;'}
                                },
                                {
                                    'key': 'DEFAULT',
                                    'value': _.i18n('status.default'),
                                    'display': {'style': 'background-color: #0000ff8c !important;'}
                                },
                                {
                                    'key': 'ARCHIVED',
                                    'value': _.i18n('status.archived'),
                                    'display': {'style': 'background-color: #ffa5008c !important;'}
                                }
                            ]
                        }
                    }
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': this.showDetails,
                        'display': {'class': 'col-w-5', 'mdi': 'mdi-eye'}
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'values',
                        allowEmpty: true,
                        enable: function (model) {
                            return !model.isNew();
                        }
                    }
                },
                {
                    'field': 'recalc',
                    'param': {
                        'type': 'POPUP',
                        'config': this.reset,
                        'display': {'class': 'col-w-5', 'mdi': 'mdi-refresh'}
                    },
                    'dependsOn': {
                        'model': model,
                        'field': 'pcrRun'
                    }
                }
            ];
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         *
         */
        showDetails: function (param) {
            require(['colorCompensationController'], function (ColorCompensationController) {
                ColorCompensationController.showMatrice(param.model);
            });
        },
        reset: function (param) {
            $('.js-global-loader').show();
            param.model.reset().always(function () {
                $('.js-global-loader').hide();
            });
        }
    }, Dynamic);
});

