define('dynamicCreateEditBoolean',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template(''),

        className: 'js-info-input',
        tagName: 'input',
        attributes: function () {
            var attributes = {};
            attributes['data-field-name'] = this.options.field;
            attributes.placeholder = '';
            attributes.autocomplete = 'off';
            if (this.options.display && this.options.display.readOnly) {
                attributes.disabled = 'disabled';
                attributes.readonly = 'readonly';
            }
            attributes.type = 'checkbox';
            attributes.checked = this.options.value;
            return attributes;
        },

        serializeData: function () {
            return {};
        }
    });
});
