/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowController',[
    'module',
    'jquery',
    'underscore',
    'app',
    'settings',
    'contentShowView'
], function (
    module,
    $,
    _,
    App,
    Settings,
    View
) {
    'use strict';

    module.exports = {
        showContent: function () {
            var defer = $.Deferred(),
                view,
                currentUserGlobal = Settings.get('currentUserGlobal');

            if (App.regions &&
                _.isUndefined(App.getView()) ||
                (App.getView() &&
                    _.isUndefined(App.getView().getRegion)) ||
                _.isUndefined(App.getView().getRegion('content')) ||
                this.switchCaccount || App.getCurrentRoute() === '') {

                this.switchCaccount = false;
                var cAccount = Settings.get('currentCAccount');
                var reloadData = $.Deferred();
                reloadData.done(_.bind(function () {
                    currentUserGlobal = Settings.get('currentUserGlobal');
                    if (!currentUserGlobal) {
                        defer.reject();
                        return;
                    }
                    view = new View();

                    var defers = [];
                    var UserpidService = require('services/caccounts/userpids');
                    defers.push(UserpidService.getByGlobal(currentUserGlobal.secId));
                    var SettingService = require('services/caccounts/setting');
                    defers.push(SettingService.getAllSettingFull());
                    $.when.apply($, defers).done(_.bind(function (user, settings) {
                        Settings.set('currentUserModel', user);
                        App.showView(view);
                        require(['headerShowController', 'sidebarShowController'], function(HeaderShowController, SidebarShowController) {
                            HeaderShowController.showHeader();
                            SidebarShowController.showSidebar(settings);
                        });
                        App.off('switchCaccount');
                        App.on('switchCaccount', _.bind(this.onSwitchCAccount, this));
                        defer.resolve();
                    }, this));
                }, this));

                if (cAccount && cAccount.secId && currentUserGlobal) {
                    reloadData.resolve();
                } else {
                    require(['services/global/token'], function (TokenEntities) {
                        TokenEntities.findBy().done(function (model) {
                            Settings.set('currentUserGlobal', model.userpidGlobal);
                            Settings.set('currentCAccount', model.caccount);
                            reloadData.resolve();
                        }).fail(function () {
                            Settings.set('lastUrlPageCookie', App.getCurrentRoute());
                            App.clearAuthCookieValues();
                            App.navigate('login', {trigger: true});
                        });
                    });
                }
            } else {
                App.getView().triggerMethod('before:render');
                defer.resolve();
            }
            return defer.promise();
        },

        onSwitchCAccount: function (caccount) {
            var CAccountService = require('services/global/caccounts');
            var postCaccount = CAccountService.postCAccount(caccount.secId);

            postCaccount.done(_.bind(function () {
                Settings.clearMemory();
                Settings.set('currentCAccount', caccount);
                this.switchCaccount = true;
                App.navigate('switchCAccount', {trigger: true});
            }, this));
        }
    };
});

