define('roleController',[
    'module',
    'entities/ns',
    'roleView',
    'underscore',
    'backbone',
    'jquery'
], function (
    module,
    app,
    RoleView,
    _,
    Backbone,
    $
) {
    'use strict';

    module.exports = {
        /**
         *
         * @param param
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.region - The region to show the view in.
         * @param {boolean} param.readOnly - The flag to indicate if the view is read-only.
         */
        showDetails: function (param) {
            var roleService = require('services/caccounts/role');
            var defers = [];
            defers.push(roleService.findAll());
            defers.push(roleService.findDepends(param.model.get('privileges')));
            $.when.apply($, defers).done(function (roles, depends) {
                var tmp = [];
                _.map(depends, _.bind(function (rolePrivilege) {
                    if (param.model.get('privileges').indexOf(rolePrivilege) === -1) {
                        tmp.push({code: rolePrivilege});
                    }
                }, this));
                param.model.set('depends', new Backbone.Collection(tmp));
                var view = new RoleView({
                    model: param.model,
                    collection: roles,
                    readOnly: param.readOnly
                });
                if (param.region) {
                    param.region.show(view);
                } else {
                    view.show({
                        title: param.title ? param.title : _.i18n('roles.view'),
                        className: 'baseTableEditAddPopup role',
                        buttons: {
                            'Close': function () {
                                this.hide();
                            }
                        }
                    });
                }
            });
        }
    };
});

