define('entities/caccounts/displayModeConfigurations',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.DisplayModeConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/displayModeConfigurations',
        fetch: function () {
            this.url = Settings.url('rest/v2/displayModeConfigurations/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'parameter': null,
            'locations': []
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/displayModeConfigurations');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DisplayModeConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DisplayModeConfiguration',
        importable: true,
        getConfig: function (location) {
            if (location === 'ValMini') {
                return {
                    align: 'horizontal',
                    display: {
                        ct: false,
                        code: false
                    }
                };
            }
            if (location === 'WellTarget') {
                return {
                    align: 'horizontal',
                    display: {
                        ct: false,
                        code: true
                    }
                };
            }
            if (location === 'TooltipTarget') {
                return {
                    align: 'horizontal',
                    display: {
                        ct: false,
                        code: true
                    }
                };
            }
            if (location === 'WellCurve') {
                return {
                    align: 'horizontal',
                    display: {
                        ct: true,
                        code: true
                    }
                };
            }
            if (this.get('parameter').type === 'DOT') {
                switch (location) {
                    case 'Plate':
                        return {
                            display: {
                                ct: false,
                                code: false,
                                propertyName: 'sequenceDisplay'
                            }
                        };
                    case 'Tooltip':
                        return {
                            display: {
                                ct: false,
                                code: false
                            }
                        };
                    case 'Well':
                        return {
                            display: {
                                ct: false,
                                code: false
                            }
                        };
                    case 'Val':
                        return {
                            display: {
                                ct: false,
                                code: false
                            }
                        };
                    case 'WellList':
                        return {
                            display: {
                                ct: true,
                                code: true
                            },
                            width: 90
                        };
                    case 'TargetList':
                        return {
                            display: {
                                ct: false,
                                code: false
                            },
                            width: 20
                        };
                }
            } else if (this.get('parameter').type === 'HISTOGRAM') {
                var lines = [];
                var min = 0;
                var max = 6;
                var chartMinNegative;
                var chartMaxNegative;
                var minNegative;
                var maxNegative;
                var display = 'VERTICAL';

                var param = this.get('parameter').param.split(';');
                if (param.length > 1 && _.isNumber(parseInt(param[1], 10))) {
                    min = parseInt(param[1], 10);
                }
                if (param.length > 2 && _.isNumber(parseInt(param[2], 10))) {
                    max = parseInt(param[2], 10);
                }
                if (param.length > 3 && _.isNumber(parseInt(param[3], 10))) {
                    lines.push({index: parseInt(param[3], 10), color: 'blue', dashed: false});
                }
                if (param.length > 4 && _.isNumber(parseInt(param[4], 10))) {
                    lines.push({index: parseInt(param[4], 10), color: 'red', dashed: false});
                }
                if (param.length > 5 && _.isNumber(parseInt(param[5], 10))) {
                    lines.push({index: parseInt(param[5], 10), color: 'gray', dashed: true});
                }
                if (param.length > 6 && _.isNumber(parseInt(param[6], 10))) {
                    lines.push({index: parseInt(param[6], 10), color: 'gray', dashed: true});
                }
                if (param.length > 7 && _.isNumber(parseFloat(param[7]))) {
                    chartMinNegative = parseFloat(param[7]);
                }
                if (param.length > 8 && _.isNumber(parseFloat(param[8]))) {
                    chartMaxNegative = parseFloat(param[8]);
                }
                if (param.length > 9 && _.isNumber(parseFloat(param[9]))) {
                    minNegative = parseFloat(param[9]);
                }
                if (param.length > 10 && _.isNumber(parseFloat(param[10]))) {
                    maxNegative = parseFloat(param[10]);
                }
                if (param.length > 11 && param[11]) {
                    display = param[11];
                }

                switch (location) {
                    case 'Plate':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: false,
                                code: false,
                                propertyName: 'sequenceDisplay'
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                    case 'Tooltip':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: false,
                                code: false
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                    case 'Well':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: false,
                                code: false
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            width: 20,
                            height: 20,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                    case 'Val':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: false,
                                code: false
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            height: 30,
                            width: display === 'HORIZONTAL' ? 60 : 14,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                    case 'WellList':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: true,
                                code: true
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            height: 35,
                            width: 20,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                    case 'TargetList':
                        return {
                            type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                            display: {
                                ct: false,
                                code: false
                            },
                            lines: lines,
                            min: min,
                            max: max,
                            height: 35,
                            width: 20,
                            chartMinNegative: chartMinNegative,
                            chartMaxNegative: chartMaxNegative,
                            minNegative: minNegative,
                            maxNegative: maxNegative
                        };
                }
            } else {
                throw new Error('Unknown parameter type ' + this.get('parameter').type);
            }
            throw new Error('Unknown location ' + location);
        }
    });

    app.ns.DisplayModeConfiguration.role = RolesMixin.DISPLAYMODE;

    app.ns.DisplayModeConfigurationCollection = Backbone.Collection.extend({
        model: app.ns.DisplayModeConfiguration,
        comparator: 'secId'
    });

});

