define('menuItems',[
    'underscore',
    'settings',
    'itemPagesNameMixin',
    'rolesMixin'
], function (_,
             Settings,
             ItemPagesNameMixin,
             RolesMixin) {
    'use strict';

    return [

        // Process on sample
        {
            title: _.i18n('menu.routing'),
            itemPageName: ItemPagesNameMixin.ROUTING,
            link: function () {
                return 'routing';
            },
            role: RolesMixin.ROUTING,
            subMenu: []
        },

        //PREPRUNS
        {
            title: _.i18n('menu.prepruns'),
            itemPageName: ItemPagesNameMixin.PREPRUNS,
            link: function () {
                return 'runs/prep';
            },
            role: RolesMixin.RUN_PREP,
            subMenu: []
        },

        //PCRRUNS
        {
            title: _.i18n('menu.runs'),
            itemPageName: ItemPagesNameMixin.RUNS,
            link: function () {
                return 'runs/pcr';
            },
            role: RolesMixin.RUN_PCR,
            subMenu: []
        },

        //PCR_SETUP
        {
            title: _.i18n('menu.pcrSetup'),
            itemPageName: ItemPagesNameMixin.PCR_SETUP,
            link: function () {
                return 'pcrSetup';
            },
            role: RolesMixin.PCR_SETUP,
            subMenu: []
        },

        //SAMPLES
        {
            title: _.i18n('menu.sampleManagement'),
            itemPageName: ItemPagesNameMixin.SAMPLES,
            link: function () {
                return 'samples';
            },
            role: RolesMixin.SAMPLES,
            subMenu: []
        },

        //ORDERS
        {
            title: _.i18n('menu.orders'),
            itemPageName: ItemPagesNameMixin.ORDERS,
            link: function () {
                return 'orders';
            },
            role: RolesMixin.ORDERS,
            subMenu: []
        },

        //ASSAY DEFINITION
        {
            title: _.i18n('menu.assay.definition'),
            itemPageName: ItemPagesNameMixin.ADMIN_ASSAYDEFINITION,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.admin.assays'),
                    itemPageName: ItemPagesNameMixin.ADMIN_ASSAYS,
                    link: function () {
                        return 'admin/assays';
                    },
                    role: RolesMixin.ASSAY,
                    subMenu: []
                }, {
                    title: _.i18n('menu.assay.assayGroup'),
                    itemPageName: ItemPagesNameMixin.ASSAYGROUP,
                    link: function () {
                        return 'admin/assaygroup';
                    },
                    role: RolesMixin.ASSAYGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kitprots'),
                    itemPageName: ItemPagesNameMixin.KITPROTS,
                    link: function () {
                        return 'admin/kitprots';
                    },
                    role: RolesMixin.PCR_PROTOCOL,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.mbanas'),
                    itemPageName: ItemPagesNameMixin.MBANAS,
                    link: function () {
                        return 'admin/mbanas';
                    },
                    role: RolesMixin.MBANA,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.mbanaresgrs'),
                    itemPageName: ItemPagesNameMixin.MBANARESGRS,
                    link: function () {
                        return 'admin/mbanaresgrs';
                    },
                    role: RolesMixin.MBANA,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kits'),
                    itemPageName: ItemPagesNameMixin.KITS_PCR,
                    link: function () {
                        return 'admin/kits/pcr';
                    },
                    role: RolesMixin.KIT_PCR,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kitLots'),
                    itemPageName: ItemPagesNameMixin.KITS_PCR_LOT,
                    link: function () {
                        return 'admin/kits/pcr/lot';
                    },
                    role: RolesMixin.KIT_PCR_LOT,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.colorCompensation'),
                    itemPageName: ItemPagesNameMixin.COLORCOMPENSATION,
                    link: function () {
                        return 'admin/colorCompensation';
                    },
                    role: RolesMixin.COLORCOMPENSATION,
                    subMenu: []
                }, {
                    title: _.i18n('menu.standardCurve'),
                    itemPageName: ItemPagesNameMixin.STANDARDCURVES,
                    link: function () {
                        return 'admin/standardCurves';
                    },
                    role: RolesMixin.STANDARDCURVE,
                    subMenu: []
                }, {
                    title: _.i18n('menu.sampleResultDefinition'),
                    itemPageName: ItemPagesNameMixin.ADMIN_SAMPLERESULTDEFINITION,
                    link: function () {
                        return 'admin/sampleResultDefinitions';
                    },
                    role: RolesMixin.SPO_OVAR,
                    subMenu: []
                }, {
                    title: _.i18n('menu.displayMode'),
                    itemPageName: ItemPagesNameMixin.DISPLAYMODE,
                    link: function () {
                        return 'admin/displayModes';
                    },
                    role: RolesMixin.DISPLAYMODE,
                    subMenu: []
                }
            ]
        },

        //PREANALYTIC
        {
            title: _.i18n('menu.preanalytic'),
            itemPageName: ItemPagesNameMixin.PREANALYTIC,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.assay.routingGroup'),
                    itemPageName: ItemPagesNameMixin.ROUTINGGROUP,
                    link: function () {
                        return 'admin/routinggroup';
                    },
                    role: RolesMixin.ROUTINGGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.assay.routingAction'),
                    itemPageName: ItemPagesNameMixin.ROUTINGACTION,
                    link: function () {
                        return 'admin/routingaction';
                    },
                    role: RolesMixin.ROUTINGACTION,
                    subMenu: []
                }, {
                    title: _.i18n('menu.preProcess'),
                    itemPageName: ItemPagesNameMixin.PREPROCESS,
                    link: function () {
                        return 'admin/preProcess';
                    },
                    role: RolesMixin.PREPROCESS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.extraction.exMethod'),
                    itemPageName: ItemPagesNameMixin.EXTRACTIONMETHOD,
                    link: function () {
                        return 'admin/extractionMethods';
                    },
                    role: RolesMixin.EXTRACTIONMETHOD,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kits.extr'),
                    itemPageName: ItemPagesNameMixin.KITS_EXTRACTION,
                    link: function () {
                        return 'admin/kits/extraction';
                    },
                    role: RolesMixin.KIT_EXTRACTION,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.kits.extrLots'),
                    itemPageName: ItemPagesNameMixin.KITS_EXTRACTION_LOT,
                    link: function () {
                        return 'admin/kits/extraction/lot';
                    },
                    role: RolesMixin.KIT_EXTRACTION_LOT,
                    subMenu: []
                }
            ]
        },

        //LIS
        {
            title: _.i18n('menu.lis'),
            itemPageName: ItemPagesNameMixin.LIS_MENU,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.admin.lis'),
                    itemPageName: ItemPagesNameMixin.LIS,
                    link: function () {
                        return 'admin/lis';
                    },
                    role: RolesMixin.LIS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.sampleClass'),
                    itemPageName: ItemPagesNameMixin.SAMPLECLASS,
                    link: function () {
                        return 'admin/sampleClass';
                    },
                    role: RolesMixin.SAMPLECLASS,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisana'),
                    itemPageName: ItemPagesNameMixin.LISANA,
                    link: function () {
                        return 'admin/lisanas';
                    },
                    role: RolesMixin.LISANA,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.biogroups'),
                    itemPageName: ItemPagesNameMixin.BIOGROUPS,
                    link: function () {
                        return 'admin/biogroups';
                    },
                    role: RolesMixin.BIOGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisBioGroups'),
                    itemPageName: ItemPagesNameMixin.LISBIOGROUP,
                    link: function () {
                        return 'admin/lisBioGroups';
                    },
                    role: RolesMixin.LISBIOGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.specie'),
                    itemPageName: ItemPagesNameMixin.SPECIES,
                    link: function () {
                        return 'admin/species';
                    },
                    role: RolesMixin.SPECIE,
                    subMenu: []
                }, {
                    title: _.i18n('menu.lis.lisspecie'),
                    itemPageName: ItemPagesNameMixin.LISSPECIE,
                    link: function () {
                        return 'admin/lisSpecies';
                    },
                    role: RolesMixin.LISSPECIE,
                    subMenu: []
                }
            ]
        },

        //PROCESS CTRL
        {
            title: _.i18n('menu.process.control'),
            itemPageName: ItemPagesNameMixin.PROCESS_CTRL,
            link: function () {
                return '';
            },
            subMenu: [{
                title: _.i18n('menu.admin.exportStatus'),
                itemPageName: ItemPagesNameMixin.ADMIN_EXPORT,
                link: function () {
                    return 'admin/export/ASTM/status';
                },
                role: RolesMixin.LIS,
                subMenu: []
            }, {
                title: _.i18n('menu.attachment'),
                itemPageName: ItemPagesNameMixin.ATTACHMENTS,
                link: function () {
                    return 'admin/attachments';
                },
                role: RolesMixin.ATTACHMENT,
                subMenu: []
            }, {
                // TRASH
                title: _.i18n('menu.trash'),
                itemPageName: ItemPagesNameMixin.TRASH,
                link: function () {
                    return 'admin/trash';
                },
                role: RolesMixin.TRASH,
                subMenu: []
            }, {
                // TRASH
                title: _.i18n('menu.connector.hl7.tracings'),
                itemPageName: ItemPagesNameMixin.CONNECTOR_HL7_TRACING,
                link: function () {
                    return 'admin/connector/hl7';
                },
                role: RolesMixin.CONNECTOR_HL7,
                subMenu: []
            }
            ]
        },

        //ACCOUNT
        {
            title: _.i18n('menu.account'),
            itemPageName: ItemPagesNameMixin.ACCOUNT,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.myAccount'),
                    itemPageName: ItemPagesNameMixin.MY_ACCOUNT,
                    link: function () {
                        return 'caccount/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
                    },
                    role: RolesMixin.ACCOUNT,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.authorityGroups'),
                    itemPageName: ItemPagesNameMixin.ADMIN_AUTHORITYGROUP,
                    link: function () {
                        return 'admin/authorityGroups';
                    },
                    role: RolesMixin.AUTHORITYGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.userGroups'),
                    itemPageName: ItemPagesNameMixin.ADMIN_USERGROUP,
                    link: function () {
                        return 'admin/userGroups';
                    },
                    role: RolesMixin.USERGROUP,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.users'),
                    itemPageName: ItemPagesNameMixin.ADMIN_USERS,
                    link: function () {
                        return 'admin/users';
                    },
                    role: RolesMixin.USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.account.suppliers'),
                    itemPageName: ItemPagesNameMixin.SUPPLIER,
                    link: function () {
                        return 'admin/suppliers';
                    },
                    role: RolesMixin.SUPPLIER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.usercodelists'),
                    itemPageName: ItemPagesNameMixin.USERCODELISTS,
                    link: function () {
                        return 'admin/userCodelists';
                    },
                    role: RolesMixin.USERCODELIST,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.settings'),
                    itemPageName: ItemPagesNameMixin.SETTING,
                    link: function () {
                        return 'admin/settings';
                    },
                    role: RolesMixin.SETTING,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.fileFormat.plateLayouts'),
                    itemPageName: ItemPagesNameMixin.FILEFORMAT_PLATELAYOUT,
                    link: function () {
                        return 'admin/fileFormat/plateLayouts';
                    },
                    role: RolesMixin.FILEFORMAT,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.fileFormat.sampleLists'),
                    itemPageName: ItemPagesNameMixin.FILEFORMAT_SAMPLELIST,
                    link: function () {
                        return 'admin/fileFormat/sampleLists';
                    },
                    role: RolesMixin.FILEFORMAT,
                    subMenu: []
                }
            ]
        },
        //INSTRUMENT
        {
            title: _.i18n('menu.instrument'),
            itemPageName: ItemPagesNameMixin.ADMIN_INSTRUMENTS,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.admin.cyclers'),
                    itemPageName: ItemPagesNameMixin.CYCLERS,
                    link: function () {
                        return 'admin/cyclers';
                    },
                    role: RolesMixin.CYCLER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.extractors'),
                    itemPageName: ItemPagesNameMixin.EXTRACTORS,
                    link: function () {
                        return 'admin/extractors';
                    },
                    role: RolesMixin.EXTRACTOR,
                    subMenu: []
                }, {
                    title: _.i18n('menu.admin.sampleHandlers'),
                    itemPageName: ItemPagesNameMixin.SAMPLEHANDLERS,
                    link: function () {
                        return 'admin/sampleHandlers';
                    },
                    role: RolesMixin.SAMPLEHANDLER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.printer'),
                    itemPageName: ItemPagesNameMixin.PRINTERS,
                    link: function () {
                        return 'admin/printers';
                    },
                    role: RolesMixin.PRINTER,
                    subMenu: []
                }
            ]
        },

        //ACCOUNT
        {
            title: _.i18n('menu.templates'),
            itemPageName: ItemPagesNameMixin.RUNTEMPLATES,
            link: function () {
                return '';
            },
            subMenu: [
                {
                    title: _.i18n('menu.template.prepRun'),
                    itemPageName: ItemPagesNameMixin.PREPRUNTEMPLATES,
                    link: function () {
                        return 'admin/runtemplates/prep';
                    },
                    role: RolesMixin.RUN_PREP_TEMPLATE,
                    subMenu: []
                }, {
                    title: _.i18n('menu.template.pcrRun'),
                    itemPageName: ItemPagesNameMixin.PCRRUNTEMPLATES,
                    link: function () {
                        return 'admin/runtemplates/pcr';
                    },
                    role: RolesMixin.RUN_PCR_TEMPLATE,
                    subMenu: []
                }, {
                    title: _.i18n('menu.report'),
                    itemPageName: ItemPagesNameMixin.REPORTS,
                    link: function () {
                        return 'admin/reports';
                    },
                    role: RolesMixin.REPORT,
                    subMenu: []
                }
            ]
        },

        //SUPER ADMIN
        {
            title: _.i18n('menu.superAdmin'),
            itemPageName: ItemPagesNameMixin.SUPERADMIN,
            link: function () {
                return 'superAdmin';
            },
            role: RolesMixin.SUPER_USER,
            subMenu: [
                {
                    title: _.i18n('menu.translations'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_TRANSLATIONS,
                    link: function () {
                        return 'superAdmin/translations';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.caccounts'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_CACCOUNTS,
                    link: function () {
                        return 'superAdmin/caccounts';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.users'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_USERS,
                    link: function () {
                        return 'superAdmin/users';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.detectionFormats'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_DETECTIONFORMATS,
                    link: function () {
                        return 'superAdmin/detectionFormats';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.cyclers'),
                    itemPageName: ItemPagesNameMixin.SUPERCYCLERS,
                    link: function () {
                        return 'superAdmin/cyclers';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.codelists'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_CODELISTS,
                    link: function () {
                        return 'superAdmin/codelists';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.arrays'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_ARRAYS,
                    link: function () {
                        return 'superAdmin/arrays';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.godMode'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_MENUS,
                    link: function () {
                        return 'superAdmin/godMode';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.dynamicDefinitions'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_DYNAMICDEFINITIONS,
                    link: function () {
                        return 'superAdmin/dynamicDefinition';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.logs'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_LOGS,
                    link: function () {
                        return 'superAdmin/logs';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.routes'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_ROUTES,
                    link: function () {
                        return 'superAdmin/routes';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }, {
                    title: _.i18n('menu.superadmin.modules'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN_MODULES,
                    link: function () {
                        return 'superAdmin/modules';
                    },
                    role: RolesMixin.SUPER_USER,
                    subMenu: []
                }
            ]
        }
    ];
});

