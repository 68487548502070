
define('template!adminAttachmentsCreateEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            ';
 if (newItem) { ;
__p += '\n            <div class="form-group">\n                <div class="col-xs-3 dataLabelContainer" data-field-name="file">\n                        <label class="control-label dataLabel" for="doc-file">' +
((__t = ( _.i18n('file.file') )) == null ? '' : __t) +
'</label>\n                </div>\n                <div class="col-xs-9 p-l-0 p-r-0" data-field-name="file">\n                        <input class="js-upload-file"\n                               data-icon="true"\n                               id="doc-file"\n                               title="" type="file"/>\n                </div>\n            </div>\n            ';
 } else { ;
__p += '\n            <div class="form-group">\n                <div class="col-xs-3 dataLabelContainer" data-field-name="file">\n                        <label class="control-label dataLabel" for="doc-file">' +
((__t = ( _.i18n('attachment.fileName')
                            )) == null ? '' : __t) +
'</label>\n                </div>\n                <div class="col-xs-6" data-field-name="fileName">\n                        <input autocomplete="off" class="form-control floating-label inputBackground"\n                               data-content="' +
((__t = ( _.i18n('accDocument.duplicatedNameError') )) == null ? '' : __t) +
'"\n                               data-field-name="fileName"\n                               data-placement="top"\n                               placeholder="" type="text" value="' +
((__t = ( fileName )) == null ? '' : __t) +
'" disabled="disabled">\n                </div>\n            </div>\n            ';
 } ;
__p += '\n            <div class="form-group">\n                <div class="col-xs-3 dataLabelContainer" data-field-name="name">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n                </div>\n                <div class="col-xs-6" data-field-name="name">\n                        <input autocomplete="off" class="form-control floating-label js-info-input inputBackground"\n                               data-content="' +
((__t = ( _.i18n('accDocument.duplicatedNameError') )) == null ? '' : __t) +
'" data-field-name="name"\n                               data-placement="top"\n                               placeholder="" type="text" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n            <div class="form-group">\n                <div class="col-xs-3 dataLabelContainer" >\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('attachment.context') )) == null ? '' : __t) +
'</label>\n                </div>\n                <div class="col-xs-2 p-l-0 p-r-0">\n                        <input autocomplete="off" class="form-control floating-label inputBackground disabled"\n                               data-content="' +
((__t = ( _.i18n('accDocument.duplicatedNameError') )) == null ? '' : __t) +
'"\n                               data-placement="top"\n                               placeholder="" type="text" value="' +
((__t = ( context )) == null ? '' : __t) +
'" readonly="readonly">\n                </div>\n            </div>\n            <div class="form-group">\n                <div class="col-xs-3 dataLabelContainer" data-field-name="type">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('attachment.type') )) == null ? '' : __t) +
'</label>\n                    </div>\n                <div class="col-xs-2 js-type p-l-0 p-r-0" data-field-name="type">\n                </div>\n            </div>\n            <div class="js-comment">\n            </div>\n            <div class="cancelConfirmRow">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

