/**
 * Created by RKL on 26/08/2015.
 */
define('emptyView',[
    'module',
    'backbone.marionette',
    'underscore',
    'app',
    'template!emptyViewTpl'
], function (
    module,
    Marionette,
    _,
    App,
    viewTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl
    });
});
