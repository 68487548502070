
define('template!assayReagentMMXTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12 m-0 p-0">\n    <h2 class="m-0 m-b-5 col-xs-12 p-0">' +
((__t = ( _.i18n('assay.reagent.mmx.title') )) == null ? '' : __t) +
'</h2>\n</div>\n<div class="d-f col-w-100" style="gap: 3px;">\n    <div class="col-w-5" style="width: 24px;">\n        <span class="mdi mdi-plus-circle action clickable js-add" style="line-height: 24px; font-size: 24px;"></span>\n    </div>\n    <div class="col-w-43">' +
((__t = ( _.i18n('assayReagent.kit.title') )) == null ? '' : __t) +
'</div>\n    <div class="col-w-15">' +
((__t = ( _.i18n('assayReagent.reag.title') )) == null ? '' : __t) +
'</div>\n    <div class="col-w-15">' +
((__t = ( _.i18n('assayReagent.vol.title') )) == null ? '' : __t) +
'</div>\n    <div class="col-w-15">' +
((__t = ( _.i18n('assayReagent.comm.title') )) == null ? '' : __t) +
'</div>\n    <div class="col-w-7">' +
((__t = ( _.i18n('sequence') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="collection col-xs-12 p-0 m-b-5"></div>\n\n<div class="d-f col-w-100" style="gap: 3px;">\n    <div class="col-w-59 dataLabelContainer">\n        <label class="control-label">' +
((__t = ( _.i18n('assay.volumeMMX') )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-w-15">\n        <input type="number" step="0.01"\n               class="js-info-input form-control inputBackground"\n               placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n               value="' +
((__t = ( volumeMMX )) == null ? '' : __t) +
'" disabled="disabled"/>\n    </div>\n</div>';

}
return __p
};});

