define('wellsGraphView',[
    'module',
    'underscore',
    'mobioChartView',
    'colorUtils',
    'fieldUtils',
    'cssUtils'
], function (
    module,
    _,
    MobioChartView,
    ColorUtils,
    FieldUtils,
    CssUtils
) {
    'use strict';

    module.exports = MobioChartView.extend({

        initialize: function (options) {
            this.displayLegend = options.displayLegend === undefined ? true : options.displayLegend;
        },

        setModel: function (models) {
            this.models = models;
        },

        serializeData: function () {
            this.data = {
                datasets: []
            };

            var c;
            var dataAlgosCurves = [];

            _.each(this.models, _.bind(function (model) {
                if (this.assayResultVersion) {
                    var result = model.get('results').chain().filter(_.bind(function (result) {
                        return result.get('refAssayResult') === this.assayResultVersion && result.get('result');
                    }, this)).first().value();
                    if (result) {
                        var curve = result.get('curves').first();

                        dataAlgosCurves.push({
                            curve: curve,
                            color: result.get('result').get('color'),
                            resCode: result.get('result').get('code'),
                            errCode: result.get('errCode'),
                            comment: result.get('comment'),
                            target: result
                        });
                    }
                }
            }, this));

            _.each(dataAlgosCurves, _.bind(function (algoCurve) {
                if (!_.isUndefined(algoCurve.curve) && !_.isEmpty(algoCurve.curve)) {
                    c = this.getColorFromWellResChannel(algoCurve.target.get('refPcrWell'), algoCurve.target);
                    if (!c) {
                        c = this.getColorFromRes(
                            //runModel, assay, smpType, target, res, errCode, forceNull, highlight, targetColor
                            algoCurve.target.get('refPcrWell'),
                            algoCurve.target.get('refPcrWell').get('refAssay'),
                            algoCurve.target.get('refPcrWell').get('smpType'),
                            algoCurve.channel,
                            algoCurve.resCode,
                            algoCurve.errCode,
                            !this.fixedColor,
                            this.highlight ? algoCurve.target.get('refPcrWell').get('secId') === this.highlight.get('secId') : false,
                            algoCurve.color);
                    }

                    if (algoCurve.target.get('refPcrWell').get('repeatStatus')) {
                        c.dashed = {
                            borderDashOffset: 3,
                            borderDash: [2, 4]
                        };
                        c.r = 0;
                        c.g = 0;
                        c.b = 0;
                    }

                    var legendContent = algoCurve.target.get('refPcrWell').get('pos');
                    if (algoCurve.target) {
                        if (algoCurve.target.get('cts')) {
                            legendContent += ' (' + algoCurve.target.get('cts') + ')';
                        }
                    }

                    var result = this.addData(
                        algoCurve.curve.get('lines'),
                        c.r,
                        c.g,
                        c.b,
                        legendContent,
                        c.line,
                        c.dashed,
                        algoCurve.comment,
                        algoCurve.target.get('refPcrWell'),
                        null,
                        null,
                        this.options.rangeSmooth,
                        this.options.smooth,
                        this.withoutCC
                    );

                    var exponentialData = _.filter(result.data, function (data) {
                        return data.exponential;
                    });
                    result.data = _.reject(result.data, function (data) {
                        return data.exponential;
                    });
                    this.data.datasets.push(result);
                    if (exponentialData.length > 0 && !this.options.hideExtraPoint) {
                        var result2 = JSON.parse(JSON.stringify(result));
                        result2.data = exponentialData;
                        result2.color = 'rgba(0, 0, 0, 0.5)';
                        result2.borderColor = 'rgba(0, 0, 0, 0.5)';
                        result2.dashed = {
                            borderDashOffset: 3,
                            borderDash: [2, 4]
                        };
                        this.data.datasets.push(result2);
                    }
                }

            }, this));

            var all = _.flatten(_.pluck(this.data.datasets, 'data'));

            var valArray = _.pluck(all, 'y');
            var dataLength = FieldUtils.arrayMax(_.pluck(all, 'x'));

            if (dataLength === 1) {
                dataLength = 2;
            }

            var yMinValue = FieldUtils.arrayMin(valArray);
            var yMaxValue = FieldUtils.arrayMax(valArray);

            var addValue = (yMaxValue - yMinValue) * 0.15;
            yMinValue -= addValue;
            yMaxValue += addValue;

            if (this.data.datasets.length > 0) { //this.showPropC
                var prop2Color = CssUtils.getPropertyValue('--css-propC-2');

                if (this.assayResultVersion && this.assayResultVersion.get('algoResult').type === 'MUTV') {
                    var params = this.assayResultVersion.get('algoResult').param.split(';');
                    for (var i = 1; i < params.length; i = i + 6) {
                        var result = params[i];
                        if (result) {
                            var ref = parseFloat(params[i + 1]);
                            var expectedMin = ref - parseFloat(params[i + 3]);
                            var expectedMax = ref + parseFloat(params[i + 3]);
                            var errorMin = ref - parseFloat(params[i + 2]);
                            var errorMax = ref + parseFloat(params[i + 2]);
                            var tresholdMin = parseFloat(params[i + 4]);
                            var thresholdMax = parseFloat(params[i + 5]);
                            this.addVerticalLine(yMinValue, yMaxValue, errorMin, '#ff0000');
                            this.addVerticalLine(yMinValue, yMaxValue, errorMax, '#ff0000');
                            this.addVerticalLine(yMinValue, yMaxValue, expectedMin, '#eed862');
                            this.addVerticalLine(yMinValue, yMaxValue, expectedMax, '#eed862');
                            if (this.deriv1Enable) {
                                this.addHorizontalLine(errorMin, errorMax, tresholdMin, '#ff0000');
                                this.addHorizontalLine(errorMin, errorMax, thresholdMax, '#eed862');
                            }
                        }
                    }
                }
                if (this.assayResultVersion && (this.assayResultVersion.get('algoResult').type === 'AMP' || this.assayResultVersion.get('algoResult').type === 'AMP_IC')) {
                    if (this.deriv2Enable) {
                        var threshold = this.assayResultVersion.get('algoResult').param.split(';')[1];
                        // add Amp line
                        this.addHorizontalLine(1, dataLength, threshold, prop2Color);
                    }
                }
            }

            if (this.data.datasets.length) {
                if (this.baseline !== null && this.baseline !== undefined) {
                    this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
                }
                if (this.threshold !== null && this.threshold !== undefined) {
                    this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
                }
            }

            this.generateLegend();
            return this.data;
        }
    });
});
