define('runPcrValidationPlateGridCollectionView',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'runPcrValidationPlateGridItemView',
    'runPcrValidationPlateGridItemHeaderView',
    'runPcrValidationPlateGridItemMenuView'
], function (
    module,
    Backbone,
    _,
    Marionette,
    ItemView,
    ItemHeaderView,
    ItemMenuView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'grid-wells',
        attributes: function () {
            var column = Number.parseInt(this.model.get('run').get('plateSize').substring(1));
            var line = this.model.get('run').get('plateSize').substring(0, 1).charCodeAt(0) - 64;
            return {
                'style': 'display: grid; grid-template-columns: 20px repeat(' + column + ', 25px); grid-template-rows: 20px repeat(' + line + ', 20px);'
            };
        },
        childView: function (model) {
            if (model.get('type') === 'header') {
                return ItemHeaderView;
            } else if (model.get('type') === 'menu') {
                return ItemMenuView;
            } else {
                return ItemView;
            }
        },
        initialize: function () {
            this.collection = new Backbone.Collection();
            this.collection.comparator = 'pos';
            this.model.get('run').get('wells').each(_.bind(function (model) {
                this.collection.add(model);
            }, this));
            var plateSize = this.model.get('run').get('plateSize');
            this.column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            this.collection.push(new Backbone.Model({pos: '000', type: 'menu'}));
            for (var i = 1; i <= this.column; i++) {
                this.collection.push(new Backbone.Model({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                this.collection.push(new Backbone.Model({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            this.collection.sort();
            this.listenTo(this.model.get('wellSelected'), 'add remove reset', _.bind(this.selectedWell, this));
        },
        childViewOptions: function (model) {
            var backgroundColor = 'transparent';

            return {
                model: model,
                view: this.options.view,
                backgroundColor: backgroundColor,
                validationModel: this.options.model
            };
        },
        childViewEvents: {
            'click': function (model) {
                var wells = [];
                var assayResultVersion = this.model.get('assayResultVersion');
                var availableWells = new Backbone.Collection(this.model.get('run').get('wells').filter(function (well) {
                    return well.get('assayVersion') && well.get('assayVersion').get('results').find(function (result) {
                        return result === assayResultVersion;
                    });
                }));
                if (model.get('pos').endsWith('00')) {
                    wells = availableWells.filter(function (well) {
                        return well.get('pos').substring(0, 1) === model.get('pos').substring(0, 1);
                    });
                } else if (model.get('pos').startsWith('0') && model.get('pos') !== '000') {
                    wells = availableWells.filter(function (well) {
                        return well.get('pos').substring(1, 3) === model.get('pos').substring(1, 3);
                    });
                } else {
                    wells.push(model);
                }
                var everyCheck = _.every(wells, function (well) {
                    return this.model.get('wellSelected').some(function (selectedWell) {
                        return selectedWell === well;
                    });
                }, this);
                if (everyCheck) {
                    if (availableWells.length === this.model.get('wellSelected').length) {
                        this.model.get('wellSelected').reset(wells);
                    } else {
                        if (this.model.get('wellSelected').every(function (selectedWell) {
                            return _.some(wells, function (well) {
                                return selectedWell === well;
                            });
                        })) {
                            this.model.get('wellSelected').reset(availableWells.models);
                        } else {
                            this.model.get('wellSelected').remove(wells);
                        }
                    }
                } else {
                    this.model.get('wellSelected').add(wells);
                }
            },
            'menu:resetFilter': function () {
                this.trigger('resetWellSelected');
            }
        },
        selectedWell: function () {
            this.children.each(_.bind(function (view) {
                if (view.model.get('type') !== 'header' && view.model.get('type') !== 'menu') {
                    view.model.trigger('selected', this.model.get('wellSelected').models.includes(view.model));
                }
            }, this));
        }
    });
});

