define('kitLotsActionWellsView',[
    'module',
    'dialogFormView',
    'template!kitLotsActionWellsView',
    'kitLotActionPcrWellCollectionView',
    'kitLotActionPrepWellCollectionView'
], function (
    module,
    DialogFormView,
    Tpl,
    PcrWellCollectionView,
    PrepWellCollectionView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,
        regions: {
            pcrWells: '.js-pcr-wells',
            prepWells: '.js-prep-wells',
            pcrWellCollection: '.js-pcr-wells-collection',
            prepWellCollection: '.js-prep-wells-collection'
        },

        className: 'kitLotWells',

        serializeData: function () {
            return {
                prepWell: this.options.prepWells ? !this.options.prepWells.isEmpty() : false,
                pcrWell: this.options.prepWells ? !this.options.prepWells.isEmpty() : false
            };
        },

        onRender: function () {
            var pcrWells = this.options.pcrWells;
            var prepWells = this.options.prepWells;
            if (!pcrWells || pcrWells.isEmpty()) {
                this.getRegion('pcrWells').empty();
            } else {
                this.showChildView('pcrWellCollection', new PcrWellCollectionView({collection: pcrWells}));
            }
            if (!prepWells || prepWells.isEmpty()) {
                this.getRegion('prepWells').empty();
            } else {
                this.showChildView('prepWellCollection', new PrepWellCollectionView({collection: prepWells}));
            }
        }
    });
});
