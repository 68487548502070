define('runEditLayoutView',[
    'module',
    'dialogFormView',
    'template!runEditLayoutTpl',
    'runEditRunNameView',
    'savingBehavior',
    'underscore',
    'jquery',
    'app',
    'settings',
    'autocompleteView',
    'fieldUtils',
    'dateUtils',
    'wellUtils'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    CreateEditRunNameView,
    SavingBehavior,
    _,
    $,
    App,
    Settings,
    AutocompleteView,
    FieldUtils,
    DateUtils,
    WellUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            sheetFile: '#sheetFile',
            plateSizeNb: '.js-run-plate-size-Nb',
            periodDt: '.js-period',
            copyParameters: '.js-copyParameters'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'change @ui.sheetFile': 'onChangeSheet',
            'click #removeSheet': 'onRemoveSheet',
            'click #addSheet': 'onAddSheet',
            'click .js-edit-runName': 'onEditRunName'
        },

        regions: {
            selectRespUser: '.js-select-respUser-region',
            selectLmbCycler: '.js-select-lmbcycler-region',
            selectCyclerPublic: '.js-select-cyclerPublic-region',
            selectCycler: '.js-select-cycler-region',
            selectKitProt: '.js-select-kitprot-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            change: 'renderSafe'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        onEditRunName: function () {
            var view = new CreateEditRunNameView({
                model: this.model.clone(),
                runModel: this.model,
                settings: this.model.get('settings')
            });
            view.show({
                title: _.i18n('run.editRunName'),
                className: 'runEditAddPopup'
            });
        },

        initialize: function () {
            this.oldData = {
                name: this.model.get('name'),
                status: this.model.get('status'),
                valStatus: this.model.get('valStatus')
            };
        },

        serializeData: function () {
            var templateData = {
                name: this.model.get('name'),
                prepRuns: this.model.get('prepRuns').map(function (prepRun) {
                    return {code: prepRun.get('code'), name: prepRun.get('name')};
                }),
                comment: this.model.get('comment'),
                plateIdPC: this.model.get('plateIdPC')
            };
            templateData.valStatusIcon = this.options.valStatusIcon;
            templateData.statusIcon = this.options.statusIcon;

            var dpgsToDisplay = {
                department: this.model.get('department') ? this.model.get('department') : ' ',
                periodFormatted: this.model.get('periodFormatted') ? this.model.get('periodFormatted') : ' ',
                group: this.model.get('group') ? this.model.get('group') : ' ',
                sequenceFormatted: FieldUtils.getSequenceFormatted(this.model.get('sequence'), this.model.get('sequenceFormat'))
            };

            templateData.dpgs = dpgsToDisplay.department + ' - ' + dpgsToDisplay.periodFormatted + ' - ' + dpgsToDisplay.group + ' - ' + dpgsToDisplay.sequenceFormatted;
            templateData.assays = this.model.get('assays').map(function (assay) {
                return {code: assay.get('code'), name: assay.get('name')};
            });
            templateData.creatDateToDisplay = DateUtils.toDateTimeFormatStringFromSettings(this.model.get('creatDate'), this.model.get('settings'));
            templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size());

            templateData.cyclerToDisplay = this.model.get('refLmbCycler') && this.model.get('refLmbCycler').code && this.model.get('refCycler') && this.model.get('refCycler').get('code') ? this.model.get('refCycler').get('code') : undefined;
            templateData.cyclerPublicToDisplay = this.model.get('refCycler') && this.model.get('refCycler').get('code') && this.model.get('refCyclerPublic') && this.model.get('refCyclerPublic').code ? this.model.get('refCyclerPublic').code : undefined;
            templateData.kitProtToDisplay = this.model.get('refKitProt') && this.model.get('refKitProt').get('code') ? this.model.get('refKitProt').get('code') : undefined;
            return templateData;
        },

        onRender: function () {
            App.trigger('forClipboard', this.ui.copyParameters);
            this.showRefRespUserDropDown();

            this.showKitProtDropDown();

            if (!(this.model.get('refCycler') && this.model.get('refCycler').get('code') && this.model.get('refCyclerPublic') && this.model.get('refCyclerPublic').code)) {
                var CyclerPublicService = require('services/caccounts/cyclerpublics');
                this.getRegion('selectCyclerPublic').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(CyclerPublicService.getAutocompleteParam({
                        modelProperty: 'refCyclerPublic',
                        callBackOnChange: this.onCyclerPublicChange
                    }))));
            }

            if (!(this.model.get('refLmbCycler') && this.model.get('refLmbCycler').code && this.model.get('refCycler') && this.model.get('refCycler').get('code'))) {
                var CyclerService = require('services/caccounts/cyclers');
                this.getRegion('selectCycler').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(CyclerService.getAutocompleteParam({
                        modelProperty: 'refCycler',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'cyclerCode': this.model.get('refCyclerPublic') ? this.model.get('refCyclerPublic').code : undefined
                        },
                        callBackOnChange: this.onCyclerChange
                    }))));
            }

            var LmbCyclerService = require('services/caccounts/lmbcyclers');
            this.getRegion('selectLmbCycler').show(new AutocompleteView(
                this._getAutocompleteOptionObject(LmbCyclerService.getAutocompleteParam({
                    modelProperty: 'refLmbCycler',
                    callBackOnChange: this.onLmbCyclerChange,
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code',
                        'refCycler.refCycler.code': this.model.get('refCyclerPublic') ? this.model.get('refCyclerPublic').code : undefined,
                        'refCycler.code': this.model.get('refCycler') && this.model.get('refCycler').get('code') ? this.model.get('refCycler').get('code') : undefined,
                        kitProt: this.model.get('refKitProt') ? this.model.get('refKitProt').get('secId') : undefined
                    }
                }))
            ));
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.save();
            }
        },

        save: function () {
            $('.js-global-loader').show();
            this.model.saveHeader()
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .fail(_.bind(function (error) {
                    console.error(error);
                    this.model.fetch();
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.hide();
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onChangeSheet: function (e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.trigger('sheet:upload', e.target);
            }
        },

        onRemoveSheet: function () {
            this.trigger('sheet:remove');
        },

        onAddSheet: function () {
            $('#sheetFile').click();
        },

        showRefRespUserDropDown: function () {
            if (this.$el.find('.js-select-respUser-region').length > 0) {
                if (this.respUserView) {
                    this.respUserView.destroy();
                }
                var UserpidService = require('services/caccounts/userpids');
                this.respUserView = new AutocompleteView(
                    this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                        modelProperty: 'refRespUser',
                        valueProperty: 'nickName',
                        queryProperty: 'nickName',
                        valueKey: 'nickName',
                        callBackOnChange: this.onRespUserChange
                    }))
                );
                this.getRegion('selectRespUser').show(this.respUserView);
            }
        },

        showKitProtDropDown: function () {
            if (this.model.get('assays').length === 0) {
                var KitProtService = require('services/caccounts/kitprots');
                this.getRegion('selectKitProt').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(KitProtService.getAutocompleteParam({
                        modelProperty: 'refKitProt',
                        callBackOnChange: this.onKitProtChange,
                        callBackAdd: this.onAddKitProt,
                        callBackEdit: this.onEditKitProt,
                        canNewValue: true,
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'lmbCyclerSecId': this.model.get('refLmbCycler') ? this.model.get('refLmbCycler').secId : undefined
                        }
                    }))));
            }
        },

        onAddKitProt: function () {
            var KitProtService = require('services/caccounts/kitprots');
            this.showEditAddKitProtView(KitProtService.getModel());
        },

        onEditKitProt: function (secId) {
            var KitProtService = require('services/caccounts/kitprots');
            var model = KitProtService.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                this.showEditAddKitProtView(model);
            }, this));
        },

        showEditAddKitProtView: function (model) {
            require(['dynamicController'], _.bind(function (DynamicController) {
                DynamicController.showDetails({
                    model: model, useCreateEditView: _.bind(function (view) {
                        view.on('saved', _.bind(function (model) {
                            this.model.set('refKitProt', model.attributes);
                        }, this));
                    }, this)
                });
            }, this));
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        onKitProtChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.model.trigger('change');
        },

        onLmbCyclerChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            if (model) {
                this.onChange('refCycler', model ? model.get('refCycler') : null);
                this.onChange('refCyclerPublic', model && model.get('refCycler') ? model.get('refCycler').refCycler : null);
            }
            this.model.trigger('change');
        },

        onCyclerChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            if (model) {
                this.onChange('refCyclerPublic', model ? model.get('refCycler') : null);
            }
            this.model.trigger('change');
        },

        onCyclerPublicChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.model.trigger('change');
        }
    });
});

