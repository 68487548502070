define('prepWellPcrKitLotValidationItem',[
    'module',
    'backbone.marionette',
    'template!prepWellPcrKitLotValidationItem'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'd-f baseline bottom-border-cell-light',
        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('kit').get('code');
            templateData.kitLot = this.model.get('code');
            return templateData;
        }
    });
});
