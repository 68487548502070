/**
 * Created by RKL on 22/09/2015.
 */
define('stateMessages',[
    'module',
    'backbone.marionette'
], function (
    module,
    Marionette
) {
    'use strict';

    var loadingBehavior = Marionette.Behavior.extend({
        ui: {
            message: '.js-loading-message',
            block: '.js-loading-block'
        },

        onShowMessage: function (type, message) {
            this._resetMessage();
            this._addType(type);
            this._show(message);
        },

        onErrorMessage: function (message) {
            this._resetMessage();
            this._addType('danger');
            this._show(message);
            this._delay(5000);
        },

        onDelayMessage: function (delayTime) {
            this._delay(delayTime);
        },

        onHideMessage: function () {
            this._hide();
        },

        _delay: function (delayTime) {
            var that = this;

            this.delayTimeout = setTimeout(function () {
                that._hide();
            }, delayTime);
        },

        _hide: function () {
            this.ui.block.fadeOut('slow');
        },

        _show: function (message) {
            this.ui.message.text(message);
            this.ui.block.show();
        },

        _addType: function (type) {
            this.ui.message.addClass('label-' + type);
        },

        _resetMessage: function () {
            clearTimeout(this.delayTimeout);
            this.ui.message.removeClass('label-default');
            this.ui.message.removeClass('label-primary');
            this.ui.message.removeClass('label-success');
            this.ui.message.removeClass('label-warning');
            this.ui.message.removeClass('label-danger');
            this.ui.message.removeClass('label-info');
        }

    });

    module.exports = loadingBehavior;
});
