define('runEditRunNameView',[
    'module',
    'dialogFormView',
    'template!runEditRunNameTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'autocompleteView',
    'bootbox',
    'moment',
    'fieldUtils'
], function (
    module,
    DialogFormView,
    RunEditRunNameTpl,
    SavingBehavior,
    _,
    $,
    Settings,
    AutocompleteView,
    Bootbox,
    moment,
    FieldUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: RunEditRunNameTpl,

        ui: {
            input: '.js-info-input',
            periodDt: '.js-period'
        },

        events: {
            'click .js-generate-runName': 'onGenerateRunName',
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            selectRunPattern: '.js-runName-pattern',
            selectRunNameDepartment: '.js-select-runName-department',
            selectRunNameGroup: '.js-select-runName-runGroup'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            error: 'onSaveError',
            'change:period': 'onChangeDt'
        },

        fieldsToValidate: [
            {name: 'name', type: 'required'}
        ],

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.periodFmt = (this.options.settings && this.options.settings['PERIOD-FMT']) ? this.options.settings['PERIOD-FMT'] : '';
            templateData.period = templateData.period === null ? null : moment(this.model.get('period')).format('DD-MM-YY');
            if (this.model.get('sequenceFormat') && this.model.get('sequenceFormat') === 'NNN') {
                templateData.sequence = FieldUtils.threeDigit(templateData.sequence);
            } else if (this.model.get('sequenceFormat') && this.model.get('sequenceFormat') === 'NN') {
                templateData.sequence = FieldUtils.twoDigit(templateData.sequence);
            }
            return templateData;
        },

        onRender: function () {
            this.showPatternDropDown();
            this.showDepartmentDropDown();
            this.showRunGroupDropDown();
            this.initDatePicker(this.ui.periodDt, 'period', true);
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                if (this.model.hasChanged('name')) {
                    if (this.isValidName()) {
                        this.model.availableRunName()
                            .done(_.bind(function (response) {
                                if (!response) {
                                    Bootbox.confirm(_.i18n('run.edit.alreadyExistName'), _.bind(function (result) {
                                        if (!result) {
                                            return;
                                        }
                                        this.save();
                                    }, this));
                                } else {
                                    this.save();
                                }
                            }, this));
                    }
                } else {
                    this.save();
                }
            }
        },

        save: function () {
            this.options.runModel.set('name', this.model.get('name'));
            this.options.runModel.set('period', this.model.get('period'));
            this.options.runModel.set('department', this.model.get('department'));
            this.options.runModel.set('periodFormatted', this.model.get('periodFormatted'));
            this.options.runModel.set('group', this.model.get('group'));
            this.options.runModel.set('sequence', this.model.get('sequence'));
            this.options.runModel.set('suffix', this.model.get('suffix'));
            this.options.runModel.set('pattern', this.model.get('pattern'));

            this.model = this.options.runModel;
            $('.js-global-loader').show();
            this.model.saveHeader()
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .fail(_.bind(function (error) {
                    console.error(error);
                    this.model.fetch().done(function () {
                        this.render();
                        this.hide();
                    });
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onCancel: function () {
            this.hide();
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },

        onGenerateRunName: function () {
            if (this.model.get('pattern')) {
                this.model.generateRunName()
                    .done(_.bind(function (name) {
                        this.onSetModel('name', name);
                        this.$el.find('#editRunNameField').val(name);
                    }, this));
            }
        },

        isValidName: function () {
            if (this.model.get('name').match(/\s/g)) {
                this.$('input[data-field-name="name"]').addClass('invalidInput');
                return false;
            } else {
                if (this.$('input[data-field-name="name"]').hasClass('invalidInput')) {
                    this.$('input[data-field-name="name"]').removeClass('invalidInput');
                }
                return true;
            }
        },

        showPatternDropDown: function () {
            if (this.selectPatternView) {
                this.selectPatternView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectPatternView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'pattern',
                        codeListCode: 'PATTERN_PCR',
                        callBackOnChange: this.onChangeSelection
                    })));
            this.getRegion('selectRunPattern').show(this.selectPatternView);
        },

        showDepartmentDropDown: function () {
            if (this.selectDepartmentView) {
                this.selectDepartmentView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectDepartmentView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'department',
                        codeListCode: 'DEPT',
                        callBackOnChange: this.onChangeSelection
                    })));
            this.getRegion('selectRunNameDepartment').show(this.selectDepartmentView);
        },

        showRunGroupDropDown: function () {
            if (this.selectRunGroupView) {
                this.selectRunGroupView.destroy();
            }
            var UserCodeListService = require('services/caccounts/userCodelist');
            this.selectRunGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    UserCodeListService.getAutocompleteParam({
                        modelProperty: 'group',
                        codeListCode: 'RUNGROUP_PCR',
                        callBackOnChange: this.onChangeSelection
                    })));
            this.getRegion('selectRunNameGroup').show(this.selectRunGroupView);
        },

        onChangeDt: function (fieldName) {
            var datePickerField = $('input.js-datePicker[data-field-name=' + fieldName + ']');
            var tempDt = datePickerField.val();
            var displayDt = moment(tempDt, 'DD-MM-YYYY').format('DD-MM-YY');
            datePickerField.val(displayDt);

            var int64Date = moment(tempDt, 'DD-MM-YYYY').format('x');
            this.model.getGeneratePeriodFormatted(int64Date)
                .done(_.bind(function (response) {
                    $('#periodFormatted2').text(response);
                }, this));
        },

        onInputChange: function (e) {
            var $target = $(e.currentTarget),
                value = $target.val(),
                fieldName = $target.data('field-name');
            this.onSetModel(fieldName, value);
        },

        onSetModel: function (fieldName, value) {
            var toSave = {};
            toSave[fieldName] = value;
            this.model.set(toSave, {silent: true});
        },

        onChangeSelection: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onSetModel(fieldName, model ? model.get('code') : null);
            this.triggerMethod('enable:cancel:confirm');
        }
    });
});

