define('entities/caccounts/userpids',[
    'entities/ns',
    'jquery',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/global/userpids',
    'entities/caccounts/authorityGroup',
    'entities/caccounts/userGroup'
], function (
    app,
    $,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.UserPid = app.ns.UserPidGlobal.extend({
        service: 'services/caccounts/userpids',
        fetch: function () {
            this.url = Settings.url('rest/v2/userpid/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            secId: null,
            firstName: '',
            lastName: '',
            email: '',
            nickName: '',
            logonId: '',
            userGroup: null,
            authorityGroups: [],
            authorityGroup: null,
            authorities: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'authorityGroups',
            relatedModel: 'AuthorityGroup',
            collectionType: 'AuthorityGroupCollection',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'authorityGroup',
            relatedModel: 'AuthorityGroup'
        }, {
            type: Backbone.HasOne,
            key: 'userGroup',
            relatedModel: 'UserGroup',
            includeInJSON: ['secId', 'code', 'name']
        }],
        save: function () {
            this.url = Settings.url('rest/v2/userpid/');
            return Backbone.Model.prototype.save.call(this);
        },

        _hasAuthority: function (authority) {
            return _.any(this.get('authorities'), function (authorityGroup) {
                return authorityGroup === authority;
            });
        },

        hasType: function (context, typeSearch) {
            return this._hasAuthority(context + '$' + typeSearch);
        },
        hasRole: function (roleSearch) {
            return this._hasAuthority('ROLE_' + roleSearch);
        },
        toString: function () {
            if (this.get('nickName')) {
                return this.get('nickName') + ' (' + this.get('lastName') + ' ' + this.get('firstName') + ')';
            }
            if (this.get('lastName') && this.get('firstName')) {
                return this.get('lastName') + ' ' + this.get('firstName');
            }
            return this.get('logonId');
        }
    });

    app.ns.UserPid.role = RolesMixin.USER;

    app.ns.UserPidCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/userpid/');
        },
        model: app.ns.UserPid
    });
});

