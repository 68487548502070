
define('template!wellTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="well jq-grid-table-page">\n    <div class="kitl-table">\n        <table class="wellTable" id="jq-grid-list" role="grid"></table>\n    </div>\n</div>';

}
return __p
};});

