define('extractionMethodsReagentsView',[
    'module',
    'dialogFormView',
    'template!extractionMethodsReagentsTpl'
], function (
    module,
    DialogFormView,
    ExtractionMethodsReagentsTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: ExtractionMethodsReagentsTpl,

        serializeData: function () {
            var templateData = this.options.model.toJSON();
            templateData.wellsNumber = this.options.wellsNumber;
            return templateData;
        }
    });
});
