define('sampleResultView',[
    'module',
    'backbone.marionette',
    'template!sampleResultView',
    'underscore',
    'editSampleResultView'
], function (
    module,
    Marionette,
    Tpl,
    _,
    EditSampleResultView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        events: {
            'click .js-edit': 'onEdit'
        },
        modelEvents: {
            'change': 'render'
        },
        serializeData: function () {
            var templateData = {
                sampleResultDefinition: this.model.get('sampleResultDefinition').get('code'),
                comment: this.model.get('pcrWellResults').map(function (pcrWellResult) {
                    return pcrWellResult.get('mbAna').get('code');
                }).join(', ')
            };
            templateData = _.extend(templateData, this.model.getResultIcon());
            return templateData;
        },
        onEdit: function () {
            var view = new EditSampleResultView({
                model: this.model
            });
            view.show({
                title: _.i18n('smpres.edit'),
                className: 'baseTableEditAddPopup sampleBaseTable '
            });
        }
    });
});
