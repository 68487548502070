
define('template!dynamicCreateEditCustomAssayConfigurationResultFromAssay', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-4 p-l-0 p-r-2" data-field-name="assay">\n    <div class="js-assay-region"></div>\n</div>\n<div class="col-xs-4 p-l-2 p-r-2" data-field-name="assayConfiguration">\n    <div class="js-assayConfiguration-region"></div>\n</div>\n<div class="col-xs-4 p-l-2 p-r-0" data-field-name="assayConfigurationResult">\n    <div class="js-assayConfigurationResult-region"></div>\n</div>';

}
return __p
};});

