define('services/caccounts/assay',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'assayCustomJqGridView',
    'entities/caccounts/assay'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    AssayCustomJqGridView
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Assay,
                collection: app.ns.AssayCollection
            };
        },
        getName: function () {
            return 'assay';
        },
        getUrl: function () {
            return 'rest/v2/assays/';
        },
        getDynamicJson: function () {
            return 'AssayJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {clearSearch: false},
                width: '50px'
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {clearSearch: false},
                width: '70px'
            }, {
                label: _.i18n('run.prot'),
                name: 'refKitProt.code',
                classes: 'kitc-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refKitProt.code',
                searchoptions: {clearSearch: false},
                width: 40
            }, {
                label: _.i18n('assay.groupName'),
                name: 'refAssayGroup.code',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refAssayGroup.code',
                searchoptions: {clearSearch: false},
                width: 40
            }, {
                label: _.i18n('assay.assayConfiguration'),
                name: 'configurations',
                classes: 'assay-link',
                formatter: this.assayConfigurationFormatter,
                sortable: false,
                search: true,
                index: 'configurationCode',
                searchoptions: {clearSearch: false},
                width: 80
            }, {
                label: _.i18n('assay.assayConfiguration.cyclers'),
                name: 'configurationsCyclers',
                classes: 'assay-link',
                formatter: this.cyclerFormatter,
                sortable: false,
                search: true,
                index: 'cyclerCode',
                searchoptions: {clearSearch: false},
                width: 80
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {clearSearch: false}
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment',
                searchoptions: {clearSearch: false}
            });
            return columns;
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.customLeft = new AssayCustomJqGridView({assay: this});
            return config;
        },

        assayConfigurationFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            var codes = _.map(cellValue, function (configuration) {
                return configuration.code;
            });
            cellValue = codes.join(', ');

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        cyclerFormatter: function (cellValue, options, model) {
            var codes = _.map(model.configurations, function (configuration) {
                return _.map(configuration.cyclers, function (cycler) {
                    return cycler.code;
                }).join(', ');
            });
            cellValue = codes.join(', ');

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        getAutocompleteParamFromRun: function (pcrRun, config) {
            var url = pcrRun.getUrl() + pcrRun.get('secId') + '/assays';
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = url;
            return configuration;
        },
        getCollection: function (models) {
            return new app.ns.AssayCollection(models);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code']};
        },
        find: function (params) {
            var url = Settings.url(this.getUrl(), params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Object} param.service - The service object.
         */
        showDetails: function (param) {
            require(['adminAssayController'], _.bind(function (DynamicController) {
                param.controller = DynamicController;
                this._showDetails(param);
            }, this));
        },

        showEditAdd: function (model, callBack) {
            if (!model) {
                model = this.getModel({});
            }
            this.showDetails({model: model, callBackRefresh: callBack});
        },
        createRunFromResult: function (files, cyclerPublicSecId) {
            var defer = $.Deferred(),
                formdata = new FormData(),
                param = {
                    cyclerPublicSecId: cyclerPublicSecId
                };

            var url = Settings.url(this.getUrl() + 'createFromRunResult', param);
            _.each(files, function (file) {
                formdata.append('file', file);
            });

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function (resp) {
                    defer.resolve(this.getModel(resp));
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        }
    }, Dynamic);
});

