define('arrayBoolView',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template(''),

        attributes: function () {
            return {
                'data-field-name': 'value',
                style: 'width:100%;',
                type: 'checkbox',
                readOnly: this.options.readOnly,
                disabled: this.options.readOnly,
                checked: this.model.get('value') ? true : null
            };
        },
        events: {
            'change': 'onInputChange'
        },

        modelEvents: {
            'change:value': 'triggerChange'
        },

        className: function () {
            return 'js-info-input' + (this.options.readOnly ? 'disabled' : '');
        },
        tagName: 'input',

        serializeData: function () {
            return {};
        },
        triggerChange: function () {
            this.trigger('change');
        }
    });
});
