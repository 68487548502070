define('editSampleResultResultView',[
    'module',
    'template!editSampleResultResultView',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView'
], function (
    module,
    Tpl,
    _,
    $,
    AutocompleteView,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        regions: {
            mbAnaResRegion: '.js-mbAnaRes'
        },

        modelEvents: {
            'change:result': 'render'
        },

        serializeData: function () {
            var templateData = {
                target: this.model.get('sampleResultDefinition').get('target').get('code'),
                sampleResultDefinition: this.model.get('sampleResultDefinition').get('code'),
                resCode: this.model.get('result').get('code'),
                comment: this.model.get('comment')
            };
            templateData = _.extend(templateData, this.model.getResultIcon());

            templateData.canEdit = !this.model.get('exported');

            return templateData;
        },

        onRender: function () {
            if (!this.model.get('exported')) {
                this.getRegion('mbAnaResRegion').show(new AutocompleteView(
                    this._getAutocompleteOptions('result', 'code', 'code',
                        'rest/v2/mbanaresgrs/' + this.model.get('sampleResultDefinition').get('target').get('mbAnaResGr').secId + '/mbanares/?sord=asc&sidx=code',
                        'common.empty.placeholder', 'code', this.onChangeResult)
                ));
            }
        },

        onChangeResult: function (fieldName, model) {
            if (model) {
                var MbAnaResService = require('services/caccounts/mbanares');
                var errorParameter = MbAnaResService.getErrorParameter(model);
                model.codeErr = errorParameter.code;
                model.commentErrAuto = errorParameter.message;
                model.justificatory = errorParameter.message;
            }
            this.model.set(model, {silent: true});
            this.render();
        }
    });
});

