define('runRemoveAssayView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runRemoveAssayView',
    'commonWellSelectionView',
    'commonCancelActionView',
    'autocompleteView',
    'customBootboxMessage'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView,
    AutocompleteView,
    CustomBootboxMessage
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            assayRegion: '.js-assay-region',
            wellSelectionRegion: '.js-well-selection-region',
            cancelActionRegion: '.js-cancel-action-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'assay', type: 'required'},
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView}
            ];
        },

        modelEvents: {
            'change:assay': 'onValidateChange',
            'change:wellSelection': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('assay', null);
            this.model.set('wellSelection', null);
            if (!this.options.start) {
                this.options.start = 'A01';
            }
            if (!this.options.to) {
                this.options.to = this.options.model.get('plateSize');
            }
        },

        onRender: function () {
            this.wellSelectionView = new CommonWellSelectionView({
                direction: this.options.model.get('settings')['LAB-SETTINGS'] || 'C',
                start: this.options.start,
                to: this.options.to,
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);
            this.commonCancelActionView.enableButtons(false);

            var AssayService = require('services/caccounts/assay');
            this.getRegion('assayRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParamFromRun(this.options.model, {
                    modelProperty: 'assay',
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code'
                    }
                }))
            ));
        },

        onValidateChange: function () {
            this.commonCancelActionView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onActionClick: function () {
            var confirmParams = {
                message: _.i18n('plateLayout.clear.assay.confirm'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, _.bind(function (result) {
                if (result) {
                    var loading = $('.js-global-loader');
                    loading.show();
                    this.options.model.clearAssay(this.model)
                        .done(_.bind(function () {
                            this.hide();
                        }, this))
                        .always(_.bind(function () {
                            loading.hide();
                        }, this));
                } else {
                    this.hide();
                }
            }, this));
        }
    });
});
