/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicCreateEditPopup',[
    'module',
    'underscore',
    'formView'
], function (
    module,
    _,
    FormView
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template(''),
        events: {
            'click': 'onPopupClick'
        },
        tagName: 'span',
        className: function () {
            return 'mdi ' + (this.options.display ? this.options.display.mdi : '') + ' clickable ';
        },
        attributes: function () {
            var attr = {};
            return _.extend(attr, {
                'style': 'line-height: 25px; padding-left: 15px;'
            });
        },
        serializeData: function () {
            var templateData = {};
            templateData.field = this.options.field;
            templateData.mdi = this.options.display ? this.options.display.mdi : '';
            return templateData;
        },
        onPopupClick: function () {
            if (this.options.events) {
                if (this.options.events.click) {
                    this.options.events.click();
                }
            }
        }
    });
});
