define('editSampleResultView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!editSampleResultView',
    'savingBehavior',
    'underscore',
    'jquery',
    'app',
    'settings',
    'moment',
    'autocompleteView',
    'editSampleResultResultView',
    'sampleCreateEditHeaderView'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    App,
    Settings,
    moment,
    AutocompleteView,
    EditSampleResultResultView,
    SampleHeader
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,
        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        regions: {
            resultRegion: '.js-result-region',
            header: '.js-header-region'
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            var service = require('services/caccounts/pcrwellresults');
            this.setPermissions(service.canAddOrEdit());
            this.getRegion('resultRegion').show(new EditSampleResultResultView({
                model: this.model
            }));
            this.getRegion('header').show(new SampleHeader({
                model: this.model.get('sampleParent')
            }));
        },

        onConfirm: function () {
            $('.js-global-loader').show();
            this.model.save()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onSave: function () {
            this.model.trigger('change');
            this.box.modal('hide');
        },

        onCancel: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(_.bind(function () {
                this.box.modal('hide');
                $('.js-global-loader').hide();
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.triggerMethod('hide:label');
            }
        }
    });
});

