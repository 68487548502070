define('prepRunExtrKitsLotsView',[
    'module',
    'dialogFormView',
    'template!prepRunExtrKitsLotsView',
    'underscore',
    'dateUtils'
], function (
    module,
    DialogFormView,
    prepRunExtrKitsLotsTpl,
    _,
    dateConverter
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: prepRunExtrKitsLotsTpl,

        className: 'extractionMethodDetails',

        serializeData: function () {
            var templateData = {
                start: {code: null, date: null, user: null, comment: null},
                end: {code: null, date: null, user: null, comment: null}
            };
            if (this.model.get('extractionDetails').get('refExtractionMethod')) {
                templateData.start = {};
                templateData.start.code = this.model.get('extractionDetails').get('refExtractionMethod').get('code');
                templateData.start.date = dateConverter.toDateTimeFormatString(this.model.get('extractionDetails').get('startDate'));
                templateData.start.user = this.model.get('extractionDetails').get('startUserpid') ? this.model.get('extractionDetails').get('startUserpid').toString() : '';
                templateData.start.comment = this.model.get('extractionDetails').get('startComment');
            }
            if (this.model.get('extractionDetails').get('refLmbExtractor')) {
                templateData.end = {};
                templateData.end.code = this.model.get('extractionDetails').get('refLmbExtractor').get('code');
                templateData.end.date = dateConverter.toDateTimeFormatString(this.model.get('extractionDetails').get('endDate'));
                templateData.end.user = this.model.get('extractionDetails').get('endUserpid') ? this.model.get('extractionDetails').get('endUserpid').toString() : '';
                templateData.end.comment = this.model.get('extractionDetails').get('endComment');
            }

            var kitLots = this.options.kitLots ? this.options.kitLots : false;
            templateData.wellsNumber = this.model.get('wells').length;
            templateData.kitLots = kitLots;

            return templateData;
        }
    });
});

