define('commonSelect',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'commonSelectItemView',
    'commonSelectItemGroupView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    ItemView,
    ItemGroupView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: function (model) {
            return model.get('type') === 'group' ? ItemGroupView : ItemView;
        },
        tagName: 'select',
        triggers: {
            'change': 'select:change'
        },

        className: function () {
            return this.options.className || '';
        },
        initialize: function () {
            if (_.isArray(this.collection)) {
                this.collection = new Backbone.Collection(this.collection);
            }
            this.listenTo(this, 'change:select', _.bind(this.onChangeSelect, this));
        },
        attributes: function () {
            return this.options.attributes || {'style': 'flex-grow: 1;'};
        },
        childViewOptions: function (model) {
            var options = this.options.childViewOptions || {};
            options.model = model;
            if (model.get('type') === 'group') {
                options.collection = model.get('items');
            }
            return options;
        },
        onChangeSelect: function (value) {
            this.$el.val(value);
        },
        previous: function () {
            var index = this.$el.prop('selectedIndex');
            if (index > 0) {
                this.$el.prop('selectedIndex', index - 1);
                this.$el.trigger('change');
            }
        },
        next: function () {
            var index = this.$el.prop('selectedIndex');
            if (index < this.$el.prop('length') - 1) {
                this.$el.prop('selectedIndex', index + 1);
                this.$el.trigger('change');
            }
        }
    });

});
