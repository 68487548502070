/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemLabel',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<%= value %>'),
        tagName: 'span',
        serializeData: function () {
            var templateData = {};
            templateData.value = _.i18n(this.options.dynamicConfigurationField.service.getName() + '.' + this.model.get(this.options.field.get('field')) + '.name');
            return templateData;
        }
    });
});
