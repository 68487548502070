define('dynamicCreateEditLink',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        className: 'label clickable',
        tagName: 'span',
        events: {
            'click': 'onClick'
        },

        initialize: function () {
            if (!this.model) {
                this.model = new this.options.entity.relatedModel();
            }
        },

        serializeData: function () {
            if (this.model.isNew()) {
                return {value: '+'};
            }
            return {value: this.model.get('code')};
        },
        onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.options.service.showDetails({model: this.model, callBackRefresh: _.bind(this.render, this)});
        }
    });
});

