
define('template!kitLotsActionWellsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-pcr-wells">\n    <h1>Pcr Wells</h1>\n    <div class="js-pcr-wells-collection"></div>\n</div>\n<div class="js-prep-wells">\n    <h1>Prep Wells</h1>\n    <div class="js-prep-wells-collection"></div>\n</div>';

}
return __p
};});

