define('assayVersionLotCollection',[
    'module',
    'backbone.marionette',
    'template!assayVersionLotCollection',
    'assayVersionLotItem'
], function (
    module,
    Marionette,
    Tpl,
    View
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: View,
        childViewContainer: '.collection'
    });
});
