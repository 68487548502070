define('pcrWellResultDotView',[
    'module',
    'backbone.marionette',
    'pcrWellResultIconView'
], function (
    module,
    Marionette,
    PcrWellResultIconView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        attributes: {
            'style': 'display: flex;'
        },
        childView: PcrWellResultIconView,
        childViewOptions: function () {
            return {
                width: this.options.config.width ? this.options.config.width : '20px',
                config: this.options.config
            };
        }
    });
});
