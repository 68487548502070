
define('template!menuSubMenuView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a aria-controls="' +
((__t = ( id )) == null ? '' : __t) +
'" aria-expanded="true" data-parent="#sideBarMenu" data-toggle="collapse" for="' +
((__t = ( id )) == null ? '' : __t) +
'" href="#' +
((__t = ( id )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'\n    <span class="mdi mdi-chevron-right js-chevron"></span>\n</a>\n<div class="js-menu-region collapse" id="' +
((__t = ( id )) == null ? '' : __t) +
'"></div>';

}
return __p
};});

