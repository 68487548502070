define('kitLotsPcrController',[
    'module',
    'underscore',
    'backbone',
    'kitLotsPcrTargetView',
    'qcChartPopup',
    'tabs',
    'tabsCollectionPopup',
    'jquery',
    'qcListView'
], function (
    module,
    _,
    Backbone,
    View,
    QCChartPopup,
    Tabs,
    TabsCollectionPopup,
    $,
    QcListView
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Object} param.options - The options object.
         */
        editValues: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            if (!param.options) {
                param.options = {model: param.model};
            } else {
                param.options = _.defaults({model: param.model}, param.options);
            }
            var createEditView = new View(param.options);
            var KitLotPcrService = require('services/caccounts/kitlotpcr');
            createEditView.show({
                title: _.i18n(KitLotPcrService.getName() + '.new'),
                className: 'baseTableEditAddPopup kitLotPcrsEditAddPopup',
                service: KitLotPcrService
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        },
        showDetails: function (param) {
            require(['kitLotsController'], function (Controller) {
                Controller.showDetails(param);
            });
        },
        showQCFromWell: function (model) {
            $('.js-global-loader').show();
            var defers = [];

            var promiseQCGraph = $.Deferred();
            defers.push(promiseQCGraph);
            model.getControlKitLot()
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.refMbAnaRes && controlKit.assayReagentTargetPcrKitLot.refMbAnaRes.code !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection,
                        settings: model.get('settings')
                    });
                    promiseQCGraph.resolve(qcChartPopupLayoutView);
                }, this));
            var promiseQCList = $.Deferred();
            defers.push(promiseQCList);
            defers.push(model.fetch());
            var PcrWellService = require('services/caccounts/pcrwells');
            var qcListView = new QcListView({
                model: model,
                service: PcrWellService
            });
            promiseQCList.resolve(qcListView);

            $.when.apply($, defers).done(_.bind(function (qcChartPopupLayoutView, qcListView) {
                var collection = new Backbone.Collection();
                collection.push(new Backbone.Model({
                    content: qcChartPopupLayoutView,
                    name: _.i18n('qcChartPopup.title')
                }));
                collection.push(new Backbone.Model({
                    content: qcListView,
                    name: _.i18n('qcListChartPopup.title')
                }));
                var tabsCollection = new Tabs({collection: collection, className: 'qcChartPopup'});
                var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                tabsCollectionPopup.show({
                    title: _.i18n('qcChartPopup.title'),
                    className: 'baseTableEditAddPopup qcChartPopup'
                });
            }, this)).always(function () {
                $('.js-global-loader').hide();
            });
        },
        showQCFromLot: function (model) {
            $('.js-global-loader').show();
            var defers = [];

            var promiseQCGraph = $.Deferred();
            defers.push(promiseQCGraph);
            model.getControlKitLot()
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.refMbAnaRes && controlKit.assayReagentTargetPcrKitLot.refMbAnaRes.code !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection,
                        settings: model.get('settings')
                    });
                    promiseQCGraph.resolve(qcChartPopupLayoutView);
                }, this));
            var promiseQCList = $.Deferred();
            defers.push(promiseQCList);
            var PcrKitLotService = require('services/caccounts/kitlotpcr');
            var qcListView = new QcListView({
                model: model,
                service: PcrKitLotService
            });
            promiseQCList.resolve(qcListView);

            $.when.apply($, defers).done(_.bind(function (qcChartPopupLayoutView, qcListView) {
                var collection = new Backbone.Collection();
                collection.push(new Backbone.Model({
                    content: qcChartPopupLayoutView,
                    name: _.i18n('qcChartPopup.title')
                }));
                collection.push(new Backbone.Model({
                    content: qcListView,
                    name: _.i18n('qcListChartPopup.title')
                }));
                var tabsCollection = new Tabs({collection: collection, className: 'qcChartPopup'});
                var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                tabsCollectionPopup.show({
                    title: _.i18n('qcChartPopup.title'),
                    className: 'baseTableEditAddPopup qcChartPopup'
                });
            }, this)).always(function () {
                $('.js-global-loader').hide();
            });
        }
    };
});

