define('entities/ns',[
    'backbone',
    'settings',
    'underscore',
    'module',
    'backboneRelational'
], function (
    Backbone,
    Settings,
    _,
    module
) {
    'use strict';

    var app;
    if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
        app = {ns: Backbone.Relational.store._modelScopes[0]};
    } else {
        app = {ns: {}};
        Backbone.Relational.store.addModelScope(app.ns);
    }

    module.exports = app;
});
