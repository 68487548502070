
define('template!dynamicListToList', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-9 m-t-10">\n        <label class="control-label">' +
((__t = ( _.i18n('setting.oldparams') )) == null ? '' : __t) +
'</label>\n        <textarea\n                id="rawparams" style="resize: vertical;width:100%;"\n                data-field-name="params"\n                placeholder="' +
((__t = ( _.i18n('setting.params') )) == null ? '' : __t) +
'"\n        ' +
((__t = ( readOnly? 'readonly' : '' )) == null ? '' : __t) +
'>' +
((__t = ( rawParams )) == null ? '' : __t) +
'</textarea>\n    </div>\n    <div class="col-xs-3 m-t-10" style="margin-top:30px;">\n        <span class="mdi mdi-content-copy js-copy clickable" title="' +
((__t = ( _.i18n('copy') )) == null ? '' : __t) +
'"></span>\n        ';
 if(!readOnly) { ;
__p += '\n        ';
 if(accessToClipboard) { ;
__p += '\n        <span class="mdi mdi-content-paste js-paste clickable" title="' +
((__t = ( _.i18n('paste') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n        <span class="mdi mdi-delete js-delete clickable" title="' +
((__t = ( _.i18n('delete') )) == null ? '' : __t) +
'"></span>\n        ';
 } ;
__p += '\n    </div>\n</div>\n<div class="col-w-100 p-0 d-f"\n     style="display:flex; align-items: center; justify-content: center; flex-direction: row;">\n    <label class="col-w-40">' +
((__t = ( _.i18n('select.available') )) == null ? '' : __t) +
'</label>\n    <div class="col-w-20"></div>\n    <label class="col-w-40">' +
((__t = ( _.i18n('select.selected') )) == null ? '' : __t) +
'</label>\n</div>\n<div class="col-w-100 p-0 d-f"\n     style="display:flex; align-items: center; justify-content: center; flex-direction: row;">\n    <select class="col-w-40 js-available" multiple size="' +
((__t = ( maxSize )) == null ? '' : __t) +
'" style="background-color: #e6e6e6;">\n    </select>\n\n    <div class="col-w-20">\n        <div class="col-w-all p-0"\n             style="display:flex; align-items: center; justify-content: center; flex-direction: column;">\n            <span class="mdi mdi-transfer-right clickable" style="font-size: 80px; line-height: 80px;"></span>\n            <span class="mdi mdi-transfer-left clickable" style="font-size: 80px; line-height: 80px;"></span>\n        </div>\n    </div>\n\n    <select class="col-w-40 js-selected" multiple size="' +
((__t = ( maxSize )) == null ? '' : __t) +
'">\n    </select>\n</div>\n\n    <div class="cancelConfirmRow">\n        <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n        <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n    </div>';

}
return __p
};});

