define('entities/caccounts/routingaction',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/routingaction_routinggroup',
    'entities/caccounts/routingaction_state'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.RoutingAction = Backbone.RelationalModel.extend({
        service: 'services/caccounts/routingaction',
        fetch: function () {
            this.url = Settings.url('rest/v2/routingaction/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'type': null,
            'refreshAnalyzes': false,
            'plateSize': null,
            'sequence': null,
            'routingGroups': [],
            'states': [{
                'state': 'SAMPLECLASS_NOT_FOUND',
                'sequence': 0
            }, {
                'state': 'NO_DATASOURCE',
                'sequence': 1
            }, {
                'state': 'SAMPLE_NOT_FOUND',
                'sequence': 2
            }, {
                'state': 'SAMPLE_ALREADY_REGISTERED',
                'sequence': 3
            }, {
                'state': 'SAMPLE_NOT_REGISTERED',
                'sequence': 4
            }, {
                'state': 'ERROR',
                'sequence': 5
            }, {
                'state': 'SAMPLE_WITHOUT_LISANA',
                'sequence': 6
            }, {
                'state': 'MULITPLE_SAMPLES_SAME_ORDER',
                'sequence': 7
            }, {
                'state': 'NO_ROUTE',
                'sequence': 8
            }
            ]
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'routingGroups',
            relatedModel: 'RoutingActionRoutingGroup',
            collectionType: 'RoutingActionRoutingGroupCollection',
            reverseRelation: {
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'states',
            relatedModel: 'RoutingActionState',
            collectionType: 'RoutingActionStateCollection',
            reverseRelation: {
                includeInJSON: false
            }
        }],

        postUrl: function () {
            return Settings.url('rest/v2/routingaction/');
        },

        save: function () {
            this.url = this.postUrl();
            Backbone.Model.prototype.save.call(this).done(_.bind(function () {
                this.get('routingGroups').sort();
            }, this));
        },

        jsonObjectName: 'RoutingActionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'RoutingAction',

        importable: true
    });

    app.ns.RoutingAction.role = RolesMixin.ROUTINGACTION;

    app.ns.RoutingActionCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/routingaction/');
        },
        model: app.ns.RoutingAction,
        comparator: 'sequence'
    });
});

