
define('template!runPcrContentContaminationWellView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="watermark">\n    ';
 if(errorWarning && expertMode) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon"></span>\n    <span class="mdi mdi-school inWarning-expertIcon"></span>\n    ';
 } else if(expertMode) { ;
__p += '\n    <span class="mdi mdi-school expertIcon"></span>\n    ';
 } else if(errorWarning) { ;
__p += '\n    <span class="mdi mdi-alert warningErrorIcon"></span>\n    <!-- vvv START: move this selection to the target line bellow to show highlighted repeat icon if the status is exported  vvv -->\n    ';
 } else if (well.exported && well.repeatStatus) { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'" style="opacity: 0.2;"></span>\n    ';
 } else if (well.repeatStatus) { ;
__p += '\n    <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
'"></span>\n    ';
 } else if(well.val2 && well.smpType !== 'E') { ;
__p += '\n    <span class="mdi mdi-check-all"></span>\n    ';
 } else if(well.exported && well.smpType !== 'E') { ;
__p += '\n    <span class="mdi mdi-send"></span>\n    ';
 } ;
__p += '\n</div>\n<div class="wellResColorBar" style="' +
((__t = ( (wellResColor? ('background-color:'+ wellResColor + ';'):'') )) == null ? '' : __t) +
';"></div>\n<div style="width: 100%;">\n    <div class="btn-group-flat absolute">\n        <div>\n            <button class="wellButton js-btn-preprun-kitLot">\n                <span class="mdi mdi-flask-outline clickable"></span>\n            </button>\n        </div>\n        ';
 if (well.smpType === 'NC' || well.smpType === 'PC' || well.smpType === 'RC' ||
        well.smpType === 'C' || well.smpType === 'OC' )
        { ;
__p += '\n        ';
 if (well.showQC){ ;
__p += '\n        <div>\n            <button class="wellButton smp-id-link mdi-qc">\n                <span class="mdi mdi-alpha-q"></span>\n                <span class="mdi mdi-alpha-c"></span>\n            </button>\n        </div>\n        ';
 } ;
__p += '\n        ';
 } else { ;
__p += '\n        ';
 if(well.smpType === 'U') { ;
__p += '\n        <div>\n            <button class="wellButton smp-id-link">\n                <span class="mdi mdi-alpha-s clickable"></span>\n            </button>\n        </div>\n        <div>\n            <button class="wellButton js-btn-comment ' +
((__t = ( hasComment ? 'visible': '' )) == null ? '' : __t) +
'">\n                <span class="mdi mdi-alpha-c clickable"></span>\n            </button>\n        </div>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        ';
 if(error) { ;
__p += '\n        <div>\n            <button class="wellButton">\n                <span class="mdi mdi-alert-octagram clickable" title="' +
((__t = ( _.i18n('common.showErrors') )) == null ? '' : __t) +
'"></span>\n            </button>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="content-cell">\n        <div class="arrow">\n            ';
 if(topLeftCT && topLeftCT === 'outgoing') { ;
__p += '\n            ';
 } else if(topLeftCT && topLeftCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-bottom-right-thick" style="color: ' +
((__t = ( topLeftCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(topCenterCT && topCenterCT === 'outgoing') { ;
__p += '\n            ';
 } else if(topCenterCT && topCenterCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-down-bold"\n                  style="color: ' +
((__t = ( topCenterCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(topRightCT && topRightCT === 'outgoing') { ;
__p += '\n            ';
 } else if(topRightCT && topRightCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-bottom-left-thick"\n                  style="color: ' +
((__t = ( topRightCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(middleLefCT && middleLefCT === 'outgoing') { ;
__p += '\n            ';
 } else if(middleLefCT && middleLefCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-right-bold"\n                  style="color: ' +
((__t = ( middleLefCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div style="display: flex; flex-direction: column; justify-content: flex-end;">\n            ';
 if(wellResNum) { ;
__p += '\n            ';
 if(wellResNum && well.existOtherWellOtherAssay) { ;
__p += '\n            <span class="mdi mdi-triangle mdi-flip-v"\n                  style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; position: absolute; left: 17px; top: 30px; line-height: 15px; font-size: 15px;"></span>\n            ';
 } ;
__p += '\n            ';
 if(wellResNum && well.existOtherWellSameAssay) { ;
__p += '\n            <span class="mdi mdi-triangle"\n                  style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; position: absolute; left: 8px; top: 30px; line-height: 15px; font-size: 15px;"></span>\n            ';
 } ;
__p += '\n            <div class="ct" style="font-size: ' +
((__t = ( fontSize )) == null ? '' : __t) +
'; top: ' +
((__t = ( ctTop )) == null ? '' : __t) +
';">\n                ' +
((__t = ( wellResNum )) == null ? '' : __t) +
'\n            </div>\n            <div class="divMeter">\n                <div class="divMeterContainer">\n                    <div class="divMeterBar">\n                    </div>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(middleRightCT && middleRightCT === 'outgoing') { ;
__p += '\n            ';
 } else if(middleRightCT && middleRightCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-left-bold"\n                  style="color: ' +
((__t = ( middleRightCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(bottomLeftCT && bottomLeftCT === 'outgoing') { ;
__p += '\n            ';
 } else if(bottomLeftCT && bottomLeftCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-top-right-thick"\n                  style="color: ' +
((__t = ( bottomLeftCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(bottomCenterCT && bottomCenterCT === 'outgoing') { ;
__p += '\n            ';
 } else if(bottomCenterCT && bottomCenterCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-up-bold"\n                  style="color: ' +
((__t = ( bottomCenterCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n        <div class="arrow">\n            ';
 if(bottomRightCT && bottomRightCT === 'outgoing') { ;
__p += '\n            ';
 } else if(bottomRightCT && bottomRightCT === 'incoming') { ;
__p += '\n            <span class="mdi mdi-arrow-top-left-thick"\n                  style="color: ' +
((__t = ( bottomRightCtColor )) == null ? '' : __t) +
';"></span>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>';

}
return __p
};});

