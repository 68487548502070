define('adminTrashController',[
    'module',
    'underscore',
    'app',
    'adminTrashView'

], function (
    module,
    _,
    App,
    View
) {
    'use strict';

    module.exports = {
        showTrashList: function (data) {
            this.view = new View({data: data});
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            App.getView().getRegion('content').show(this.view);
        },

        onGridLoaded: function () {
            var TrashService = require('services/trash');
            var data = {
                url: TrashService.getTrashCollectionUrl(),
                rowCountUrl: TrashService.getTrashCountUrl()
            };
            this.view.displayGrid(data);
        }
    };
});
