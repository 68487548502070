
define('template!runHeaderMiddleView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<button class="next rounded disabled" type="button" disabled="disabled">\n    <span class="mdi mdi-chevron-left"> </span>\n</button>\n<div class="js-middle-region middle rounded-5"></div>\n<button class="previous rounded disabled" type="button" disabled="disabled">\n    <span class="mdi mdi-chevron-right"> </span>\n</button>\n';

}
return __p
};});

