
define('template!dynamicCreateEditComponent', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class=" col-xs-4 text-right control-label" data-field-name="' +
((__t = ( dataFieldName )) == null ? '' : __t) +
'">' +
((__t = ( _.i18n(label) )) == null ? '' : __t) +
'</label>\n\n<div class="' +
((__t = ( displayClass )) == null ? '' : __t) +
' js-region-component" data-field-name="' +
((__t = ( dataFieldName )) == null ? '' : __t) +
'">\n</div>\n';
 for(var i = 0; i < extraCount; i++) { ;
__p += '\n<div class="js-region-component-' +
((__t = ( i )) == null ? '' : __t) +
'"></div>\n';
 } ;


}
return __p
};});

