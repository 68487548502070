/**
 * Created by OBL on 14/01/2016.
 */
define('fileExplorerController',[
	'module',
	'underscore',
	'jquery',
	'fileExplorer',
	'backbone'
], function (
	module,
	_,
	$,
	View,
	Backbone
) {
	'use strict';

	module.exports = {
		show: function (settingSecId, selectable, callBackConfirm) {
			var view = new View({
				settingSecId: settingSecId,
				selectable: selectable,
				callBackConfirm: callBackConfirm,
				model: new Backbone.Model()
			});
			view.show({
				title: _.i18n('fileExplorer.title'),
				className: 'baseTableEditAddPopup fileExplorer'
			});
		}
	};
});
