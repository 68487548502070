
define('template!dynamicCreateEditCustomMbAnaClassifiedValue', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label>' +
((__t = ( _.i18n('target.result') )) == null ? '' : __t) +
'</label>\n\n<div class="num">\n    <label>' +
((__t = ( _.i18n('target.result.limit') )) == null ? '' : __t) +
'</label>\n    <div class="target-result-num">\n        <div><input type="number" class="form-control inputBackground num1" value="' +
((__t = ( num1 )) == null ? '' : __t) +
'"/></div>\n        <div><input type="number" class="form-control inputBackground num2" value="' +
((__t = ( num2 )) == null ? '' : __t) +
'"/></div>\n        <div><input type="number" class="form-control inputBackground num3" value="' +
((__t = ( num3 )) == null ? '' : __t) +
'"/></div>\n        <div><input type="number" class="form-control inputBackground num4" value="' +
((__t = ( num4 )) == null ? '' : __t) +
'"/></div>\n        <div><input type="number" class="form-control inputBackground num5" value="' +
((__t = ( num5 )) == null ? '' : __t) +
'"/></div>\n    </div>\n</div>\n<div class="result">\n    <label>' +
((__t = ( _.i18n('target.result.res') )) == null ? '' : __t) +
'</label>\n    <div class="target-result-mbAnaRes">\n        <div class="js-mbAnaRes-region-1"></div>\n        <div class="js-mbAnaRes-region-2"></div>\n        <div class="js-mbAnaRes-region-3"></div>\n        <div class="js-mbAnaRes-region-4"></div>\n        <div class="js-mbAnaRes-region-5"></div>\n        <div class="js-mbAnaRes-region-6"></div>\n    </div>\n</div>';

}
return __p
};});

