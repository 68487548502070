/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemPopup',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        events: {
            'click': 'onPopupClick'
        },
        tagName: 'span',
        className: function () {
            return 'mdi ' + (this.options.field && this.options.field.get('param') && this.options.field.get('param').display ? this.options.field.get('param').display.mdi : '') + ' clickable ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            return _.extend(attr, {
                'style': 'line-height: 25px;'
            });
        },
        serializeData: function () {
            var templateData = {};
            templateData.field = this.options.field;
            templateData.mdi = this.options.field && this.options.field.get('param') && this.options.field.get('param').display ? this.options.field.get('param').display.mdi : '';
            return templateData;
        },
        onPopupClick: function () {
            var config = _.findWhere(this.options.dynamicConfigurationField.service.getCreateEditLine(this.model), {field: this.options.field.get('field')});
            config.param.config({model: this.model});
        }
    });
});
