/**
 * Created by Matteo on 19/07/2015.
 */
define('unauthorizedShowView',[
    'module',
    'backbone.marionette',
    'app',
    'template!unauthorizedViewTpl'
], function (
    module,
    Marionette,
    App,
    viewTpl
) {
    'use strict';

    var AnauthorizedView = Marionette.View.extend({
        template: viewTpl,
        initialize: function (options) {
            this.message = options.message;
        },
        serializeData: function () {
            return {message: this.message};
        }
    });

    module.exports = AnauthorizedView;
});
