/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemText',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var config = ItemView.prototype.attributes.apply(this, arguments);
            config = _.extend(config, {
                'autocomplete': 'off',
                'placeholder': '',
                'type': 'text',
                length: this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.maxLength ? this.options.field.get('param').display.maxLength : '',
                'value': this.model.get(this.options.field.get('field'))
            });
            if (this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.minLength) {
                config.minLength = this.options.field.get('param').display.minLength;
            }
            if (this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.maxLength) {
                config.maxLength = this.options.field.get('param').display.maxLength;
            }
            return config;
        }
    });
});
