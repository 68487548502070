define('qcChartView',[
	'module',
	'underscore',
	'chartView'
], function (
	module,
	_,
	ChartView
) {
	'use strict';

	module.exports = ChartView.extend({

		serializeData: function () {
			var controls = this.options.controls;
			var averageValue = controls && controls.val ? controls.val : null;
			var upperLimitValue = controls && controls.deltaError ? averageValue + controls.deltaError : null;
			var upperWarningValue = controls && controls.deltaWarning ? averageValue + controls.deltaWarning : null;
			var lowerWarningValue = controls && controls.deltaWarning ? averageValue - controls.deltaWarning : null;
			var lowerLimitValue = controls && controls.deltaError ? averageValue - controls.deltaError : null;
			var minimumValue = Math.min(upperLimitValue, upperWarningValue, averageValue, lowerWarningValue, lowerLimitValue) || null;
			var maximumValue = Math.max(upperLimitValue, upperWarningValue, averageValue, lowerWarningValue, lowerLimitValue) || null;

			this.data = this.getBaseData();
			// it will be: this.data.datasets[0] or this.data.datasets[this.data.datasets.length - 1] with well target values
			_.each(this.options.pcrKitLotValueRuns, _.bind(function (v) {
				this.data.labels.push(v.pcrWell.pcrRun.name);

				_.last(this.data.datasets).data.push(v.value);

				if (minimumValue === null || v.value < minimumValue) {
					minimumValue = v.value;
				}
				if (maximumValue === null || v.value > maximumValue) {
					maximumValue = v.value;
				}
			}, this));
			_.last(this.data.datasets).label = this.options.title;
			minimumValue = minimumValue - 1;
			maximumValue = maximumValue + 1;

			this.addChartControls(this.data.datasets[0].data.length, maximumValue, upperLimitValue, upperWarningValue, averageValue, lowerWarningValue, lowerLimitValue, minimumValue);

			this.generateLegend(false);

			this.generateTitle(false, this.options.title, 20, 10, 'left'); // target title
			this.setScales(true, false, true, false, 10, 'Verdana', maximumValue, minimumValue, 1,
				90, _.i18n('y.axes.well.qc.target.label'), _.i18n('x.axes.well.qc.target.label'), 15);

			return this.data;
		}
	});
});
