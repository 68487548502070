// Kick off the application.
require([
    'keycloak',
    'underscore',
    'app'
], function (
    Keycloak,
    _,
    App
) {
    'use strict';

    var env = document.env || {};
    var keycloak = new Keycloak['default']({
        url: env.keycloakUrl || 'https://sso.mobiolink.com',
        realm: env.keycloakRealm || 'Mobiolink',
        clientId: env.keycloakClientId || 'mobiolink-opendid-client-app-dev'
    });

    keycloak.init({
        onLoad: 'login-required'
    }).then(function (authenticated) {
        if (authenticated) {
            App.keycloak = keycloak;
            App.start();
        } else {
            alert('User is not authenticated');
        }
    });

    setInterval(_.bind(function () {
        keycloak.updateToken(30).then(function () {
        }, _.bind(function () {
            keycloak.login();
        }, this));
    }, this), 10000);

    keycloak.onTokenExpired = _.bind(function () {
        keycloak.login();
    }, this);

    keycloak.onAuthLogout = _.bind(function () {
        keycloak.login();
    }, this);
});

define("main", function(){});

