
define('template!standardCurveAssayConfigurationResultView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="h-100" style="display: flex;">\n    <div class="col-w-15 p-r-10 p-l-0 scrollable js-pcrRuns"></div>\n    <div class="col-w-85 p-l-5 p-r-0 js-content" style="border-left: black solid 1px;"></div>\n</div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

