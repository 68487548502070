define('imageUtils',[
	'module',
	'jquery'
], function (
	module,
	$
) {
	'use strict';

	var ImageUtils = {
		getLogo: function (logoUid, name, params, shape) {
			var fontSize = 128,
				colours, logo, nameSplit, initials,
				charIndex, colourIndex;

			if ((logoUid === null || logoUid === undefined) && name) {
				colours = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
					'#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
					'#f1c40f',
					'#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400',
					'#c0392b',
					'#bdc3c7', '#7f8c8d'];
				nameSplit = name.split(' ');
				if (nameSplit.length > 1) {
					initials = nameSplit[0].charAt(0).toUpperCase() +
						nameSplit[1].charAt(0).toUpperCase();
				} else {
					initials = nameSplit[0].charAt(0).toUpperCase() +
						nameSplit[0].charAt(1).toUpperCase();
				}

				charIndex = initials.charCodeAt(0) - 65;
				colourIndex = charIndex % 19;

				logo = this.drawToCanvasAndRead(initials, colours[colourIndex], fontSize, shape);
				return logo;
			}

			return 'data:image;base64,' + logoUid;
		},

		getPreview: function (ext) {
			var backgroundColor = '#7f8c8d',
				fontSize = 96;
			return this.drawToCanvasAndRead(ext, backgroundColor, fontSize);
		},

		drawToCanvasAndRead: function (initials, color, fontSize, shape) {
			var logo, canvas, context,
				canvasWidth, canvasHeight, canvasCssWidth, canvasCssHeight;
			canvas = document.createElement('canvas');
			context = canvas.getContext('2d');

			canvasWidth = 256;
			canvasHeight = 256;
			canvasCssWidth = canvasWidth;
			canvasCssHeight = canvasHeight;

			if (window.devicePixelRatio) {
				$(canvas).attr('width', canvasWidth * window.devicePixelRatio);
				$(canvas).attr('height', canvasHeight * window.devicePixelRatio);
				$(canvas).css('width', canvasCssWidth);
				$(canvas).css('height', canvasCssHeight);
				context.scale(window.devicePixelRatio, window.devicePixelRatio);
			}

			context.fillStyle = color;
			switch (shape) {
				case 'rect':
					context.fillRect(0, 0, canvas.width, canvas.height);
					break;
				case 'circle':
					this.drawCircle(context, canvas.width / 2, canvas.height / 2, canvas.width / 2);
					break;
				default:
					context.fillRect(0, 0, canvas.width, canvas.height);
			}
			context.font = fontSize + 'px Arial';
			context.textAlign = 'center';
			context.fillStyle = '#FFF';
			context.textBaseline = 'middle';
			context.fillText(initials, canvasCssWidth / 2, canvasCssHeight / 2);

			logo = canvas.toDataURL('image/png', 1);

			return logo;
		},

		drawCircle: function (context, centerX, centerY, radius) {
			context.beginPath();
			context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
			context.fill();
			context.closePath();
		},

		encodeInputFileToBase64: function (inputFile, index, callback) {
			var file, reader;

			file = ImageUtils.getFile(inputFile, index);

			if (file) {
				reader = new FileReader();

				reader.onload = function () {
					callback(btoa(ImageUtils.ab2str(reader.result)));
				};
				reader.onerror = function () {
					console.log('Error ..');
				};
				reader.readAsArrayBuffer(file);
			} else {
				callback(null);
			}
		},

		encodeFileToBase64: function (file, callback) {
			var reader;

			if (file) {
				reader = new FileReader();

				reader.onloadend = function (event) {
					callback(event.target.result);
				};
				reader.onerror = function () {
					console.log('Error ..');
				};
				reader.readAsDataURL(file);
			} else {
				callback(null);
			}
		},

		ab2str: function (buf) {
			var str = '',
				ab = new Uint8Array(buf),
				abLen = ab.length,
				CHUNK_SIZE = Math.pow(2, 16),
				offset, len, subab;

			for (offset = 0; offset < abLen; offset += CHUNK_SIZE) {
				len = Math.min(CHUNK_SIZE, abLen - offset);
				subab = ab.subarray(offset, offset + len);
				str += String.fromCharCode.apply(null, subab);
			}
			return str;
		},

		//The result can be set in the "src" attribute of an image
		loadImageByteArray: function (inputFile, index, callback) {
			var file, reader;

			reader = new FileReader();
			file = ImageUtils.getFile(inputFile, index);

			if (file) {
				reader.onload = function (event) {
					callback(event.target.result);
				};

				reader.onerror = function () {
					console.log('Error ..');
				};

				reader.readAsDataURL(file);
			} else {
				callback(null);
			}
		},

		getFileInfo: function (inputFile, index) {
			var fileInfo, file, formdata;

			file = ImageUtils.getFile(inputFile, index);
			fileInfo = null;
			if (file) {
				formdata = new FormData();
				formdata.append('file', file);
				formdata.append('filename', file.name);
				fileInfo = {
					fileType: file.type,
					fileExt: file.name.substring(file.name.lastIndexOf('.')),
					fileName: file.name,
					fileLastModified: file.lastModified,
					formData: formdata,
					base64Value: ''
				};
			}
			return fileInfo;
		},

		getFile: function (inputFile, index) {
			if (typeof window.FileReader !== 'function' &&
				typeof window.FileReader !== 'object') {
				console.log('The file API isn\'t supported on this browser yet.');
				return null;
			}
			if (!inputFile) {
				console.log('couldn\'t find the fileinput element.');
				return null;
			} else if (!inputFile.files) {
				console.log('This browser doesn\'t seem to support the `files`' +
					'property of file inputs.');
				return null;
			} else if (inputFile.files.length === 0) {
				console.log('Please select a file before clicking "Load"');
				return null;
			} else {
				if (index < inputFile.files.length) {
					return inputFile.files[index];
				} else {
					return null;
				}
			}
		},

		convertImgToBase64: function (url, callback, outputFormat) {
			var img = new Image();
			img.crossOrigin = 'Anonymous';
			img.onload = function () {
				var canvas = document.createElement('CANVAS');
				var ctx = canvas.getContext('2d');
				canvas.height = this.height;
				canvas.width = this.width;
				ctx.drawImage(this, 0, 0);
				var dataURL = canvas.toDataURL(outputFormat || 'image/png');
				callback(dataURL);
				canvas = null;
			};
			img.src = url;
		}
	};

	module.exports = ImageUtils;
});

