define('prepWellPoolItem',[
    'module',
    'backbone.marionette',
    'template!prepWellPoolItem'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'd-f baseline bottom-border-cell-light',
        serializeData: function () {
            var templateData = {};
            templateData.pos = this.model.get('pos');
            templateData.runName = this.model.get('run').get('code');
            templateData.smpId = {code: this.model.get('smpId').get('code')};
            return templateData;
        }
    });
});
