/**
 * Created by GSP on 16/07/2015.
 */
define('services/system',[
    'jquery',
    'settings',
    'module',
    'backbone',
    'underscore'
], function (
    $,
    Settings,
    module,
    Backbone,
    _
) {
    'use strict';

    module.exports = {
        getInformation: function () {
            var defer = $.Deferred(),
                url;
            url = Settings.url('rest/global/system/version');

            $.ajax({
                type: 'GET',
                dataType: 'text',
                url: url,
                success: function (response) {
                    defer.resolve(JSON.parse(response));
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        clearCache: function () {
            var defer = $.Deferred();
            var url = Settings.url('rest/global/system/clear/cache');

            $.ajax({
                type: 'GET',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        findModules: function () {
            var defer = $.Deferred();
            var url = Settings.url('rest/global/system/modules');

            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    var modules = new Backbone.Collection();
                    modules.comparator = 'module';
                    _.each(response, function (module) {
                        var roles = new Backbone.Collection();
                        roles.comparator = 'code';
                        _.each(module.roles, function (role) {
                            roles.add({
                                code: role
                            });
                        });
                        modules.add({module: module.module, roles: roles});
                    });
                    defer.resolve(modules);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    };
});

