define('dynamicListItemColor',[
    'module',
    'dynamicListItemPropertyView',
    'underscore'
], function (
    module,
    ItemView,
    _
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<div class="js-colorPicker input-group colorpicker-component <%= readOnly %>"><span class="input-group-addon" style="border: 1px solid #80808066; padding: 5px 5px 5px 5px;"><i></i></span>' +
            '<input type="text" value="<%= value %>" class="form-control js-info-input-color"/></div>'),

        ui: {
            colorPicker: '.js-colorPicker'
        },

        events: {
            'change .js-info-input-color': 'onInputChange'
        },

        modelEvents: {
            'change': 'test'
        },

        test: function () {
            this.render();
        },

        initialize: function () {
            ItemView.prototype.initialize.apply(this, arguments);
            if (this.model.get(this.options.field.get('field')) && this.model.get(this.options.field.get('field')).length === 7) {
                this.model.set(this.options.field.get('field'), this.model.get(this.options.field.get('field')) + 'ff');
            }
        },

        serializeData: function () {
            return {
                value: this.model.get(this.options.field.get('field')),
                readOnly: this.options.readOnly ? 'disabled' : 'clickable'
            };
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            if (this.model.get(this.options.field.get('field'))) {
                var data = {};
                data[this.options.field.get('field')] = this.model.get(this.options.field.get('field')).toLowerCase();
                this.model.set(data, {silent: true});
            }
            this.ui.colorPicker.colorpicker({
                color: this.model.get(this.options.field.get('field')),
                format: 'hex'
            });
        },
        onInputChange: function (e) {
            var fieldName = this.options.field.get('field');
            if (this.model.get(fieldName) !== e.currentTarget.value) {
                this.onChange(fieldName, e.currentTarget.value);
            }
        }
    });
});
