define('services/caccounts/assayResultVersion',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayResultVersion'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.AssayResultVersion,
                collection: app.ns.AssayResultVersionCollection
            };
        },
        getName: function () {
            return 'assayresultversion';
        },
        getDynamicJson: function () {
            return 'AssayResultVersionJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        }
    }, Dynamic);
});

