define('runPcrValidationTargetsResultCollection',[
    'module',
    'backbone.marionette',
    'underscore',
    'runPcrValidationTargetsResultItem',
    'colorUtils'
], function (
    module,
    Marionette,
    _,
    ItemView,
    ColorUtils
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: _.template('<div class="info"><span class="mdi mdi-flask-outline clickable"></span><div class="title"><%= code %></div><div class="version">(<%= version %>)</div></div>'),
        childView: ItemView,
        className: 'assay',
        childViewOptions: function (model) {
            return {
                model: model,
                validationModel: this.options.validationModel
            };
        },
        serializeData: function () {
            return {
                code: this.model.get('assayVersion').get('code'),
                version: this.model.get('assayVersion').get('secId').split('_')[0]
            };
        },
        attributes: function () {
            var indexOf = 1;
            var found = false;
            this.options.validationModel.get('resultsByAssay').forEach(_.bind(function (resultByAssay) {
                if (!found) {
                    indexOf++;
                }
                if (resultByAssay.get('assayVersion') === this.model.get('assayVersion')) {
                    found = true;
                }
            }, this));

            var index = indexOf < this.options.assays.length ? indexOf : this.options.assays.length % indexOf;
            return {
                style: 'background-color: ' + ColorUtils.defineAlpha(this.options.assays[index], this.options.assays[0]) + ';'
            };
        },
        events: {
            'click .mdi-flask-outline': 'onKitClick'
        },
        onKitClick: function () {
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                this.options.validationModel.get('wellSelected').first().getPcrKitLots().done(_.bind(function (response) {
                    AssayVersionController.showDetails({
                        model: this.model.get('assayVersion'), options: {
                            lots: response,
                            cyclerPublicSecId: this.options.validationModel.get('run').get('refCyclerPublic').secId
                        }
                    });
                }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }, this));
        }
    });
});

