
define('template!dynamicCreateEditCustomSampleListToolTip', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div style="z-index: 9000;">\n    csv_s_n_x_x_x_x_...<br/>\n    or<br/>\n    txt_s_n_x_x_x_x_...<br/><br/>\n    s = char separtor (C = \',\', SC = \';\', T = tab))<br/>\n    n = number of header lines<br/>\n    x = represent one column of file csv or txt<br/>\n    <hr>\n    xls_n_x_x_x_x_...<br/>\n    or<br/>\n    xlsx_n_x_x_x_x_...<br/>\n    n = number of header lines<br/>\n    x = represent one column of file<br/>\n    <hr>\n    Possible value for \'x\' :\n    <ul>\n        <li>E = empty</li>\n        <li>STYPE = Sample type</li>\n        <li>ASS = Assay (Prep = list of assays, Pcr = assay)</li>\n        <li>SID = Sample</li>\n        <li>ORDERID = Order id from sample (only for well typed "U", others will empty)</li>\n    </ul>\n</div>';

}
return __p
};});

