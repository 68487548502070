
define('template!prepWellEmptyItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="t-a-l">' +
((__t = ( title )) == null ? '' : __t) +
'</label>\n<div>' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

