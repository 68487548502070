define('translation',[
    'module',
    'jquery',
    'underscore',
    'bootbox',
    'settings',
    'polyglotInstance'
], function (module,
             $,
             _,
             bootbox,
             Settings,
             Polyglot
) {
    'use strict';


    /**
     * Create the global object with pair/key (localized/text key) eg: fr: {common.save: save}
     * @param translationObj
     * @param tObject
     */
    function setTranslation(translationObj, tObject) {
        var code, patt;

        if (tObject && tObject.code && tObject.text) {
            patt = Settings.get('application') + '.';
            code = tObject.code.replace(patt, '');

            _.each(tObject.text.localized, function (item, key) {
                if (!translationObj[key]) {
                    translationObj[key] = {};
                }
                translationObj[key][code] = tObject.text.localized[key];
            });

            //if the localized is not available for a text we add the localized with the english translation
            if (!tObject.text.localized[Settings.get('lang')]) {
                if (!translationObj[Settings.get('lang')]) {
                    translationObj[Settings.get('lang')] = {};
                }
                translationObj[Settings.get('lang')][code] = tObject.text.localized.en;
            }
        }
    }

    module.exports = {
        isLoaded: false,
        fetchTranslations: function () {
            Polyglot.prefix = Settings.get('application');
            var translationObj = {};
            return $.ajax({
                async: false,
                url: Settings.url('rest/public/texts/app/' +
                    Settings.get('application'))
            }).success(_.bind(function (data) {
                _.each(data, function (item) {
                    setTranslation(translationObj, item);
                });
                Polyglot.extend(translationObj);

                bootbox.addLocale(Settings.get('lang'), {
                    OK: _.i18n('common.ok'),
                    CANCEL: _.i18n('common.cancel'),
                    CONFIRM: _.i18n('common.confirm')
                });
                bootbox.setLocale(Settings.get('lang'));
            }, this));
        }
    };
});

