define('imageUploadBehavior',[
    'module',
    'backbone.marionette',
    'imageUtils',
    'imageSelectionPopupView',
    'app',
    'underscore'
], function (
    module,
    Marionette,
    imageUtils,
    ImageSelectionPopupView,
    App,
    _
) {
    'use strict';

    module.exports = Marionette.Behavior.extend({
        ui: {
            changeLogo: '.js-change-logo'
        },

        events: {
            'click .js-logo-holder': '_onClickLogoHolder',
            'change @ui.changeLogo': '_onChangeLogo'
        },

        triggers: {
            cancel: 'image:upload:cancel'
        },

        fileExt: 'png',

        _onClickLogoHolder: function () {
            this.ui.changeLogo.trigger('click');
        },

        _onChangeLogo: function (e) {
            this.triggerMethod('alert');
            var fileInfo = e.target.files[0];

            this.listenTo(this.view.model, 'request', this._showUploadLabel);

            if (fileInfo) {
                this.view.model.updateLogo(fileInfo).done(_.bind(function () {
                    this.view.triggerMethod('hide:label');
                }, this));
            }
        },

        _showUploadLabel: function () {
            this.view.triggerMethod('uploading');
        }
    });
});
