define('kitLotActionPrepWellCollectionView',[
    'module',
    'backbone.marionette',
    'kitLotActionPrepWellItemView',
    'template!kitLotActionPrepWellCollectionView'
], function (
    module,
    Marionette,
    ItemView,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: ItemView,
        childViewContainer: 'tbody',
        tagName: 'table',
        className: 'table table-striped table-hover'

    });
});
