define('arrayTextView',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<%= value %>'),
        attributes: function () {
            return {
                'data-field-name': 'value',
                style: 'width:100%;',
                readOnly: this.options.readOnly,
                disabled: this.options.readOnly,
                rows: 1
            };
        },

        events: {
            'change': 'onInputChange'
        },

        modelEvents: {
            'change:value': 'triggerChange'
        },

        className: function () {
            return 'js-info-input ' + (this.options.readOnly ? 'disabled' : '');
        },
        tagName: 'textarea',

        serializeData: function () {
            return {value: this.model.get('value')};
        },
        triggerChange: function () {
            this.trigger('change');
        }
    });
});
