define('translationsFilterLanguageListView',[
    'module',
    'backbone.marionette',
    'template!translationsFilterLanguageListTpl',
    'jquery'
], function (
    module,
    Marionette,
    filterLanguageTpl,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: filterLanguageTpl,

        ui: {
            languageInput: 'input',
            selectAllCheckbox: 'input[data-abbreviation=all]'
        },

        events: {
            'click .js-language': 'onItemClick',
            'change @ui.languageInput': 'onLanguageChanged'
        },

        attributes: {
            style: 'display: flex;align-items: flex-start;margin: 5px;flex-direction: column;'
        },
        collectionEvents: {
            change: 'render'
        },
        serializeData: function () {
            var templateData = {
                allChecked: this._isAllChecked()
            };
            templateData.items = this.collection.toJSON();
            return templateData;
        },

        onLanguageChanged: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $target = $(e.currentTarget);
            if ($target.data('abbreviation') === 'all') {
                this._toggleAll($target.is(':checked'));
                return;
            }
            this.collection.get($target.data('abbreviation')).set({checked: $target.is(':checked')}, {silent: true});
        },

        _toggleAll: function (isChecked) {
            this.ui.languageInput.prop('checked', isChecked);
            this.collection.each(function (model) {
                model.set('checked', isChecked, {silent: true});
            });
        },

        _isAllChecked: function () {
            return this.collection.filter(function (language) {
                return !language.get('checked');
            }).length === 0;
        },

        onItemClick: function (e) {
            //disable bootstrap navbar default behavior
            e.stopPropagation();
        }
    });
});

