
define('template!runCopyFromRunView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group" data-field-name="assay">\n    <label class="col-xs-4" data-field-name="assay">' +
((__t = ( fromRunLabel )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-8 js-select-run-region"></div>\n</div>\n<div class="form-group js-well-selection-region"></div>\n<div class="form-group">\n    <label class="col-xs-4" for="copySampleTypeCheckbox">' +
((__t = (
        _.i18n('platelayout.copy.sample.type')
        )) == null ? '' : __t) +
'</label>\n    <input checked class="col-xs-1 controlSidCheckBox js-info-input"\n           data-field-name="copySampleType"\n           id="copySampleTypeCheckbox"\n           style="transform: scale(2);" type="checkbox"/>\n</div>\n<div class="form-group">\n    <label class="col-xs-4" for="copySampleIdsCheckbox">' +
((__t = (
        _.i18n('platelayout.copy.sample.ids')
        )) == null ? '' : __t) +
'</label>\n    <input checked class="col-xs-1 controlSidCheckBox js-info-input"\n           data-field-name="copySampleId"\n           id="copySampleIdsCheckbox"\n           style="transform: scale(2);" type="checkbox"/>\n</div>\n<div class="form-group">\n    <label class="col-xs-4" for="copyAssaysCheckbox">' +
((__t = (
        _.i18n('platelayout.copy.assays.ids')
        )) == null ? '' : __t) +
'</label>\n    <input checked class="col-xs-1 controlSidCheckBox js-info-input" data-field-name="copyAssay"\n           id="copyAssaysCheckbox"\n           style="transform: scale(2);" type="checkbox"/>\n    ';
 if(isPrepRun) { ;
__p += '\n    <label class="col-xs-4" for="copyAllAssaysCheckbox">' +
((__t = (
        _.i18n('platelayout.copy.allAssays.ids')
        )) == null ? '' : __t) +
'</label>\n    <input class="col-xs-1 controlSidCheckBox js-info-input" data-field-name="allAssays"\n           id="copyAllAssaysCheckbox"\n           style="transform: scale(2);" type="checkbox"/>\n    ';
 } ;
__p += '\n</div>\n<div class="form-group js-well-target-region"></div>\n<div class="js-cancel-action-region"></div>';

}
return __p
};});

