define('routingGroupItemCollection',['module',
        'backbone.marionette',
        'template!routingGroupItemCollection',
        'dialogFormView',
        'colorUtils',
        'bootbox',
        'underscore',
        'jquery',
        'renderer',
        'template!routingGroupItemToolTip'
    ],
    function (module,
              Marionette,
              RoutingGroupItemCollectionTpl,
              DialogFormView,
              ColorUtils,
              Bootbox,
              _,
              $,
              Renderer,
              RoutingGroupItemToolTip
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: RoutingGroupItemCollectionTpl,

            ui: {
                thumbnail: '.routingGroup',
                button: 'button'
            },

            events: {
                'click @ui.button': 'onProcessValidClick',
                'click .js-samples.clickable': 'onSamplesClick'
            },

            className: function () {
                return 'routingGroup item ' + (this.options.settingMode && this.options.settingMode.params === 'ONE' ? 'invisible' : '') + (this.model.get('secId') ? '' : ' empty');
            },

            attributes: function () {
                var color = this.model.get('color');
                if (!color) {
                    color = '#e0fbff';
                }
                return {style: 'background-color: ' + color + ';'};
            },

            select: function (selected, selectedOne) {
                if (!this.model.get('secId')) {
                    selected = false;
                }
                if (selected) {
                    if (this.options.settingMode.params === 'ONE') {
                        this.$el.removeClass('invisible');
                    }
                    this.$el.addClass('selected');
                    if (selectedOne) {
                        this.$el.addClass('border');
                    } else {
                        this.$el.removeClass('border');
                    }
                    if (this.model.get('showPopup') && selectedOne) {
                        this.ui.button.css('display', '');
                        this.trigger('waitProcessValid', this.ui.button);
                    }
                } else {
                    if (this.options.settingMode && this.options.settingMode.params === 'ONE') {
                        this.$el.addClass('invisible');
                    }
                    this.$el.removeClass('selected');
                    this.$el.removeClass('border');
                    this.ui.button.css('display', 'none');
                }
            },
            onProcessValidClick: function () {
                this.trigger('onProcessValidClick', this.ui.button);
                this.ui.button.css('display', 'none');
            },

            serializeData: function () {
                return {
                    rackId: this.model.get('rackId'),
                    code: this.model.get('routingGroup') ? this.model.get('routingGroup').get('code') : '',
                    name: this.model.get('routingGroup') ? this.model.get('routingGroup').get('name') : '',
                    showPopup: this.model.get('showPopup'),
                    preProcessing: this.model.get('preProcessing'),
                    width: this.options.width,
                    empty: !this.model.get('secId')
                };
            },

            onSamplesClick: function () {
                $('.js-global-loader').show();
                this.model.get('routingActionRoutingGroup').findSamples().done(_.bind(function (samples) {
                    if (samples.isEmpty()) {
                        Bootbox.alert(_.i18n('routingGroup.noSamples'));
                        return;
                    }
                    // display samples from property "samples" in a bootbox
                    var message = '<ul>';
                    samples.each(function (sample) {
                        message += '<li><b>' + sample.get('code') + '</b> : ' + sample.get('assayGroups').map(function (model) {
                            return model.get('code');
                        }).join(', ') + '</li>';
                    });
                    message += '</ul>';
                    Bootbox.dialog({
                        title: _.i18n('routingGroup.samples'),
                        message: message,
                        className: 'bootbox'
                    });
                }, this)).always(function () {
                    $('.js-global-loader').hide();
                });
            },
            onRender: function () {
                if (this.model.get('rackId')) {
                    var templateData = {
                        rackId: this.model.get('rackId'),
                        code: this.model.get('routingGroup').get('code'),
                        name: this.model.get('routingGroup').get('name'),
                        showPopup: this.model.get('showPopup'),
                        preProcessing: this.model.get('preProcessing'),
                        condition: this.model.get('routingGroup').get('condition'),
                        width: this.options.width
                    };
                    var renderer = new Renderer({template: RoutingGroupItemToolTip, templateData: templateData});
                    renderer.render();
                    var content = renderer.$el.html();
                    var placement = 'right';
                    this.$el.attr('data-content', content);
                    this.$el.popover({
                        trigger: 'hover',
                        html: 'true',
                        placement: placement,
                        container: 'body'
                    }).data('bs.popover').tip().css('max-width', '500px').css('padding', '10px 0 10px 0');
                }
            }
        });
    }
);
