define('menuGroupQuickActionCollection',[
    'module',
    'backbone.marionette',
    'menuGroupQuickActionItem',
    'menuGroupQuickActionItemCollection'
], function (
    module,
    Marionette,
    MenuGroupQuickActionItemView,
    MenuGroupQuickActionItemCollection
) {
    'use strict';

    /**
     * @class QuickActionCollectionView
     * @extends Marionette.CollectionView
     * @param {Object} options
     * @param {Object} options.collection
     * @param {Object} options.collection.items
     *
     * collection {
     *     items: Backbone.Collection
     * }
     */
    module.exports = Marionette.CollectionView.extend({
        className: 'quick-action',
        childView: function (model) {
            if (model.get('items')) {
                return MenuGroupQuickActionItemCollection;
            }
            return MenuGroupQuickActionItemView;
        },
        childViewOptions: function (model) {
            if (model.get('items')) {
                return {
                    collection: model.get('items')
                };
            }
            return {
                model: model
            };
        }
    });
});

