define('linkNewTabView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= code %>'),

        className: 'js-click clickable openNewTab',
        tagName: 'span',

        events: {
            'click': 'onClick'
        },
        attributes: function () {
            return {
                'data-secId': this.options.secId,
                title: this.options.name
            };
        },

        initialize: function (options) {
            if (!options.service) {
                throw new Error('service is required');
            }
        },

        serializeData: function () {
            return {code: this.options.code};
        },

        onClick: function () {
            window.open(window.location.origin + window.location.pathname + this.options.service.getRoute() + '/' + this.options.secId, '_blank');
        }
    });
});
