define('imageSelectionPopupView',[
    'module',
    'dialogView',
    'template!imageSelectionPopupTpl',
    'underscore',
    'settings',
    'imageUtils'
], function (
    module,
    DialogView,
    imageSelectionPopupTpl,
    _,
    Settings,
    imageUtils
) {
    'use strict';

    var ImageSelectionPopup = DialogView.extend({
        template: imageSelectionPopupTpl,

        ui: {
            cancel: '.js-cancel',
            confirm: '.js-confirm',
            images: '.js-image'
        },

        events: {
            'click @ui.cancel': '_onCancel',
            'click @ui.confirm': '_onConfirm'
        },

        checkedRadioSelector: 'input[type=radio]:checked',

        imagesBase64: [],

        radioButtons: [
            {
                id: 'image-selection-remove-all-white',
                label: _.i18n('common.removeAllWhite'),
                imageUrl: undefined
            }, {
                id: 'image-selection-remove-from-edges',
                label: _.i18n('common.removeFromEdges'),
                imageUrl: undefined
            }, {
                id: 'image-selection-not-remove-white',
                label: _.i18n('common.notRemoveWhite'),
                imageUrl: undefined
            }
        ],

        serializeData: function () {
            return {
                radioButtons: this.options.hasWhite === 'out' ?
                    this.radioButtons.slice(1, 3) : this.radioButtons
            };
        },

        onRender: function () {
            this._showImages();
        },

        _onConfirm: function () {
            var imageBase64 = this.imagesBase64[parseInt(this.$(this.checkedRadioSelector).val(), 10)].split('data:image/png;base64,')[1];
            this.trigger('confirm', imageBase64, 'png');
            this.hide();
        },

        _onCancel: function () {
            this.trigger('cancel');
            this.hide();
        },

        _showImages: function () {
            // Loading images and saving image base64
            var that = this;

            function showAndCacheImageBase64(index) {
                return function (imageBase64) {
                    that.imagesBase64[index] = imageBase64;
                    that.ui.images.filter('[data-index=' + index + ']').attr('src', imageBase64);
                };
            }

            imageUtils.convertImgToBase64(Settings.url('rest/file', this.options.imageUid, {
                action: 'rb',
                params: 'falsetrim'
            }), showAndCacheImageBase64(0));
            imageUtils.convertImgToBase64(Settings.url('rest/file', this.options.imageUid, {
                action: 'rb',
                params: 'truetrim'
            }), showAndCacheImageBase64(1));
            imageUtils.convertImgToBase64(Settings.url('rest/file', this.options.imageUid),
                showAndCacheImageBase64(2));
        }
    });

    module.exports = ImageSelectionPopup;
});

