define('services/caccounts/reports',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/reports'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.Report,
                collection: app.ns.ReportCollection
            };
        },
        getName: function () {
            return 'report';
        },
        getUrl: function () {
            return 'rest/v2/reports/';
        },
        getDynamicJson: function () {
            return 'ReportJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push(
                {
                    label: _.i18n('report.type'),
                    name: 'type',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'type',
                    width: 40
                }, {
                    label: _.i18n('report.fileName'),
                    name: 'fileName',
                    formatter: viewDynamic.defaultFormatter,
                    search: true,
                    index: 'fileName',
                    width: 40
                });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'type']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('code', 'name', 'tags', 'type');
            return ignored;
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.deletable = false;
            config.attachmentable = false;
            config.creatable = false;
            return config;
        },
        getCreateEditFieldCustom: function (model) {
            return [
                {
                    'field': 'fileName',
                    'dependsOn': {
                        'model': model,
                        'field': 'type'
                    },
                    'param': {
                        'type': 'AUTOCOMPLETE_SYNC',
                        'config': {'getAutocompleteParam': _.bind(this.getAutocompleteParamByType, this)}
                    }
                }
            ];
        },
        getAutocompleteParamByType: function (config, context, model) {
            var defer = $.Deferred();
            config = {
                modelProperty: 'fileName',
                valueProperty: 'name',
                queryProperty: 'name',
                valueKey: 'name',
                url: this.getUrl() + 'type/' + (model.get('type') ? model.get('type') : ''),
                canAddOrEdit: false
            };
            if (!context) {
                context = this;
            }
            config = Dynamic.getAutocompleteParam(config, context);
            var url = Settings.url('rest/v2/reports/type/' + (model.get('type') ? model.get('type') : ''));

            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (data) {
                    config.data = data;
                    defer.resolve(config);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});

