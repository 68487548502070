
define('template!wellEditSmpIdView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group header">\n    <label class="t-a-l">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n    <label class="control-label t-a-l">' +
((__t = ( pos )) == null ? '' : __t) +
'</label>\n    <label class="t-a-l">' +
((__t = ( _.i18n('well.type') )) == null ? '' : __t) +
'</label>\n    <label class="t-a-l">' +
((__t = ( _.i18n('well.lis') )) == null ? '' : __t) +
'</label>\n    <label class="t-a-l">' +
((__t = ( _.i18n('well.sampleClass') )) == null ? '' : __t) +
'</label>\n</div>\n<div class="form-group detail">\n    <label class="t-a-l">' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'</label>\n    <div class="div-input-smpId">\n        <input class="form-control js-info-input js-input-smpid inputBackground" data-field-name="smpId"\n               maxlength="99" placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n               type="text" value="' +
((__t = ( smpId )) == null ? '' : __t) +
'">\n    </div>\n    <div class="js-select-smpType"></div>\n    <label class="control-label t-a-l">' +
((__t = ( lis )) == null ? '' : __t) +
'</label>\n    <label class="control-label t-a-l">' +
((__t = ( sampleClass )) == null ? '' : __t) +
'</label>\n</div>\n';
 if(canChooseAssay) { ;
__p += '\n<div class="form-group assay">\n    <label class="control-label t-a-l">' +
((__t = ( _.i18n('well.refAssay') )) == null ? '' : __t) +
'</label>\n    <div class="js-assay-region">\n    </div>\n</div>\n';
 } ;
__p += '\n<div class="row">\n    <div class="pull-right p-r-15">\n        <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'\n        </button>\n        <button class="btn btn-primary disabled-grey js-confirm"\n                disabled>' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'\n        </button>\n    </div>\n</div>\n';

}
return __p
};});

