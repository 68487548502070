define('pcrWellResultIconView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!pcrWellResultIconView'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        modelEvents: {
            'change': 'render'
        },
        attributes: function () {
            return {
                style: 'width: 100%; height: 100%;display: flex; align-content: center; flex-wrap: nowrap; ' +
                    'align-items: center;' + (this.options.width ? 'flex-basis: ' + this.options.width + ';' : '')
            };
        },

        serializeData: function () {
            return {
                isResult: this.model.get('result') && this.model.get('result').get('code') ? 'border: grey solid 1px;' : '',
                color: this.model.get('result') ? this.model.get('result').get('color') + ' !important' : 'transparent',
                resCodeErr: this.model.get('codeErr'),
                resCommentErrAuto: this.model.get('commentErrAuto'),
                valSt: this.model.get('valSt'),
                repeatSt: this.model.get('refPcrWell').get('repeatStatus'),
                ic: this.model.get('internalControl'),
                displayCt: this.options.config.display.ct,
                displayCode: this.options.config.display.code,
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                result: this.model.get('result') ? this.model.get('result').get('code') : ''
            };
        }
    });
});

