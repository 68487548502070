define('pcrWellResultMagPixItemView',[
    'module',
    'backbone.marionette',
    'template!pcrWellResultMagPixItemView',
    'underscore',
    'jquery',
    'editWellView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    EditWellView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        regions: {
            result: '.js-result',
            displayMode: '.js-displayMode'
        },

        events: {
            'click .btn-edit-well-target-result': 'onEditWellTargetResult'
        },

        triggers: {
            'click .mdi-format-list-bulleted': 'show:properties',
            'click .result-custom': 'show:properties'
        },

        modelEvents: {
            'sync': 'render'
        },

        className: 'result result-magpix',

        serializeData: function () {
            var templateData = {
                refPcrWellExported: this.model.get('refPcrWell') ? this.model.get('refPcrWell').get('exported') : false,
                mbAna: this.model.get('mbAna') ? this.model.get('mbAna').get('code') : undefined,
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                comment: this.model.get('comment'),
                codeErr: this.model.get('codeErr'),
                valSt: this.model.get('valSt'),
                needValidation: this.model.get('needValidation'),
                readOnly: (this.options.readOnly !== undefined && this.options.readOnly) || this.model.isReadOnly()
            };

            templateData.userVal1Code = '';
            templateData.userVal1Title = '';
            if (this.model.get('refValUser')) {
                templateData.userVal1Code = this.model.get('refValUser').get('nickName');
                if (this.model.get('refValUser').get('email')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('email');
                }
                if (templateData.userVal1Code === '' && templateData.userVal1Title) {
                    templateData.userVal1Code = templateData.userVal1Title;
                }
                if (this.model.get('refValUser').get('firstName') && this.model.get('refValUser').get('lastName')) {
                    templateData.userVal1Title = this.model.get('refValUser').get('firstName') + ' ' + this.model.get('refValUser').get('lastName');
                }
            }
            templateData.userVal2Code = '';
            templateData.userVal2Title = '';
            if (this.model.get('refValUser2')) {
                templateData.userVal2Code = this.model.get('refValUser2').get('nickName');
                if (this.model.get('refValUser2').get('email')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('email');
                }
                if (templateData.userVal2Code === '' && templateData.userVal2Title) {
                    templateData.userVal2Code = templateData.userVal2Title;
                }
                if (this.model.get('refValUser2').get('firstName') && this.model.get('refValUser2').get('lastName')) {
                    templateData.userVal2Title = this.model.get('refValUser2').get('firstName') + ' ' + this.model.get('refValUser2').get('lastName');
                }
            }

            templateData.properties = '';
            _.each(this.model.get('properties'), function (property) {
                var value = property.value;
                if (!_.isNaN(value)) {
                    value = parseFloat(value).toFixed(1);
                }
                templateData.properties += property.name.split('.').pop() + ': ' + value + ', ';
            });

            templateData.properties = {mdd: null, mfi: null, beadCount: null};

            var property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MFI Value');
            });

            if (property) {
                templateData.properties.mfi = parseFloat(property.value).toFixed(0);
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MFI Threshold');
            });
            if (property) {
                templateData.properties.mfi += ' (' + parseFloat(property.value).toFixed(0) + ')';
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MDD Value');
            });
            if (property) {
                templateData.properties.mdd = parseFloat(property.value).toFixed(0);
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('MDD Threshold');
            });
            if (property) {
                templateData.properties.mdd += ' (' + parseFloat(property.value).toFixed(0) + ')';
            }
            property = _.find(this.model.get('properties'), function (property) {
                return property.name.endsWith('Bead Count');
            });
            if (property) {
                templateData.properties.beadCount = property.value;
            }

            templateData.commentErrAuto = this.model.get('commentErrAuto');

            templateData.errorMessage = '';
            if (templateData.codeErr) {
                templateData.errorMessage = templateData.codeErr + ' ' + templateData.commentErrAuto;
            }
            if (templateData.justificatory) {
                templateData.errorMessage = templateData.justificatory;
            }

            templateData.hasProperties = this.model.get('properties') && this.model.get('properties').length > 0;

            return templateData;
        },

        onRender: function () {
            PcrWellResultController.show(this.model, 'WellTarget', this.getRegion('result'));
            if (this.model.get('valSt') === 0) {
                this.getRegion('displayMode').empty();
            } else {
                PcrWellResultController.show(this.model, 'Well', this.getRegion('displayMode'));
            }
        },

        onEditWellTargetResult: function () {
            var createEditView = new EditWellView({
                model: this.model.get('refPcrWell'),
                modelResult: this.model,
                wellResColor: this.wellResColor,
                newItem: false
            });
            createEditView.show({
                title: _.i18n('well.info.edit'),
                className: 'popupWellView'
            });
        }
    });
});

