define('qcListView',[
    'module',
    'backbone.marionette',
    'jquery',
    'underscore',
    'qcListListView'
], function (
    module,
    Marionette,
    $,
    _,
    QcListListView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="col-xs-5 js-grid p-l-0 p-r-2"></div><div class="col-xs-7 js-graph p-r-2 p-l-0"></div>'),
        regions: {
            grid: '.js-grid',
            graph: '.js-graph'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.view = new QcListListView({
                model: this.options.model
            });
            this.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.listenTo(this.view, 'wellSelected', _.bind(this.displayWell, this));
            this.getRegion('grid').show(this.view);
        },
        onGridLoaded: function () {
            var data = this.options.service.getDataForPcrKitLot(this.model);
            this.view.displayGrid(data);
        },
        displayWell: function (wellSecId) {
            var PcrWellService = require('services/caccounts/pcrwells');
            var well = PcrWellService.getModel({secId: wellSecId});
            well.fetch().done(function () {
                require(['pcrWellController'], function (PcrWellController) {
                    PcrWellController.showDetails({model: well});
                });
            });
        }
    });
});
