define('pcrWellToolTipDotSubCollection',[
    'module',
    'backbone.marionette',
    'pcrWellToolTipDotSubItemView'
], function (
    module,
    Marionette,
    itemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: itemView,
        className: 'd-f',
        attributes: {
            style: 'flex-direction: column; gap: 5px;'
        },
        viewFilter: function (view) {
            return view.model.get('result') && view.model.get('result').get('code') === 'P';
        }
    });
});
