define('services/languages',[
    'entities/ns',
    'module',
    'entities/languages'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        getCollection: function (languagesJSON) {
            if (!languagesJSON) {
                languagesJSON = [];
            }
            return new app.ns.LanguageCollection(languagesJSON);
        }
    };
});
