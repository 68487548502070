define('pcrSetupPcrRunEmpty',[
    'module',
    'dialogFormView',
    'template!pcrSetupPcrRunEmpty'
], function (
    module,
    DialogFormView,
    Tpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        className: 'rounded runCard empty col-xs-12 clickable',
        attributes: function () {
            return {
                style: 'border-style: dashed; height: 237px; background-color: white;'
            };
        },

        events: {
            'click': 'onClick'
        },

        onClick: function () {
            this.trigger('item:new');
        }
    });
});
