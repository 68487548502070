define('entities/caccounts/routinggroup',[
    'entities/ns',
    'app',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    App,
    Backbone,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.ns.RoutingGroup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/routinggroup',
        fetch: function () {
            this.url = Settings.url('rest/v2/routinggroup/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'condition': null,
            'preProcessing': false
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/routinggroup/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'RoutingGroupJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'RoutingGroup',

        importable: true,
        validateCondition: function (condition) {
            var params = {
                condition: condition
            };

            var url = Settings.url('rest/v2/routinggroup/validateCondition', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.RoutingGroup.role = RolesMixin.ROUTINGGROUP;

    app.ns.RoutingGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/routinggroup/');
        },
        model: app.ns.RoutingGroup
    });
});

