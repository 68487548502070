
define('template!runPcrHeaderPlateLeftView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-plate-menu"></div>\n<div class="js-result-menu"></div>\n<div class="js-other-menu"></div>';

}
return __p
};});

