define('menuRoutingGroupCollection',['module',
        'backbone.marionette',
        'menuRoutingGroupItem'
    ],
    function (module,
              Marionette,
              MenuRoutingGroupItem
    ) {
        'use strict';

        module.exports = Marionette.CollectionView.extend({
            childView: MenuRoutingGroupItem,
            className: 'col-xs-12 p-l-0 p-r-0 h-100',
            attributes: function () {
                return {
                    style: 'display:flex; flex-direction: column; justify-content: center; align-items: center; overflow-y: auto;'
                };
            },
            childViewOptions: function () {
                return {
                    type: this.options.type
                };
            }
        });
    });
