define('entities/caccounts/lisBioGroups',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lis',
    'entities/caccounts/biogroups'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.LisBioGroup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisBioGroups',
        fetch: function () {
            this.url = Settings.url('rest/v2/lisBioGroups/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'lis': null,
            'bioGroup': null,
            'code': '',
            'name': '',
            'description': '',
            'tags': [],
            'criterias': [],
            'jsonobjectdocumentation': 'TB_LisBioGroupJson.pdf'
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'lis',
            relatedModel: 'Lis'
        }, {
            type: Backbone.HasOne,
            key: 'bioGroup',
            relatedModel: 'BioGroup'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/lisBioGroups/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('LisBioGroupJson.pdf');
        }
    });

    app.ns.LisBioGroup.role = RolesMixin.LISBIOGROUP;

    app.ns.LisBioGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lisBioGroups/');
        },
        model: app.ns.LisBioGroup
    });
});

