define('runPlateLayoutEditView',[
    'module',
    'backbone',
    'backbone.marionette',
    'dialogMixin',
    'underscore',
    'jquery',
    'template!runPlateLayoutEditView',
    'runPlateLayoutEditCollection'
], function (
    module,
    Backbone,
    Marionette,
    DialogMixin,
    _,
    $,
    Tpl,
    RunPlateLayoutEditCollection
) {
    'use strict';

    module.exports = Marionette.View.extend(_.extend({
        template: Tpl,
        regions: {
            container: '.js-container',
            cancelConfirmRegion: '.js-cancel-confirm-region'
        },

        serializeData: function () {
            return {columns: this.model.getColumn()};
        },
        onRender: function () {
            this.getRegion('container').show(new RunPlateLayoutEditCollection({model: this.model, settings: this.options.settings, mode: this.options.mode}));
        }
    }, DialogMixin));
});
