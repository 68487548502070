
define('template!roleView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <h2>' +
((__t = ( _.i18n('role.object') )) == null ? '' : __t) +
'</h2>\n    <h2>' +
((__t = ( _.i18n('role.userRight') )) == null ? '' : __t) +
'</h2>\n\n    ';
 if(!readOnly) { ;
__p += '\n    <label>' +
((__t = ( _.i18n('userRight.select') )) == null ? '' : __t) +
'</label>\n    <div class="selectUnselect">\n        <div>\n            <label>ALL</label>\n            <div>\n                <input type="checkbox" class="js-checked" value="ALL" checked>\n                <input type="checkbox" class="js-unchecked" value="ALL">\n            </div>\n        </div>\n        <div>\n            <label>REFERENCE</label>\n            <div>\n                <input type="checkbox" class="js-checked" value="REFERENCE" checked>\n                <input type="checkbox" class="js-unchecked" value="REFERENCE">\n            </div>\n        </div>\n        <div>\n            <label>READ</label>\n            <div>\n                <input type="checkbox" class="js-checked" value="READ" checked>\n                <input type="checkbox" class="js-unchecked" value="READ">\n            </div>\n        </div>\n        <div>\n            <label>WRITE</label>\n            <div>\n                <input type="checkbox" class="js-checked" value="WRITE" checked>\n                <input type="checkbox" class="js-unchecked" value="WRITE">\n            </div>\n        </div>\n        <div>\n            <label>ARCHIVE</label>\n            <div>\n                <input type="checkbox" class="js-checked" value="ARCHIVE" checked>\n                <input type="checkbox" class="js-unchecked" value="ARCHIVE">\n            </div>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n\n</div>\n\n<div class="js-roles"></div>\n';

}
return __p
};});

