
define('template!prepRunCreateBeadPoolingCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="collection">\n</div>\n<div class="rounded runCard col-xs-12 clickable js-add" style="border-style: dashed; height: 50px;">\n    <div class="col-xs-12 m-0 p-0" style="display: flex; align-items: center; flex-direction: column;">\n        <label style="color: lightgrey; font-size: 25px; line-height: 25px; cursor: pointer;">' +
((__t = (
            _.i18n('add') )) == null ? '' : __t) +
'</label>\n    </div>\n</div>';

}
return __p
};});

