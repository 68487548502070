
define('template!assayConfigurationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="header">\n    <div class="col-xs-1 action">\n        <span class="mdi mdi-delete delete-row clickable"></span>\n        <span class="mdi mdi-content-copy duplicate-row clickable"></span>\n    </div>\n    <div class="col-xs-2">\n        <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.code') )) == null ? '' : __t) +
'</label><br/>\n        <input type="text"\n               class="form-control js-info-input-assayConfiguration inputBackground"\n               data-field-name="code" placeholder=""\n               value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n               data-placement="top" autocomplete="off">\n    </div>\n    <div class="col-xs-2">\n        <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.cyclerPublic') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-cyclerPublic-region"></div>\n    </div>\n    <div class="col-xs-3 js-cyclers">\n        <label class="control-label noWrapHidden">' +
((__t = ( _.i18n('assayConfiguration.cyclers') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-cyclers-region"></div>\n    </div>\n    <div class="col-xs-2 js-colorCompensation">\n        <label class="control-label noWrapHidden" style="text-align: right;">' +
((__t = (
            _.i18n('assayConfiguration.colorCompensation') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-select-colorCompensation-region"></div>\n    </div>\n</div>\n\n<!-- liaison results - table -->\n<div class="js-assayResult-region"></div>';

}
return __p
};});

