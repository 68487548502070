define('entities/caccounts/runats',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.RunAt = Backbone.RelationalModel.extend({
        service: 'services/caccounts/runats',
        fetch: function () {
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'baseline': 0,
            'threshold': 0,
            'ctA': 0,
            'ctB': 0,
            'override': false,
            'valTst': 0
        },
        idAttribute: 'secId',

        postUrl: function (runSecId) {
            return Settings.url('rest/v2/runs/pcr/' + runSecId + '/runats');
        },

        save: function () {
            this.url = this.postUrl('undefined');
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('RunAtJson.pdf');
        }
    });

    app.ns.RunAt.role = RolesMixin.RUN_PCR;
});

