define('systemModuleView',[
    'module',
    'backbone.marionette',
    'underscore',
    'systemModuleItemView'
], function (
    module,
    Marionette,
    _,
    SystemModuleItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: _.template('<h1>MODULES</h1><div class="js-modules"></div>'),

        childViewContainer: '.js-modules',
        childView: SystemModuleItemView,
        childViewOptions: function (model) {
            return {
                model: model,
                collection: model.get('roles')
            };
        },

        serializeData: function () {
            return {
                modules: this.collection
            };
        }
    });
});

