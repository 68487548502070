
define('template!chartView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div style="flex:1;">\n    <canvas class="chart-canvas" style="flex:1;"></canvas>\n    <div class="vertical">\n        <div class="slider-range-vert" style="position: absolute; bottom: 0; right: -6px; cursor: ns-resize;"></div>\n        <div class="mdi mdi-rotate-3d-variant reset-slider-vert clickable"\n             style="display: none; position: absolute; right: -6px; top: -24px;font-size: 20px;line-height: 20px;"></div>\n    </div>\n</div>\n<div class="horizontal">\n    <div class="slider-range" style="margin-left: 28px; margin-right: 6px; cursor: ew-resize;"></div>\n    <div class="mdi mdi-rotate-3d-variant reset-slider clickable"\n         style="display: none; position: absolute; bottom: -15px; left: 0;font-size: 20px;line-height: 20px;"></div>\n</div>';

}
return __p
};});

