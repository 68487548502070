define('pcrKitLotForValidationCollection',[
    'module',
    'backbone.marionette',
    'pcrKitLotForValidationItem',
    'template!pcrKitLotForValidationCollection'
], function (
    module,
    Marionette,
    PcrKitLotForValidationItem,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: PcrKitLotForValidationItem,
        tagName: 'table',
        className: 'table table-striped',
        childViewContainer: 'tbody',
        childViewOptions: function () {
            return {
                well: this.model
            };
        },

        serializeData: function () {
            return {};
        },
        getSelected: function () {
            return this.children.map(function (child) {
                return child.getSelected();
            });
        }
    });
});

