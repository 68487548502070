define('runPcrHeaderDetailsView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!runPcrHeaderDetailsView',
    'prepRunCollectionView',
    'runEditLayoutView',
    'app',
    'renderer',
    'template!pcrRunStatus',
    'runPcrKitLotCollectionView',
    'template!pcrRunStatus',
    'template!runPcrTooltip',
    'fieldUtils',
    'dateUtils',
    'wellUtils',
    'jquery',
    'backbone'
], function (
    module,
    Marionette,
    _,
    Tpl,
    PrepRunCollectionView,
    CreateEditLayoutView,
    App,
    Renderer,
    PcrRunStatusTpl,
    RunPcrKitLotCollectionView,
    PcrRunStatus,
    RunPcrTooltipTpl,
    FieldUtils,
    dateFormatter,
    WellUtils,
    $,
    Backbone
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'details',
        regions: {
            'prepRuns': '.js-prepruns'
        },
        ui: {
            copyParameters: '.js-copyParameters',
            statusToolTip: '.js-status-tooltip',
            mainHeaderRunBox: '.pop-mainHeaderRunBox'
        },
        modelEvents: {
            'change': 'renderSafe'
        },
        events: {
            'click .btn-edit-pcrrun': 'onEditPcrRun',
            'click .btn-show-kits': 'onShowKits',
            'click .btn-show-attachments': 'onShowAttachments',
            'click .btn-show-properties': 'onShowProperties',
            'click .js-run-refresh': 'onRunRefresh'
        },
        serializeData: function () {
            return {
                code: this.model.get('code'),
                status: this.model.get('status'),
                valStatus: this.model.get('valStatus')
            };
        },
        renderSafe: function () {
            if (!this.isDestroyed) {
                setTimeout(_.bind(this.render, this), 50);
            }
        },
        onRender: function () {
            this.showChildView('prepRuns', new PrepRunCollectionView({
                collection: this.model.get('prepRuns'),
                model: this.model
            }));
            App.trigger('forClipboard', this.ui.copyParameters);
            this.showMainHeaderRunPopover();
            this.showStatusRunPopover();
        },
        onEditPcrRun: function () {
            var view = new CreateEditLayoutView({
                model: this.model
            });
            view.show({
                title: _.i18n('pcrrun.edit'),
                className: 'runEditAddPopup'
            }, _.bind(function () {
                this.render();
            }, this));
        },
        onShowKits: function () {
            this.model.getPcrKitLots().done(_.bind(function (response) {
                var collection = new Backbone.Collection();
                _.each(response, function (item) {
                    _.each(item.assayReagents, function (reagent) {
                        _.each(reagent.pcrKitLots, function (pcrKitLot) {
                            var model = new Backbone.Model();
                            model.set('assay', item.refAssay.code);
                            model.set('kit', reagent.kit.code);
                            model.set('reagent', reagent.reagent.code);
                            model.set('reagentType', reagent.reagent.type);
                            model.set('kitLot', pcrKitLot.refKitLot.code);
                            model.set('count', pcrKitLot.count);
                            model.set('countInPrepWell', pcrKitLot.countInPrepWell);
                            model.set('volume', pcrKitLot.volumeByWell);
                            collection.add(model);
                        });
                    });
                });
                // besoin des kitLots
                this.runPcrKitsLotsView = new RunPcrKitLotCollectionView({
                    collection: collection
                });
                this.runPcrKitsLotsView.show({
                    title: _.i18n('run.pcr.kits'),
                    className: 'runShowKitsPopup'
                });

            }, this));
        },
        onShowAttachments: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            var AttachmentService = require('services/caccounts/attachment');
            AttachmentService.openAttachment(PcrRunService, this.model);
        },
        onRunRefresh: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(function () {
                $('.js-global-loader').hide();
            });
        },
        showStatusRunPopover: function () {
            if (this.ui.statusToolTip && this.ui.statusToolTip.length > 0) {
                var renderer = new Renderer({
                    template: PcrRunStatus,
                    templateData: {
                        current: this.model.get('status')
                    }
                });
                renderer.render();
                var content = renderer.$el.html();

                this.ui.statusToolTip.attr('data-content', content);
                this.ui.statusToolTip.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().css('maxWidth', '500px');
            }
        },
        showMainHeaderRunPopover: function () {
            if (this.ui.mainHeaderRunBox && this.ui.mainHeaderRunBox.length > 0) {
                var templateData = {
                    code: this.model.get('code'),
                    department: this.model.get('department'),
                    periodFormatted: this.model.get('periodFormatted'),
                    group: this.model.get('group'),
                    sequenceFormatted: this.model.get('sequenceFormatted'),
                    kitProt: this.model.get('refKitProt') ? this.model.get('refKitProt').get('code') : '',
                    lmbCycler: this.model.get('refLmbCycler') ? this.model.get('refLmbCycler').code : '',
                    respUser: this.model.get('refRespUser') ? (this.model.get('refRespUser').nickName ? this.model.get('refRespUser').nickName : (this.model.get('refRespUser').email ? this.model.get('refRespUser').email : '')) : '',
                    comment: this.model.get('comment'),
                    valStatus: this.model.get('valStatus')
                };
                templateData.valStatusIcon = this.getValStatusIcon(this.model.get('status'), this.model.get('valStatus'));

                var formattedPcrRuns = [];
                this.model.get('prepRuns').each(function (obj) {
                    formattedPcrRuns.push(obj.get('code'));
                });
                templateData.formattedPcrRuns = formattedPcrRuns.length > 0 ? formattedPcrRuns.join(';') : '';

                templateData.assaysToDisplay = this.model.get('assays') && !this.model.get('assays').isEmpty() ? this.model.get('assays').pluck('code').join(';') : '';
                templateData.sequenceFormatted = FieldUtils.getSequenceFormatted(this.model.get('sequence'), this.model.get('sequenceFormat'));
                templateData.wellSize = this.model.get('wells') && this.model.get('wells').size() ? this.model.get('wells').size() : '';
                templateData.creatDateToDisplay = dateFormatter.toDateTimeFormatStringFromSettings(this.model.get('creatDate'), this.model.get('settings'));
                templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size(), true);

                var renderer = new Renderer({template: RunPcrTooltipTpl, templateData: templateData});
                renderer.render();
                var content = renderer.$el.html();
                this.ui.mainHeaderRunBox.attr('data-content', content);
                this.ui.mainHeaderRunBox.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().addClass('run-pcr-popover');
            }
        },
        getValStatusIcon: function (templateStatus, templateValStatus, editPopup) {
            var status = parseInt(templateStatus, 10);
            var valStatus = templateValStatus;
            var iconColor = [];
            var bottom = editPopup ? '5px' : '16px';
            var statusIcon = '';
            statusIcon += '<div>';
            statusIcon += '<span class="jqgrid-statusNb" style="bottom:' + bottom + '; font-size: 12px;">' + valStatus + '</span>';
            statusIcon += '<div class="cell-default" style="display: flex; top: 5px;\n' +
                '            position: relative;" title="' + valStatus + '">';

            var valStatusArray = valStatus.split('');
            for (var i = 1; i <= 5; i++) {
                iconColor[i] = '';
                if (status >= i) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                if (valStatusArray.includes(i.toString())) {
                    iconColor[i] = 'statusIcon-toDo';
                }
                if (i === 5 && valStatusArray.includes(i.toString()) && status === 5) {
                    iconColor[i] = 'statusIcon-upTo';
                }
                statusIcon += '<div class="jqgrid-Header-valStatusIcon ' + iconColor[i] + '"></div>';
            }
            statusIcon += '</div>';
            statusIcon += '</div>';
            return statusIcon;
        },
        onShowProperties: function () {
            require(['propertiesController'], _.bind(function (Controller) {
                Controller.showProperties(this.model);
            }, this));
        }
    });
});

