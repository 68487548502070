define('dynamicCreateEditCustomSampleList',[
    'module',
    'dialogFormView',
    'template!dynamicCreateEditCustomSampleList',
    'autocompleteView',
    'backbone.marionette',
    'renderer',
    'template!dynamicCreateEditCustomSampleListToolTip'
], function (
    module,
    DialogFormView,
    tpl,
    AutocompleteView,
    Marionette,
    Renderer,
    DynamicCreateEditCustomSampleListToolTip
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input',
            field: '.js-input-field',
            fieldCustom: '.js-input-fieldCustom'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'focus @ui.fieldCustom': 'onFocusCustom'
        },

        regions: {
            service: '.js-select-service'
        },

        serializeData: function () {
            var templateData = {};
            templateData.model = this.model.toJSON();
            templateData.tpl = {};
            templateData.tpl.field = this.options.field;
            templateData.tpl.fieldCustom = this.options.fieldCustom;
            return templateData;
        },

        onRender: function () {
            this.getRegion('service').show(new AutocompleteView(
                this._getAutocompleteOptions(this.options.field, 'code', 'name', this.options.url,
                    this.options.field + 'placeholder', 'code', this.changeService)
            ));
            this.onChangeField();
        },

        changeService: function (fieldName, model) {
            if (model === undefined || model === null) {
                this.onChange(fieldName, '');
                this.model.set(this.options.fieldCustom, '');
            } else {
                this.onChange(fieldName, model.get('code'));
            }
            this.onChangeField();
        },

        onChangeField: function () {
            if (this.isDestroyed()) {
                return;
            }
            if (this.model.get(this.options.field) && this.model.get(this.options.field).startsWith('Custom')) {
                this.ui.fieldCustom.parent().removeClass('invisible');
                this.ui.fieldCustom.parent().parent().removeClass('invisible'); // added div form-control-wrapper by ??? IDK, it's a quick fix
                this.onFocusCustom();
            } else {
                this.ui.fieldCustom.parent().addClass('invisible');
            }
        },
        onFocusCustom: function () {
            var customTooltips = new Renderer({template: DynamicCreateEditCustomSampleListToolTip});
            customTooltips.render();
            var content = customTooltips.$el.html();
            var placement = 'bottom';
            this.ui.fieldCustom.attr('data-content', content);
            this.setPopover(this.ui.fieldCustom, placement);
        },

        setPopover: function (pop, pos) {
            pop.popover({
                trigger: 'hover',
                html: 'true',
                placement: pos,
                container: 'body'
            }).data('bs.popover').tip().addClass('jqgrid-popover');
        }
    });
});
