define('renderer',[
    'module',
    'backbone.marionette'
], function (
    module,
    Marionette
) {
    'use strict';

    module.exports = Marionette.View.extend({
        getTemplate: function () {
            if (typeof this.options.template === 'function') {
                return this.options.template;
            }
            return 'template!' + this.options.template;
        },
        serializeData: function () {
            if (!this.options.templateData) {
                return {};
            }
            return this.options.templateData;
        }
    });
});
