define('entities/caccounts/connector/connectorHl7Tracings',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ns.ConnectorHL7Tracing = Backbone.RelationalModel.extend({
        service: 'services/caccounts/connector/connectorHl7Tracings',
        fetch: function () {
            this.url = Settings.url('rest/v2/connector/hl7/tracing/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {},
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/connector/hl7/tracing/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'Request',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Request',

        importable: false,
        resend: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/connector/hl7/tracing/' + this.get('secId') + '/resend');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function () {
                    defer.resolve();
                },
                error: _.bind(function (xhr) {
                    defer.reject(xhr);
                }, this)
            });

            return defer.promise();
        }
    });

    app.ns.ConnectorHL7Tracing.role = RolesMixin.CONNECTOR_HL7;
});

