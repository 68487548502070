define('sampleCommentView',[
    'module',
    'dialogFormView',
    'underscore',
    'template!sampleCommentView',
    'savingBehavior'
], function (
    module,
    DialogFormView,
    _,
    tpl,
    SavingBehavior
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        serializeData: function () {
            var templateData = {};
            templateData.pos = this.options.wellModel.get('pos');
            templateData.sample = this.model.get('code');
            templateData.commentCombined = (this.model.get('lisComment') ? this.model.get('lisComment') : '') + ' - ' + (this.model.get('comment') ? this.model.get('comment') : '');
            templateData.lisComment = this.model.get('lisComment');
            templateData.comment = this.model.get('comment');
            return templateData;
        },

        onRender: function () {
            this.setPermissions(true);
        },

        onConfirm: function () {
            this.model.updateComment(this.model.get('comment')).done(_.bind(function () {
                this.options.wellModel.set('sampleComment', this.model.get('comment'));
                this.box.modal('hide');
            }, this));
        }
    });
});
