/**
 * Created by OLD on 30/10/2015.
 */
define('adminMainRouter',[
    'underscore',
    'jquery',
    'require',
    'exports',
    'module',
    'logger',
    'marionetteAppRouter',
    'app',
    'itemPagesNameMixin',
    'wellUtils',
    'cssUtils',
    'dynamicController',
    'sidebarShowController'
], function (
    _,
    $,
    require,
    exports,
    module,
    Logger,
    AppRouter,
    App,
    ItemPagesNameMixin,
    WellUtils,
    CssUtils,
    DynamicController,
    SidebarShowController
) {
    'use strict';

    var Controller = {
        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showKitsAdmin: function () {
            this.dynamicShowList('caccounts/kitspcr', ItemPagesNameMixin.KITS_PCR);
        },


        showKitLotsAdmin: function () {
            require(['kitLotsPcrController'], _.bind(function (Controller) {
            this.dynamicShowList('caccounts/kitlotpcr', ItemPagesNameMixin.KITS_PCR_LOT, Controller.showDetails);
            }, this));
        },

        showKitsExtractionAdmin: function () {
            this.dynamicShowList('caccounts/kitsextraction', ItemPagesNameMixin.KITS_EXTRACTION);
        },

        showKitLotsExtractionAdmin: function () {
            require(['kitLotsController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/kitlotextraction', ItemPagesNameMixin.KITS_EXTRACTION_LOT, Controller.showDetails);
            }, this));
        },

        showAssaysAdminList: function () {
            require(['adminAssayController'], _.bind(function (AdminAssayController) {
                this.dynamicShowList('caccounts/assay', ItemPagesNameMixin.ADMIN_ASSAYS, AdminAssayController.showDetails);
            }, this));
        },

        showKitProtsAdminList: function () {
            this.dynamicShowList('caccounts/kitprots', ItemPagesNameMixin.KITPROTS);
        },

        showSettingAdminList: function () {
            this.dynamicShowList('caccounts/setting', ItemPagesNameMixin.SETTING);
        },
        showFilesFromSettingAdminList: function (secId) {
            this.dynamicShowList('caccounts/files', ItemPagesNameMixin.SETTING, undefined, {settingSecId: secId});
        },

        showStatusASTMExportAdminList: function () {
            require(['adminExportStatusController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/lisRecords', ItemPagesNameMixin.ADMIN_EXPORT, Controller.showDetails);
            }, this));
        },

        showMbAnasAdminList: function () {
            this.dynamicShowList('caccounts/mbanas', ItemPagesNameMixin.MBANAS);
        },

        showMbAnaResGrsAdminList: function () {
            this.dynamicShowList('caccounts/mbanaresgrs', ItemPagesNameMixin.MBANARESGRS);
        },

        showBioGroupsAdminList: function () {
            this.dynamicShowList('caccounts/biogroups', ItemPagesNameMixin.BIOGROUPS);
        },
        showDisplayModesAdminList: function () {
            this.dynamicShowList('caccounts/displayModes', ItemPagesNameMixin.DISPLAYMODE);
        },
        showSampleResultDefinitionsAdminList: function () {
            this.dynamicShowList('caccounts/sampleResultDefinitions', ItemPagesNameMixin.ADMIN_SAMPLERESULTDEFINITION);
        },
        showSpeciesAdminList: function () {
            this.dynamicShowList('caccounts/species', ItemPagesNameMixin.SPECIES);
        },
        showStandardCurveAdminList: function () {
            require(['standardCurveController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/standardCurve/standardCurves', ItemPagesNameMixin.STANDARDCURVES, Controller.showDetails);
            }, this));
        },
        showPrintersAdminList: function () {
            this.dynamicShowList('caccounts/printers', ItemPagesNameMixin.PRINTERS);
        },
        showReportsAdminList: function () {
            this.dynamicShowList('caccounts/reports', ItemPagesNameMixin.REPORTS);
        },
        showSampleClassAdminList: function () {
            this.dynamicShowList('caccounts/sampleClass', ItemPagesNameMixin.SAMPLECLASS);
        },
        showCyclersAdminList: function () {
            this.dynamicShowList('caccounts/cyclers', ItemPagesNameMixin.CYCLERS);
        },
        showExtractorsAdminList: function () {
            this.dynamicShowList('caccounts/extractors', ItemPagesNameMixin.EXTRACTORS);
        },
        showAuthorityGroupsList: function () {
            this.dynamicShowList('caccounts/authorityGroup', ItemPagesNameMixin.ADMIN_AUTHORITYGROUP);
        },
        showUserGroupsList: function () {
            this.dynamicShowList('caccounts/userGroup', ItemPagesNameMixin.ADMIN_USERGROUP);
        },
        showConnectorHl7List: function () {
            require(['connectorHl7Controller'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/connector/connectorHl7Tracings', ItemPagesNameMixin.CONNECTOR_HL7_TRACING, Controller.showDetails);
            }, this));
        },
        showPcrRunTemplatesList: function () {
            require(['pcrRunTemplateController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/pcrruntemplates', ItemPagesNameMixin.PCRRUNTEMPLATES, Controller.showDetails);
            }, this));
        },
        showPrepRunTemplatesList: function () {
            require(['prepRunTemplateController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/prepruntemplates', ItemPagesNameMixin.PREPRUNTEMPLATES, Controller.showDetails);
            }, this));
        },
        showSampleHandlersAdminList: function () {
            this.dynamicShowList('caccounts/samplehandlers', ItemPagesNameMixin.SAMPLEHANDLERS);
        },
        showFileFormatPlateLayoutsAdminList: function () {
            this.dynamicShowList('caccounts/fileFormatPlateLayouts', ItemPagesNameMixin.FILEFORMAT_PLATELAYOUT);
        },
        showFileFormatSampleListsAdminList: function () {
            this.dynamicShowList('caccounts/fileFormatSampleLists', ItemPagesNameMixin.FILEFORMAT_SAMPLELIST);
        },
        showLisAdminList: function () {
            this.dynamicShowList('caccounts/lis', ItemPagesNameMixin.LIS);
        },

        showTrashAdminList: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'adminTrashController'
                ], function (TrashController) {
                    TrashController.showTrashList({
                        type: 'trash'
                    });
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.TRASH);
                });
            });
        },

        showLisanasAdminList: function () {
            this.dynamicShowList('caccounts/lisana', ItemPagesNameMixin.LISANA);
        },

        showLisBioGroupsAdminList: function () {
            this.dynamicShowList('caccounts/lisBioGroups', ItemPagesNameMixin.LISBIOGROUP);
        },
        showLisSpeciesAdminList: function () {
            this.dynamicShowList('caccounts/lisSpecies', ItemPagesNameMixin.LISSPECIE);
        },

        showSamples: function () {
            require(['samplesController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/samples', ItemPagesNameMixin.SAMPLES, Controller.showDetails);
            }, this));
        },

        showOrders: function () {
            require(['ordersController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/orders', ItemPagesNameMixin.ORDERS, Controller.showDetails);
            }, this));
        },

        showAssayGroupAdminList: function () {
            this.dynamicShowList('caccounts/assaygroup', ItemPagesNameMixin.ASSAYGROUP);
        },

        showRoutingGroupAdminList: function () {
            this.dynamicShowList('caccounts/routinggroup', ItemPagesNameMixin.ROUTINGGROUP);
        },

        showRoutingActionAdminList: function () {
            this.dynamicShowList('caccounts/routingaction', ItemPagesNameMixin.ROUTINGACTION);
        },

        showPreProcessAdminList: function () {
            this.dynamicShowList('caccounts/preprocess', ItemPagesNameMixin.PREPROCESS);
        },

        showSuppliersAdminList: function () {
            require(['suppliersController'], _.bind(function (Controller) {
                this.dynamicShowList('caccounts/suppliers', ItemPagesNameMixin.SUPPLIER, Controller.showDetails);
            }, this));
        },

        showUsersAdminList: function () {
            this.dynamicShowList('caccounts/userpids', ItemPagesNameMixin.ADMIN_USERS);
        },

        showExtractionMethodsAdminList: function () {
            this.dynamicShowList('caccounts/extractionMethod', ItemPagesNameMixin.EXTRACTIONMETHOD);
        },

        showColorCompensationAdminList: function () {
            this.dynamicShowList('caccounts/colorCompensations', ItemPagesNameMixin.COLORCOMPENSATION);
        },
        showUserCodeListAdminList: function () {
            this.dynamicShowList('caccounts/userCodelist', ItemPagesNameMixin.USERCODELISTS);
        },

        showAttachmentsAdminList: function () {
            require(['adminAttachmentController'], _.bind(function (AdminAttachmentController) {
                this.dynamicShowList('caccounts/attachment', ItemPagesNameMixin.ATTACHMENTS, AdminAttachmentController.showDetails);
            }, this));
        },

        dynamicShowList: function (entityNamePath, itemPagesNameMixin, callBackCreateEditView, params) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                DynamicController.showList(
                    App.getView().getRegion('content'), {
                        entityNamePath: entityNamePath,
                        callBackCreateEditView: callBackCreateEditView,
                        params: params
                    });
                SidebarShowController.setActiveNavItem(itemPagesNameMixin);
            });
        }
    };

    var AdminRouter = AppRouter.extend({
        appRoutes: {
            'samples': 'showSamples',
            'orders': 'showOrders',
            'admin/assays': 'showAssaysAdminList',
            'admin/mbanas': 'showMbAnasAdminList',
            'admin/kitprots': 'showKitProtsAdminList',
            'admin/lisanas': 'showLisanasAdminList',
            'admin/settings': 'showSettingAdminList',
            'admin/settings/:secId/files': 'showFilesFromSettingAdminList',
            'admin/export/ASTM/status': 'showStatusASTMExportAdminList',
            'admin/mbanaresgrs': 'showMbAnaResGrsAdminList',
            'admin/assaygroup': 'showAssayGroupAdminList',
            'admin/kits/pcr': 'showKitsAdmin',
            'admin/kits/pcr/lot': 'showKitLotsAdmin',
            'admin/kits/extraction': 'showKitsExtractionAdmin',
            'admin/kits/extraction/lot': 'showKitLotsExtractionAdmin',
            'admin/routinggroup': 'showRoutingGroupAdminList',
            'admin/routingaction': 'showRoutingActionAdminList',
            'admin/preProcess': 'showPreProcessAdminList',
            'admin/suppliers': 'showSuppliersAdminList',
            'admin/biogroups': 'showBioGroupsAdminList',
            'admin/displayModes': 'showDisplayModesAdminList',
            'admin/sampleResultDefinitions': 'showSampleResultDefinitionsAdminList',
            'admin/lisBioGroups': 'showLisBioGroupsAdminList',
            'admin/species': 'showSpeciesAdminList',
            'admin/printers': 'showPrintersAdminList',
            'admin/reports': 'showReportsAdminList',
            'admin/lisSpecies': 'showLisSpeciesAdminList',
            'admin/sampleClass': 'showSampleClassAdminList',
            'admin/cyclers': 'showCyclersAdminList',
            'admin/extractors': 'showExtractorsAdminList',
            'admin/sampleHandlers': 'showSampleHandlersAdminList',
            'admin/fileFormat/plateLayouts': 'showFileFormatPlateLayoutsAdminList',
            'admin/fileFormat/sampleLists': 'showFileFormatSampleListsAdminList',
            'admin/lis': 'showLisAdminList',
            'admin/trash': 'showTrashAdminList',
            'admin/extractionMethods': 'showExtractionMethodsAdminList',
            'admin/users': 'showUsersAdminList',
            'admin/colorCompensation': 'showColorCompensationAdminList',
            'admin/userCodelists': 'showUserCodeListAdminList',
            'admin/attachments': 'showAttachmentsAdminList',
            'admin/runtemplates/pcr': 'showPcrRunTemplatesList',
            'admin/runtemplates/prep': 'showPrepRunTemplatesList',
            'admin/authorityGroups': 'showAuthorityGroupsList',
            'admin/userGroups': 'showUserGroupsList',
            'admin/standardCurves': 'showStandardCurveAdminList',
            'admin/connector/hl7': 'showConnectorHl7List'
        },
        controller: Controller
    });

    module.exports = new AdminRouter();
});

