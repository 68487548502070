define('kitLotsPcrTargetView',[
    'module',
    'dialogFormView',
    'template!kitLotsPcrTargetView',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'autocompleteView',
    'kitLotsPcrLotView',
    'backbone'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    Settings,
    AutocompleteView,
    KitLotsPcrLotView,
    Backbone
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            left: '.js-left',
            otherLot: '.js-other-lot'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click .mdi-transfer-left.clickable': 'onTransfer'
        },

        regions: {
            assay: '.js-assay',
            reagent: '.js-reagent',
            currentLot: '.js-current-lot',
            otherLot: '.js-other-lot'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        initialize: function (options) {
            this.current = {assay: undefined, reagent: null};
            if (options.current) {
                this.current = options.current;
            }
            this.otherLots = new Backbone.Collection(this.model.get('kit').get('lots').models);
            this.otherLots.remove(this.model);
            this.enableOtherLot = this.otherLots.length > 0;
        },
        serializeData: function () {
            var templateData = {};
            templateData.kit = this.model.get('kit').get('code');
            return templateData;
        },

        onRender: function () {
            var service = require('services/caccounts/kitlotpcr');
            this.setPermissions(service.canAddOrEdit());
            var KitLotPcrService = require('services/caccounts/kitlotpcr');
            this.getRegion('assay').show(new AutocompleteView(KitLotPcrService.getAutocompleteParam({
                data: _.map(this.model.getAssays(), function (assay) {
                    var json = assay.toJSON();
                    json.result = _.where(json.results, {needValidation: true});
                    return json;
                }),
                callBackOnChange: _.bind(this.onChangeAssay, this),
                value: this.current.assay ? this.current.assay.get('code') : null
            })));
            this.getRegion('reagent').show(new AutocompleteView(KitLotPcrService.getAutocompleteParam({
                data: _.map(this.model.getReagents(this.current.assay), function (assay) {
                    return assay.toJSON();
                }),
                callBackOnChange: _.bind(this.onChangeReagent, this),
                value: this.current.reagent ? this.current.reagent.get('code') : null
            })));

            this.showCurrentLot(this.model);
            if (this.enableOtherLot) {
                this.showOtherLot(this.otherLots.first());
                this.ui.left.removeClass('invisible');
                this.ui.otherLot.removeClass('invisible');
            }
            this.filterCollection();
        },

        showCurrentLot: function (model) {
            this.currentLot = new KitLotsPcrLotView({
                model: model,
                reagent: this.current.reagent,
                assay: this.current.assay,
                editable: true,
                enableLeft: false,
                enableRight: false
            });
            this.getRegion('currentLot').show(this.currentLot);
        },

        showOtherLot: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            this.otherLotView = new KitLotsPcrLotView({
                model: model,
                reagent: this.current.reagent,
                assay: this.current.assay,
                enableLeft: currentIndex > 0,
                enableRight: currentIndex < this.otherLots.length - 1
            });
            this.getRegion('otherLot').show(this.otherLotView);

            this.listenTo(this.otherLotView, 'left', this.onLeft);
            this.listenTo(this.otherLotView, 'right', this.onRight);
        },

        onChangeAssay: function (fieldName, model) {
            this.current.assay = model;
            if (model === null) {
                this.current.reagent = null;
            }
            var KitLotPcrService = require('services/caccounts/kitlotpcr');
            this.getRegion('reagent').show(new AutocompleteView(KitLotPcrService.getAutocompleteParam({
                data: _.map(this.model.getReagents(model), function (model) {
                    return model.toJSON();
                }),
                callBackOnChange: _.bind(this.onChangeReagent, this)
            })));
            this.filterCollection();
        },
        onChangeReagent: function (fieldName, model) {
            this.current.reagent = model;
            this.filterCollection();
        },

        filterCollection: function () {
            this.currentLot.filterCollection(this.current.assay, this.current.reagent);
            if (this.enableOtherLot) {
                this.otherLotView.filterCollection(this.current.assay, this.current.reagent);
            }
        },

        onConfirm: function () {
            this.model.save();
        },

        onSave: function () {
            this.triggerMethod('saved');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
        },

        onDuplicateCode: function () {
            this.ui.endUserIdInput.popover('show');
            this.ui.endUserIdInput.addClass('invalid');
            this.ui.endUserIdInput.closest('div[data-field-name=code]').addClass('has-error');
        },
        onTransfer: function () {
            var origin = this.otherLotView.model;
            var target = this.currentLot.model;
            origin.get('assayReagentTargetPcrKitLots').each(function (assayReagentTargetPcrKitLot) {
                var currentAssayReagentTargetPcrKitLot = _.first(target.get('assayReagentTargetPcrKitLots').filter(function (currentARTPKL) {
                    return currentARTPKL.get('refAssayReagent').get('secId') === assayReagentTargetPcrKitLot.get('refAssayReagent').get('secId') &&
                        currentARTPKL.get('refMbAna').get('secId') === assayReagentTargetPcrKitLot.get('refMbAna').get('secId');
                }));
                if (currentAssayReagentTargetPcrKitLot) {
                    var data = {
                        val: assayReagentTargetPcrKitLot.get('val'),
                        deltaWarning: assayReagentTargetPcrKitLot.get('deltaWarning'),
                        deltaError: assayReagentTargetPcrKitLot.get('deltaError'),
                        refMbAnaRes: assayReagentTargetPcrKitLot.get('refMbAnaRes')
                    };
                    currentAssayReagentTargetPcrKitLot.set(data, {silent: true});
                }
            });
            this.model.trigger('change');
        },

        onLeft: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            if (currentIndex > 0) {
                this.showOtherLot(this.otherLots.at(currentIndex - 1));
            }
        },

        onRight: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            if (currentIndex < this.otherLots.length) {
                this.showOtherLot(this.otherLots.at(currentIndex + 1));
            }
        }
    });
});

