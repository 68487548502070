define('dynamicCreateEditText',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template(''),
        className: 'form-control js-info-input inputBackground',
        tagName: 'input',
        attributes: function () {
            var attr = {
                placeholder: '',
                type: 'text',
                'data-field-name': this.options.field,
                autocomplete: 'off',
                value: this.model.get(this.options.field)
            };
            if (this.options.readOnly || (this.options.display && this.options.display.readOnly)) {
                attr.readOnly = true;
            }
            if (this.options.display && this.options.display.attributes) {
                // add array attributes to attr
                _.each(this.options.display.attributes, function (attribute) {
                    _.keys(attribute).forEach(function (key) {
                        attr[key] = attribute[key];
                    });
                });
            }
            return attr;
        },

        initialize: function () {
            this.listenTo(this.model, 'change:' + this.options.field, _.bind(this.render, this));
        },
        serializeData: function () {
            return {};
        }
    });
});

