define('dynamicListItemView',['module',
        'backbone.marionette',
        'underscore',
        'jquery',
        'backbone',
        'dynamicListItemBoolean',
        'dynamicListItemColor',
        'dynamicListItemDate',
        'dynamicListItemDateTime',
        'dynamicListItemLabel',
        'dynamicListItemNumber',
        'dynamicListItemPopup',
        'dynamicListItemTextArea',
        'dynamicListItemRadio',
        'arrayCustomFromTypeView',
        'dynamicListItemText',
        'dynamicListItemAutocomplete',
        'dynamicListItemCodeListView',
        'arrayInputView',
        'dynamicListItemSelect',
        'dynamicCreateEditCustomLisConfiguration',
        'dynamicCreateEditCustomAssayConfigurationResultFromAssay',
        'dynamicListItemButton',
        'dynamicListItemEmpty',
        'dynamicCreateEditCustomListToList'
    ],
    function (module,
              Marionette,
              _,
              $,
              Backbone,
              BooleanView,
              ColorView,
              DateView,
              DateTimeView,
              LabelView,
              NumberView,
              PopupView,
              TextAreaView,
              RadioView,
              TypeParamView,
              TextView,
              AutocompleteView,
              CodeListView,
              ArrayListView,
              DynamicCreateEditSelect,
              DynamicCreateEditCustomLisConfiguration,
              DynamicCreateEditCustomAssayConfigurationResultFromAssay,
              ButtonView,
              EmptyView,
              DynamicCreateEditCustomListToList
    ) {
        'use strict';

        module.exports = Marionette.CollectionView.extend({
            className: 'col-w-all col-w-100 d-f p-l-5 p-r-5 m-b-3',

            initialize: function () {
                _.each(this.options.dynamicConfigurationField.service.getCreateEditLine(this.model), _.bind(function (f) {
                    if (f.param.defaultValue) {
                        this.model.set(f.field, f.param.defaultValue(this.model));
                    }
                }, this));
            },

            collectionEvents: {
                'renderModel': 'testRender'
            },

            testRender: function (model, dynamicConfigurationField) {
                this.options.updateCollection(model, dynamicConfigurationField);
                this.render();
            },

            serializeData: function () {
                var templateData = {};
                // templateData.model = this.model.toJSON();
                // var entity = this.model.getEntity();
                // templateData.name = entity.getName();
                // templateData.fields = _.clone(entity.getCreateEditLine(this.model));
                // _.each(templateData.fields, _.bind(function (field) {
                //     var param;
                //     if (_.isArray(field.param)) {
                //         param = _.first(_.filter(field.param, _.bind(function (param) {
                //             return this.model.get(param.fieldNameKey) === param.fieldNameValue;
                //         }, this)));
                //     } else {
                //         param = field.param;
                //     }
                //     if (!param) {
                //         param = field.defaultParam;
                //     }
                //     field.param = param;
                //     if (field.param.type === 'POPUP') {
                //         field.param.display = field.param && field.param.display ? field.param.display : {};
                //         field.param.display.css = (field.param.display.css ? field.param.display.css : '') + 'display: flex; justify-content: center;';
                //     }
                //     field.param.display.css = field.param && field.param.display && field.param.display.css ? field.param.display.css : '';
                //     if (field.param.type === 'BOOLEAN') {
                //         field.param.display.css += 'display: flex; align-items: center; justify-content: center;';
                //     }
                //     field.param.display['class'] = field.param && field.param.display && field.param.display['class'] ? field.param.display['class'] : '';
                //     field.fieldName = field.field.replaceAll('.', '-');
                // }, this));
                // templateData.config = entity.getConfigCreateEditLine();


                return templateData;
            },

            // getModel: function (model, fields) {
            //     if (fields.length === 1) {
            //         return model;
            //     }
            //     var firstField = fields.shift();
            //     if (model instanceof Backbone.Model) {
            //         model = model.attributes[firstField];
            //     } else if (_.isObject(model)) {
            //         model = model[firstField];
            //     } else {
            //         return model;
            //     }
            //     return this.getModel(model, fields);
            // },
            childViewOptions: function (model) {
                if (model.get('button')) {
                    return {
                        model: this.options.model,
                        field: model,
                        callBackOnClick: _.bind(this.onDelete, this, this.options.model),
                        dynamicConfigurationField: this.options.dynamicConfigurationField
                    };
                }

                switch (model.get('param').type) {
                    case 'TEXT':
                    case 'LABEL':
                    case 'POPUP':
                    case 'DATETIME':
                    case 'DATE':
                    case 'ARRAYLIST':
                    case 'BOOLEAN':
                    case 'COLOR':
                    case 'NUMBER':
                    case 'RADIO':
                    case 'TEXTAREA':
                    case 'REFERENCE':
                    case 'SELECT':
                    case 'USERCODELIST':
                    case 'CODELIST':
                    case 'EMPTY':
                        return {
                            model: this.options.model,
                            field: model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField,
                            service: this.options.dynamicConfigurationField.service
                        };
                    case 'LISTTOLIST':
                        return {
                            model: this.options.model,
                            field: model.get('field'),
                            display: model.get('param').display,
                            items: {items: model.get('param').items},
                            service: this.options.dynamicConfigurationField.service
                        };
                    case 'LISCONFIGURATION':
                        return {
                            'field': model.get('field'),
                            'model': this.model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField
                        };
                    case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                        return {
                            'field': model.get('field'),
                            'model': this.model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField
                        };
                    case 'TYPEPARAM':
                        return {
                            fieldName: 'parameter',
                            fieldNameCodePrefix: 'DM_',
                            'fieldNameParam': model.get('field'),
                            'model': this.model,
                            service: this.options.dynamicConfigurationField.service
                        };
                }
            },
            childView: function (model) {
                if (model.get('button')) {
                    return ButtonView;
                }

                switch (model.get('param').type) {
                    case 'TEXT':
                        return TextView;
                    case 'LABEL':
                        return LabelView;
                    case 'POPUP':
                        return PopupView;
                    case 'REFERENCE':
                        return AutocompleteView;
                    case 'SELECT':
                        return DynamicCreateEditSelect;
                    case 'CODELIST':
                    case 'USERCODELIST':
                        return CodeListView;
                    case 'DATE':
                        return DateView;
                    case 'DATETIME':
                        return DateTimeView;
                    case 'ARRAYLIST':
                        return ArrayListView;
                    case 'LISCONFIGURATION':
                        return DynamicCreateEditCustomLisConfiguration;
                    case 'COLOR':
                        return ColorView;
                    case 'NUMBER':
                        return NumberView;
                    case 'BOOLEAN':
                        return BooleanView;
                    case 'TEXTAREA':
                        return TextAreaView;
                    case 'RADIO':
                        return RadioView;
                    case 'TYPEPARAM':
                        return TypeParamView;
                    case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                        return DynamicCreateEditCustomAssayConfigurationResultFromAssay;
                    case 'EMPTY':
                        return EmptyView;
                    case 'LISTTOLIST':
                        return DynamicCreateEditCustomListToList;
                    default:
                        throw new Error('[DYNAMIC] no implementation for "' + model.get('param').type + '"');
                }
            },

            childViewEvents: {
                'item:change': function (view, e) {
                    view.onChange(e.currentTarget.attributes['data-field-name'].value, e.target.value);
                }
            },

            // _displayRegion: function (field2, clearValue, regionFromChange) {
            //     var field = _.findWhere(this.model.getEntity().getCreateEditLine(this.model), {'field': field2.field});
            //     var regionName = '.js-itemView-' + field.field.replaceAll('.', '-');
            //     var region;
            //     if (!regionFromChange) {
            //         region = this.addRegion('itemView-' + field.field, regionName);
            //     } else {
            //         region = regionFromChange;
            //     }
            //
            //
            //     var param;
            //     if (_.isArray(field.param)) {
            //         param = _.first(_.filter(field.param, _.bind(function (param) {
            //             return this.model.get(param.fieldNameKey) === param.fieldNameValue;
            //         }, this)));
            //     } else {
            //         param = field.param;
            //     }
            //     if (!param) {
            //         param = field.defaultParam;
            //     }
            //     // clone l'objet pour ne pas modifier le param de base
            //     field = _.clone(field);
            //     field.param = param;
            //
            //     if (field.dependsOn && !field.dependsOn.disableEvent) {
            //         if (_.isArray(field.dependsOn.field)) {
            //             _.each(field.dependsOn.field, _.bind(function (fieldDepends) {
            //                 this.listenTo(field.dependsOn.model, 'change:' + fieldDepends, _.bind(this._displayRegion, this, field2, field.dependsOn.clearValue !== undefined ? field.dependsOn.clearValue : true, region));
            //             }, this));
            //         } else {
            //             this.listenTo(field.dependsOn.model, 'change:' + field.dependsOn.field, _.bind(this._displayRegion, this, field2, field.dependsOn.clearValue !== undefined ? field.dependsOn.clearValue : true, region));
            //         }
            //         if ((!this.model.get(field.dependsOn.field) && !field.dependsOn.allowEmpty) || (this.model.get(field.dependsOn.field) instanceof Backbone.Collection && this.model.get(field.dependsOn.field).isEmpty() && !field.dependsOn.allowEmpty)) {
            //             region.show(new Marionette.View({template: _.template('')}));
            //             return;
            //         }
            //         if (field.dependsOn.enable) {
            //             if (!field.dependsOn.enable(this.model)) {
            //                 region.show(new Marionette.View({template: _.template('')}));
            //                 return;
            //             }
            //         }
            //         if (field.dependsOn.readOnly) {
            //             if (!field.param.display) {
            //                 field.param.display = {};
            //             }
            //             field.param.display.readOnly = field.dependsOn.readOnly(this.model);
            //         }
            //     }
            //
            //     if (field.param && field.param.display && field.param.display.condition) {
            //         if (this.model.get(field.param.display.condition.key) !== field.param.display.condition.value) {
            //             region.show(new Marionette.View({template: _.template('')}));
            //             return;
            //         }
            //     }
            //
            //     if (clearValue) {
            //         var data = {};
            //         data[field.field] = null;
            //         this.model.set(data, {silent: true});
            //     }
            //     var model = this.model;
            //     if (field.field.includes('.')) {
            //         var fields = field.field.split('.');
            //         field.field = _.last(fields);
            //         model = this.getModel(model, fields);
            //     }
            //     var view;
            //     if (!view) {
            //         new Error('[DYNAMIC] no implementation for "' + field.param.type + '"');
            //     }
            //     if (field && field.param && field.param.onChange && field.param.type !== 'REFERENCE') {
            //         // reference go to onAutocompleteChange
            //         if (!view.onChange) {
            //             this.onChange = _.bind(function (p1, p2) {
            //                 field.param.onChange(p1, p2, this.model);
            //             }, this);
            //         } else {
            //             view.onChange = _.bind(function (p1, p2) {
            //                 field.param.onChange(p1, p2, this.model);
            //             }, this);
            //         }
            //     }
            //     if (!field.dependsOn || field.dependsOn.disableEvent) {
            //         this.listenTo(this.model, 'change:' + field.field, _.bind(this._displayRegion, this, field2, false, region));
            //     }
            //     region.show(view);
            // },
            // onRender: function () {
            //     _.filter(this.model.getEntity().getCreateEditLine(this.model), _.bind(function (field) {
            //         if (field.param && field.param.needExist && this.model.isNew()) {
            //             return;
            //         }
            //         this._displayRegion(field);
            //     }, this));
            //
            //     _.filter(this.model.getEntity().getCreateEditLine(this.model), _.bind(function (field) {
            //         _.each(field.param.events, _.bind(function (event, name) {
            //             switch (name) {
            //                 case 'change':
            //                     this.listenTo(this.model, 'change', _.bind(event, this, this.model));
            //                     break;
            //             }
            //         }, this));
            //     }, this));
            //
            // },
            onDelete: function (model) {
                model.collection.remove(model);
            },
            onChange: function (fieldName, value) {
                var data = {};
                data[fieldName] = value;
                this.options.model.set(data, {silent: true});
                this.options.model.trigger('change:' + fieldName);
            }
        });
    });

