define('arrayCustomAssayResultView',[
    'module',
    'dialogFormView',
    'arrayEncodeEditLayoutController',
    'template!arrayCustomAssayResultView',
    'backbone'
], function (
    module,
    DialogFormView,
    ArrayEncodeEditLayoutController,
    Tpl,
    Backbone
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            parameters: '.js-params'
        },

        attributes: function () {
            return {
                style: 'position: relative; padding-left: 98px;'
            };
        },
        events: {
            'click': 'onShowParameters'
        },
        modelEvents: {
            'change:type': 'updateModel',
            'change:param': 'updateModel'
        },
        initialize: function () {
            this.model = new Backbone.Model(this.model.get(this.options.fieldName));
        },
        serializeData: function () {
            return {param: this.model.get('param')};
        },
        onShowParameters: function (e) {
            ArrayEncodeEditLayoutController.showPopup(this.model, 'param', this.options.model.get(this.options.fieldName).param, this.options.fieldNameCode, e, false, this.options.model);
        },
        onRender: function () {
            ArrayEncodeEditLayoutController.generateToolTip(this.$el, this.options.fieldNameCode, this.options.model.get(this.options.fieldName).param);
        },
        disabled: function () {
            this.ui.parameters.val(null);
            this.ui.parameters.attr('disabled', 'disabled');
        },
        updateModel: function () {
            var data = {};
            data[this.options.fieldName] = this.model.toJSON();
            this.options.model.set(data, {silent: true});
            this.options.model.trigger('change:' + this.options.fieldName);
        }
    });
});
