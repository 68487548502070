define('prepWellView',[
    'module',
    'backbone.marionette',
    'backbone',
    'wellUtils',
    'template!prepRunWellTpl',
    'template!prepRunWellMultiDisplayTpl',
    'underscore',
    'jquery',
    'prepRunDisplayKitsLotsView',
    'qcChartPopup',
    'wellDisplaySampleView',
    'wellSmpTypeView'
], function (
    module,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    wellMultiDisplayTpl,
    _,
    $,
    PrepRunDisplayKitsLotsView,
    QcView,
    WellDisplaySampleView,
    WellDisplayTypeView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        ui: {
            elementRight: '.elementRight',
            selectableItem: '.wellSelectableItem',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'
        },

        regions: {
            wellDisplaySample: '.js-well-sample',
            wellDisplayType: '.js-well-type'
        },

        className: 'cell-well prep',

        events: {
            'click .js-btn-kitLot': 'onKitLotClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .pool-label': 'onClickPool',
            'click .js-btn-comment': 'onClickComment',
            'click .js-btn-sample': 'onClickSample',
            'click .repeatStatus': 'onClickSample',
            'click .mdi-alert-octagram': 'onClickError'
        },

        modelEvents: {
            'change': 'render',
            'change:smpId': 'render'
        },

        attributes: function () {
            return {
                style: 'background-color: ' + this.options.backgroundColor
            };
        },

        onRender: function () {
            var params = {
                target: this.$el,
                model: this.model
            };

            WellUtils.showPrepWellPopover(params);

            var shortted = !this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P';

            var view = new WellDisplaySampleView({
                model: this.model,
                shortted: shortted,
                plateView: true
            });
            this.getRegion('wellDisplaySample').show(view);

            var viewSampleType = new WellDisplayTypeView({
                smpType: this.model.get('smpType')
            });
            this.getRegion('wellDisplayType').show(viewSampleType);

            if (this.error) {
                this.$el.addClass('has-error');
            }
            if (this.model.get('smpId').get('verified') === 'IN_PROGRESS') {
                this.$el.addClass('watermark-progress');
            }
        },

        getTemplate: function () {
            if (this.options.isMultiDisplay) {
                return wellMultiDisplayTpl;
            } else {
                return wellTpl;
            }
        },

        serializeData: function () {
            this.error = this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' ||
                this.model.get('smpId').get('entityError') ||
                (_.isEmpty(this.model.get('assayStatus')) && !_.isEmpty(this.model.get('smpId').get('code')));
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    repeatStatus: this.model.get('repeatStatus'),
                    pooled: this.model.get('pooled'),
                    repeatPcr: this.model.get('repeatPcr')
                }
            };
            if (!this.options.editMode && this.model.get('smpId').get('name') && this.model.get('smpType') !== 'P') {
                templateData.wellSmpIdToDisplay = this.model.get('smpId').get('shortId');
            } else {
                templateData.wellSmpIdToDisplay = this.model.get('smpId').get('name');
            }

            templateData.colorRepeats = '#ffcc00';
            if (this.model.get('run').get('settings') && this.model.get('run').get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.model.get('run').get('settings')['OTHER-ASSAY-IND'].split(';');
                if (otherAssayInd[2]) {
                    templateData.colorRepeats = otherAssayInd[2];
                }
            }

            templateData.firstOfAssay = this.options.firstOfAssay;

            templateData.runStatus = this.model.get('run').get('status');


            templateData.pooled = !this.model.get('wellsPool').isEmpty();
            templateData.pool = !this.model.get('wellsPooled').isEmpty();

            templateData.refPcrKits = [];
            templateData.pcrKitsLots = [];
            _.each(this.model.get('kitLotByKits'), _.bind(function (item) {
                if (item.refPcrKit && item.refPcrKit.code)
                    templateData.refPcrKits.push(item.refPcrKit.code);
                _.each(item.pcrKitLots, _.bind(function (pcrKitLot) {
                    templateData.pcrKitsLots.push(pcrKitLot.code);
                }, this));
            }, this));

            templateData.refPcrKits = templateData.refPcrKits.join(';');
            templateData.pcrKitsLots = templateData.pcrKitsLots.join(';');

            templateData.assaysToDisplay = WellUtils.getFormattedAssayStatus(this.model, 'well');

            templateData.error = this.error;
            templateData.hasComment = this.model.get('smpId').get('comment') || this.model.get('lisComment');
            return templateData;
        },

        onClickPool: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.$el.trigger('mouseleave');
            console.info('%c findDissolved does not exists in prepWell', 'color: #00f8ff');
        },

        onClickSample: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['samplesController'], _.bind(function (Controller) {
                this.model.get('smpId').fetch().done(_.bind(function () {
                    Controller.showDetails({model: this.model.get('smpId')});
                }, this)).always(_.bind(function () {
                    $('.empty-page-loader').hide();
                    this.$el.trigger('mouseleave');
                }, this));
            }, this));
        },

        onClickError: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['errorController'], _.bind(function (ErrorController) {
                ErrorController.showE(this.model, this.model.get('entityError'));
            }, this));
        },

        onClickComment: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['sampleCommentView'], _.bind(function (EditSampleCommentView) {
                this.model.get('smpId').fetch().done(_.bind(function () {
                    this.editSampleCommentView = new EditSampleCommentView({
                        wellModel: this.model,
                        model: this.model.get('smpId')
                    });
                    this.editSampleCommentView.show({
                        title: _.i18n('edit.sampleComment'),
                        className: ''
                    });
                }, this));
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.empty-page-loader').show();
            if (this.model.get('smpType') !== 'PC' &&
                this.model.get('smpType') !== 'NC' &&
                this.model.get('smpType') !== 'RC' &&
                this.model.get('smpType') !== 'OC') {

                require(['samplesController'], _.bind(function (Controller) {
                    this.model.get('smpId').fetch().done(_.bind(function () {
                        Controller.showDetails({model: this.model.get('smpId')});
                    }, this)).always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }, this));

            } else {
                if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('secId')) {
                    return;
                }
                this.model.getControlKitLot().done(_.bind(function (controlKits) {
                    this.qcChartPopupLayoutView = new QcView({
                        wellModel: this.model,
                        qcTargetModel: null,
                        collection: new Backbone.Collection(controlKits),
                        settings: this.model.get('settings')
                    });
                    this.qcChartPopupLayoutView.show({
                        title: _.i18n('kitlot:qc:chart:popup'),
                        className: 'runQcChartViewPopup'
                    });
                }, this))
                    .always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
            }
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();

            this.model.get('run').getKitLots().done(_.bind(function (prepRunKitLot) {
                var kitLotView = new PrepRunDisplayKitsLotsView({
                    model: this.model,
                    extractionKitLots: prepRunKitLot.extractionKitLots
                });

                kitLotView.show({
                    title: _.i18n('prep.run') + '&nbsp;&nbsp;&nbsp;' + this.model.get('run').get('name') + '&nbsp;&nbsp;&nbsp;' + _.i18n('prep.run.well.kitlot'),
                    className: 'editPrepRunWellComment'
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        }
    });
});

