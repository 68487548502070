// define([
//   'module',
//   'underscore',
//   'rolesMixin'
// ], function(
//   module,
//   _,
//   RolesMixin
// ) {
//   'use strict';
//
//   module.exports = [
//     {
//       name: 'table',
//       title: _.i18n('runs.table.tabTitle')
//     },
//     {
//       name: 'details',
//       title: _.i18n('runs.details.tabTitle')
//     },
//     {
//       name: 'assays-targets',
//       title: _.i18n('runs.assays.targets.tabTitle'),
//       displayOnPooled: false
//     },
//     {
//       name: 'results',
//       title: _.i18n('runs.results.tabTitle'),
//       displayOnPooled: false
//     }
//   ];
// });
define("runTabsData", function(){});

