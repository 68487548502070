
define('template!routingCommonSampleDetailView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-4">\n    <div class="row">\n        <label class="col-xs-4 text-right p-l-0">' +
((__t = ( _.i18n('scan.sample.current') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-7 p-l-0 p-r-0">\n            <input class="js-current-sample-input form-control inputBackground" type="text"\n                   disabled="disabled" value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'"/>\n        </div>\n        <span class="mdi mdi-eye clickable js-show-sample"\n              title="' +
((__t = ( _.i18n('sample.show') )) == null ? '' : __t) +
'" style="display: none; font-size: 28px; line-height: 28px;"></span>\n    </div>\n    <div class="row">\n        <label class="col-xs-4 text-right p-l-0">' +
((__t = ( _.i18n('sample.lis') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8 p-l-0 p-r-0">\n            <input class="form-control inputBackground" type="text" disabled="disabled"\n                   value="' +
((__t = ( lisCode )) == null ? '' : __t) +
'"/>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-4 text-right p-l-0">' +
((__t = ( _.i18n('sample.order') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8 p-l-0 p-r-0">\n            <input class="form-control inputBackground" type="text" disabled="disabled"\n                   value="' +
((__t = ( orderCode )) == null ? '' : __t) +
'"/>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-4 text-right p-l-0">' +
((__t = ( _.i18n('sample.biogroup') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8 p-l-0 p-r-0">\n            <input class="form-control inputBackground" type="text" disabled="disabled"\n                   value="' +
((__t = ( bioGroupCode )) == null ? '' : __t) +
'"/>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-4 text-right p-l-0">' +
((__t = ( _.i18n('sample.specie') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8 p-l-0 p-r-0">\n            <input class="form-control inputBackground" type="text" disabled="disabled"\n                   value="' +
((__t = ( specieCode )) == null ? '' : __t) +
'"/>\n        </div>\n    </div>\n</div>\n<div class="col-xs-8">\n    <div class="row">\n        <label class="col-xs-3 text-right p-l-0">' +
((__t = ( _.i18n('sample.patient') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 p-l-0 p-r-0">\n            <textarea class="form-control inputBackground" disabled="disabled" rows="1">' +
((__t = ( patient )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-3 text-right p-l-0">' +
((__t = ( _.i18n('sample.lisana') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 p-l-0 p-r-0">\n            <textarea class="form-control inputBackground" disabled="disabled" rows="1">' +
((__t = ( lisAnas )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-3 text-right p-l-0">' +
((__t = ( _.i18n('sample.targets') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 p-l-0 p-r-0">\n            <textarea class="form-control inputBackground" disabled="disabled" rows="1">' +
((__t = ( targets )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n    <div class="row">\n        <label class="col-xs-3 text-right p-l-0">' +
((__t = ( _.i18n('sample.assayGroup') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 p-l-0 p-r-0">\n            <textarea class="form-control inputBackground" disabled="disabled" rows="1">' +
((__t = ( assayGroupCode )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

