define('prepRunCollectionView',[
    'module',
    'backbone',
    'backbone.marionette',
    'linkNewTabView'
], function (
    module,
    Backbone,
    Marionette,
    View
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        tagName: 'div',
        attributes: function () {
            return {
                id: 'prepRunFromPcrRun',
                style: 'border: solid 1px #333; border-radius: 5px; height: 34px; overflow: auto; white-space: normal; font-size: 12px; padding-left: 2px;'
            };
        },
        className: 'elements-container',

        childView: View,

        childViewOptions: function (model) {
            var PrepRunService = require('services/caccounts/prepruns');
            return PrepRunService.parameterForLink(model, PrepRunService);
        }
    });
});
