define('runRemoteFolderView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'template!runRemoteFolderTpl',
    'underscore',
    'jquery',
    'app',
    'settings'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    chooseUploadLayoutTpl,
    _,
    $,
    App,
    Settings
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: chooseUploadLayoutTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.uploadRuns': 'onUploadRunsPopup',
            'click .js-cancel-popup': 'hide',
            'click .js-confirm': 'onConfirm',
            'click .run-import-remote-folder': 'onImportFromRemoteFolder',
            'change @ui.input': 'onInputChange'
        },

        fromTemplate: true,

        fieldsToValidate: [
            {name: 'file', type: 'required'}
        ],

        initialize: function (initObject) {
            this.files = initObject.files;
            this.runModel = initObject.runModel;
            this.model = new Backbone.Model({
                file: this.files[0]
            });
            this.type = initObject.type;
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.files = this.files;
            return templateData;
        },

        onRender: function () {
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                var url = ''; // ligne pour éviter un problème jshint, à supprimer quand le jshint ignore sera enlevé
                url = Settings.url('rest/import/' + this.type + '/file', {fileName: encodeURIComponent(this.model.get('file'))});
                /* jshint ignore:start */
                fetch(url)
                    .then(_.bind(function (response) {
                        return response.blob();
                    }, this))
                    .then(_.bind(function (blob) {
                        var fd = new File([blob], this.model.get('file'));
                        this.triggerMethod('run:import-xml', fd, false, true);
                        this.hide();
                    }, this));
                /* jshint ignore:end */
            }
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
            if (App.getCurrentRoute() === 'runs/import') {
                App.navigate('runs', {trigger: true});
            }
        }
    });
});

