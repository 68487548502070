define('dynamicCreateEditCustomRoutingActionState',[
	'module',
	'dialogFormView',
	'template!dynamicCreateEditCustomRoutingActionStateTpl',
	'colorUtils'
], function (
	module,
	DialogFormView,
	tpl,
	ColorUtils
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: tpl,

		ui: {
			input: '.js-info-input',
			color: '.js-color'
		},

		events: {
			'change @ui.input': 'onInputChange',
			'change @ui.color': 'onChangeColor',
			'change .js-checkbox': 'onChangeShowPopup'
		},

		serializeData: function () {
			var templateData = {};
			templateData.model = this.model.toJSON();
			templateData.colorHexa = this.model.get('color');
			return templateData;
		},

		onChangeColor: function (e) {
			var colorRGB = ColorUtils.hexToRgba(e.currentTarget.value);
			var color = ColorUtils.rgbToDecimal(colorRGB.r, colorRGB.g, colorRGB.b);
			this.onChange('color', color);
		},

		onChangeShowPopup: function(e) {
			this.onChange('showPopup', e.currentTarget.checked);
		}
	});
});
