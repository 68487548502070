define('tabsContentCollection',[
    'module',
    'backbone.marionette',
    'tabsContentItem'
], function (
    module,
    Marionette,
    TabsContentItem
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: TabsContentItem,
        className: 'tab-content',
        changeActive: function (model) {
            this.children.each(function (child) {
                child.setActive(child.model === model);
            });
        }
    });
});
