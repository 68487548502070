define('dynamicCreateEditInteger',[
	'module',
	'formView',
	'template!dynamicCreateEditIntegerTpl'
], function (
	module,
	FormView,
	tpl
) {
	'use strict';

	module.exports = FormView.extend({
		template: tpl,

		serializeData: function () {
			return this.options;
		}
	});
});
