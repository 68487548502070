define('propertiesView',[
    'module',
    'underscore',
    'dialogFormView',
    'propertiesGridView'
], function (
    module,
    _,
    DialogFormView,
    PropertiesGridView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-jqgrid"></div>'),

        regions: {
            gridRegion: '.js-jqgrid'
        },

        onRender: function () {
            this.view = new PropertiesGridView({
                data: this.options.data
            });
            this.getRegion('gridRegion').show(this.view);
        },

        serializeData: function () {
            return {};
        }
    });
});

