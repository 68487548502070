define('commonCancelUploadView',[
    'module',
    'backbone.marionette',
    'underscore',
    'backbone',
    'template!commonCancelUploadView',
    'fileExplorerController'
], function (
    module,
    Marionette,
    _,
    Backbone,
    Tpl,
    FileExplorerController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            localUpload: '.js-upload',
            remoteUpload: '.js-remote-upload',
            file: '.js-file',
            cancel: '.js-cancel-popup'
        },
        className: 'row enterEditPlateLayout-buttons',

        triggers: {
            'click @ui.cancel': 'cancel:click'
        },

        events: {
            'click @ui.remoteUpload': 'onRemoteUploadClick',
            'click @ui.localUpload': 'onUploadClick',
            'change @ui.file': 'onFileChange'
        },

        attributes: {
            style: 'display: flex; justify-content: center;'
        },

        onRender: function () {
            if (!this.options.settingImport || !this.options.settingImport.params || !this.options.settingImport.params.split(';')[0]) {
                this.ui.remoteUpload.hide();
            }
            if (this.options.multiple) {
                this.ui.file.attr('multiple', 'multiple');
            }
        },
        serializeData: function () {
            return {
                accept: this.options.accept
            };
        },
        enableButtons: function (enable) {
            if (enable) {
                this.ui.localUpload.removeAttr('disabled');
                this.ui.localUpload.removeClass('disabled-grey');
                this.ui.remoteUpload.removeAttr('disabled');
                this.ui.remoteUpload.removeClass('disabled-grey');
            } else {
                this.ui.localUpload.attr('disabled', 'disabled');
                this.ui.localUpload.addClass('disabled-grey');
                this.ui.remoteUpload.attr('disabled', 'disabled');
                this.ui.remoteUpload.addClass('disabled-grey');
            }
        },
        onRemoteUploadClick: function () {
            FileExplorerController.show(this.options.settingImport.secId, true, _.bind(function (e) {
                this.trigger('upload:click', e);
            }, this));
        },
        onFileChange: function (e) {
            if (e && e.target && e.target.files) {
                this.trigger('upload:click', e.target.files);
            }
        },
        onUploadClick: function () {
            this.ui.file.click();
        }
    });
});
