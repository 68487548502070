
define('template!standardCurve', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="js-codeName"></div>\n        <div class="m-b-5">\n            <label class="col-xs-4 text-right" data-field-name="dilution">' +
((__t = ( _.i18n('standardCurve.dilution.name')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1" data-field-name="dilution">\n                <input type="text"\n                       class="form-control js-info-input inputBackground t-a-r ' +
((__t = ( alreadyValues ? 'disabled' : '' )) == null ? '' : __t) +
'"\n                       data-field-name="dilution"\n                       placeholder=""\n                       value="' +
((__t = ( dilution )) == null ? '' : __t) +
'" autocomplete="off" ' +
((__t = ( alreadyValues ? 'readOnly disabled' : '' )) == null ? '' : __t) +
'>\n            </div>\n        </div>\n        <div class="m-b-5">\n            <label class="col-xs-4 text-right">' +
((__t = ( _.i18n('standardCurve.ct.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1" data-field-name="fromCt">\n                <input type="text" class="form-control js-info-input inputBackground t-a-r" data-field-name="fromCt"\n                       placeholder=""\n                       value="' +
((__t = ( fromCt )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n            <div class="col-xs-1" data-field-name="toCt">\n                <input type="text" class="form-control js-info-input inputBackground t-a-r" data-field-name="toCt"\n                       placeholder=""\n                       value="' +
((__t = ( toCt )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n        </div>\n        <div class="m-b-5">\n            <label class="col-xs-4 text-right" for="commentRangeBelow">' +
((__t = ( _.i18n('standardCurve.commentRangeBelow') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5" data-field-name="commentRangeBelow">\n            <textarea id="commentRangeBelow" class="form-control js-info-input inputBackground" data-field-name="commentRangeBelow"\n                      placeholder="" autocomplete="off">' +
((__t = ( commentRangeBelow )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n        <div class="m-b-5">\n            <label class="col-xs-4 text-right" for="commentRangeAbove">' +
((__t = ( _.i18n('standardCurve.commentRangeAbove') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5" data-field-name="commentRangeAbove">\n            <textarea id="commentRangeAbove" class="form-control js-info-input inputBackground" data-field-name="commentRangeAbove"\n                      placeholder="" autocomplete="off">' +
((__t = ( commentRangeAbove )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n        <div class="m-b-5">\n            <label class="col-xs-4 text-right">' +
((__t = ( _.i18n('standardCurve.bioGroups') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8 js-bioGroup-region"></div>\n        </div>\n        <div class="m-b-5">\n            <div class="col-xs-6 js-samples" style="padding-right: 2px;">\n            </div>\n            <div class="col-xs-6" style="padding-left: 2px;">\n                <h2>' +
((__t = ( _.i18n('standardCurve.descriptionKitLot.name') )) == null ? '' : __t) +
'</h2>\n                <textarea class="form-control js-info-input inputBackground" data-field-name="descriptionKitLot">' +
((__t = ( descriptionKitLot )) == null ? '' : __t) +
'</textarea>\n            </div>\n        </div>\n\n        <div class="js-results m-b-5"></div>\n        <div class="js-description"></div>\n        <div class="cancelConfirmRow">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

