
define('template!dynamicCreateEditCustomRoutingActionStateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 p-l-0" data-field-name="colorHexa">\n	<input type="color" class="js-color" data-field-name="colorHexa" value="' +
((__t = ( colorHexa )) == null ? '' : __t) +
'"/>\n</div>\n<div class="col-xs-2 p-r-0">\n	<input type="text" class="form-control js-info-input js-input-fieldCustom"\n		   data-field-name="rackId"\n		   value="' +
((__t = ( model.rackId )) == null ? '' : __t) +
'" autocomplete="off">\n</div>\n<div class="col-xs-1" data-field-name="showPopup">\n	<input type="checkbox" class="js-checkbox" data-field-name="showPopup" ' +
((__t = ( model.showPopup ? 'checked="checked"' : '' )) == null ? '' : __t) +
' title="' +
((__t = (
	_.i18n('routingActionState.showPopup') )) == null ? '' : __t) +
'"/>\n</div>';

}
return __p
};});

