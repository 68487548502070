
define('template!arrayCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 p-0" style="font-weight: bold;"> ' +
((__t = ( _.i18n('array.seq') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-5 p-0" style="font-weight: bold;">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-0" style="font-weight: bold;"> ' +
((__t = ( _.i18n('type') )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-0" style="font-weight: bold;"></div>\n<div class="col-xs-4 p-0" style="font-weight: bold;">' +
((__t = ( _.i18n('common.value') )) == null ? '' : __t) +
'</div>';

}
return __p
};});

