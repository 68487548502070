
define('template!errorItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( className )) == null ? '' : __t) +
' (' +
((__t = ( code )) == null ? '' : __t) +
') : ' +
((__t = ( type )) == null ? '' : __t) +
'<br>\n<div class="' +
((__t = ( region )) == null ? '' : __t) +
'"></div>';

}
return __p
};});

