define('entities/caccounts/biogroups',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lisBioGroups'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.BioGroup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/biogroups',
        fetch: function () {
            this.url = Settings.url('rest/v2/biogroups/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            criterias: [],
            'lisBioGroups': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lisBioGroups',
            relatedModel: 'LisBioGroup',
            collectionType: 'LisBioGroupCollection',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/biogroups/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'BioGroupJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'BioGroup',

        importable: true
    });

    app.ns.BioGroup.role = RolesMixin.BIOGROUP;
    
    app.ns.BioGroupCollection = Backbone.Collection.extend({
        model: app.ns.BioGroup,
        url: Settings.url('rest/v2/biogroups/')
    });
});

