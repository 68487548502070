define('services/caccounts/prepruntracs',[
    'entities/ns',
    'settings',
    'module',
    'entities/caccounts/prepruntracs'
], function (
    app,
    Settings,
    module
) {
    'use strict';


    module.exports = {
        getNamespace: function () {
            return {
                model: app.ns.PrepRunTrac,
                collection: app.ns.PrepRunTracCollection
            };
        },
        getCollectionUrl: function () {
            return Settings.url('rest/v2/runtracs/prep/find');
        },

        getCountURl: function () {
            return 'rest/v2/runtracs/prep/find/rowCount';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.PrepRunTracCollection(modelJson);
        }
    };
});

