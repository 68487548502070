define('dynamicCreateEditCustomListToEntityItem',[
    'module',
    'formView',
    'backbone',
    'underscore'
], function (
    module,
    FormView,
    Backbone,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        className: 'label clickable',
        tagName: 'span',
        events: {
            'click': 'onClick'
        },

        serializeData: function () {
            return {value: this.model.get('code')};
        },
        onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var service = require(this.model.service);
            service.showDetails({model: this.model, service: service, callBackRefresh: _.bind(this.render, this)});
        }
    });
});

