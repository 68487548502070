define('colorCompensationMatriceValueColumnItemManual',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        className: 'rounded-5 t-a-r',
        attributes: function () {
            var style = this.options.result ? 'width: 60px; font-size: 14px;' : 'width: 40px; font-size: 10px;';
            return {style: style + 'background-color: var(--css-theme-image-filter-color-02);'};
        },
        getTemplate: function () {
            return _.template('<%= value %>');
        },
        serializeData: function () {
            return {value: this.model.get('value')};
        }

    });
});
