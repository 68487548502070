define('colorCompensationMatriceNoRun',[
    'module',
    'backbone',
    'dialogFormView',
    'template!colorCompensationMatriceNoRun',
    'savingBehavior',
    'moment',
    'colorCompensationMatriceValueLineCollection',
    'underscore'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    moment,
    LineCollection,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'click .js-confirm': 'onConfirm',
            'click .js-cancel-popup': 'onCancel',
            'click .js-reset': 'onReset'
        },

        regions: {
            'valuesCorrectedRegion': '.js-values-corrected-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            error: 'onSaveError'
        },

        fieldsToValidate: [],

        serializeData: function () {
            var templateData = {};
            templateData.colorCompensationCode = this.model.get('colorCompensation').get('code');
            templateData.colorCompensationName = this.model.get('colorCompensation').get('name');
            templateData.temperature = this.model.get('colorCompensation').get('temperature');
            templateData.createdDate = moment(this.model.get('createdDate')).format('DD-MM-YYYY');
            templateData.lmbCyclerCode = this.model.get('lmbCycler') ? this.model.get('lmbCycler').get('code') : '';
            return templateData;
        },

        onRender: function () {
            var x = this.model.get('values').groupBy(function (model) {
                return model.get('x');
            });
            var collection = new Backbone.Collection();

            _.each(x, _.bind(function (v, indexX) {
                var col;
                var sampleIds = this.model.get('colorCompensation').get('sampleIds').split(';');
                indexX = parseInt(indexX, 10);
                if (indexX === 0) {
                    col = new Backbone.Collection();
                    col.push({x: -1, y: -1, value: '', header: true});
                    _.each(v, _.bind(function (model, indexY) {
                        indexY = parseInt(indexY, 10);
                        col.push({
                            x: -1,
                            y: indexY,
                            value: sampleIds.length > indexY ? sampleIds[indexY] : '',
                            header: true
                        });
                    }, this));
                    collection.push(new Backbone.Model({collection: col}));
                }
                col = new Backbone.Collection();
                _.each(v, function (model, indexY) {
                    indexY = parseInt(indexY, 10);
                    if (indexY === 0) {
                        col.push({
                            x: indexX,
                            y: -1,
                            value: sampleIds.length > indexX ? sampleIds[indexX] : '',
                            header: true
                        });
                    }
                    col.push(model);
                });
                collection.push(new Backbone.Model({collection: col}));
            }, this));

            var gridView = new LineCollection({collection: collection, manual: true, result: true});
            this.getRegion('valuesCorrectedRegion').show(gridView);
        },

        onConfirm: function () {
            this.model.save();
        },

        onSave: function (model) {
            this.model = model;
            this.triggerMethod('saved', model);
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onSaveError: function (model, response) {
            if (response.status === 409) {
                this.onDuplicateCode();
                this.triggerMethod('hide:label');
            }
            this.triggerMethod('enable:cancel:confirm');
        }
    });
});
