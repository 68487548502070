define('prepRunCreateBeadPoolingCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'jquery',
    'template!prepRunCreateBeadPoolingCollection',
    'prepRunCreateBeadPoolingItem'
], function (
    module,
    Backbone,
    Marionette,
    _,
    $,
    Tpl,
    View
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },
        events: {
            'click .js-add': 'onAdd'
        },
        childView: View,
        childViewContainer: '.collection',
        childViewEvents: {
            'item:delete': function (model) {
                this.collection.remove(model);
            }
        },
        onAdd: function () {
            this.collection.push(new Backbone.Model());
        }
    });
});
