/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowController',[
    'module',
    'app',
    'sidebarShowView',
    'menuItemsUtils'
], function (
    module,
    App,
    View,
    MenuItemUtils
) {
    'use strict';
    module.exports = {
        showSidebar: function (settings) {
            var MenuService = require('services/menu');
            var menu = MenuItemUtils.getMenuItems();
            this.menu = MenuService.getModel(menu);
            this.view = new View({controller: this, menu: this.menu, settings: settings});
            App.getView().getRegion('sidebar').show(this.view);
        },

        setActiveNavItem: function (name) {
            this.view.setActive(name);
        }
    };
});

