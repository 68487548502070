define('pcrRunCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!pcrRunCustomJqGridView',
    'runPcrController'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl,
    RunPcrController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-createRun-fromFile-importResult': 'showViewNewRunFromFileImportResult',
            'click .js-createRun-fromFile-plateLayout': 'showViewNewRunFromFilePlateLayout',
            'click .js-createRun-fromFile-sampleList': 'showViewNewRunFromFileSampleList'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        showViewNewRunFromFileImportResult: function () {
            RunPcrController.createFromResultFile();
        },

        showViewNewRunFromFilePlateLayout: function () {
            RunPcrController.createFromPlateLayoutFile();
        },

        showViewNewRunFromFileSampleList: function () {
            RunPcrController.createFromSampleListFile();
        }
    });
});
