/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemTextArea',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<%= value %>'),
        tagName: 'textarea',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            return _.extend(attr, {
                'rows': 1,
                'style': 'resize: vertical;',
                'title': this.model.get(this.options.field.get('field'))
            });
        },
        serializeData: function () {
            var templateData = {};
            var model = this.model;
            var field = this.options.field.get('field');
            if (this.options.field.get('dependsOn')) {
                model = this.options.field.get('dependsOn').model.get(this.options.field.get('dependsOn').field);
                field = field.replace(this.options.field.get('dependsOn').field + '.', '');
            }
            templateData.value = model ? model.get(field) : '';
            return templateData;
        }
    });
});
