define('kitLotActionPcrWellItemView',[
    'module',
    'backbone.marionette',
    'template!kitLotActionPcrWellItemView',
    'pcrKitLotForValidationCompareView',
    'underscore',
    'dynamicCreateEditCustomListToEntity'
], function (
    module,
    Marionette,
    Tpl,
    PcrKitLotForValidationCompareView,
    _,
    DynamicCreateEditCustomListToEntity
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        tagName: 'tr',

        regions: {
            assay: '.js-assay'
        },

        events: {
            'click .js-show-compare': 'onShowCompare'
        },

        serializeData: function () {
            return {
                runCode: this.model.get('run').get('code'),
                refWellPos: this.model.get('wellReference').get('pos'),
                testWellPos: this.model.get('pos'),
                sampleId: this.model.get('smpId').get('code')
            };
        },

        onShowCompare: function () {
            this.model.get('wellReference').fetch().done(_.bind(function () {
                new PcrKitLotForValidationCompareView({
                    model: this.model
                }).show({
                    title: _.i18n('run') + ' ' + this.model.get('run').get('code') + ' - ' + this.model.get('smpId').get('code'),
                    className: 'baseTableEditAddPopup pcrKitLotCompare'
                });
            }, this));
        },

        onRender: function () {
            var service = require('services/caccounts/assay');
            var assays = service.getCollection(this.model.get('refAssay'));
            this.showChildView('assay', new DynamicCreateEditCustomListToEntity({
                'field': '',
                'collection': assays,
                service: service
            }));
        }
    });
});
