
define('template!dynamicCreateEditTabCollectionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<ul class="nav nav-tabs collection">\n</ul>';

}
return __p
};});

