/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowController',[
    'module',
    'underscore',
    'app',
    'backbone',
    'settings',
    'headerShowView',
    'headerPopupView',
    'imageUtils',
    'bootbox'
], function (
    module,
    _,
    App,
    Backbone,
    Settings,
    View,
    PopupView,
    ImageUtils,
    bootbox
) {
    'use strict';

    module.exports = {
        showHeader: function () {
            var SystemService = require('services/system');
            SystemService.getInformation().done(_.bind(function (information) {
                var CAccountService = require('services/global/caccounts');
                CAccountService.getCAccountCount().done(_.bind(function (count) {
                    var view = new View({
                        information: information,
                        multiCaccounts: count > 0,
                        obsolete: Settings.get('obsolete')
                    });

                    if (Settings.get('obsolete')) {
                        bootbox.alert('This version is obsolete.');
                    }

                    this.view = view;
                    view.listenTo(view, 'onLogout', _.bind(this.onLogout, this));
                    view.listenTo(view, 'onShowSidebar', _.bind(this.onShowSidebar, this));
                    view.listenTo(view, 'switchCaccount', _.bind(this.onSwitch, this));
                    view.listenTo(view, 'navigate-to:account-profile', _.bind(this.onAccountProfileShow, this));
                    view.listenTo(view, 'onEdit', _.bind(this.onEditAccount, this));
                    view.listenTo(view, 'changeLanguage', _.bind(this.onChangeLanguage, this));

                    App.off('header:update-caccount');
                    App.on('header:update-caccount', _.bind(this.onUpdateCaccount, this));

                    App.getView().getRegion('header').show(view);
                }, this));
            }, this));
        },

        onLogout: function () {
            Settings.set('currentUser', null);
            Settings.set('currentUserGlobal', null);
            Settings.set('currentCAccount', null);
            if (!Backbone.History.started) {
                Backbone.history.start();
            }
            App.trigger('');
        },

        onShowSidebar: function () {
            this.view.onShowMenu('hide');
            App.trigger('sidebar:switch');
        },

        onAccountProfileShow: function () {
            var caSecId = Settings.get('currentCAccount').secId;
            App.navigate('/caccount/' + caSecId + '/profile', {trigger: true});
        },

        onUpdateCaccount: function () {
            this.view.displayCurrentCaccountInfo();
        },

        onUpdateUserPidInfo: function () {
            this.view.displayCurrentUserPidInfo();
        },

        onSwitch: function (caccount) {
            Settings.clearMemory();
            App.trigger('switchCaccount', caccount);
        },

        onEditAccount: function () {
            if (!this.model ||
                this.model.get('secId') !== Settings.get('currentUserModel').get('secId')) {
                this.model = Settings.get('currentUserModel');
            }

            this.formData = new FormData();
            this.popupView = new PopupView({model: this.model});
            this.popupView.show({
                title: _.i18n('account.edit.popup.title'),
                className: 'headerPopupeditData'
            });

            this.popupView.listenTo(
                this.popupView,
                'onChangeLogo',
                _.bind(this.onChangeLogo, this)
            );
            this.popupView.listenTo(
                this.popupView,
                'onSaveClick',
                _.bind(this.onSaveClick, this)
            );
        },

        onChangeLanguage: function (lang) {
            Settings.set('lang', lang);
            $('.js-global-loader').show();
            window.location.reload();
        },

        onChangeLogo: function () {
            var file = this.popupView.ui.upload[0].files[0];
            this.formData.append('file', file);
            ImageUtils.encodeFileToBase64(file,
                _.bind(this.onPreviewFile, this));
        },

        onPreviewFile: function (base64File) {
            this.popupView.ui.preview
                .attr('style', 'background-image: url(' + base64File + ')');
        },

        onSaveClick: function () {
            var that = this,
                postRequest;

            if (this.validate()) {
                this.formData.append('secId', this.model.get('secId'));
                this.formData.append('firstName', this.model.get('firstName'));
                this.formData.append('lastName', this.model.get('lastName'));
                var UserpidService = require('services/global/userpids');
                postRequest = UserpidService.postData(this.formData);
                postRequest.done(function (data) {
                    Settings.set('currentUserGlobal', data);
                    that.onUpdateUserPidInfo();
                    that.popupView.hide();
                });
            }
        },

        validate: function () {
            var fieldsToValidate = [
                {name: 'firstName', type: 'required'},
                {name: 'lastName', type: 'required'}
            ];

            return this.popupView.validate(fieldsToValidate);
        }
    };
});
