/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemDateTime',[
    'module',
    'underscore',
    'dynamicListItemPropertyView',
    'dateUtils'
], function (
    module,
    _,
    ItemView,
    DateUtils
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            var config = _.extend(attr, {
                'autocomplete': 'off',
                'placeholder': '',
                'type': 'text',
                'value': this.model.get(this.options.field.get('field')) ? DateUtils.toDateTimeFormatString(this.model.get(this.options.field.get('field'))) : ''
            });
            if (this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.minLength) {
                config.minLength = this.options.field.get('param').display.minLength;
            }
            if (this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.maxLength) {
                config.maxLength = this.options.field.get('param').display.maxLength;
            }
            return config;
        }
    });
});
