/**
 * Created by RKL on 26/08/2015.
 */
define('roleMissingView',[
    'module',
    'template!roleMissingView',
    'dialogFormView',
    'roleMissingCollection'
], function (
    module,
    viewTpl,
    DialogFormView,
    RoleMissingCollection
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: viewTpl,
        regions: {
            'collection': '.js-collection'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            var collectionView = new RoleMissingCollection({
                collection: this.model.get('roles')
            });
            this.getRegion('collection').show(collectionView);
        }
    });
});

