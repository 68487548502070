define('kitLotActionPrepWellItemView',[
    'module',
    'backbone.marionette',
    'template!kitLotActionPrepWellItemView',
    'underscore',
    'dynamicCreateEditCustomListToEntity'
], function (
    module,
    Marionette,
    Tpl,
    _,
    DynamicCreateEditCustomListToEntity
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        tagName: 'tr',
        regions: {
            assay: '.js-assay'
        },

        serializeData: function () {
            return {
                runCode: this.model.get('run').get('code'),
                refWellPos: this.model.get('wellReference').get('pos'),
                testWellPos: this.model.get('pos'),
                sampleId: this.model.get('smpId').get('code')
            };
        },
        onRender: function () {
            var service = require('services/caccounts/assay');
            var assays = service.getCollection(_.chain(this.model.get('assayStatus')).map(function (assayStatus) {
                return assayStatus.assay;
            }).flatten().uniq(false, function (assay) {
                return assay.secId;
            }).value());
            this.showChildView('assay', new DynamicCreateEditCustomListToEntity({
                'field': '',
                'collection': assays,
                service: service
            }));
        }
    });
});
