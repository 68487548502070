define('services/caccounts/userGroup',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backbone',
    'entities/caccounts/userGroup'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.UserGroup,
                collection: app.ns.UserGroupCollection
            };
        },
        getName: function () {
            return 'userGroup';
        },
        getUrl: function () {
            return 'rest/v2/userGroups/';
        },
        getDynamicJson: function () {
            return 'UserGroupJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.UserGroupCollection(modelJson);
        },
        getCreateEditFieldCustom: function (model) {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'userpids',
                'param': {
                    'type': 'LIST2ENTITY',
                    convert: function (items) {
                        var collection = new Backbone.Collection();
                        items.map(function (item) {
                            collection.push({code: item.toString()});
                        });
                        return collection;
                    }
                }
            });
            custom.push({
                'field': 'authorityGroups',
                'param': {
                    'type': 'LISTTOLIST'
                }
            });
            custom.push({
                'field': 'authorityGroup',
                force: true,
                'param': {
                    'type': 'POPUP',
                    'display': {
                        'class': 'col-w-5', 'mdi': 'mdi-eye'
                    },
                    'events': {
                        'click': _.bind(function () {
                            var authorityGroupService = require('services/caccounts/authorityGroup');
                            var obj = {
                                secId: model.get(model.idAttribute) + '_USERGROUP'
                            };
                            obj.privileges = model.get('authorityGroups').chain().map(function (authorityGroup) {
                                return authorityGroup.get('privileges');
                            }).flatten().uniq().value();
                            var authorityGroupModel = authorityGroupService.getModel(obj);
                            require(['roleController'], function (Controller) {
                                Controller.showDetails({model: authorityGroupModel, readOnly: true, title: model.get('code') + ' (' + model.get('name') + ')'});
                            });
                        }, this)
                    }
                }
            });
            return custom;
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            switch (fieldName) {
                case 'userpids':
                    var UserpidService = require('services/caccounts/userpids');
                    UserpidService.findAll().done(function (userpids) {
                        defer.resolve({items: userpids});
                    });
                    break;
                case 'authorityGroups':
                    var AuthorityGroupService = require('services/caccounts/authorityGroup');
                    AuthorityGroupService.findAll().done(function (authorityGroups) {
                        defer.resolve({items: authorityGroups});
                    });
                    break;
            }
            return defer.promise();
        }
    }, Dynamic);
})
;

