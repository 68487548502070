
define('template!commonCancelUploadView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<button class="btn popupBtn-cancel cancel-b js-cancel-popup" style="padding: 3px 15px 0 15px;">\n    <span class="mdi mdi-close" style="font-size: 30px;"></span>\n</button>\n<input accept="' +
((__t = ( accept )) == null ? '' : __t) +
'"\n       class="hidden js-file"\n       data-icon="true" id="run-xml-input"\n       type="file"/>\n\n<label class="btn popupBtn-confirm btn-sm js-upload" for="blank"\n       id="importResultFromLocal"\n       style="padding: 0 15px 0 15px;" title="' +
((__t = ( _.i18n('run.import.folder.local') )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-monitor" style="font-size: 30px; color: inherit;"></span>\n</label>\n<input class="hidden js-blank" id="blank" type="text"/>\n<button class="btn popupBtn-confirm btn-sm js-remote-upload"\n        id="importResultFromRemote"\n        style="padding: 0 15px 0 15px;" title="' +
((__t = ( _.i18n('run.import.folder.remote') )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-folder-network" style="font-size: 30px; color: inherit;"></span>\n</button>';

}
return __p
};});

