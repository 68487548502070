define('arrayCustomCurveNoEditView',[
    'module',
    'underscore',
    'dialogFormView',
    'template!arrayCustomCurveNoEditView',
    'backbone',
    'arrayInputView'
], function (
    module,
    _,
    DialogFormView,
    ArrayCustomCurveNoEditTpl,
    Backbone,
    ArrayInputView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: ArrayCustomCurveNoEditTpl,

        regions: {
            input: '.js-input'
        },

        className: 'col-w-all col-w-100 d-f',

        initialize: function () {
            this.model = new Backbone.Model(this.model.get('algoResult'));
            var split = this.model.get('param') ? this.model.get('param').split(';') : [];
            this.model.set('dyeName', null);
            if (split.length > 0) {
                this.model.set('dyeName', split[0]);
            }
            if (split.length > 1) {
                this.model.set('param', _.rest(split).join(';'));
            }
        },
        serializeData: function () {
            return {
                'dyeName': this.model.get('dyeName')
            };
        },
        onRender: function () {
            this.getRegion('input').show(new ArrayInputView({
                model: this.model,
                field: new Backbone.Model({
                    field: 'param',
                    param: {
                        arrayCode: this.model.get('type'),
                        readOnly: true
                    }
                })
            }));
        }
    });
});
