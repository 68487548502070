define('errorCollection',[
    'module',
    'underscore',
    'backbone.marionette',
    'errorItem'
], function (
    module,
    _,
    Marionette,
    ItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
            childView: ItemView,
            tagName: 'ul'
        }
    );
});
