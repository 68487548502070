define('colorCompensationController',[
    'module',
    'underscore',
    'colorCompensationMatrice',
    'colorCompensationMatriceNoRun'
], function (
    module,
    _,
    Matrice,
    MatriceNoRun
) {
    'use strict';

    module.exports = {
        showMatrice: function (model, callBackRefresh) {
            if (!model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView;
            if (model.get('pcrRun')) {
                createEditView = new Matrice({
                    model: model
                });
            } else {
                createEditView = new MatriceNoRun({
                    model: model
                });
            }
            var ColorCompensationMatriceService = require('services/caccounts/colorCompensationMatrices');
            createEditView.show({
                title: _.i18n(ColorCompensationMatriceService.getName() + '.new'),
                className: ColorCompensationMatriceService.getName() + 'sEditAddPopup baseTableEditAddPopup'
            }, _.bind(function () {
                if (callBackRefresh) {
                    callBackRefresh();
                }
            }, callBackRefresh ? callBackRefresh.context : undefined));
        }
    };
});
