
define('template!autocompleteView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="input-wrapper">\n    <input class="btn btn-dropdown autocomplete form-control inputBackground"\n           data-field-name="' +
((__t = ( fieldName )) == null ? '' : __t) +
'"\n           placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'"\n           type="search"\n           value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n    <span class="mdi mdi-filter-variant autocomplete-dropdown js-dropdown-icon clickable"></span>\n</div>\n';
 if(canEdit) { ;
__p += '\n<div class="js-edit-record mdi mdi-pencil clickable" style="font-size:21px; line-height: 21px;" title="' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'"></div>\n';
 } else if(canShow) { ;
__p += '\n<div class="js-show-record mdi mdi-eye clickable" style="font-size:21px; line-height: 21px;" title="' +
((__t = ( _.i18n('common.show') )) == null ? '' : __t) +
'"></div>\n';
 } ;


}
return __p
};});

