
define('template!editSampleResultResultView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f" style="border-bottom: solid 1px #999898;">\n    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0 css-samplePrepWellInfo">\n            ' +
((__t = ( _.i18n('sample.result.target') )) == null ? '' : __t) +
'\n        </div>\n        <div class="noWrapHidden col-w-all col-w-2 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0 css-samplePrepWellInfo">\n            ' +
((__t = ( _.i18n('sample.result.sampleResultDefinition') )) == null ? '' : __t) +
'\n        </div>\n        <div class="noWrapHidden col-w-all col-w-2 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-2 p-t-5 p-b-0 css-samplePrepWellInfo">\n            ' +
((__t = ( _.i18n('sample.result.result') )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-all col-w-5 p-t-5 p-b-0 css-samplePrepWellInfo">\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n        <div class="col-w-all col-w-30 p-t-5 p-b-0 css-samplePrepWellInfo">\n            ' +
((__t = ( _.i18n('well.comment') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>\n\n<div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n    <div class="noWrapHidden col-w-10 p-t-7 p-b-0">\n        ' +
((__t = ( target )) == null ? '' : __t) +
'\n    </div>\n    <div class="noWrapHidden col-w-2 p-t-5 p-b-0"></div>\n    <div class="noWrapHidden col-w-10 p-t-7 p-b-0" >\n        ' +
((__t = ( sampleResultDefinition )) == null ? '' : __t) +
'\n    </div>\n    <div class="noWrapHidden col-w-2 p-t-5 p-b-0"></div>\n    <div class="noWrapHidden col-w-2" style="top: 1px; position: relative;">\n        ' +
((__t = ( iconHtml )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-w-all col-w-5 p-t-5 p-b-0">\n        ';
 if(canEdit) { ;
__p += '\n        <div class="js-mbAnaRes"></div>\n        ';
 } else { ;
__p += '\n        <div class="css-readOnlyField"\n             style="height: 28px; border: solid 1px #c6c6c6; font-size: 18px;">\n            ' +
((__t = ( resCode )) == null ? '' : __t) +
'\n        </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="noWrapHidden col-w-1 p-t-5 p-b-0"></div>\n    <div class="col-w-all col-w-68 p-t-5 p-b-0">\n        <input autocomplete="off" class="' +
((__t = ( canEdit ? '' : 'css-readOnlyField' )) == null ? '' : __t) +
'\n            form-control floating-label js-info-input js-code dataNameIdInput"\n               data-field-name="comment"\n               data-placement="top"\n               placeholder=""\n               style="width: 100%;"\n               type="text" value="' +
((__t = ( comment)) == null ? '' : __t) +
'" ' +
((__t = (!canEdit ? 'readonly' : '' )) == null ? '' : __t) +
'>\n    </div>\n</div>';

}
return __p
};});

