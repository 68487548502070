define('pcrWellGraphCurveView',[
    'module',
    'app',
    'backbone',
    'backbone.marionette',
    'template!pcrWellGraphCurveView',
    'underscore',
    'jquery',
    'dialogFormView',
    'runDetailsGraphView'
], function (
    module,
    App,
    Backbone,
    Marionette,
    wellDetailTpl,
    _,
    $,
    DialogFormView,
    RunGraphView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: wellDetailTpl,

        ui: {
            tabs1: '.tabs',
            firstTabMelt: '.first-tab-melt',
            firstTabAmpl: '.first-tab-ampl',
            tabMelt: '.tab-melt',
            tabAmpl: '.tab-ampl',
            blockLoading: '.empty-page-loader',
            navTabs: '.nav-tabs',
            navTab: '.nav-tab',
            rawDataAmpl: '.rawData.tab-ampl',
            rawDataMelt: '.rawData.tab-melt'
        },

        events: {
            'change .chart-prop-change': 'onShowPropChange',
            'click @ui.navTab': 'onNavTabClick',
            'change .js-colorCompensation-btn': 'onCCchange',
            'click .js-colorCompensation-btn': 'onCCchange',
            'change .js-chartLegend-btn': 'onLegendChange',
            'click .js-chartLegend-btn': 'onLegendChange',
            'change .js-smoothing-btn': 'onRangeSmoothChange',
            'click .js-smoothing-btn': 'onRangeSmoothChange',
            'change .js-rawData-btn': 'onRawDataChange',
            'click .js-rawData-btn': 'onRawDataChange'
        },

        regions: {
            graph: '.js-graph'
        },

        modelEvents: {
            'change': 'renderSafe'
        },

        renderSafe: function () {
            if (!this.isDestroyed()) {
                this.render();
            }
        },

        initialize: function (options) {
            this.withCC = true;
            this.withLegend = true;
            this.pointStyle = false;
            this.readOnly = options.readOnly;
            this.cSelection = options.cSelection;
            this.rangeSmooth = 1;
            this.rawData = 0;
            this.graphToDisplay = this.model.getGraphToDisplay();
        },

        onRender: function () {
            if (this.graphToDisplay.indexOf('M') !== -1) {
                this.showMelt();
            } else {
                this.ui.tabMelt.hide();
            }
            if (this.graphToDisplay.indexOf('A') !== -1) {
                this.showAmpl();
            } else {
                this.ui.tabAmpl.hide();
            }
            if (!this.currentTab || this.currentTab.length > 0) {
                if (this.graphToDisplay.indexOf('M') !== -1) {
                    this.currentTab = this.ui.firstTabMelt;
                } else {
                    this.currentTab = this.ui.firstTabAmpl;
                }
            }
            this.displayCurrentTab();
            this.refreshGraphs();
        },

        onRangeSmoothChange: function () {
            this.rangeSmooth = !this.rangeSmooth;
            this.refreshGraphs();
        },

        onRawDataChange: function () {
            this.rawData = !this.rawData;
            this.render();
        },

        onNavTabClick: function (event) {
            this.currentTab = this.$el.find(event.currentTarget);
            this.refreshGraphs();
        },

        displayCurrentTab: function () {
            this.ui.navTab.removeClass('active');
            this.currentTab.addClass('active');
        },

        onLegendChange: function () {
            this.withLegend = !this.withLegend;
            this.render();
        },

        onCCchange: function () {
            this.withCC = !this.withCC;
            this.render();
        },

        onShowPropChange: function (event) {
            this.pointStyle = event.target.checked ? 'cross' : false;
            this.render();
        },

        serializeData: function () {
            var templateData = {};
            templateData.displayCC = this.model.get('assayVersion') ? !!this.model.get('assayVersion').get('colorCompensation') : false;
            templateData.fromDetailsPopup = this.detailsPopup;
            templateData.hasCurves = this.model.get('curves').length > 0;
            templateData.legendChecked = this.withLegend;
            templateData.rangeSmooth = this.rangeSmooth;
            templateData.rawData = this.rawData;
            templateData.withCC = this.withCC;
            return templateData;
        },

        showMelt: function () {
            this.ui.tabMelt.show();
            if (!this.rawData) {
                this.ui.rawDataMelt.hide();
            }
        },

        showAmpl: function () {
            this.ui.tabAmpl.show();
            if (!this.rawData) {
                this.ui.rawDataAmpl.hide();
            }
        },

        refreshGraphs: function () {
            var view;
            switch (this.currentTab.data('tab')) {
                case 'MC-Raw':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'MELT',
                        rangeSmooth: this.rangeSmooth,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.yLabel = _.i18n('well.graph.legend.temp');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.raw = true;
                    view.displayLegend = this.withLegend;
                    break;
                case 'MC':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'MELT',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.yLabel = _.i18n('well.graph.legend.temp');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'MC-S':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'MELT',
                        rangeSmooth: this.rangeSmooth,
                        smooth: true,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.yLabel = _.i18n('well.graph.legend.temp');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'MC-D1':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'MELT',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.yLabel = _.i18n('well.graph.legend.temp');
                    view.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'MC-D1-S':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'MELT',
                        rangeSmooth: this.rangeSmooth,
                        smooth: true,
                        withoutCC: !this.withCC,
                        hideExtraPoint: !this.rawData
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.yLabel = _.i18n('well.graph.legend.temp');
                    view.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'AC-Raw':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = true;
                    break;
                case 'AC':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    if (this.runAt) {
                        view.baseline = this.runAt.baseline;
                        view.threshold = this.runAt.threshold;
                    }
                    break;
                case 'AC-S':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: true,
                        withoutCC: !this.withCC,
                        hideExtraPoint: !this.rawData
                    });
                    view.model = this.model;
                    view.slopeEnable = false;
                    view.brutEnable = true;
                    view.amplGraph = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.fluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    if (this.runAt) {
                        view.baseline = this.runAt.baseline;
                        view.threshold = this.runAt.threshold;
                    }
                    break;
                case 'AC-D1':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.amplGraph = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'AC-D1-S':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: true,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.deriv1Enable = true;
                    view.amplGraph = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'AC-D2':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: false,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv2Enable = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
                case 'AC-D2-S':
                    view = new RunGraphView({
                        type: 'line',
                        algoType: 'AMP',
                        rangeSmooth: this.rangeSmooth,
                        smooth: true,
                        withoutCC: !this.withCC
                    });
                    view.model = this.model;
                    view.slopeEnable = true;
                    view.brutEnable = false;
                    view.amplGraph = true;
                    view.deriv2Enable = true;
                    view.yLabel = _.i18n('well.graph.legend.cycle');
                    view.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                    view.pointStyle = this.pointStyle;
                    view.displayLegend = this.withLegend;
                    view.raw = false;
                    break;
            }
            this.getRegion('graph').show(view);
        }
    });
});

