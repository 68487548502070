define('entities/caccounts/prepruntracs',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.PrepRunTrac = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepruntracs',
        fetch: function () {
            this.url = Settings.url('rest/v2/runtracs/prep/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'creatDate': null,
            'refUserpid': {'nickname': ''},
            'action': '',
            'level': '',
            'comment': '',
            'wells': [''],
            'refRun': {'name': ''}
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/runtracs/prep/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PrepRunTracJson.pdf');
        }
    });

    app.ns.PrepRunTrac.role = RolesMixin.RUN_PREP;

    app.ns.PrepRunTracCollection = Backbone.Collection.extend({
        model: app.ns.PrepRunTrac
    });
});

