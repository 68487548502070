define('dynamicCreateEditCustomRoles',[
    'module',
    'backbone.marionette',
    'underscore',
    'roleController'
], function (
    module,
    Marionette,
    _,
    RoleController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="roles-container"></div>'),
        regions: {
            roles: '.roles-container'
        },
        onRender: function () {
            RoleController.showDetails({model: this.model, region: this.getRegion('roles')});
        }
    });

});

