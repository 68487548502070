define('assayResultTargetValueVersion',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= type %> : <%= value %>'),
        className: 'col-xs-12',
        serializeData: function () {
            return {
                type: this.model.get('type').replace('QC-', ''),
                value: this.model.get('value') ?
                    this.model.get('value') + (this.model.get('deltaWarning') && this.model.get('deltaError') ?
                        ' +/- ' + this.model.get('deltaWarning') + ';' + this.model.get('deltaError') : '') :
                    '-'
            };
        }
    });
});
