define('services/caccounts/assaygroup',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'template!TooltipRoutingGroupCondition',
    'entities/caccounts/assaygroup'
], function (
    app,
    $,
    _,
    module,
    Dynamic,
    ConditionTooltips
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.AssayGroup,
                collection: app.ns.AssayGroupCollection
            };
        },
        getName: function () {
            return 'assaygroup';
        },
        getUrl: function () {
            return 'rest/v2/assayGroup/';
        },
        getDynamicJson: function () {
            return 'AssayGroupJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('assayGroup.defaultAssay'),
                name: 'defaultAssay',
                formatter: viewDynamic.codeNameFormatter,
                search: false,
                index: 'defaultAssay',
                width: 40
            }, {
                label: _.i18n('assayGroup.assays'),
                name: 'assays',
                formatter: viewDynamic.assayFormatter,
                search: true,
                sortable: false,
                index: 'assays',
                width: 80
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('validCondition'),
                name: 'validCondition',
                formatter: viewDynamic.simpleBooleanFormatter,
                sortable: false,
                search: false,
                width: 10
            }, {
                label: _.i18n('condition'),
                name: 'condition',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 350, 'fields': ['name']};
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({
                    fieldName: 'criterias',
                    values: UserCodeListService.findItemsByCode('FCRIT_GASSAY'),
                    codeListCode: 'FCRIT_GASSAY'
                });
                defer.resolve(resp);
            });
            return defer.promise();
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('validCondition');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {field: 'defaultAssay', param: {display: {'class': 'col-xs-3'}}});
            custom.push(
                {field: 'assays', param: {type: 'LIST2ENTITY'}});
            custom.push(
                {
                    'field': 'condition',
                    'param': {
                        'type': 'TEXTAREA',
                        'display': {tooltip: ConditionTooltips}
                    },
                    'validate': function (model) {
                        return model.validateCondition(btoa(model.get('condition')));
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});

