define('entities/caccounts/extractionDetails',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'backboneRelational',
    'entities/caccounts/extractionMethod',
    'entities/caccounts/extractors',
    'entities/caccounts/lmbextractors',
    'entities/caccounts/kitlotextraction',
    'entities/caccounts/userpids',
    'entities/caccounts/extractionMethodReagent'
], function (
    app,
    Backbone,
    Settings,
    _
) {
    'use strict';

    app.ns.ExtractionDetails = Backbone.RelationalModel.extend({
        service: 'services/caccounts/extractionDetails',
        fetch: function () {
            throw new Error('ExtractionDetails.fetch() is not supported.');
        },
        defaults: {
            secId: '',
            startUserpid: null,
            endUserpid: null,
            startComment: '',
            endComment: '',
            startDate: null,
            endDate: null,
            refExtractor: null,
            refLmbExtractor: null,
            refExtractionMethod: null,
            extractionKitLots: [],
            reagents: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasOne,
            key: 'startUserpid',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'endUserpid',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'refExtractor',
            relatedModel: 'Extractor'
        }, {
            type: Backbone.HasOne,
            key: 'refLmbExtractor',
            relatedModel: 'LmbExtractor'
        }, {
            type: Backbone.HasOne,
            key: 'refExtractionMethod',
            relatedModel: 'ExtractionMethod'
        }, {
            type: Backbone.HasMany,
            key: 'extractionKitLots',
            relatedModel: 'KitLotExtraction',
            collectionType: 'KitLotExtractionCollection'
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'ExtractionMethodReagent',
            collectionType: 'ExtractionMethodReagentCollection'
        }],

        jsonObjectName: 'ExtractionDetailsJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ExtractionDetails',
        
        getExtractionKitLots: function() {
            var extractionKitLots = [];
            if(this.get('refExtractionMethod') === null) {
                return extractionKitLots;
            }
            this.get('reagents').map(_.bind(function (reagent) {
                var lot = this.get('extractionKitLots').chain().filter(function (kitLot) {
                    return kitLot.get('kit') === reagent.get('kit');
                }).first().value();
                extractionKitLots.push({
                    reagent: reagent.get('code'),
                    kitLotCode: lot.get('code'),
                    kitCode: reagent.get('kit').get('code'),
                    volumeByTest: reagent.get('volumeByTest'),
                    volumeByTestUnit: reagent.get('volumeByTestUnit')

                });
            }, this));
            return extractionKitLots;
        }
    });
});

