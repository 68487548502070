define('pcrKitLotForValidationCompareView',[
    'module',
    'dialogFormView',
    'template!pcrKitLotForValidationCompareView',
    'pcrWellResultCollectionView',
    'pcrWellGraphCurveView',
    'underscore'
], function (
    module,
    DialogFormView,
    Tpl,
    PcrWellResultCollectionView,
    PcrWellGraphCurveView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,
        regions: {
            result: '.js-result',
            resultReference: '.js-result-reference',
            graph: '.js-graph',
            graphReference: '.js-graph-reference'
        },

        className: 'js-kitlot-compare',

        serializeData: function () {
            var kitLots = this.model.get('wellReference').get('pcrKitLots').chain().map(_.bind(function (kitLot) {
                var obj = {
                    kitCode: kitLot.get('kit').get('code'),
                    lot: kitLot.get('code')
                };

                obj.reagentCode = this.model.get('assayVersion').get('reagents').chain().filter(function (regeant) {
                    return regeant.get('reagent').get('kit') === kitLot.get('kit');
                }).map(function (regeant) {
                    return regeant.get('reagent').get('code');
                }).value().join(', ');

                obj.currentLot = this.model.get('pcrKitLots').findWhere({
                    kit: kitLot.get('kit')
                }).get('code');
                return obj;
            }, this)).sortBy('kitCode').value();

            return {
                kitLots: kitLots,
                referenceWellPos: this.model.get('wellReference').get('pos'),
                wellPos: this.model.get('pos')
            };
        },

        onRender: function () {
            var wellReference = this.model.get('wellReference');
            this.showChildView('result', new PcrWellResultCollectionView({
                collection: this.model.get('results'),
                readOnly: true
            }));
            this.showChildView('resultReference', new PcrWellResultCollectionView({
                collection: wellReference.get('results'),
                readOnly: true
            }));

            if (!this.model.get('curves').isEmpty()) {
                this.showChildView('graph', new PcrWellGraphCurveView({
                    model: this.model
                }));
            }

            if (!wellReference.get('curves').isEmpty()) {
                this.showChildView('graphReference', new PcrWellGraphCurveView({
                    model: wellReference
                }));
            }
        }
    });
});

