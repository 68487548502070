define('pcrSetupPcrWellItem',[
    'module',
    'backbone.marionette',
    'template!pcrSetupPcrWellItem'
], function (
    module,
    Marionette,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        attributes: function () {
            return {style: 'width: ' + this.options.width + '%;height: ' + this.options.width + '%;'};
        },
        className: 'pcr',
        ui: {
            well: '.well',
            dot: '.dot.assay',
            sampleType: '.sampleType'
        },

        events: {
            'mouseover @ui.well': 'onMouseOver',
            'mouseleave @ui.well': 'onMouseLeave',
            'click @ui.well': 'onClickWell'
        },

        id: function () {
            return this.model.get('secId');
        },

        modelEvents: {
            'change': 'render'
        },

        serializeData: function () {
            var templateData = {};
            templateData.width = this.options.width;
            templateData.pos = this.model.get('pos');
            templateData.containAssay = !!this.model.get('assay');
            templateData.added = '';
            if (this.model.isNew()) {
                templateData.containAssay = true;
                templateData.added = 'added';
            }
            templateData.color = 'background-color: ' + (this.model.get('color') ? this.model.get('color') : 'white');
            templateData.sampleType = this.model.get('sampleType');
            templateData.clickable = this.options.editMode ? 'clickable' : '';
            templateData.temporary = '';
            if (this.model.get('temp')) {
                templateData.containAssay = this.model.get('temp').get('assay');
                templateData.sampleType = this.model.get('temp').get('sampleType');
                templateData.temporary = 'temporary';
            }
            templateData.assay = this.model.get('assay') ? this.model.get('assay').get('code') : '';
            templateData.mdi = '';
            templateData.displayAssayCode = false;
            if (templateData.assay) {
                switch (this.model.get('sampleType')) {
                    case 'PC':
                        templateData.mdi = 'mdi-plus-circle';
                        break;
                    case 'RC':
                        templateData.mdi = 'mdi-alpha-r-circle';
                        break;
                    case 'OC':
                        templateData.mdi = 'mdi-compass';
                        break;
                    case 'NC':
                        templateData.mdi = 'mdi-minus-circle';
                        break;
                    // case 'E':
                    // case 'U':
                    // case 'D':
                    default:
                        templateData.mdi = 'mdi-circle-slice-8';
                        templateData.displayAssayCode = true;
                        break;
                }
            } else {
                switch (this.model.get('sampleType')) {
                    case 'PC':
                        templateData.mdi = 'mdi-plus-circle-outline';
                        break;
                    case 'RC':
                        templateData.mdi = 'mdi-alpha-r-circle-outline';
                        break;
                    case 'OC':
                        templateData.mdi = 'mdi-compass-outline';
                        break;
                    case 'NC':
                        templateData.mdi = 'mdi-minus-circle-outline';
                        break;
                    // case 'E':
                    // case 'U':
                    // case 'D':
                    default:
                        templateData.mdi = 'mdi-circle-outline';
                        break;
                }
            }
            return templateData;
        },
        onMouseOver: function () {
            this.trigger('item:mouseOver', this);
            if (this.model.get('refPrepWell')) {
                this.model.get('refPrepWell').trigger('mouseOver');
            }
        },
        onMouseLeave: function () {
            this.trigger('item:mouseLeave');
            if (this.model.get('refPrepWell')) {
                this.model.get('refPrepWell').trigger('mouseLeave');
            }
        },
        onClickWell: function () {
            this.trigger('item:click');
        },
        editMode: function () {
            this.options.editMode = true;
            this.ui.well.addClass('clickable');
            if (this.model.get('assay') && this.options.assay && this.model.get('assay') === this.options.assay) {
                this.ui.well.addClass('highlighted');
            } else {
                this.ui.well.removeClass('highlighted');
            }
        },
        onRender: function () {
            if (this.options.assay) {
                this.editMode();
            }
        },
        defineTemporary: function (well, available) {
            this.ui.well.removeClass('notAvailable');
            this.ui.well.removeClass('temporary');
            this.model.unset('temp', {silent: true});
            if (!well) {
                return;
            }
            if (available) {
                this.model.set({'temp': well}, {silent: true});
                var sampleType = this.model.get('temp') ? this.model.get('temp').get('sampleType').substring(0, 1) : this.model.get('sampleType').substring(0, 1);
                this.ui.sampleType.text(sampleType);
                if (!!well) {
                    this.ui.well.addClass('temporary');
                }
            } else {
                this.ui.well.addClass('notAvailable');
            }
        },
        validateSelection: function () {
            if (!this.model.get('temp')) {
                return;
            }
            var wellTemp = this.model.get('temp');
            if (!wellTemp) {
                return;
            }
            this.model.set({
                previous: {
                    assay: this.model.get('assay'),
                    sampleType: this.model.get('sampleType'),
                    sample: this.model.get('sample'),
                    refPrepWell: this.model.get('refPrepWell')
                }
            }, {silent: true});
            this.model.unset('temp', {silent: true});
            switch (this.model.get('refRun').get('refPcrSetup').get('mode')) {
                case 'prepWell':
                    this.model.get('refRun').get('refPcrSetup').linkPrepWellToPcrWell(wellTemp, this.model, this.options.assay);
                    if (this.model.get('refPrepWell')) {
                        this.model.get('refPrepWell').trigger('change');
                    }
                    break;
                case 'qualityControl':
                    this.model.get('refRun').get('refPcrSetup').defineQualityControl(wellTemp, this.model);
                    break;
                case 'pcrWell':
                    break;
            }
            return this.model;
        }
    });
});
