define('commonCancelExportView',[
    'module',
    'backbone.marionette',
    'underscore',
    'backbone',
    'template!commonCancelExportView'
], function (
    module,
    Marionette,
    _,
    Backbone,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            localExport: '.js-export',
            remoteExport: '.js-remote-export',
            cancel: '.js-cancel-popup'
        },
        className: 'row enterEditPlateLayout-buttons',

        triggers: {
            'click @ui.cancel': 'cancel:click',
            'click @ui.remoteExport': 'remoteExport:click',
            'click @ui.localExport': 'localExport:click'
        },

        attributes: {
            style: 'display: flex; justify-content: center;'
        },

        onRender: function () {
            if (!this.options.settingImport || !this.options.settingImport.params || !this.options.settingImport.params.split(';')[0]) {
                this.ui.remoteExport.hide();
            }
        },
        serializeData: function () {
            return {};
        },
        enableButtons: function (enable) {
            if (enable) {
                this.ui.localExport.removeAttr('disabled');
                this.ui.localExport.removeClass('disabled-grey');
                this.ui.remoteExport.removeAttr('disabled');
                this.ui.remoteExport.removeClass('disabled-grey');
            } else {
                this.ui.localExport.attr('disabled', 'disabled');
                this.ui.localExport.addClass('disabled-grey');
                this.ui.remoteExport.attr('disabled', 'disabled');
                this.ui.remoteExport.addClass('disabled-grey');
            }
        }
    });
});
