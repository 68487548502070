define('services/caccounts/sampleResultDefinitions',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/sampleResultDefinitions'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.SampleResultDefinition,
                collection: app.ns.SampleResultDefinitionCollection
            };
        },
        getName: function () {
            return 'sampleResultDefinition';
        },
        getUrl: function () {
            return 'rest/v2/sampleResultDefinitions/';
        },
        getDynamicJson: function () {
            return 'SampleResultDefinitionJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sampleResult.algoResult'),
                name: 'algoResult',
                formatter: viewDynamic.typeParamFormatter,
                sortable: false,
                search: false,
                index: 'algoResult',
                width: 40
            }, {
                label: _.i18n('sampleResult.algoExport'),
                name: 'algoExport',
                formatter: viewDynamic.typeParamFormatter,
                sortable: false,
                search: false,
                index: 'algoExport',
                width: 40
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCollection: function (datas) {
            return new app.ns.SampleResultDefinitionCollection(datas);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'algoResult', 'param': {'type': 'TYPEPARAM', codeListCode: 'SR-RESULT'}},
                {'field': 'algoExport', 'param': {'type': 'TYPEPARAM', codeListCode: 'SR-EXPORT'}},
                {
                    'field': 'target',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-xs-3'}
                    }
                }
            );
            return custom;
        }
    }, Dynamic);
});

