
define('template!assayCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '	<div class="js-importFromResult clickable">\n		<span class="mdi mdi-file-chart action header" title="' +
((__t = ( _.i18n('assay.importFromResult') )) == null ? '' : __t) +
'">\n		</span>\n	</div>';

}
return __p
};});

