define('pcrKitLotForValidationView',[
    'module',
    'dialogFormView',
    'backbone.marionette',
    'template!pcrKitLotForValidationView',
    'pcrKitLotForValidationCollection',
    'underscore',
    'backbone',
    'savingBehavior',
    'pcrKitLotForValidationCompareView'
], function (
    module,
    DialogFormView,
    Marionette,
    Tpl,
    PcrKitLotForValidationCollection,
    _,
    Backbone,
    SavingBehavior,
    PcrKitLotForValidationCompareView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,
        regions: {
            pcrKitLotForValidation: '.js-kitlots-region'
        },

        ui: {
            confirm: '.js-confirm',
            cancel: '.js-cancel-popup',
            wellSelected: '.js-well-selected',
            assay: '.js-assay',
            compare: '.js-compare'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'change .js-well-selected': 'onWellSelected',
            'click .js-compare': 'onCompareClick'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.run = this.model.get('run').get('code');
            templateData.pos = this.model.get('pos');
            templateData.secId = this.model.get('secId');
            templateData.sampleId = this.model.get('smpId').get('code');
            templateData.wells = this.model.get('run').get('wells').chain().filter(_.bind(function (well) {
                return well !== this.model && (well.get('refAssay') || (well.get('assayStatus') && !_.isEmpty(well.get('assayStatus'))));
            }, this)).map(function (well) {
                return {secId: well.get('secId'), pos: well.get('pos'), sampleId: well.get('smpId').get('code')};
            }).value();
            return templateData;
        },

        onWellSelected: function () {
            var wellSecId = this.ui.wellSelected.find(':selected').val();
            var well = this.model.get('run').get('wells').chain().filter(function (well) {
                return well.get('secId') === wellSecId;
            }).first().value();
            this._onWelleSelected(well);
        },

        _onWelleSelected: function (well) {
            if (!well) {
                return;
            }
            if (well.get('refAssay')) {
                this.ui.assay.val(well.get('refAssay').get('code'));
            } else {
                var assays = _.chain(well.get('assayStatus')).map(function (assayStatus) {
                    return assayStatus.assay.code;
                }).value().join(', ');
                this.ui.assay.val(assays);
            }
            this.renderLot(well);
        },

        renderLot: function (well) {
            well.findPcrKitLotForValidation().done(_.bind(function (pcrKitLotForValidation) {
                if (_.isEmpty(pcrKitLotForValidation)) {
                    this.showChildView('pcrKitLotForValidation', new Marionette.View({
                        template: _.template(_.i18n('common.noData'))
                    }));
                    this.ui.confirm.hide();
                } else {
                    this.showChildView('pcrKitLotForValidation', new PcrKitLotForValidationCollection({
                        model: this.model,
                        collection: new Backbone.Collection(pcrKitLotForValidation)
                    }));
                    this.ui.confirm.show();
                }
            }, this));
        },

        onRender: function () {
            var well = this.model.get('wellReference');
            if (!well) {
                well = this.model.get('run').get('wells').chain().filter(_.bind(function (well) {
                    return well !== this.model && (well.get('refAssay') || (well.get('assayStatus') && !_.isEmpty(well.get('assayStatus'))));
                }, this)).first().value();
            }
            if (well) {
                this.ui.wellSelected.find('option[value="' + well.get('secId') + '"]').prop('selected', true);
                this._onWelleSelected(well);
            }
            var pcrKitLotService = require('services/caccounts/kitlotpcr');
            this.setPermissions(pcrKitLotService.canWellValidation());
            if (!this.model.get('results')) {
                this.ui.compare.hide();
            } else if (this.model.get('results').isEmpty() || !well || well.get('results').isEmpty()) {
                this.ui.compare.addClass('disabled');
                this.ui.compare.prop('disabled', true);
            }
            if (!this.model.get('wellReference')) {
                this.ui.compare.hide();
            }
        },

        onConfirm: function () {
            var pcrKitLotSecIds = _.chain(this.getChildView('pcrKitLotForValidation').getSelected()).filter(function (pcrKitLot) {
                return pcrKitLot;
            }).uniq().value();
            this.model.changeValidationLot({newLotIds: pcrKitLotSecIds, wellSecId: this.ui.wellSelected.find(':selected').val()}).done(_.bind(function () {
                this.hide();
            }, this));
        },

        onCompareClick: function () {
            this.model.get('wellReference').fetch().done(_.bind(function () {
                this.model.get('wellReference').get('curves').fetch().done(_.bind(function () {
                    new PcrKitLotForValidationCompareView({
                        model: this.model
                    }).show({
                        title: _.i18n('run') + ' ' + this.model.get('run').get('code') + ' - ' + this.model.get('smpId').get('code'),
                        className: 'baseTableEditAddPopup pcrKitLotCompare'
                    });
                }, this));
            }, this));
        }
    });
});

