define('runTabsLayoutController',[
    'module',
    'app',
    'jquery',
    'underscore',
    'runTabsData'
], function (
    module,
    App,
    $,
    _,
    tabs
) {
    'use strict';

    module.exports = {
        tabs: tabs,
        filters: [],
        filtersName: '',
        wellFiltersList: [],
        smpIdSearch: null,
        currentTab: null,

        setCurrentTab: function (currentTab) {
            this.currentTab = currentTab;
        },
        getCurrentTab: function () {
            return this.currentTab;
        },

        getFilteredWellsCollection: function () {
            //------------ utilisation des filtres avec 'ET' logique && entre les groupe de filtre
            this.filteredWellsCollection = [];
            var first = true;
            var filteredArray = [];
            var filteredWellres = [];

            _.each(this.wellFiltersList, _.bind(function (wellFilter) {
                var wellsFromFilter = [];
                if (wellFilter.includes('select-wellres-')) {
                    // utilisation du 'OU' || logique a l'intérieur du groupe wellRes
                    wellsFromFilter = this.getWellResFilteredWell(this._layoutView1.model.get('wells'), [], wellFilter);
                    for (var w in wellsFromFilter) {
                        filteredWellres.push(wellsFromFilter[w]);
                    }
                } else {
                    // utilisation du 'ET  && logique a l'intérieur des autres groupes
                    wellsFromFilter = this.getWellsFromFilter(wellFilter);
                    if (filteredArray) {
                        if (filteredArray.length < 1 && first) {
                            filteredArray = wellsFromFilter;
                            first = false;
                        } else if (filteredArray.length > 0 && wellsFromFilter && wellsFromFilter.length > 0) {
                            filteredArray = filteredArray.filter(function (n) {
                                return wellsFromFilter.indexOf(n) !== -1;
                            });
                        } else if (wellsFromFilter === null) {
                            filteredArray = [];
                        }
                    }
                }
            }, this));


            for (var i in filteredWellres) {
                filteredArray.push(filteredWellres[i]);
            }
            filteredArray = _.uniq(filteredArray);

            if (filteredArray.length < 1 && this.wellFiltersList.length > 0) {
                filteredArray = ['noResult'];
            }

            this.filteredWellsCollection = filteredArray;
            return this.filteredWellsCollection;
        },

        onWellSmpidSearch: function (result) {
            if (result && result !== '') {
                this.smpIdSearch = result;
            } else {
                this.smpIdSearch = null;
            }
        },

        getWellsFromFilter: function (wellFilter) {
            var wells = this._layoutView1.model.get('wells');
            var filteredWells = [];
            switch (wellFilter) {
                case 'select-smpId':
                    filteredWells = this.getSmpidFilteredWell(wells, filteredWells);
                    break;
                case 'select-val1':
                    filteredWells = this.getValStatusFilteredWell(wells, filteredWells, 2.1, 2.2);
                    break;
                case 'select-val2':
                    filteredWells = this.getValStatusFilteredWell(wells, filteredWells, 3.2);
                    break;
                case 'select-val-expert':
                    filteredWells = this.getValStatusFilteredWell(wells, filteredWells, 3.1);
                    break;
                case 'select-warning':
                    filteredWells = this.getWarningFilteredWell(wells, filteredWells);
                    break;
                case 'select-repeat-pcr':
                    filteredWells = this.getRepeatFilteredWell(wells, filteredWells, 'P');
                    break;
                case 'select-repeat-extract':
                    filteredWells = this.getRepeatFilteredWell(wells, filteredWells, 'X');
                    break;
                case 'select-sameAssay':
                    filteredWells = this.getSameAssayFilteredWell(wells, filteredWells);
                    break;
                case 'select-otherAssay':
                    filteredWells = this.getOtherAssayFilteredWell(wells, filteredWells);
                    break;
            }
            // }
            if (filteredWells.length > 0) {
                return filteredWells;
            } else {
                return null;
            }
        },

        getWellResFilteredWell: function (wells, filteredWells, wellFilter) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var found = well.get('results').find(function (result) {
                    if (result.get('sequenceDisplayGroup') > 0 && result.get('result') && result.get('result').get('code') && result.get('result').get('code') === wellFilter.replace('select-wellres-', '')) {
                        return result;
                    }
                });
                if (found) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getSmpidFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            if (this.smpIdSearch !== null) {
                var result = this.smpIdSearch;
                wells.each(_.bind(function (well) {
                    var regex = new RegExp(result, 'i');
                    if (regex.test(well.get('smpId').get('name'))) {
                        filteredWellsToReturn.push(well.get('pos'));
                    }
                }, this));
                return filteredWellsToReturn;
            }
        },

        getOtherAssayFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('smpType') !== 'PC' && well.get('smpType') !== 'NC' &&
                    well.get('smpType') !== 'RC' && well.get('smpType') !== 'OC' &&
                    well.get('existOtherWellOtherAssay')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getSameAssayFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('smpType') !== 'PC' && well.get('smpType') !== 'NC' &&
                    well.get('smpType') !== 'RC' && well.get('smpType') !== 'OC' &&
                    well.get('existOtherWellSameAssay')) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getRepeatFilteredWell: function (wells, filteredWells, type) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                if (well.get('repeatStatus') && well.get('repeatStatus').includes(type)) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getValStatusFilteredWell: function (wells, filteredWells, status, status2) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var found = well.get('results').find(_.bind(function (result) {
                    if ((!status2 && result.get('sequenceDisplay') > 0 && result.get('valSt') === status) ||
                        (status2 && result.get('sequenceDisplay') > 0 && (result.get('valSt') === status || result.get('valSt') === status2))) {
                        return result;
                    }
                }, this));
                if (found) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        getWarningFilteredWell: function (wells, filteredWells) {
            var filteredWellsToReturn = filteredWells;
            wells.each(_.bind(function (well) {
                var found = well.get('results').find(function (result) {
                    if (result.get('codeErr') !== null && result.get('codeErr') !== undefined) {
                        return result;
                    }
                });
                if (found) {
                    filteredWellsToReturn.push(well.get('pos'));
                }
            }, this));
            return filteredWellsToReturn;
        },

        onWellFiltersListChange: function (selectedWellFilter) {
            if (this.wellFiltersList.includes(selectedWellFilter)) {
                var i = this.wellFiltersList.indexOf(selectedWellFilter);
                this.wellFiltersList.splice(i, 1);
            } else {
                this.wellFiltersList.push(selectedWellFilter);
            }
        },

        removeFilterFromList: function (wellFilter) {
            if (this.wellFiltersList.includes(wellFilter)) {
                var i = this.wellFiltersList.indexOf(wellFilter);
                this.wellFiltersList.splice(i, 1);
            }
            if (wellFilter.includes('select-smpId')) {
                this.smpIdSearch = null;
            }
        },

        removeAllFiltersFromList: function () {
            this.wellFiltersList = [];
            this.smpIdSearch = null;
        },

        removeSmpIdSearchFromWellFiltersList: function () {
            this.wellFiltersList = _.filter(this.wellFiltersList,
                function (filter) {
                    if (filter.includes('select-smpId')) {
                        return false;
                    } else {
                        return true;
                    }
                });
            this.smpIdSearch = null;
        },

        removeWellResFromWellFiltersList: function () {
            this.wellFiltersList = _.filter(this.wellFiltersList,
                function (filter) {
                    if (filter.includes('select-wellres-')) {
                        return false;
                    } else {
                        return true;
                    }
                });
        },

        setWellFiltersList: function (wellFiltersList) {
            this.wellFiltersList = wellFiltersList;
        },

        getWellFiltersList: function () {
            return this.wellFiltersList;
        },

        getFilteredWells: function () {
            return this.filteredWellsCollection;
        }
    };
});

