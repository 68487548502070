
define('template!colorCompensationMatrice', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="col-xs-12 form-group">\n            <label class="col-xs-3 control-label p-l-0">' +
((__t = ( _.i18n('colorCompensation.name')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="text"\n                       class="form-control disabled inputBackground"\n                       value="' +
((__t = ( colorCompensationCode )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n            <div class="col-xs-3">\n                <input type="text"\n                       class="form-control disabled inputBackground"\n                       value="' +
((__t = ( colorCompensationName )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n            <label class="col-xs-2 control-label p-l-0">' +
((__t = (
                _.i18n('colorCompensation.temperature.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1">\n                <input type="number"\n                       class="form-control floating-label inputBackground disabled"\n                       value="' +
((__t = ( temperature )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n        </div>\n        <div class="col-xs-12 form-group">\n            <label class="col-xs-3 control-label p-l-0">' +
((__t = ( _.i18n('pcrRun') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-3">\n                <input type="text"\n                       class="form-control floating-label disabled inputBackground"\n                       value="' +
((__t = ( pcrRunCode )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n            <div class="col-xs-2">\n                <input type="text"\n                       class="form-control floating-label disabled inputBackground"\n                       value="' +
((__t = ( createdDate )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n            <label class="col-xs-2 control-label p-l-0">' +
((__t = ( _.i18n('lmbcycler.code') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <input type="text"\n                       class="form-control floating-label disabled inputBackground"\n                       value="' +
((__t = ( lmbCyclerCode )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off" readonly>\n            </div>\n        </div>\n    </div>\n    <h2 class="col-xs-12 centeredText">' +
((__t = ( _.i18n('colorCompensation.values.corrected') )) == null ? '' : __t) +
'</h2>\n    <div class="col-xs-12 js-values-result-region">\n    </div>\n    <div class="col-xs-12 m-t-20">\n        <h2 class="col-xs-6 centeredText">' +
((__t = ( _.i18n('colorCompensation.values.original') )) == null ? '' : __t) +
'</h2>\n        <h2 class="col-xs-6 centeredText">' +
((__t = ( _.i18n('colorCompensation.values.manual') )) == null ? '' : __t) +
'</h2>\n    </div>\n    <div class="col-xs-12">\n        <div class="js-values-region col-xs-6"></div>\n        <div class="js-values-corrected-region col-xs-6"></div>\n    </div>\n    <!-- cancel/confirm row -->\n    <div class="cancelConfirmRow">\n        <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n        <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n    </div>\n\n</div>';

}
return __p
};});

