
define('template!runWellSelectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group js-well-selection-region"></div>\n<div class="js-cancel-action-region"></div>';

}
return __p
};});

