define('exportResultByConfigurationType',['module',
        'backbone.marionette',
        'template!exportResultByConfigurationTypeTpl',
        'dialogFormView',
        'underscore'
    ],
    function (module,
              Marionette,
              ItemViewTpl,
              DialogFormView,
              _
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: ItemViewTpl,

            ui: {
                input: '.js-info-input-child'
            },

            events: {
                'change @ui.input': 'onInputChange'
            },

            className: 'col-xs-12',

            serializeData: function () {
                var templateData = {};
                templateData.configurations = _.filter(this.options.configurations, _.bind(function (configuration) {
                    return configuration.action === this.options.model.get('type');
                }, this));
                templateData.uniqueId = _.pluck(this.options.configurations, 'secId').join('_');
                templateData.currentConfiguration = this.options.model.get('configuration') ? this.options.model.get('configuration').secId : null;
                templateData.action = this.model.get('type');
                return templateData;
            },

            onInputChange: function (e) {
                var value = e.currentTarget.value;
                if (!value) {
                    this.model.set('configuration', null);
                } else {
                    var configuration = _.findWhere(this.options.configurations, {secId: value});
                    this.model.set('configuration', configuration);
                }
            }
        });
    });
