
define('template!caccountsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row col-lg-12">\n    <div class="well caccount-profile col-md-7 ">\n        <div class="row detailed-info-form-container">\n            <div class="form-horizontal col-md-12">\n                <div class="form-inputs-container">\n                    <div class="row">\n                        <!-- LEFT PART -->\n                        <div class="col-md-6">\n                            <div class="form-group" data-field-name="name">\n                                <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('account.profile.identification') )) == null ? '' : __t) +
'</span>\n                            </div>\n                            <div class="row profile-logo-container">\n                                <div class="_texture-bg _keep-img-link profile-img-block">\n                                    <img class="logo-holder clickable" src="data:image;base64,' +
((__t = ( logo )) == null ? '' : __t) +
'">\n                                </div>\n                            </div>\n                            <div class="form-group" data-field-name="code">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( code ? code : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                            <div class="form-group" data-field-name="phone">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( phone ? phone : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                            <div class="form-group" data-field-name="contactEmail">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( contactEmail ? contactEmail : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                        </div>\n                        <!-- RIGHT PART -->\n                        <div class="col-md-6">\n                            <div class="form-group" data-field-name="name">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                            <div class="form-group" data-field-name="address">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( address ? address : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                            <div class="form-group">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">\n                                        <div class="form-group" data-field-name="city">\n                                            <div class="col-xs-2 p-l-0 m-l-15 p-r-0">' +
((__t = ( zip ? zip : '-' )) == null ? '' : __t) +
'</div>\n                                            <span class="col-xs-1">/</span>\n                                            <div class="col-xs-6">' +
((__t = ( city ? city : '-' )) == null ? '' : __t) +
'</div>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="form-group js-countries" data-field-name="countries">\n                                <div class="col-xs-12">\n                                    <div class="col-xs-4 p-l-0">' +
((__t = ( _.i18n('common.country') )) == null ? '' : __t) +
'</div>\n                                    <div class="col-xs-8">' +
((__t = ( country ? country : '-' )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="row">\n                        <div class="pull-right p-r-15">\n                            <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

