define('services/caccounts/pcrreagents',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/pcrreagents'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.PcrReagent,
                collection: app.ns.PcrReagentCollection
            };
        },
        getName: function () {
            return 'pcrreagent';
        },
        getUrl: function () {
            return 'rest/v2/pcr/reagents/';
        },
        getDynamicJson: function () {
            return 'PcrReagentJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.shortName'),
                name: 'shortName',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'shortName',
                width: 80
            }, {
                label: _.i18n('common.city'),
                name: 'type',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'type',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name', 'kit.code', 'kit.name']};
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'type',
                    'param': {'type': 'CODELIST', 'code': 'PCRREAGENT_GROUP', 'display': {'class': 'col-w-8'}}
                },
                {
                    'field': 'code',
                    'param': {'type': 'USERCODELIST', 'code': 'PCR_REAGENT', 'display': {'class': 'col-w-10'}}
                },
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-30'}}},
                {'field': 'shortName', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15'}}}
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('KIT');
            return config;
        },
        canAddOrEdit: function () {
            return false;
        }
    }, Dynamic);
});

