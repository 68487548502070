/**
 * Created by RKL on 26/08/2015.
 */
define('roleMissingItem',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= code %>'),
        tagName: 'li',
        attributes: function () {
            return {
                style: 'margin: 5px 5px 5px 5px;'
            };
        },
        serializeData: function () {
            return {
                code: this.model.get('code')
            };
        }
    });
});

