define('runHeaderView',[
    'module',
    'backbone.marionette',
    'template!runHeaderView',
    'runHeaderMiddleView',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    MiddleView,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'header',
        regions: {
            'left': '.js-left-region',
            'middle': '.js-middle-region',
            'right': '.js-right-region'
        },
        modelEvents: {
            'sync': 'renderRegions'
        },
        initialize: function () {
            this.options.collection.listenTo(this.options.collection, 'current', _.bind(this.onChangeCurrent, this));
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('middle', new MiddleView({model: this.model, view: this.options.configuration}));
        },
        onChangeCurrent: function (tab) {
            var header = tab.get('header');
            if (header && header.left) {
                this.showChildView('left', new header.left({model: this.model}));
            } else {
                this.getRegion('left').empty();
            }
            if (header && header.right) {
                this.showChildView('right', new header.right({model: this.model}));
            } else {
                this.getRegion('right').empty();
            }
            this.currentTab = tab;
        },
        renderRegions: function () {
            this.render();
            this.onChangeCurrent(this.currentTab);
        }
    });
});

