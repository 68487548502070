define('kitLotsActionPopupView',[
    'module',
    'dialogFormView',
    'template!kitLotsActionPopupView',
    'savingBehavior',
    'kitLotsActionGenericView',
    'kitLotsActionGenericQuantityView',
    'underscore'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    KitLotsActionGenericView,
    KitLotsActionGenericQuantityView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            SavingBehavior: {
                behaviorClass: SavingBehavior
            }
        },

        regions: {
            value: '.js-value'
        },

        className: 'kitLotStatus',

        initialize: function () {
            this.oldData = {};
            _.each(this.options.data, _.bind(function (value) {
                this.oldData[value] = this.model.get(value);
            }, this));
        },

        onChangeRender: function () {
            if (_.all(this.options.fieldsToValidate, _.bind(function (field) {
                return this.model.get(field);
            }, this))) {
                this.triggerMethod('enable:cancel:confirm');
            } else {
                this.ui.confirm.prop('disabled', true);
            }
        },

        onRender: function () {
            var allFields = true;
            _.each(this.options.fieldsToValidate, _.bind(function (value) {
                this.listenTo(this.model, 'change:' + value, _.bind(this.onChangeRender, this));
                if (!this.model.get(value)) {
                    allFields = false;
                }
            }, this));
            var view;
            if (['TEST', 'VAL1', 'VAL2', 'CURRENT', 'EXHAUSTED'].includes(this.options.status)) {
                view = new KitLotsActionGenericView(this.options);
            } else if (['DELIVERED', 'PAUSED'].includes(this.options.status)) {
                view = new KitLotsActionGenericQuantityView(this.options);
            }
            this.showChildView('value', view);
            if (allFields) {
                this.triggerMethod('enable:cancel:confirm');
            }

            this.fieldsToValidate = _.map(this.options.fieldsToValidate, function (fieldToValidate) {
                return {name: fieldToValidate, type: 'required', subView: view};
            });
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.trigger('confirmAction');
                this.hide();
            }
        },
        onCancel: function () {
            this.model.set(this.oldData);
            this.model.trigger('cancelAction');
            this.hide();
        }
    });
});

