define('dynamicCreateEditCodeList',[
    'module',
    'dialogFormView',
    'underscore',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    _,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-autocomplete"></div>'),

        regions: {
            autocompleteRegion: '.js-autocomplete'
        },

        initialize: function () {
            if (!this.options.config.modelProperty) {
                alert('modelProperty not defined in codeList');
            }
            if (!this.options.config.valueProperty) {
                this.options.config.valueProperty = 'code';
            }
            if (!this.options.config.queryProperty) {
                this.options.config.queryProperty = 'code';
            }
            if (!this.options.config.valueKey) {
                this.options.config.valueKey = 'code';
            }
            if (!this.options.config.code && !this.options.config.showAll) {
                alert('code for codeList not defined in codeList');
            }
        },
        serializeData: function () {
            var templateData = {};
            templateData.model = this.model.toJSON();
            templateData.fieldName = this.options.fieldName;
            return templateData;
        },

        onRender: function () {
            var config = this.options.config;
            var CodeListService = require('services/admin/codelist');
            var autocompleteParam;
            if (config.showAll) {
                autocompleteParam = CodeListService.getAutocompleteParam({
                    modelProperty: config.modelProperty,
                    valueProperty: config.valueProperty,
                    queryProperty: config.queryProperty,
                    callBackOnChange: this.onChangeAutoComplete
                });
            } else {
                autocompleteParam = CodeListService.getAutocompleteParamForCode({
                    modelProperty: config.modelProperty,
                    valueProperty: config.valueProperty,
                    queryProperty: config.queryProperty,
                    callBackOnChange: this.onChangeAutoComplete,
                    codeListCode: config.code
                });
            }
            this.getRegion('autocompleteRegion').show(new AutocompleteView(this._getAutocompleteOptionObject(autocompleteParam)));
        },

        onChangeAutoComplete: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
        },

        disabled: function () {
            this.ui.parameters.val(null);
            this.ui.parameters.attr('disabled', 'disabled');
        }
    });
});
