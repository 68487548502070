
define('template!pcrSetupPcrWellItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well ' +
((__t = ( added )) == null ? '' : __t) +
' ' +
((__t = ( clickable )) == null ? '' : __t) +
' ' +
((__t = ( temporary )) == null ? '' : __t) +
'" data-pos="' +
((__t = ( pos )) == null ? '' : __t) +
'"\n     style="' +
((__t = ( color )) == null ? '' : __t) +
'; border-color: whitesmoke; border-style: solid; border-width: 1px; display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;" title="' +
((__t = ( assay )) == null ? '' : __t) +
'">\n    ';
 if(displayAssayCode) { ;
__p += '\n    <span data-field-sampletype="' +
((__t = ( sampleType )) == null ? '' : __t) +
'" style="line-height: 10px; font-size: 10px;">' +
((__t = ( assay.substring(0,4) + '..' )) == null ? '' : __t) +
'</span>\n    ';
} else { ;
__p += '\n    <span class="mdi ' +
((__t = ( mdi )) == null ? '' : __t) +
'" data-field-sampletype="' +
((__t = ( sampleType )) == null ? '' : __t) +
'"\n          style="line-height: 17px; font-size: 18px;"></span>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

