
define('template!dynamicCreateEditList', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h2 style="margin-top: 10px;">' +
((__t = ( _.i18n(name + '.' + field + '.list') )) == null ? '' : __t) +
'</h2>\n<div class="rounded" style="padding: 10px 0 5px 0;">\n    <div class="col-w-all col-w-100 d-b p-l-5 p-r-5 tableUnderlineTitle js-header">\n    </div>\n    <div class="js-collection"></div>\n</div>\n';

}
return __p
};});

