
define('template!runDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n    <div class="container-fluid">\n        <div class="well-table"></div>\n    </div>\n</div>';

}
return __p
};});

