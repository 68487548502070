define('pcrSetupPrepWellItem',[
    'module',
    'backbone.marionette',
    'template!pcrSetupPrepWellItem',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        attributes: function () {
            return {style: 'width: ' + this.options.width + '%;height: ' + this.options.width + '%;'};
        },

        className: 'prep',

        ui: {
            input: '.js-info-input',
            well: '.well',
            mdi: '.mdi'
        },

        events: {
            'click .well.available.clickable': 'onClick'
        },
        modelEvents: {
            change: 'render',
            mouseOver: 'onMouseOverFromPcr',
            mouseLeave: 'onMouseLeaveFromPcr'
        },

        serializeData: function () {
            var templateData = {};
            templateData.width = this.options.width;
            templateData.pos = this.model.get('pos');
            templateData.available = this.model.get('available') ? 'available' : '';
            templateData.color = 'background-color: ' + (this.model.get('available') && this.model.get('color') ? this.model.get('color') : '#FFFFFF') + ';';
            templateData.sampleType = this.model.get('sampleType');
            templateData.assay = this.model.get('waiting').pluck('code');
            templateData.selected = this.model.get('available') && this.model.get('selected') ? 'selected' : '';
            templateData.mdi = this.getMdi();
            templateData.clickable = this.clickable ? 'clickable' : '';
            return templateData;
        },

        getMdi: function () {
            if (this.model.get('waiting').isEmpty()) {
                switch (this.model.get('sampleType')) {
                    case 'PC':
                        return 'mdi-plus-circle-outline';
                    case 'RC':
                        return 'mdi-alpha-r-circle-outline';
                    case 'OC':
                        return 'mdi-compass-outline';
                    case 'NC':
                        return 'mdi-minus-circle-outline';
                    default:
                        return 'mdi-circle-outline';
                }
            }
            switch (this.model.get('sampleType')) {
                case 'PC':
                    return 'mdi-plus-circle';
                case 'RC':
                    return 'mdi-alpha-r-circle';
                case 'OC':
                    return 'mdi-compass';
                case 'NC':
                    return 'mdi-minus-circle';
                default:
                    return 'mdi-circle-slice-8';
            }
        },
        onRender: function () {
            if (this.options.assay) {
                this.editMode();
            }
        },

        editMode: function () {
            this.edit = true;
            this.clickable = this.model.get('available');
            if (this.options.assay) {
                this.clickable = this.clickable && this.model.get('waiting').any(_.bind(function (assay) {
                    return assay === this.options.assay;
                }, this));
            }
            if (this.clickable && this.options.assay) {
                this.ui.well.addClass('clickable');
                this.ui.well.removeClass('notClickable');
                if (this.model.get('available')) {
                    this.ui.well.addClass('available');
                    this.ui.well.css('background-color', '');
                }
            } else {
                this.ui.well.removeClass('clickable');
                this.ui.well.removeClass('available');
                this.ui.well.addClass('notClickable');
                if (this.options.assay) {
                    this.ui.mdi.removeClass(this.getMdi());
                    this.ui.mdi.addClass('mdi-circle-outline');
                } else {
                    this.ui.mdi.removeClass(this.getMdi());
                    this.ui.mdi.addClass('mdi-circle-outline');
                    this.ui.mdi.addClass(this.getMdi());
                }
                var style = this.ui.well.attr('style');
                if (!style || style.indexOf('background-color') === -1) {
                    this.ui.well.css('background-color', (this.model.get('available') && this.model.get('color') ? this.model.get('color') : '#FFFFFF'));
                }
            }
        },
        onClick: function (event) {
            event.stopPropagation();
            if (event.ctrlKey) {
                this.trigger('item:ctrlClick', this);
            } else if (event.shiftKey) {
                this.trigger('item:shiftClick', this);
            } else {
                this.trigger('item:click', this);
            }
        },
        unselected: function () {
            this.model.unset('selected', {silent: true});
            this.ui.well.removeClass('selected');
        },
        onMouseOverFromPcr: function () {
            // need to blink animation the well
            this.ui.well.addClass('blink');
        },
        onMouseLeaveFromPcr: function () {
            // remove blink the well
            this.ui.well.removeClass('blink');
        }
    });
});
