define('entities/global/caccounts',[
    'entities/ns',
    'jquery',
    'underscore',
    'backbone',
    'settings',
    'rolesMixin',
    'app',
    'backboneRelational'
], function (
    app,
    $,
    _,
    Backbone,
    Settings,
    RolesMixin,
    App
) {
    'use strict';

    app.ns.CAccount = Backbone.RelationalModel.extend({
        service: 'services/global/caccounts',
        fetch: function () {
            this.url = Settings.url('rest/v2/caccounts/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            vatNumber: undefined,
            name: undefined,
            zip: undefined,
            city: undefined,
            address: undefined,
            code: undefined,
            phone: undefined,
            contactEmail: undefined,
            country: undefined,
            logo: null,
            languages: []
        },

        url: function () {
            return Settings.url('rest/v2/caccounts/' + (this.get('secId') ? this.get('secId') : ''));
        },

        postUrl: function () {
            return Settings.url('rest/v2/caccounts/' + (this.get('secId') ? this.get('secId') : ''));
        },

        postCAccount: function () {
            var url = Settings.url('rest/v2/sessions.json/token/byuser/' +
                this.get('secId'));

            return $.ajax({
                type: 'POST',
                url: url
            });
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        update: function () {
            this.url = this.postUrl();
            return this.save();
        },

        idAttribute: 'secId',

        updateLogo: function (fileInfo) {
            var url = Settings.url('rest/v2/caccounts/' + this.get('secId') + '/logo'),
                defer = $.Deferred();

            this.trigger('request');

            var formdata = new FormData();
            formdata.append('file', fileInfo);
            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (resp) {
                    defer.reject(resp);
                }
            });
            return defer.promise();
        },
        importDatabase: function (fileInfo) {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('rest/v2/caccounts/' + this.get('secId') + '/database/import'),
                defer = $.Deferred();

            var formdata = new FormData();
            formdata.append('file', fileInfo);
            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise();
        },
        exportDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('rest/v2/caccounts/' + this.get('secId') + '/database/export');
            var xhr = new XMLHttpRequest();

            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader('Authorization', 'Bearer ' + App.keycloak.token);

            xhr.onreadystatechange = function () {
                loader.hide();
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
                        // Expression régulière pour extraire le nom du fichier
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDispositionHeader);

                        // Récupérer le nom du fichier depuis les correspondances
                        var fileName = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : 'exported_database.zip';

                        var blob = new Blob([xhr.response], {type: 'application/zip'});
                        var url = window.URL.createObjectURL(blob);

                        var a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = fileName;

                        document.body.appendChild(a);
                        a.click();

                        window.URL.revokeObjectURL(url);

                        alert('Your file has downloaded!');
                    } else {
                        alert('Error downloading file. Status: ' + xhr.status);
                    }
                }
            };

            xhr.send();
        },
        deleteDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('rest/v2/caccounts/' + this.get('secId') + '/database'),
                defer = $.Deferred();
            $.ajax({
                type: 'DELETE',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise();
        },
        createDatabase: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('rest/v2/caccounts/' + this.get('secId') + '/database/create'),
                defer = $.Deferred();
            $.ajax({
                type: 'GET',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (resp) {
                    defer.reject(resp);
                },
                complete: function () {
                    loader.hide();
                }
            });
            return defer.promise();
        }
    });

    app.ns.CAccount.role = RolesMixin.SUPER_USER;

    app.ns.CAccountCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/caccounts/');
        },
        model: app.ns.CAccount,
        comparator: 'code'
    });

});

