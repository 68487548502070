
define('template!translationTextItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12" style="display: flex;">\n    <label for="text-' +
((__t = ( locale.toLowerCase() )) == null ? '' : __t) +
'" style="width: 30px">' +
((__t = ( locale.toUpperCase() )) == null ? '' : __t) +
'</label>\n    <textarea class="form-control floating-label js-info-input" data-field-name="value"\n              id="text-' +
((__t = ( locale.toLowerCase() )) == null ? '' : __t) +
'"\n              rows="2">' +
((__t = ( value )) == null ? '' : __t) +
'</textarea>\n    <span class="mdi mdi-delete md-grey-cl js-remove-icon clickable"></span>\n</div>';

}
return __p
};});

