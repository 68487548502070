define('dynamicCreateEditLabel',[
    'module',
    'formView',
    'underscore'
], function (
    module,
    FormView,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        tag: 'label',
        className: 'control-label',
        serializeData: function () {
            var templateData = {};
            templateData.value = _.i18n(this.options.service.getName() + '.' + this.options.field + '.extra.label');
            return templateData;
        }
    });
});
