
define('template!prepWellPoolItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-all col-w-20 noWrapHidden"\n     title="' +
((__t = ( runName )) == null ? '' : __t) +
'">\n    ' +
((__t = ( runName )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-5 noWrapHidden"\n     title="' +
((__t = ( pos )) == null ? '' : __t) +
'">\n    ' +
((__t = ( pos )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>\n<div class="col-w-all col-w-25 noWrapHidden"\n     title="' +
((__t = ( smpId.code )) == null ? '' : __t) +
'">\n    ' +
((__t = ( smpId.code )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-all col-w-2 noWrapHidden"></div>';

}
return __p
};});

