define('services/menu',[
    'entities/ns',
    'module',
    'backbone',
    'underscore',
    'entities/menu'
], function (
    app,
    module,
    Backbone,
    _
) {
    'use strict';

    module.exports = {
        getModel: function (items) {
            if (!items) {
                items = {};
            }
            var model = app.ns.Menu.findOrCreate({title: 'menu', subMenu: items});
            this._defineCollection(model);
            return model;
        },
        _defineCollection: function (model) {
            if (!(model.get('subMenu') instanceof Backbone.Collection)) {
                model.set('subMenu', this.getCollection(model.get('subMenu')));
            }
            model.get('subMenu').each(_.bind(function (m) {
                this._defineCollection(m);
                m.set('parent', model);
            }, this));
        },
        getCollection: function (items) {
            if (!items) {
                items = {};
            }
            return new app.ns.MenuCollection(items);
        }
    };
});
