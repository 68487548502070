define('dynamicCreateEditSelect',[
    'module',
    'formView',
    'template!dynamicCreateEditSelectTpl'
], function (
    module,
    FormView,
    tpl
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        tagName: 'select',
        attributes: function () {
            return {
                'data-field-name': this.options.field
            };
        },
        className: 'form-control  inputBackground',
        events: {
            'change': 'onChangeSelect'
        },
        serializeData: function () {
            return {values: this.options.values};
        },
        onRender: function () {
            this.$el.val(this.model.get(this.options.field));
        },
        onChangeSelect: function (e) {
            this.onChange(this.options.field, e.currentTarget.value);
        }
    });
});
