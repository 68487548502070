define('extendChart',[
    'chart',
    'underscore'
], function (
    Chart,
    _
) {
    'use strict';


    //Create horizontalBar plug-in for ChartJS
    var originalLineDraw = Chart.controllers.horizontalBar.prototype.draw;
    Chart.helpers.extend(Chart.controllers.horizontalBar.prototype, {

        draw: function () {
            originalLineDraw.apply(this, arguments);

            var chart = this.chart;
            var ctx = chart.chart.ctx;

            _.each(chart.config.options.lineAtIndex, function (line) {
                var xaxis = chart.scales['x-axis-0'];
                var yaxis = chart.scales['y-axis-0'];

                var x1 = xaxis.getPixelForValue(line.index);
                var y1 = 0;

                var x2 = xaxis.getPixelForValue(line.index);
                var y2 = yaxis.height;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.strokeStyle = line.color;
                ctx.lineWidth = 1;
                ctx.lineTo(x2, y2);
                ctx.stroke();

                ctx.restore();
            });
        }
    });

    //Create horizontalBar plug-in for ChartJS
    var originalLineDrawForBar = Chart.controllers.bar.prototype.draw;
    Chart.helpers.extend(Chart.controllers.bar.prototype, {

        draw: function () {
            originalLineDrawForBar.apply(this, arguments);

            var chart = this.chart;
            var ctx = chart.chart.ctx;

            _.each(chart.config.options.lineAtIndex, function (line) {
                var xaxis = chart.scales['x-axis-0'];
                var yaxis = chart.scales['y-axis-0'];

                var x1 = xaxis.left;
                var y1 = yaxis.getPixelForValue(line.index);

                var x2 = xaxis.right;
                var y2 = yaxis.getPixelForValue(line.index);

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.strokeStyle = line.color;
                ctx.lineWidth = 1;
                ctx.lineTo(x2, y2);
                ctx.stroke();

                ctx.restore();
            });
        }
    });
});
