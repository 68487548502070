define('services/caccounts/pcrruntracs',[
    'entities/ns',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'entities/caccounts/pcrruntracs'
], function (
    app,
    Settings,
    module,
    Dynamic,
    _
) {
    'use strict';

    module.exports = _.defaults({
        getName: function () {
            return 'pcrRunTracs';
        },
        getNamespace: function () {
            return {
                model: app.ns.PcrRunTrac,
                collection: app.ns.PcrRunTracCollection
            };
        },

        getCollectionUrl: function () {
            return Settings.url('rest/v2/runtracs/pcr/find');
        },
        getCountUrl: function () {
            return 'rest/v2/runtracs/pcr/find/rowCount';
        },
        getCollection: function (runJSON) {
            if (!runJSON) {
                runJSON = [];
            }
            return new app.ns.PcrRunTracCollection(runJSON);
        }
    }, Dynamic);
});

