define('prepRunTracsView',[
    'module',
    'app',
    'underscore',
    'dialogFormView',
    'template!prepRunTracsTpl',
    'prepTracsView'
], function (
    module,
    App,
    _,
    DialogFormView,
    PrepRunTracsTpl,
    TracsView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: PrepRunTracsTpl,

        regions: {
            prepRunTracsRegion: '.js-prepRun-tracs-region'
        },

        onRender: function () {
            this.view = new TracsView({
                model: this.options.model
            });
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.getRegion('prepRunTracsRegion').show(this.view);
        },

        onGridLoaded: function () {
            var data;
            var PrepRunTracService = require('services/caccounts/prepruntracs');
            var url = PrepRunTracService.getCollectionUrl();
            var rowCountUrl = PrepRunTracService.getCountURl();
            data = {
                url: url,
                rowCountUrl: rowCountUrl
            };
            var filters = {};
            filters.runSecId = this.model.get('secId');
            if (this.options.type === 'wellTracks') {
                filters.runSecId = this.model.get('run').get('secId');
                filters.position = this.model.get('pos');
            }
            this.view.setFilter(filters);
            this.view.displayGrid(data);
        },

        serializeData: function () {
            return {};
        }
    });
});

