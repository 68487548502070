define('envHandler',[
  'module',
  'jquery',
  'env',
  'browserUtils'
], function (module, $, Environment, BrowserUtils) {
  'use strict';

  module.exports = {
      getValue: function (env, key) {
          if ($('#envConfig').length > 0 && document.env !== undefined && document.env[key] !== undefined) {
              return document.env[key];
          }
          if (!env || env === ' ') {
              if (BrowserUtils.getHref().indexOf('dist') !== -1) {
                  env = 'minified';
              } else {
                  env = 'defaults';
              }
          }
          return Environment.config[env][key];
      }
  };
});

