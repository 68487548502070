define('services/caccounts/assayreagent',[
        'entities/ns',
        'module',
        'dynamic',
        'underscore',
        'entities/caccounts/assayreagent'
    ], function (
        app,
        module,
        Dynamic,
        _
    ) {
        'use strict';

        module.exports = _.defaults({
            getNamespace: function () {
            return {
                model: app.ns.AssayReagent,
                collection: app.ns.AssayReagentCollection
            };
        },
            getName: function () {
                return 'assayreagent';
            },
            getDynamicJson: function () {
                return 'AssayReagentJson';
            }
        }, Dynamic);
    }
);

