define('prepWellEmptyItem',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!prepWellEmptyItem'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'd-f column baseline bottom-border-cell-light',
        serializeData: function () {
            return {
                title: this.options.title
            };
        }
    });
});

