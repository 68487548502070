/**
 * Created by RKL on 26/08/2015.
 */
define('caccountsController',[
    'module',
    'underscore',
    'jquery',
    'caccountsView',
    'caccountsEditView',
    'entities/ns'
], function (
    module,
    _,
    $,
    View,
    EditView,
    app
) {
    'use strict';

    module.exports = {
        show: function (region, secId) {
            $('.js-global-loader').show();
            var CAccountService = require('services/global/caccounts');
            var model = CAccountService.getModel({secId: secId});
            model.fetch().done(_.bind(function () {
                var fetchCountries = CAccountService.getCountries();
                var fetchLangs = new app.ns.LanguageCollection();
                var defers = [];
                defers.push(fetchCountries);
                defers.push(fetchLangs.getLanguages(true, false));
                defers.push(CAccountService.getModules());
                $.when.apply($, defers).done(_.bind(function (countries, langs, modules) {
                    var view = new View({
                        model: model,
                        countries: countries,
                        langs: langs,
                        modules: modules
                    });
                    region.show(view);
                }, this))
                    .always(function () {
                        $('.js-global-loader').hide();
                    });
            }, this));
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var CAccountService = require('services/global/caccounts');
            var fetchCountries = CAccountService.getCountries();
            var fetchLangs = new app.ns.LanguageCollection();
            var defers = [];
            defers.push(fetchCountries);
            defers.push(fetchLangs.getLanguages(true, false));
            defers.push(CAccountService.getModules());
            $.when.apply($, defers).done(_.bind(function (countries, langs, modules) {
                var createEditView = new EditView({
                    model: param.model,
                    countries: countries,
                    langs: langs,
                    modules: modules
                });
                createEditView.show({
                    title: _.i18n(CAccountService.getName() + '.new'),
                    className: CAccountService.getName() + 'EditAddPopup',
                    service: param.service
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            }, this));
        }
    };
});

