
define('template!prepRunCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="dropdown dropdown-jqgrid" id="menuImportListButton" title="' +
((__t = ( _.i18n('import.record') )) == null ? '' : __t) +
'">\n                        <span aria-expanded="true"\n                              aria-haspopup="true" class="dropdown-toggle mdi mdi-arrow-up-bold-circle action header clickable" data-toggle="dropdown"></span>\n    <ul aria-labelledby="menuImportListButton" class="dropdown-menu">\n        <li class="js-createRun-fromFile-plateLayout clickable">' +
((__t = ( _.i18n('run.menu.import.plateLatout') )) == null ? '' : __t) +
'\n        </li>\n        <li class="js-createRun-fromFile-sampleList clickable">' +
((__t = ( _.i18n('run.menu.import.sampleList') )) == null ? '' : __t) +
'\n        </li>\n    </ul>\n</div>';

}
return __p
};});

