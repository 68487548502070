
define('template!prepRunTracsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="css-tracs-table">\n    <!-- jqGrid table height depends of .css-tracs-table and .js-tracs-region nor it\'s calculated in grid.base.js -->\n    <!-- width depends of the jqGrid (.js-tracs-region) columns width -->\n    <div class="jq-grid-table-page js-prepRun-tracs-region" id="test"></div>\n</div>';

}
return __p
};});

