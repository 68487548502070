/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemDate',[
    'module',
    'underscore',
    'dynamicListItemPropertyView',
    'moment'
], function (
    module,
    _,
    ItemView,
    moment
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template(''),
        tagName: 'input',
        className: function () {
            return 'form-control inputBackground ' + ItemView.prototype.className.apply(this, arguments);
        },
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            this.readOnly = this.options.field.get('param') && this.options.field.get('param').display && this.options.field.get('param').display.readOnly;
            if (this.readOnly) {
                return _.extend(attr, {
                    type: 'text',
                    'data-field-name': this.options.field.get('field') + '_date',
                    value: this.model.get(this.options.field.get('field')) ? moment(this.model.get(this.options.field.get('field'))).format('DD-MM-YYYY') : '',
                    disabled: true,
                    readOnly: true
                });
            }
            return _.extend(attr, {
                type: 'datepicker',
                'data-field-name': this.options.field.get('field') + '_date',
                value: this.model.get(this.options.field.get('field')) ? moment(this.model.get(this.options.field.get('field'))).format('DD-MM-YYYY') : ''
            });
        },
        events: _.extend(ItemView.prototype.events, {
            'change': 'onChangeDate'
        }),
        initialize: function () {
            ItemView.prototype.initialize.apply(this, arguments);
            this.listenTo(this, 'change:' + this.options.field.get('field'), _.bind(this.datePickerChange, this));
        },
        onChangeDate: function (e) {
            var fieldName = e.currentTarget.attributes['data-field-name'].value.replace('_date', '');
            this.onChange(fieldName, e.currentTarget.value ? moment(e.currentTarget.value, 'DD-MM-YYYY').format() : null);
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            this.initDatePicker(this.$el, this.options.field.get('field'), true);
            this.$el.val(this.model.get(this.options.field.get('field')) ? moment(this.model.get(this.options.field.get('field'))).format('DD-MM-YYYY') : '');
        },
        datePickerChange: function (fieldName) {
            if (this.options.field.get('field') === fieldName) {
                this.render();
            }
        }
    });
});
