
define('template!runHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-left-region left"></div>\n<div class="js-middle-region middle"></div>\n<div class="js-right-region right"></div>';

}
return __p
};});

