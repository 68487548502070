
define('template!pcrKitLotForValidationCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<thead>\n<tr>\n    <th class="pcrReagent">' +
((__t = ( _.i18n('common.pcrReagent') )) == null ? '' : __t) +
'</th>\n    <th class="pcrKit">' +
((__t = ( _.i18n('run.pcr.kits') )) == null ? '' : __t) +
'</th>\n    <th class="pcrKitLot">' +
((__t = ( _.i18n('menu.admin.kitlotspcr') )) == null ? '' : __t) +
'</th>\n    <th class="action"></th>\n</tr>\n</thead>\n<tbody></tbody>\n';

}
return __p
};});

