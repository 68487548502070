
define('template!wellMultiDisplayTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem"\n     style="width:100%; ' +
((__t = ( colorAssay ? ('background-color:' + colorAssay + '40 !important;'):'')) == null ? '' : __t) +
';">\n    <div class="pcrRunWellGrid">\n        <div style="background-color: ' +
((__t = ( wellResColor ? wellResColor:'white' )) == null ? '' : __t) +
';" class="pcrRunGridResult"></div>\n        <div style="background-color: ' +
((__t = ( backgroundColor ? (backgroundColor + ' !important'):'white' )) == null ? '' : __t) +
';"\n             class="pcrRunGridAlert">\n            <div style="position: relative;">\n                <row class="m-l-2 h-14" style="display: flex;">\n                    <div class="col-auto" style="width:22px;">\n                        ';
 if(well.existOtherWellSameAssay) { ;
__p += '\n                        <div class="triangle-same-assay-mini repeat-link"\n                             style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
';"></div>\n                        ';
 } ;
__p += '\n                        ';
 if(well.existOtherWellOtherAssay) { ;
__p += '\n                        <div class="triangle-other-assay-mini repeat-link"\n                             style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
';"></div>\n                        ';
 } ;
__p += '\n                    </div>\n                </row>\n                <row style="display: flex;">\n                    <div class="well-result-icons-P24">\n                        <div class="col-xs-11 container-fluid m-l-3 m-b-5">\n                            <div class="row topResultIconsP24">\n                                ';
 if (displayResult) { ;
__p += ' <!-- displayResult -->\n                                <div class="icon-result1"></div>\n                                <div class="icon-result2"></div>\n                                ';
 } ;
__p += '\n                            </div>\n                            <div class="row">\n                                ';
 if (displayResult) { ;
__p += ' <!-- displayResult -->\n                                <div class="icon-result3"></div>\n                                <div class="icon-result4"></div>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                    </div>\n                </row>\n            </div>\n        </div>\n        ';
 if(well.smpType) { ;
__p += '\n        ';
 if (well.smpType === 'NC') { ;
__p += ' <!-- well.smpType === \'NC\' -->\n        <span class="mdi mdi-minus-circle smp-id-link"></span>\n        ';
 } else if(well.smpType === 'PC') { ;
__p += ' <!-- well.smpType === \'PC\' -->\n        <span class="mdi mdi-plus-circle smp-id-link"></span>\n        ';
 } else if(well.smpType === 'RC') { ;
__p += ' <!-- well.smpType === \'RC\' -->\n        <span class="mdi mdi-alpha-r-circle smp-id-link"\n              style="position: absolute; font-size: 18px; left: 0; top: -4px;"></span>\n        ';
 } else if(well.smpType === 'OC') { ;
__p += ' <!-- well.smpType === \'OC\' -->\n        <span class="mdi mdi-compass" style="position: absolute; font-size: 18px; left: 0; top: -4px;"></span>\n        ';
 } else if(well.smpType === 'D') { ;
__p += ' <!-- well.smpType === \'OC\' -->\n        <span class="mdi mdi-alpha-d-circle" style="position: absolute; font-size: 18px; left: 0; top: -4px;"></span>\n        ';
 } else if(well.smpType === 'C') { ;
__p += ' <!-- well.smpType === \'C\' -->\n        <span class="mdi mdi-alpha-c-circle" style="position: absolute; font-size: 18px; left: 0; top: -4px;"></span>\n        ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
};});

