define('commonWellSelectionView',[
    'module',
    'dialogFormView',
    'backbone',
    'template!commonWellSelectionView',
    'dynamicCreateEditCodeList'
], function (
    module,
    DialogFormView,
    Backbone,
    Tpl,
    CodeListView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            start: '.js-start',
            to: '.js-to'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        regions: {
            plateFillDirection: '.js-plate-fill-direction-region'
        },

        attributes: {
            style: 'width: 100%; display: flex; flex-direction: row; align-items: baseline;'
        },

        fieldsToValidate: [
            {name: 'start', type: 'required'},
            {name: 'to', type: 'required'},
            {name: 'direction', type: 'required'}
        ],

        modelEvents: {
            'change:start': 'onFieldChange',
            'change:to': 'onFieldChange',
            'change:direction': 'onFieldChange'
        },

        initialize: function () {
            this.model = new Backbone.Model({
                start: this.options.start,
                to: this.options.to,
                direction: this.options.direction
            });
            var data = {};
            data[this.options.fieldName] = this.model;
            this.options.model.set(data, {silent: true});
        },

        onRender: function () {
            this.getRegion('plateFillDirection').show(new CodeListView({
                model: this.model,
                config: {
                    modelProperty: 'direction',
                    code: 'PLATE_FILL_DIRECTION'
                }
            }));
            if (this.options.hiddenTo) {
                this.$el.find('.js-to').hide();
            }
        },
        onFieldChange: function () {
            this.options.model.trigger('change:' + this.options.fieldName);
        }
    });
});
