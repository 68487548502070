define('services/caccounts/runats',[
    'entities/ns',
    'settings',
    'module',
    'entities/caccounts/runats'
], function (
    app,
    Settings,
    module
) {
    'use strict';

    module.exports = {
        getNamespace: function () {
            return app.ns.RunAt;
        }
    };
});

