define('entities/caccounts/assaygroup',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.ns.AssayGroup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assaygroup',
        fetch: function () {
            this.url = Settings.url('rest/v2/assayGroup/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'defaultAssay': null,
            'condition': null,
            'criterias': [],
            'assays': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasOne,
            key: 'defaultAssay',
            relatedModel: 'Assay'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/assayGroup/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'AssayGroupJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayGroup',

        importable: true,
        validateCondition: function (condition) {
            var params = {
                condition: condition
            };

            var url = Settings.url('rest/v2/assayGroup/validateCondition', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.AssayGroup.role = RolesMixin.ASSAYGROUP;

    app.ns.AssayGroupCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assayGroup/');
        },
        model: app.ns.AssayGroup,
        comparator: function (a, b) {
            if (a.get('code') === '') {
                return 1;
            }
            return a.get('code') < b.get('code');
        }
    });
});

