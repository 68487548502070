define('runContentGridItemHeaderView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Backbone,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= name %>'),
        className: 'grid-item-header',
        serializeData: function () {
            return {name: this.model.get('name')};
        }
    });
});

