
define('template!textCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="hidden" id="translations-upload"\n       type="file">\n<span class="mdi mdi-file-upload action header clickable" title="' +
((__t = ( _.i18n('common.upload') )) == null ? '' : __t) +
'">\n</span>\n<span class="mdi mdi-file-download action header clickable" title="' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'">\n</span>\n<input class="col-xs-8 js-text" placeholder="search by text" type="text"/>\n<span class="mdi mdi-magnify clickable header action"></span>';

}
return __p
};});

