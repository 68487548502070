define('services/trash',[
    'settings',
    'module',
    'jquery'
], function (
    Settings,
    module,
    $
) {
    'use strict';

    module.exports = {
        getTrashCollectionUrl: function () {
            return Settings.url('rest/v2/trash/find');
        },

        getTrashCountUrl: function () {
            return 'rest/v2/trash/find/rowCount';
        },

        downloadTrashByFileName: function (fileName) {
            var url = Settings.url('rest/v2/trash/download/' + fileName),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });

            return defer.promise();
        }
    };
});

