define('services/global/dynamicDefinitionField',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'entities/global/dynamicDefinitionField'
], function (
    app,
    _,
    module,
    Dynamic,
    Settings
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.DynamicDefinitionField,
                collection: app.ns.DynamicDefinitionFieldCollection
            };
        },
        getName: function () {
            return 'dynamicDefinitionField';
        },
        getUrl: function () {
            return 'rest/global/dynamic/';
        },
        getDynamicJson: function () {
            return 'DynamicDefinitionField';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({'field': 'lisBioGroups', 'param': {'type': 'LIST2ENTITY'}});
            return custom;
        },
        getCreateEditLine: function () {
            var TextService = require('services/global/text');
            return [
                {'field': 'field', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 m-l-5', readOnly: true}}},
                {'field': 'type', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 m-l-5', readOnly: true}}},
                {'field': 'context', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 m-l-5', readOnly: true}}},
                {
                    'field': 'text', 'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-w-40 m-l-5'},
                        'config': {
                            'getAutocompleteParam': TextService.getAutocompleteParam
                        },
                        'context': TextService
                    }
                }
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = false;
            return config;
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

