define('runDissolvedView',[
    'module',
    'app',
    'underscore',
    'dialogFormView',
    'template!runDissolvedTpl'
], function (
    module,
    App,
    _,
    DialogFormView,
    runDissolvedTpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: runDissolvedTpl,

        events: {
            'click .js-confirm': 'onConfirm'
        },

        serializeData: function () {
            var templateData = {
                wells: [],
                runName: this.model.get('run').get('name'),
                wellPool: this.model.toJSON(),
                notDissolved: []
            };

            var wellResToDisplay = this.model.getWellRes();
            var results = this.model.getTargetsResultIcons(this.model);
            templateData.wellResToDisplay = wellResToDisplay ? wellResToDisplay.toJSON() : null;
            templateData.wellResResults = results ? results : null;

            if (wellResToDisplay && wellResToDisplay.get('result')) {
                templateData.wellResColorFormatted = wellResToDisplay.get('result').get('color');
            } else {
                templateData.wellResColorFormatted = null;
            }

            var PcrWellService = require('services/caccounts/pcrwells');
            _.each(this.options.wells, _.bind(function (well) {
                var tempWell = PcrWellService.getModel(well);
                well.results = tempWell.getTargetsResultIcons(well);

                var wellResToDisplay = tempWell.getWellRes();
                well.wellResToDisplay = wellResToDisplay.toJSON();
                if (wellResToDisplay && wellResToDisplay.get('result')) {
                    well.wellResColorFormatted = wellResToDisplay.get('result').get('color');
                } else {
                    well.wellResColorFormatted = null;
                }

                templateData.wells.push(well);
            }, this));

            _.each(this.model.get('wellsPooled'), function (wellPooled) {
                var dissolved = _.findWhere(templateData.wells, {'smpId': wellPooled.smpId.name});
                if (dissolved) {
                    dissolved.wellPooled = wellPooled;
                }
                if (!dissolved) {
                    templateData.notDissolved.push(wellPooled);
                }
            });

            if (templateData.notDissolved) {
                var notDissolvedSorted = _.sortBy(templateData.notDissolved, 'run');
                templateData.notDissolved = notDissolvedSorted;
            }

            if (templateData.wells) {
                var wellsSorted = _.sortBy(templateData.wells, function (well) {
                    return well.wellPooled.runName;
                });

                templateData.wells = wellsSorted;
            }
            return templateData;
        },

        onConfirm: function () {
            this.hide();
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});

