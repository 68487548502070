define('entities/caccounts/samplehandlers',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.SampleHandler = Backbone.RelationalModel.extend({
        service: 'services/caccounts/samplehandlers',
        fetch: function () {
            this.url = Settings.url('rest/v2/sampleHandlers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'lmbSampleHandlers': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'lmbSampleHandlers',
            relatedModel: 'LmbSampleHandler',
            collectionType: 'LmbSampleHandlerCollection',
            reverseRelation: {
                key: 'refSampleHandler'
            }
        }],

        postUrl: function () {
            return Settings.url('rest/v2/sampleHandlers/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SampleHandlerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'SampleHandler',

        importable: true
    });

    app.ns.SampleHandler.role = RolesMixin.SAMPLEHANDLER;

    app.ns.SampleHandlerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/sampleHandlers/');
        },
        model: app.ns.SampleHandler
    });
});

