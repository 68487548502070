define('loadServices',[
    'services/menu',
    'services/trash',
    'services/languages',
    'services/common',
    'services/errors',
    'services/system',
    'services/global/dynamicDefinitionField',
    'services/global/dynamicDefinition',
    'services/global/text',
    'services/global/routes',
    'services/global/caccounttypes',
    'services/global/userpids',
    'services/global/token',
    'services/global/logs',
    'services/global/caccounts',
    'services/global/textSlave',
    'services/caccounts/kitprotconfiguration',
    'services/caccounts/fileFormatSampleLists',
    'services/caccounts/lisRecords',
    'services/caccounts/suppliers',
    'services/caccounts/routingaction_routinggroup',
    'services/caccounts/lisana',
    'services/caccounts/routingaction_state',
    'services/caccounts/assaygroup',
    'services/caccounts/sampleClass',
    'services/caccounts/runats',
    'services/caccounts/curves',
    'services/caccounts/assayreagent',
    'services/caccounts/role',
    'services/caccounts/assayReagentTargetPcrKitLots',
    'services/caccounts/printers',
    'services/caccounts/colorCompensations',
    'services/caccounts/assay',
    'services/caccounts/kitsextraction',
    'services/caccounts/lmbcyclers',
    'services/caccounts/lisConfiguration',
    'services/caccounts/assayResultVersion',
    'services/caccounts/displayModes',
    'services/caccounts/cyclers',
    'services/caccounts/prepruntracs',
    'services/caccounts/attachment',
    'services/caccounts/prepruntemplates',
    'services/caccounts/pcrwells',
    'services/caccounts/reports',
    'services/caccounts/prepwells',
    'services/caccounts/biogroups',
    'services/caccounts/platesize',
    'services/caccounts/lmbsamplehandlers',
    'services/caccounts/pcrruntemplates',
    'services/caccounts/extractionMethodReagent',
    'services/caccounts/assayconfiguration',
    'services/caccounts/extractors',
    'services/caccounts/assayConfigurationResult',
    'services/caccounts/mbanas',
    'services/caccounts/userCodelistelement',
    'services/caccounts/extractionreagents',
    'services/caccounts/fileFormatPlateLayouts',
    'services/caccounts/pcrwellresults',
    'services/caccounts/cyclerpublics',
    'services/caccounts/colorCompensationMatrices',
    'services/caccounts/mbanaresgrs',
    'services/caccounts/lisSpecies',
    'services/caccounts/orders',
    'services/caccounts/assayReagentVersion',
    'services/caccounts/displayModeConfigurations',
    'services/caccounts/lisRecordQueries',
    'services/caccounts/prepruns',
    'services/caccounts/lmbextractors',
    'services/caccounts/pcrruns',
    'services/caccounts/lis',
    'services/caccounts/routinggroup',
    'services/caccounts/preprocess',
    'services/caccounts/mbanares',
    'services/caccounts/userpids',
    'services/caccounts/routingaction',
    'services/caccounts/pcrruntracs',
    'services/caccounts/kitlotpcr',
    'services/caccounts/lisBioGroups',
    'services/caccounts/userCodelist',
    'services/caccounts/colorCompensationMatriceValues',
    'services/caccounts/userGroup',
    'services/caccounts/sampleResultDefinitions',
    'services/caccounts/reportProperties',
    'services/caccounts/kitspcr',
    'services/caccounts/pcrreagents',
    'services/caccounts/authorityGroup',
    'services/caccounts/setting',
    'services/caccounts/kitprots',
    'services/caccounts/files',
    'services/caccounts/kitlotextraction',
    'services/caccounts/samples',
    'services/caccounts/assayVersion',
    'services/caccounts/extractionMethod',
    'services/caccounts/samplehandlers',
    'services/caccounts/species',
    'services/caccounts/setting/defaultCheckbox',
    'services/caccounts/connector/connectorHl7Tracings',
    'services/caccounts/pcrSetup/pcrWellLite',
    'services/caccounts/pcrSetup/pcrRunLite',
    'services/caccounts/pcrSetup/prepWellLite',
    'services/caccounts/pcrSetup/pcrSetup',
    'services/caccounts/standardCurve/standardCurveAssayConfigurationResults',
    'services/caccounts/standardCurve/standardCurves',
    'services/caccounts/standardCurve/standardCurveSamples',
    'services/admin/array',
    'services/admin/translations',
    'services/admin/dye',
    'services/admin/cyclers',
    'services/admin/detectionFormat',
    'services/admin/codelistelement',
    'services/admin/arrayelement',
    'services/admin/codelist'
], function () {

});
