define('translationsPopupTextsView',[
    'module',
    'backbone.marionette',
    'translationTextItemView',
    'underscore',
    'backbone'
], function (
    module,
    Marionette,
    View,
    _,
    Backbone
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'form-group row m-b-20',
        childView: View,

        attributes: {
            style: 'display: flex;flex-direction: column;'
        },

        initialize: function () {
            this._changeLanguages(this.options.languages);
        },
        viewFilter: function (child) {
            var model = this.options.languages.findWhere({abbreviation: child.model.get('locale')});
            return model && model.get('checked');
        },

        changeLanguages: function (languages) {
            this.options.languages = new Backbone.Collection(languages);
            this._changeLanguages();
        },

        _changeLanguages: function () {
            this.options.languages.filter(_.bind(function (language) {
                var model = this.collection.findWhere({'locale': language.get('abbreviation')});
                if (!model && language.get('checked')) {
                    this.collection.add({
                        locale: language.get('abbreviation'),
                        value: ''
                    });
                }
            }, this));
            this.render();
        }
    });
});
