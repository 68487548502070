
define('template!prepRunExtrKitsLotsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2>' +
((__t = ( _.i18n('run.extractionMethod') )) == null ? '' : __t) +
'</h2>\n<div class="details">\n    <div class="header">\n        <div class="type"></div>\n        <div class="extractionMethod">' +
((__t = ( _.i18n('run.extractionMethod') )) == null ? '' : __t) +
'</div>\n        <div class="extractor">' +
((__t = ( _.i18n('common.lmbExtractor') )) == null ? '' : __t) +
'</div>\n        <div class="date">' +
((__t = ( _.i18n('date') )) == null ? '' : __t) +
'</div>\n        <div class="user">' +
((__t = ( _.i18n('user') )) == null ? '' : __t) +
'</div>\n        <div class="comment">' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="start">\n        <div class="type">' +
((__t = ( _.i18n('prep.extraction.start') )) == null ? '' : __t) +
'</div>\n        <div class="extractionMethod">' +
((__t = ( start.code )) == null ? '' : __t) +
'</div>\n        <div class="extractor"></div>\n        <div class="date">' +
((__t = ( start.date )) == null ? '' : __t) +
'</div>\n        <div class="user">' +
((__t = ( start.user )) == null ? '' : __t) +
'</div>\n        <div class="comment">' +
((__t = ( start.comment )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="stop">\n        <div class="type">' +
((__t = ( _.i18n('prep.extraction.end') )) == null ? '' : __t) +
'</div>\n        <div class="extractionMethod"></div>\n        <div class="extractor">' +
((__t = ( end.code )) == null ? '' : __t) +
'</div>\n        <div class="date">' +
((__t = ( end.date )) == null ? '' : __t) +
'</div>\n        <div class="user">' +
((__t = ( end.user )) == null ? '' : __t) +
'</div>\n        <div class="comment">' +
((__t = ( end.comment )) == null ? '' : __t) +
'</div>\n    </div>\n</div>\n\n<h2>' +
((__t = ( _.i18n('run.extraction.reagents') )) == null ? '' : __t) +
'</h2>\n\n<div class="reagents">\n    <div class="header">\n        <div class="type">' +
((__t = ( _.i18n('extraction.reagent.reagentType') )) == null ? '' : __t) +
'</div>\n        <div class="kit">' +
((__t = ( _.i18n('extraction.reagent.kit') )) == null ? '' : __t) +
'</div>\n        <div class="lot">' +
((__t = ( _.i18n('extraction.reagent.litlot') )) == null ? '' : __t) +
'</div>\n        <div class="quantity">' +
((__t = ( _.i18n('extraction.reagent.qty') )) == null ? '' : __t) +
'</div>\n        <div class="volumeTest">' +
((__t = ( _.i18n('extraction.reagent.vol.test') )) == null ? '' : __t) +
'</div>\n        <div class="volumeDeath">' +
((__t = ( _.i18n('extraction.reagent.vol.death') )) == null ? '' : __t) +
'</div>\n        <div class="volumeTotal">' +
((__t = ( _.i18n('extraction.reagent.vol.tot') )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 if(kitLots && kitLots.extractionKitLots && kitLots.extractionKitLots.length > 0) { ;
__p += '\n    ';
 _.each(kitLots.extractionKitLots, function(extractionKitLot) { ;
__p += '\n    <div>\n        <div class="type">' +
((__t = ( extractionKitLot.reagent ? extractionKitLot.reagent.code : '' )) == null ? '' : __t) +
'</div>\n        <div class="kit">' +
((__t = ( extractionKitLot.kit.code )) == null ? '' : __t) +
'</div>\n        <div class="lot">' +
((__t = ( extractionKitLot.refKitLot.code )) == null ? '' : __t) +
'</div>\n        <div class="quantity">' +
((__t = ( extractionKitLot.count )) == null ? '' : __t) +
'</div>\n        <div class="volumeTest">' +
((__t = ( extractionKitLot.volumeByTest )) == null ? '' : __t) +
'</div>\n        <div class="volumeDeath">' +
((__t = ( extractionKitLot.volumeDeath )) == null ? '' : __t) +
'</div>\n        <div class="volumeTotal">' +
((__t = ( ((extractionKitLot.count*extractionKitLot.volumeByTest) +
            extractionKitLot.volumeDeath).toFixed(2) )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n    <div class="empty">' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n</div>\n\n<h2>' +
((__t = ( _.i18n('run.extraction.ic') )) == null ? '' : __t) +
'</h2>\n\n<div class="wells">\n    <div class="header">\n        <div class="icKit">' +
((__t = ( _.i18n('extraction.icKit.code') )) == null ? '' : __t) +
'</div>\n        <div class="icKit">' +
((__t = ( _.i18n('extraction.icKit.lot') )) == null ? '' : __t) +
'</div>\n        <div class="count">' +
((__t = ( _.i18n('extraction.icKit.quantity') )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 if(kitLots && kitLots.pcrKitLots && kitLots.pcrKitLots.length > 0) {;
__p += '\n    ';
 _.each(kitLots.pcrKitLots, function (pcrKitLot) { ;
__p += '\n    <div>\n        <div class="icKit">' +
((__t = ( pcrKitLot.refKitLot.kit.code )) == null ? '' : __t) +
'</div>\n        <div class="icKit">' +
((__t = ( pcrKitLot.refKitLot.code )) == null ? '' : __t) +
'</div>\n        <div class="count">' +
((__t = ( pcrKitLot.count )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n    <div class="empty">' +
((__t = ( _.i18n('jqgrid.emptyrecords') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

