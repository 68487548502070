
define('template!editWellTargetCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row m-t-5 m-b-5">\n    <div class="col-xs-12 p-l-0 p-r-0" style="display: flex;">\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('kitp.refMbAna') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-2 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-2" style="top: 1px; position: relative;">\n        </div>\n        <div class="col-w-all col-w-7 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('result') )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('target.val') )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-10 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('target.quantification') )) == null ? '' : __t) +
'</label>\n        </div>\n\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n\n        <div class="noWrapHidden col-w-all col-w-30 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('comment') )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n        <div class="col-w-all col-w-4 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('well.codeErr') )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="noWrapHidden col-w-all col-w-1 p-t-5 p-b-0"></div>\n        <div class="noWrapHidden col-w-all col-w-30 p-t-5 p-b-0">\n            <label class="m-b-0">' +
((__t = ( _.i18n('well.justificatory') )) == null ? '' : __t) +
'</label>\n        </div>\n    </div>\n</div>';

}
return __p
};});

