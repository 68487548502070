
define('template!prepRunTemplatesCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group" data-field-name="code">\n            <label class="col-xs-3 control-label dataNameIdLabel">' +
((__t = ( _.i18n('prepRun.runTemplate') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-1" data-field-name="code">\n                <input type="text"\n                       class="form-control floating-label js-info-input js-code"\n                       data-field-name="code" placeholder=""\n                       value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content=""\n                       data-placement="top" autocomplete="off">\n            </div>\n            <div class="col-xs-4" data-field-name="name">\n                <input type="text"\n                       class="form-control floating-label js-info-input"\n                       data-field-name="name" placeholder=""\n                       value="' +
((__t = ( name )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n\n            <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('seq') )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-1" data-field-name="seq">\n                <input type="text"\n                       class="form-control floating-label js-info-input"\n                       data-field-name="seq" placeholder=""\n                       value="' +
((__t = ( seq ? seq : '' )) == null ? '' : __t) +
'" autocomplete="off">\n            </div>\n        </div>\n\n\n        <div class="form-group" data-field-name="pattern">\n            <label class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.pattern')
                )) == null ? '' : __t) +
'</label>\n            <div id="prepRunTemplatePatternInput" class="col-xs-5 js-selectPattern"></div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-define-plateLayout" style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                    _.i18n('prepRun.define.PlateLayout') )) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="form-group" data-field-name="department">\n            <label class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.department')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5 js-selectDepartment"></div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-assign-assay"\n                        style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                    _.i18n('pcr.platelayout.assign.assay') )) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n        <div class="form-group" data-field-name="group">\n            <label class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.rungroup')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5 js-selectRunGroup"></div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-remove-assay"\n                        style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                    _.i18n('pcr.platelayout.remove.assay') )) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n\n        <div class="form-group" data-field-name="sequenceFormat">\n            <label class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('prepRun.runName.sequenceFormat')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5 js-selectSequenceFormat"></div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-generate-smpids"\n                        style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                    _.i18n('pcr.platelayout.generate.smpId') )) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n        <div class="form-group js-info-input" data-field-name="plateSize">\n            <label class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('plateSize') )) == null ? '' : __t) +
'</label>\n            ';
 if(newItem) { ;
__p += '\n            <div class="col-xs-5 js-selectPlateSize" style="width: 75px; margin: 0;"></div>\n            ';
 } else { ;
__p += '\n            <span class="col-xs-5">' +
((__t = ( plateSize )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-remove-smpids"\n                        style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                    _.i18n('pcr.platelayout.remove.smpId') )) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n\n        <div class="form-group" data-field-name="pool">\n            <label for="pool" class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('runTemplate.pool') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5">\n                <input style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n                       class="js-pool js-info-input" data-field-name="pool"\n                       id="pool"\n                       name="pool" ' +
((__t = ( pool? 'checked' : '' )) == null ? '' : __t) +
'>\n            </div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                <button class="btn btn-primary js-add-test" style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px; position: relative; height: 23px; width: 200px;">\n                    ' +
((__t = ( _.i18n('pcr.platelayout.add.test'))) == null ? '' : __t) +
'\n                </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="form-group" data-field-name="printRunSheet">\n            <label for="printRunSheet" class="col-xs-3 control-label dataLabel">' +
((__t = ( _.i18n('pcrRun.printDef')
                )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-5">\n                <input style="width: 20px; height: 20px; margin: 0;" type="checkbox"\n                       class="js-printRunSheet"\n                       id="printRunSheet"\n                       name="printRunSheet" ' +
((__t = ( printRunSheet? 'checked' : '' )) == null ? '' : __t) +
'>\n            </div>\n            <div class="col-xs-4" style="text-align: right;">\n                ';
 if(!newItem){ ;
__p += '\n                    <button class="btn btn-primary js-remove-test"\n                            style="padding: 2px 20px 2px 20px; bottom: 8px; margin-right: 15px;\n                                position: relative; height: 23px; width: 200px;">' +
((__t = (
                        _.i18n('pcr.platelayout.remove.test') )) == null ? '' : __t) +
'\n                    </button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="js-description m-t-5 m-b-5"></div>\n        <div class="cancelConfirmRow">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n        </div>\n    </div>\n</div>';

}
return __p
};});

