define('dynamicCreateEditCustomListToEntity',[
    'module',
    'backbone.marionette',
    'dynamicCreateEditCustomListToEntityItem'
], function (
    module,
    Marionette,
    DynamicCreateEditCustomListToEntityItem
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: DynamicCreateEditCustomListToEntityItem,
        attributes: {
            style: 'display: flex; flex-direction: row;flex-wrap: wrap; gap: 3px;'
        },
        childViewOptions: function () {
            return {
                service: this.options.service
            };
        }
    });
});
