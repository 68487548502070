/**
 * Created by RKL on 21/08/2015.
 */
define('pcrSetupController',[
    'module',
    'underscore',
    'jquery',
    'pcrSetup'
], function (
    module,
    _,
    $,
    View
) {
    'use strict';

    module.exports = {
        show: function (region) {
            $('.js-global-loader').show();
            var PcrSetupService = require('services/caccounts/pcrSetup/pcrSetup');
            PcrSetupService.find().done(_.bind(function (pcrSetup) {
                var view = new View({
                    model: pcrSetup
                });
                region.show(view);
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    };
});
