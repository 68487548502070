
define('template!prepRunExtractionStop', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="col-xs-12">\n            ';
 if(!isManual) { ;
__p += '\n            <div class="form-group" data-field-name="refExtractionMethod">\n                <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('common.lmbExtractor') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-4 js-select-extractor-region" data-field-name="refLmbExtractor"></div>\n            </div>\n            ';
 } ;
__p += '\n\n            <div class="form-group" data-field-name="endUserpid">\n                <label class="col-xs-4 control-label">' +
((__t = ( _.i18n('run.extractionUser') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-4 js-select-respUser-region" data-field-name="endUserpid"></div>\n            </div>\n\n            <div class="form-group commentOrDescriptionContainer m-t-20">\n                <div class="col-xs-1 mdi-editor-insert-comment commentOrDescriptionIcon"\n                     style="font-size: 40px;">\n                </div>\n                <div class="commentOrDescriptionInputDiv col-xs-11">\n                                    <textarea class="form-group floating-label js-info-input"\n                                              style="margin: 0; width:inherit; resize: vertical; background-color: white;"\n                                              rows="3" wrap="on" data-field-name="comment"\n                                              placeholder="">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n                </div>\n            </div>\n        </div>\n        <!-- cancel/confirm row -->\n        <div class="cancelConfirmRow">\n            <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n            <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

