
define('template!pcrKitLotForValidationCompareView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="d-f">\n    <div class="header">\n        <div style="display: flex;">' +
((__t = ( _.i18n('lot.referenceWell') )) == null ? '' : __t) +
' - ' +
((__t = ( referenceWellPos )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 _.each(kitLots, function(kitLot) { ;
__p += '\n    <div class="content">\n        <div class="kit">' +
((__t = ( kitLot.kitCode )) == null ? '' : __t) +
'</div>\n        <div class="reagent">' +
((__t = ( kitLot.reagentCode )) == null ? '' : __t) +
'</div>\n        <div class="lot">' +
((__t = ( kitLot.lot )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 }); ;
__p += '\n    <div class="js-result-reference"></div>\n    <div class="js-graph-reference"></div>\n</div>\n<div class="d-f">\n    <div class="header">\n        <div style="display: flex;">' +
((__t = ( _.i18n('lot.testWell') )) == null ? '' : __t) +
' - ' +
((__t = ( wellPos )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 _.each(kitLots, function(kitLot) { ;
__p += '\n    <div class="content">\n        <div class="testLot">' +
((__t = ( kitLot.currentLot )) == null ? '' : __t) +
'</div>\n    </div>\n    ';
 }); ;
__p += '\n    <div class="js-result"></div>\n    <div class="js-graph"></div>\n</div>\n';

}
return __p
};});

