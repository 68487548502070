define('runPcrValidationView',[
    'module',
    'backbone.marionette',
    'backbone',
    'underscore',
    'template!runPcrValidationView',
    'runPcrValidationPlateView',
    'runPcrValidationMainView',
    'runPcrValidationTargetsView',
    'emptyView'
], function (
    module,
    Marionette,
    Backbone,
    _,
    Tpl,
    ValidationPlateView,
    ValidationMainView,
    ValidationTargetsView,
    EmptyView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'validation',
        regions: {
            plate: '.plate-region',
            main: '.main-region',
            targets: '.targets-region'
        },
        initialize: function () {
            this.model = new Backbone.Model({run: this.options.model});

            this.model.set('resultsByAssay', new Backbone.Collection(
                this.model.get('run').get('assayVersions')
                    .chain()
                    .map(function (assayVersion) {
                        return assayVersion.get('results').models;
                    })
                    .flatten()
                    .filter(function (result) {
                        if (result.get('assayVersion').get('results').any(function (r) {
                            return r.get('sequenceValidation');
                        })) {
                            return result.get('sequenceValidation');
                        }
                        if (result.get('type') === 'CALCULATED') {
                            return null;
                        }
                        return result.get('sequence');
                    })
                    .groupBy(function (result) {
                        return result.get('assayVersion').get('secId');
                    })
                    .map(_.bind(function (results, assayVersionSecId) {
                        var collection = new Backbone.Collection(results);
                        collection.comparator = 'sequenceValidation';
                        collection.sort();
                        return new Backbone.Model({
                            assayVersion: this.model.get('run').get('assayVersions').findWhere({secId: assayVersionSecId}),
                            results: collection
                        });
                    }, this))
                    .value()));
            this.model.set('assayResultVersion', this.model.get('resultsByAssay').first().get('results').first());
            this.model.set('wellSelected', require('services/caccounts/pcrwells').getCollection(this.model.get('run').get('wells')
                .chain()
                .filter(_.bind(function (well) {
                    return well.get('results').some(_.bind(function (result) {
                        return result.get('refAssayResult') === this.model.get('assayResultVersion');
                    }, this));
                }, this))
                .value()));
            this.model.get('wellSelected').run = this.model.get('run');
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('plate', new ValidationPlateView({model: this.model}));
            var colorsAssay = ['20', '60', '#fff302', '#d06767', '#00FF00', '#285085', '#0012ff'];
            if (this.model.get('run').get('settings')['COLORS-ASSAY']) {
                colorsAssay = this.model.get('run').get('settings')['COLORS-ASSAY'].split(';');
            }
            this.showChildView('targets', new ValidationTargetsView({
                model: this.model,
                assays: colorsAssay
            }));
            this.showChildView('main', new EmptyView());
            this.model.get('run').get('wells').fetch({details: true}).always(_.bind(function () {
                this.showChildView('main', new ValidationMainView({model: this.model}));
            }, this));
        }
    });
});
