
define('template!exportResultByLisTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h2 class="m-t-0 m-b-5">' +
((__t = ( lis )) == null ? '' : __t) +
'</h2>\n<div class="collection">\n</div>';

}
return __p
};});

