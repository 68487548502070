define('routingCommonHeaderView',[
        'app',
        'module',
        'dialogFormView',
        'template!routingCommonHeaderView'
    ], function (
        App,
        module,
        DialogFormView,
        Tpl
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: Tpl,

            ui: {
                backToList: '.js-back-to-list'
            },

            events: {
                'click @ui.backToList': 'onBackToListClick'
            },

            className: 'selectRouting',

            onBackToListClick: function () {
                App.navigate('routing', {trigger: true});
            },
            serializeData: function () {
                return {
                    i18nCode: this.model.get('i18nCode'),
                    code: this.model.get('code'),
                    name: this.model.get('name'),
                    displaySynchronizeLIS: this.model.get('i18nCode') === 'sampleRouting'
                };
            }
        });
    }
);
