define('dynamicCreateEditAutocomplete',[
	'module',
	'dialogFormView',
	'backbone',
	'autocompleteView',
    'underscore'
], function (
	module,
	DialogFormView,
	Backone,
	AutocompleteView,
    _
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: _.template('<div class="autocomplete-region"></div>'),

		regions: {
			autocompleteRegion: '.autocomplete-region'
		},

		serializeData: function () {
			return this.options;
		},

		onRender: function () {
			var config = this.options.getAutocompleteParam;
			config.callback = config.callback ? config.callback : this.onChangeValue;
			var autocomplete = new AutocompleteView(this._getAutocompleteOptionObject(config));
			this.getRegion('autocompleteRegion').show(autocomplete);
		},

		onChangeValue: function (fieldName, model) {
			this.onChange(fieldName, model ? model.toJSON() : null);
		}
	});
});
