
define('template!dynamicCreateEditIntegerTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input type="number" class="form-control input-number input-number-quantity js-info-input  inputBackground"\n	   value="' +
((__t = ( value )) == null ? '' : __t) +
'" min="0" data-field-name="' +
((__t = ( field )) == null ? '' : __t) +
'">';

}
return __p
};});

