/**
 * Created by RKL on 10/09/2015.
 */
define('dialogView',[
    'module',
    'logger',
    'backbone.marionette',
    'underscore',
    'jquery',
    'dialogMixin',
    'template!emptyDialogTpl'
], function (
    module,
    Logger,
    Marionette,
    _,
    $,
    DialogMixin,
    EmptyDialogTpl
) {
    'use strict';

    module.exports = Marionette.View.extend(
        _.extend({
            getTemplate: function () {
                if (this.options.collection && this.options.collection.length === 0) {
                    return EmptyDialogTpl;
                } else {
                    return this.template;
                }
            },

            onRender: function () {
                $('.popover').hide();
            }

        }, DialogMixin)
    );
});
