define('services/global/dynamicDefinition',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'entities/global/dynamicDefinition'
], function (
    app,
    _,
    module,
    Dynamic,
    Settings
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.DynamicDefinition,
                collection: app.ns.DynamicDefinitionCollection
            };
        },
        getName: function () {
            return 'dynamicDefinition';
        },
        getUrl: function () {
            return 'rest/public/dynamic/';
        },
        getDynamicJson: function () {
            return 'DynamicDefinitionJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.selectable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.exportable = false;
            config.importable = false;
            config.attachmentable = false;
            config.duplicable = false;
            config.deletable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('dynamicDefinition.context'),
                name: 'context',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'context',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('dynamicDefinition.classNameJson'),
                name: 'classNameJson',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'classNameJson',
                searchoptions: {size: 10},
                width: 30
            }, {
                label: _.i18n('dynamicDefinition.packageName'),
                name: 'packageName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'packageName',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('dynamicDefinition.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 40,
                key: true
            }, {
                label: _.i18n('dynamicDefinition.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {size: 10},
                width: 40
            });
            return columns;
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('id');
            return ignored;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({'field': 'context', 'param': {'display': {readOnly: true}}});
            custom.push({'field': 'classNameJson', 'param': {'display': {readOnly: true}}});
            return custom;
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

