define('entities/caccounts/displayModes',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.DisplayMode = Backbone.RelationalModel.extend({
        service: 'services/caccounts/displayModes',
        fetch: function () {
            this.url = Settings.url('rest/v2/displayModes/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'displayTooltip': 'LIST',
            'displayValidation': 'TARGET',
            'configurations': [],
            'kitProts': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'DisplayModeConfiguration',
            collectionType: 'DisplayModeConfigurationCollection'
        }, {
            type: Backbone.HasMany,
            key: 'kitProts',
            relatedModel: 'KitProt',
            collectionType: 'KitProtCollection',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/displayModes/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DisplayModeJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DisplayMode',
        importable: true,
        findParameterForLocation: function (location) {
            if (['ValMini', 'WellTarget', 'TooltipTarget', 'WellCurve'].includes(location)) {
                return app.ns.DisplayModeConfiguration.findOrCreate({
                    'parameter': {
                        'type': 'DOT'
                    },
                    'locations': ['ValMini', 'WellTarget', 'TooltipTarget', 'WellCurve'],
                    'secId': location
                });
            }
            return this.get('configurations').find(function (configuration) {
                return configuration.get('locations').includes(location);
            });
        },
        findDotForTooltip: function () {
            var configuration = this.get('configurations').find(function (configuration) {
                return configuration.get('locations').includes('Tooltip') && configuration.get('parameter').type === 'DOT';
            });
            if (configuration) {
                return configuration;
            }
            return app.ns.DisplayModeConfiguration.findOrCreate({
                'parameter': {
                    'type': 'DOT',
                    'param': 'true'
                },
                'locations': ['Tooltip'],
                'secId': location
            });
        }
    });

    app.ns.DisplayMode.role = RolesMixin.DISPLAYMODE;

    app.ns.DisplayModeCollection = Backbone.Collection.extend({
        model: app.ns.DisplayMode,
        comparator: 'secId'
    });
});

