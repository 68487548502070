define('entities/caccounts/assayResultVersion',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/mbanas',
    'entities/caccounts/assay'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.AssayResultVersion = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayResultVersion',
        defaults: {
            'target': null,
            'algoResult': {type: '', param: ''},
            'algoValidation': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''},
            'ic': false,
            'configuration': null,
            'sequence': undefined,
            'sequenceDisplay': undefined,
            'sequenceDisplayGroup': undefined,
            'sequenceValidation': undefined,
            'needValidation': false
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'target',
            relatedModel: 'MbAna',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'assay',
            relatedModel: 'Assay',
            includeInJSON: ['id', 'name', 'code']
        }],


        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayResultVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },
        jsonObject: 'AssayResultVersion'
    });

    app.ns.AssayResultVersion.role = [RolesMixin.ASSAY, RolesMixin.RUN_PCR];

    app.ns.AssayResultVersionCollection = Backbone.Collection.extend({
        model: app.ns.AssayResultVersion,
        comparator: 'sequence'
    });
});

