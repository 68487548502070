define('wellEditSmpIdView',[
        'module',
        'backbone',
        'dialogFormView',
        'template!wellEditSmpIdView',
        'savingBehavior',
        'wellSmpTypeView',
        'bootbox',
        'underscore',
        'app',
        'autocompleteView',
        'jquery',
        'entities/ns',
        'settings',
        'rolesMixin',
        'privileges'
    ], function (
        module,
        Backbone,
        DialogFormView,
        createEditLayoutTpl,
        SavingBehavior,
        SmpTypeView,
        bootbox,
        _,
        App,
        AutocompleteView,
        $,
        app,
        Settings,
        RolesMixin,
        Privileges
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: createEditLayoutTpl,

            ui: {
                input: '.js-info-input',
                cmbsSmpid: '.cmb-smpId',
                inputSmpid: '.js-input-smpid',
                pools: '.js-input-pools'
            },

            regions: {
                smpTypeRegion: '.js-select-smpType',
                assayRegion: '.js-assay-region'
            },

            events: {
                'change @ui.input': 'onInputChange',
                'click .js-cancel-popup': 'onCancel',
                'click .js-confirm': 'onConfirm',
                'change @ui.inputSmpid': 'onChangeSmpid'
            },

            modelEvents: {
                'change': 'render'
            },

            behaviors: {
                Saving: {behaviorClass: SavingBehavior}
            },

            initialize: function (options) {
                this.model = new Backbone.Model();
                this.model.set('secId', options.model.get('secId'));
                this.model.set('sampleClass', options.sampleClass);
                this.model.set('smpId', options.model.get('smpId') && options.model.get('smpId').get('name') ? options.model.get('smpId').get('name') : '');
                this.model.set('test', options.model.get('test'));
                this.model.set('pos', options.model.get('pos'));
                this.model.set('smpType', options.sampleClass ? options.sampleClass.get('sampleType') : options.model.get('smpType'));
                this.model.set('refAssay', options.model.get('refAssay'));
                this.canChooseAssay = !!options.model.get('run') && !(options.model instanceof app.ns.PrepWell);
                this.fromTemplate = _.any(this.options.model.attributes, function (value, key) {
                    return key.includes('RunTemplate');
                });
                this.readOnly = (this.options.model.isReadOnly !== undefined && this.options.model.isReadOnly()) ||
                    (this.options.model.hasAlreadyTargetValidated !== undefined && this.options.model.hasAlreadyTargetValidated());
                this.model.set('sampleClass', this.options.sampleClass);
                this.canChangeSmpId = options.model.canChangeSmpId ? options.model.canChangeSmpId() : true;
            },

            serializeData: function () {
                var templateData = {};
                templateData.smpId = this.model.get('smpId');
                templateData.sampleClass = this.model.get('sampleClass') ? this.model.get('sampleClass').get('code') : '-';
                templateData.lis = this.model.get('sampleClass') && this.model.get('sampleClass').get('refLIS') ? this.model.get('sampleClass').get('refLIS').code : '-';
                templateData.pos = this.model.get('pos');
                templateData.canChooseAssay = this.canChooseAssay && !this.readOnly;
                return templateData;
            },

            onRender: function () {
                this.display();
                if (this.canChooseAssay && !this.readOnly) {
                    var cyclerPublicSecId;
                    var cyclerSecId;
                    var kitProtSecId;
                    if (this.options.model.get('run')) {
                        cyclerPublicSecId = this.options.model.get('run').get('refCyclerPublic') ? this.options.model.get('run').get('refCyclerPublic').secId : null;
                        cyclerSecId = this.options.model.get('run').get('refCycler') ? this.options.model.get('run').get('refCycler').get('secId') : null;
                        kitProtSecId = this.options.model.get('run').get('refKitProt') ? this.options.model.get('run').get('refKitProt').get('secId') : null;
                    } else if (this.options.model.get('run')) {
                        cyclerPublicSecId = this.options.model.get('run').get('cyclerPublic') ? this.options.model.get('run').get('cyclerPublic').secId : null;
                        cyclerSecId = this.options.model.get('run').get('cycler') ? this.options.model.get('run').get('cycler').get('secId') : null;
                        kitProtSecId = this.options.model.get('run').get('refKitProt') ? this.options.model.get('run').get('refKitProt').get('secId') : null;
                    }
                    var AssayService = require('services/caccounts/assay');
                    this.getRegion('assayRegion').show(new AutocompleteView(this._getAutocompleteOptionObject(AssayService.getAutocompleteParam({
                        modelProperty: 'refAssay',
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'cyclerPublicSecId': cyclerPublicSecId,
                            'cyclerSecId': cyclerSecId,
                            'kitProtSecId': kitProtSecId
                        }
                    }))));
                }
                this.triggerMethod('enable:cancel:confirm');
                var role = this.options.model instanceof app.ns.PcrWell ? RolesMixin.WELL_PCR : RolesMixin.WELL_PREP;
                this.setPermissions(Settings.get('currentUserModel').hasType(role, Privileges.WRITE));
            },

            onChangeSmpid: function () {
                var SampleClassService = require('services/caccounts/sampleClass');
                SampleClassService.findBySampleId(this.ui.inputSmpid.val()).done(_.bind(function (sampleClass) {
                    var sampleType = 'E';
                    if (this.ui.inputSmpid.val() !== '') {
                        sampleType = 'D';
                    }
                    if (sampleClass !== null) {
                        sampleType = sampleClass.get('sampleType');
                        this.model.set({'sampleClass': sampleClass}, {silent: true});
                    }
                    this.model.set('smpType', sampleType);
                    if (sampleType !== 'U' || !(this.options.model instanceof app.ns.PcrWell)) {
                        return;
                    }
                    var SettingService = require('services/caccounts/setting');
                    SettingService.getAllSettingFull().done(_.bind(function (settings) {
                        if ((settings['PREP-DISABLED'] === undefined || (!('PREP-DISABLED' in settings) || !Boolean(settings['PREP-DISABLED'])))) {
                            // if (sampleType === 'U') {
                            var PrepWellService = require('services/caccounts/prepwells');
                            PrepWellService.findBySampleId(this.ui.inputSmpid.val()).done(_.bind(function (prepWells) {
                                var inputOptions = prepWells.map(function (prepWell) {
                                    return {text: prepWell.get('run').get('code') + ' - ' + prepWell.get('pos'), value: prepWell.get('secId')};
                                });
                                inputOptions.unshift({text: _.i18n('prepWell.noSelect'), value: ''});
                                bootbox.prompt({
                                    title: _.i18n('prepWell.findBySampleId'),
                                    inputType: 'select',
                                    callback: _.bind(function (result) {
                                        if (result) {
                                            this.model.set('prepWellSecId', result);
                                        }
                                    }, this),
                                    inputOptions: inputOptions,
                                    buttons: {
                                        confirm: {
                                            label: '<i></i>',
                                            className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm'
                                        },
                                        cancel: {
                                            label: '<i></i>',
                                            className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                                        }
                                    },
                                    className: 'bootbox-checkbox bootbox-linkToPrepWell'
                                });
                            }, this));
                        }
                    }, this));
                }, this));
            },

            display: function () {
                this.getRegion('smpTypeRegion').show(new SmpTypeView({
                    smpType: this.model.get('smpType'),
                    size: 'big'
                }));
                switch (this.model.get('smpType')) {
                    case 'E':
                        this.ui.pools.hide();
                        this.ui.pools.find('input.js-info-input').val('');
                        break;
                    case 'U':
                        this.ui.pools.hide();
                        this.ui.pools.val('');
                        break;
                    case 'PC':
                    case 'NC':
                    case 'RC':
                    case 'OC':
                        this.ui.pools.hide();
                        this.ui.pools.val('');
                        break;
                    case 'D':
                        this.ui.pools.hide();
                        this.ui.pools.val('');
                        break;
                    case 'P':
                        this.ui.pools.show();
                        this.ui.pools.val(this.model.get('posPools') ? this.model.get('posPools') : '');
                        break;
                    default:
                        this.ui.pools.hide();
                        this.ui.pools.val('');
                        break;
                }
                this.ui.inputSmpid.prop('disabled', !this.canChangeSmpId);
            },

            onConfirm: function () {
                if (this.validate(this.fieldsToValidate)) {
                    var request = {
                        sampleType: this.model.get('smpType'),
                        sampleId: this.model.get('smpId'),
                        assaySecId: this.model.get('refAssay') ? this.model.get('refAssay').get('secId') : null,
                        test: this.options.test,
                        prepWellSecId: this.model.get('prepWellSecId')
                    };

                    $('.js-global-loader').show();
                    this.options.model.saveHeader(request)
                        .done(_.bind(this.hide, this))
                        .fail(_.bind(function () {
                            this.options.model.fetch()
                                .done(_.bind(function () {
                                    this.box.modal('hide');
                                }, this));
                        }, this))
                        .always(function () {
                            $('.js-global-loader').hide();
                        });
                }
            },

            onSaveError: function (model, response) {
                if (response.status === 409) {
                    this.triggerMethod('hide:label');
                }
                console.error(response);
            }
        });
    }
);

