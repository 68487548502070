
define('template!menuItemMdi', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="mdi ' +
((__t = ( mdi )) == null ? '' : __t) +
'" data-code="' +
((__t = ( nameCode )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'"></span>\n';

}
return __p
};});

