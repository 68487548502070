
define('template!menuRoutingGroupItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card clickable">\n    <div class="card-body" style="padding: 0 15px 0 15px;">\n        <h3 class="card-title">' +
((__t = ( code )) == null ? '' : __t) +
'</h3>\n        <h5 class="card-subtitle mb-2">' +
((__t = ( name )) == null ? '' : __t) +
'</h5>\n    </div>\n</div>\n';

}
return __p
};});

