define('dialogCollectionView',[
    'module',
    'logger',
    'backbone.marionette',
    'underscore',
    'dialogMixin',
    'template!emptyDialogTpl'
], function (
    module,
    Logger,
    Marionette,
    _,
    DialogMixin,
    EmptyDialogTpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend(
        _.extend({
            getTemplate: function () {
                if (this.options.collection && this.options.collection.length === 0) {
                    return EmptyDialogTpl;
                } else {
                    return this.template;
                }
            }

        }, DialogMixin)
    );
});
