define('entities/caccounts/colorCompensationMatriceValues',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.ColorCompensationMatriceValue = Backbone.RelationalModel.extend({
        service: 'services/caccounts/colorCompensationMatriceValues',
        defaults: {
            'x': null,
            'y': null,
            'value': 0,
            'manualCorrection': 0
        },
        idAttribute: 'secId',
        jsonObjectName: 'ColorCompensationMatriceValueJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensationMatriceValue',

        importable: false
    });

    app.ns.ColorCompensationMatriceValue.role = RolesMixin.COLORCOMPENSATION;

    app.ns.ColorCompensationMatriceValueCollection = Backbone.Collection.extend({
        model: app.ns.ColorCompensationMatriceValue,
        comparator: function (o1, o2) {
            if (o1.get('x') === o2.get('x')) {
                return o1.get('y') - o2.get('y');
            }
            return o1.get('x') - o2.get('x');
        }
    });
});

