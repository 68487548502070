define('services/caccounts/pcrwellresults',[
    'entities/ns',
    'module',
    'entities/caccounts/pcrwellresults'
], function (
    app,
    module
) {
    'use strict';

    module.exports = {
        getNamespace: function () {
            return {
                model: app.ns.PcrWellResult,
                collection: app.ns.PcrWellResultCollection
            };
        },
        getCollection: function (items) {
            return new app.ns.PcrWellResultCollection(items);
        }
    };

    return app.ns.PcrWellResult;
});

