
define('template!dynamicListHeaderItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( _.i18n(name + '.' + field) )) == null ? '' : __t);

}
return __p
};});

