define('arrayInputView',[
    'module',
    'dialogFormView',
    'arrayEncodeEditLayoutController',
    'template!arrayInputView',
    'underscore'
], function (
    module,
    DialogFormView,
    ArrayEncodeEditLayoutController,
    ArrayInputTpl,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: ArrayInputTpl,

        ui: {
            parameters: '.js-params'
        },

        className: '',
        attributes: function () {
            return {
                style: 'position: relative;'
            };
        },
        events: {
            'click': 'onShowParameters'
        },

        initialize: function (options) {
            if (!options.disableRender) {
                this.listenTo(this.model, 'change:' + this.options.field.get('field'), this.render);
            }
            if (this.options.field.get('forceValue') !== undefined && this.options.field.get('forceValue') !== null) {
                this.value = this.options.field.get('forceValue');
            } else {
                this.value = this.model.get(this.options.field.get('field'));
            }
        },
        serializeData: function () {
            var templateData = {};
            templateData.value = this.value;
            templateData.fieldName = this.options.field.get('field');
            templateData.readOnly = this.options.field.get('param').readOnly;
            return templateData;
        },
        onShowParameters: function (e) {
            var arrayCode = _.isFunction(this.options.field.get('param').arrayCode) ? this.options.field.get('param').arrayCode(this.model) : this.options.field.get('param').arrayCode;
            ArrayEncodeEditLayoutController.showPopup(this.model, this.options.field.get('field'), this.model.get(this.options.field.get('field')), arrayCode, e, this.options.field.get('param').readOnly, this.options.field.get('referenceModel'));
        },
        onRender: function () {
            var arrayCode = _.isFunction(this.options.field.get('param').arrayCode) ? this.options.field.get('param').arrayCode(this.model) : this.options.field.get('param').arrayCode;
            ArrayEncodeEditLayoutController.generateToolTip(this.$el, arrayCode, this.model.get(this.options.field.get('field')));
            if (this.options.field.get('param').readOnly) {
                this.ui.parameters.attr('disabled', 'disabled');
            }
        },
        disabled: function () {
            this.ui.parameters.val(null);
            this.ui.parameters.attr('disabled', 'disabled');
        }
    });
});
