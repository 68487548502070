define('pcrWellToolTipDotItemOvagNpxView',[
    'module',
    'backbone.marionette',
    'template!pcrWellToolTipDotItemOvagNpxView',
    'pcrWellResultController',
    'pcrWellToolTipDotSubCollection'
], function (
    module,
    Marionette,
    Tpl,
    PcrWellResultController,
    PcrWellToolTipDotSubCollection
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        regions: {
            result: '.js-result',
            linkedResults: '.js-linked-results'
        },

        className: 'd-f m-b-3',
        attributes: {
            style: 'color: white; flex-direction: column;'
        },

        serializeData: function () {
            var errMessComToDisplayList = '';
            var commentsList = [];

            if (this.model.get('codeErr') && this.model.get('codeErr') !== '') {
                commentsList.push(this.model.get('codeErr'));
            }
            if (this.model.get('justificatory') && this.model.get('justificatory') !== '') {
                commentsList.push(this.model.get('justificatory'));
            }
            if (this.model.get('comment') && this.model.get('comment') !== '') {
                commentsList.push(this.model.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }
            return {
                targetCode: this.model.get('mbAna').get('code'),
                resCode: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                errMessComToDisplayList: errMessComToDisplayList
            };
        },
        onRender: function () {
            PcrWellResultController.show(this.model, 'TooltipTarget', this.getRegion('result'));
            this.showChildView('linkedResults', new PcrWellToolTipDotSubCollection({
                collection: this.model.get('results')
            }));
        }
    });
});
