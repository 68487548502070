
define('template!pcrWellResultSPOOvarItemView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="noWrapHidden result-wellres"></div>\n\n<div class="noWrapHidden result-target">\n    <label data-html="true" data-toggle="tooltip" title="' +
((__t = ( mbAna )) == null ? '' : __t) +
'">' +
((__t = ( mbAna )) == null ? '' : __t) +
'</label>\n</div>\n\n<div class="noWrapHidden result-edit">\n</div>\n\n<div class="noWrapHidden result-result js-result">\n</div>\n\n<div class="noWrapHidden result-num">\n    ' +
((__t = ( cts )) == null ? '' : __t) +
' ' +
((__t = ( _.isEmpty(quantifications) ? '' : '(' + quantifications + ')' )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-displayMode js-displayMode">\n</div>\n<div class="noWrapHidden result-action">\n    ';
 if (hasProperties) { ;
__p += '\n    <span class="mdi mdi-format-list-bulleted clickable" title="' +
((__t = ( _.i18n('well.result.properties') )) == null ? '' : __t) +
'"></span>\n    ';
 } ;
__p += '\n</div>\n<div class="noWrapHidden result-custom"></div>\n\n<div class="noWrapHidden result-comment" title="' +
((__t = ( comment )) == null ? '' : __t) +
'">\n    ' +
((__t = ( comment )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-error" title="' +
((__t = ( errorMessage )) == null ? '' : __t) +
'">\n    ' +
((__t = ( errorMessage )) == null ? '' : __t) +
'\n</div>\n\n<div class="noWrapHidden result-val1">\n    <label title="' +
((__t = ( userVal1Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal1Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n\n<div class="noWrapHidden result-val2">\n    <label title="' +
((__t = ( userVal2Title )) == null ? '' : __t) +
'">\n        ' +
((__t = ( userVal2Code )) == null ? '' : __t) +
'\n    </label>\n</div>\n';

}
return __p
};});

