define('runPcrValidationPlateGridItemMenuView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Backbone,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<span class="clickable hidden-print mdi mdi-filter-remove" title="' + _.i18n('clearFilter') + '"></span>'),
        className: 'grid-item-menu',
        events: {
            'click span.mdi-filter-remove': 'onClickResetFilter'
        },
        serializeData: function () {
            return {};
        },
        onClickResetFilter: function () {
            this.trigger('menu:resetFilter');
        }
    });
});

