define('entities/caccounts/kitprots',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/assay',
    'entities/caccounts/kitprotconfiguration',
    'entities/caccounts/displayModes'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.KitProt = Backbone.RelationalModel.extend({
        service: 'services/caccounts/kitprots',
        fetch: function () {
            this.url = Settings.url('rest/v2/kitprots/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            comment: null,
            'ampParam': '',
            'ampSmooth': '',
            'meltParam': '',
            'meltSmooth': '',
            displayMode: null,
            'configurations': [],
            'assays': [],
            tags: []
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'KitProtConfiguration',
            collectionType: 'KitProtConfigurationCollection',
            reverseRelation: {
                key: 'refKitProt',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection'
        }, {
            type: Backbone.HasOne,
            key: 'displayMode',
            relatedModel: 'DisplayMode'
        }],


        postUrl: function () {
            return Settings.url('rest/v2/kitprots/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'KitProtJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'KitProt',

        importable: true
    });

    app.ns.KitProt.role = RolesMixin.PCR_PROTOCOL;

    app.ns.KitProtCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/kitprots/');
        },
        model: app.ns.KitProt
    });
});

