define('dynamicCreateEditTags',[
	'module',
	'formView',
	'underscore'
], function (
	module,
	FormView,
	_
) {
	'use strict';

	module.exports = FormView.extend({
		template: _.template('<%= value %>'),

		events: {
			'change': 'onChangeTags'
		},

		tagName: 'textarea',
		className: 'form-control inputBackground',
		attributes: function () {
			return {
				'data-field-name': 'tags',
				'placeholder': _.i18n('common.empty.placeholder'),
				'style': 'resize: vertical;'
			};
		},

		onChangeTags: function (e) {
			var datas = [];
			_.each(e.currentTarget.value.split(/[,;\s]/), function (data) {
				datas.push(data.trim());
			});
			this.onChange(this.options.field, datas);
		},

		serializeData: function () {
			var templateData = this.options;
			templateData.value = this.model.get(this.options.field) ? this.model.get(this.options.field).join(', ') : '';
			return templateData;
		}
	});
});
