define('services/global/textSlave',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'entities/global/textSlave'
], function (
    app,
    _,
    module,
    Dynamic,
    Settings
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.TextSlave,
                collection: app.ns.TextSlaveCollection
            };
        },
        getName: function () {
            return 'textSlave';
        },
        getUrl: function () {
            return 'rest/global/textSlaves/';
        },
        getDynamicJson: function () {
            return 'TextSlaveJson';
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        }
    }, Dynamic);
});

