
define('template!runPcrHeaderContaminationLeftView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="previous rounded left clickable">\n    <span class="mdi mdi-chevron-left"> </span>\n</div>\n<div class="middle details">\n    <div class="select-target-region"></div>\n</div>\n<div class="next rounded right clickable">\n    <span class="mdi mdi-chevron-right"> </span>\n</div>';

}
return __p
};});

