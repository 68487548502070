define('entities/caccounts/prepwells',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'wellUtils',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/prepruns',
    'entities/caccounts/samples',
    'entities/caccounts/kitlotpcr',
    'entities/caccounts/pcrwells'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    WellUtils,
    RolesMixin
) {
    'use strict';

    app.ns.PrepWell = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepwells',
        fetch: function () {
            this.url = Settings.url('rest/v2/wells/prep/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRun',
            collectionType: 'PcrRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample'
        }, {
            type: Backbone.HasMany,
            key: 'referencedWells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasOne,
            key: 'wellReference',
            relatedModel: 'PrepWell',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLots',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLotsForValidation',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }],

        getAssayStatusFilterIcons: function () {
            return WellUtils.getPrepWellAssaysFilterIcons();
        },

        getWellAssaysWaitingList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysRunningList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'RUNNING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysDoneList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'DONE') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },

        getAssayStatusCount: function () {
            var assaysWaiting = 0;
            var assaysRunning = 0;
            var assaysDone = 0;

            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assaysWaiting++;
                } else if (item.status === 'RUNNING') {
                    assaysRunning++;
                } else if (item.status === 'DONE') {
                    assaysDone++;
                }
            }, this));

            return {
                waiting: assaysWaiting,
                running: assaysRunning,
                done: assaysDone
            };
        },

        postUrl: function (runSecId, routingGroup) {
            var params = {
                'routingGroupSecId': routingGroup ? routingGroup.get('secId') : null
            };

            return Settings.url('rest/v2/runs/prep/' + runSecId + '/wells', params);
        },

        saveDetail: function () {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveHeader: function (request) {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch()
                        .done(_.bind(function () {
                            this.fetch().done(function () {
                                defer.resolve();
                            });
                        }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function () {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/sampleId'),
                defer = $.Deferred();

            var body = JSON.stringify({sampleId: this.get('smpId').get('name')});
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        save: function (routingGroup) {
            this.url = this.postUrl(this.get('run').get('secId'), routingGroup);
            return Backbone.Model.prototype.save.call(this);
        },

        canChangeSmpId: function () {
            return this.getWellAssaysRunningList().length === 0 && this.getWellAssaysDoneList().length === 0;
        },
        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        changeValidationLot: function (param) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(param),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findPcrKitLotForValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        setTest: function (test) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test', {test: test});

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.ns.PrepWell.role = RolesMixin.WELL_PREP;

    app.ns.PrepWellCollection = Backbone.Collection.extend({
        fetch: function () {
            if (this.run) {
                this.url = Settings.url('rest/v2/wells/prep/from/' + this.run.get('secId'));
            } else {
                this.url = Settings.url('rest/v2/runs/prep/');
            }
            return Backbone.Model.prototype.fetch.call(this);
        },
        model: app.ns.PrepWell,
        /**
         * récupère une liste de model sur base d'une recherche
         * @param from
         * @param step
         * @param to
         * @returns {*}
         */
        filterByPos: function (from, step, to) {
            var fromRow = from.substring(0, 1).charCodeAt(0) - 65;
            var fromColumn = Number(from.substring(1, 3));
            var toRow = to.substring(0, 1).charCodeAt(0) - 65;
            var toColumn = Number(to.substring(1, 3));
            var models = this.filter(function (model) {
                var pos = model.get('pos');
                var posRow = pos.substring(0, 1).charCodeAt(0) - 65;
                var posColumn = Number(pos.substring(1, 3));
                return (fromRow % step === posRow % step && fromColumn % step === posColumn % step) && (posRow <= toRow && posColumn <= toColumn);
            });
            return new app.ns.PrepWellCollection(models);
        },

        comparator: function (m1, m2) {
            if (!m1 || !m1.get('pos')) {
                return -1;
            }
            if (!m2 || !m2.get('pos')) {
                return 1;
            }

            if (m1.get('run') !== m2.get('run') && m1.get('run') instanceof app.ns.PrepRun && m2.get('run') instanceof app.ns.PrepRun) {
                if (m1.get('run').get('period') && m2.get('run').get('period')) {
                    return String(m1.get('run').get('period')).localeCompare(String(m2.get('run').get('period')));
                }
                if (m1.get('run').get('created') && m2.get('run').get('created')) {
                    return String(m1.get('run').get('created')).localeCompare(String(m2.get('run').get('created')));
                }
            }

            var labSetting = m1.get('run') && m1.get('run').get && m1.get('run').get('settings') ? m1.get('run').get('settings')['LAB-SETTINGS'] : 'C';
            if (labSetting === 'R') {
                return m1.get('pos').localeCompare(m2.get('pos'));
            }
            return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
        },

        /**
         * find all pcrKitLots from well
         */
        findPcrKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/pcrKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.KitLotPcrCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * find all pcrKitLots for validation from well
         */
        findPcrKitLotsValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/pcrKitLotValidation');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.KitLotPcrCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getReferencedWells: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.pluck('secId').join(','));

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.PrepWellCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getWellReference: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.pluck('secId').join(',') + '/reference');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(new app.ns.PrepWellCollection(resp));
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }

    });
});

