
define('template!dynamicCreateEditFileTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<input type="file">\n\n';
 if(file) { ;
__p += '\n<span class="mdi mdi-file-download clickable" title="' +
((__t = ( _.i18n('logo.download') )) == null ? '' : __t) +
'"></span>\n';
 } ;


}
return __p
};});

