
define('template!translationsPopupFormTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row col-xs-5 border-right">\n    <div class="row">\n        <div class="form-group col-xs-12" data-field-name="code">\n            <span>dashboard.</span>\n            <input class="form-control floating-label js-unique" data-field-name="code"\n                   placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n    </div>\n    <div class="row">\n        <div class="form-group col-xs-12">\n            <div class="js-languages-region"></div>\n        </div>\n    </div>\n</div>\n<div class="row col-xs-7">\n    <div class="js-translations-region"></div>\n</div>\n<div class="row">\n    <div class="form-group pull-right m-b-0 m-r-10">\n        <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n        <button class="btn btn-primary js-save">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n    </div>\n</div>\n';

}
return __p
};});

