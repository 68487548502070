define('entities/caccounts/fileFormatPlateLayouts',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ns.FileFormatPlateLayout = Backbone.RelationalModel.extend({
        service: 'services/caccounts/fileFormatPlateLayouts',
        fetch: function () {
            this.url = Settings.url('rest/v2/fileFormat/plateLayouts/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'inputPlateLayoutService': '',
            'outputPlateLayoutService': '',
            'customInputPlateLayout': '',
            'customOutputPlateLayout': ''
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/fileFormat/plateLayouts/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'FileFormatPlateLayoutJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'FileFormatPlateLayout',

        importable: true
    });

    app.ns.FileFormatPlateLayout.role = RolesMixin.FILEFORMAT;

    app.ns.FileFormatPlateLayoutCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/fileFormat/plateLayouts/');
        },
        model: app.ns.FileFormatPlateLayout
    });
});

