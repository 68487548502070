define('pcrKitLotForValidationItem',[
    'module',
    'backbone.marionette',
    'template!pcrKitLotForValidationItem',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        tagName: 'tr',

        ui: {
            pcrKitLot: '.pcrKitLot'
        },

        serializeData: function () {
            var templateData = {
                pcrReagent: _.map(this.model.get('pcrReagents'), function (pcrReagent) {
                    return pcrReagent.code;
                }).join(', '),
                kit: {
                    code: this.model.get('kit').code,
                    secId: this.model.get('kit').secId,
                    name: this.model.get('kit').name
                },
                code: this.model.get('code'),
                name: this.model.get('name'),
                secId: this.model.get('secId'),
                canUpdate: this.model.get('canUpdate')
            };
            templateData.pcrKitLots = _.chain(this.model.get('kit').lots)
                .filter(function (pcrKitLot) {
                    return ['DELIVERED', 'TEST'].includes(pcrKitLot.status);
                }).map(function (pcrKitLot) {
                    return {
                        code: pcrKitLot.code,
                        secId: pcrKitLot.secId
                    };
                }).value();
            if (this.model.get('pcrKitLot')) {
                templateData.pcrKitLots.unshift(this.model.get('pcrKitLot'));
                templateData.pcrKitLots = _.uniq(templateData.pcrKitLots, false, function (pcrKitLot) {
                    return pcrKitLot.secId;
                });
            }
            templateData.currentKitLotCode = this.model.get('kit').refCurrentLot ? this.model.get('kit').refCurrentLot.code : null;

            return templateData;
        },
        getSelected: function () {
            var selectedOption = this.ui.pcrKitLot.find('option:selected');
            if (selectedOption.hasClass('current')) {
                return null;
            }
            return selectedOption.val();
        },

        onRender: function () {
            if (!this.model.get('canUpdate')) {
                this.ui.pcrKitLot.prop('disabled', true);
                this.ui.pcrKitLot.addClass('disabled');
            }
            if (this.model.get('kit').refCurrentLot) {
                this.ui.pcrKitLot.find('option[value="' + this.model.get('kit').refCurrentLot.secId + '"]').addClass('current');
            }

            var pcrKitLot = this.options.well.get('pcrKitLotsForValidation').chain().filter(_.bind(function (pcrKitLot) {
                return pcrKitLot.get('kit').get('secId') === this.model.get('kit').secId;
            }, this)).first().value();
            if (!this.model.get('canUpdate') && !pcrKitLot) {
                pcrKitLot = this.options.well.get('pcrKitLots').chain().filter(_.bind(function (pcrKitLot) {
                    return pcrKitLot.get('kit').get('secId') === this.model.get('kit').secId;
                }, this)).first().value();
            }
            if (pcrKitLot) {
                this.ui.pcrKitLot.find('option[value="' + pcrKitLot.get('secId') + '"]').prop('selected', true);
            }
        }
    });
});

