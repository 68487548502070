define('services/admin/arrayelement',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/admin/arrayelement'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';


    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.ArrayElement,
                collection: app.ns.ArrayElementCollection
            };
        },
        getName: function () {
            return 'arrayelement';
        },
        getDynamicJson: function () {
            return 'ArrayElementJson';
        },
        getCreateEditLine: function (model) {
            var ret = [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15 m-l-5'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20 m-l-5'}}},
                {'field': 'seq', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5 m-l-5'}}},
                {
                    'field': 'contentType',
                    'param': {
                        'type': 'CODELIST',
                        'code': 'ARRAYELTYPE',
                        'display': {'class': 'col-w-5 m-l-5'}
                    }
                },
                {
                    'field': 'contentTypeParam',
                    'dependsOn': {
                        'model': model,
                        'field': 'contentType',
                        enable: function (model) {
                            var ret = model.get('contentType') && (model.get('contentType') === 'CL' || model.get('contentType').startsWith('L2L'));
                            if (!ret) {
                                model.set({'contentTypeParam': ''}, {silent: true});
                            }
                            return ret;
                        }
                    },
                    defaultParam: {
                        type: 'EMPTY',
                        'display': {'class': 'col-w-10 m-l-5'}
                    },
                    'param': [{
                        'fieldNameKey': 'contentType',
                        'fieldNameValue': 'CL',
                        'type': 'CODELIST',
                        'display': {'class': 'col-w-10 m-l-5'}
                    }, {
                        'fieldNameKey': 'contentType',
                        'fieldNameValue': 'L2L-E',
                        'type': 'CODELIST',
                        'code': 'ARRAY-L2L-E',
                        'display': {'class': 'col-w-10 m-l-5'}
                    }, {
                        'fieldNameKey': 'contentType',
                        'fieldNameValue': 'L2L',
                        'type': 'CODELIST',
                        'code': 'ARRAY-L2L',
                        'display': {'class': 'col-w-10 m-l-5'}
                    }]
                },
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-40 m-l-5'}}}
            ];

            _.each(ret, function (line) {
                if (_.isArray(line.param) && model) {
                    line.param = _.first(_.filter(line.param, function (param) {
                        return model.get(param.fieldNameKey) === param.fieldNameValue;
                    }));
                }
                if ((!line.param || _.isArray(line.param)) && line.defaultParam) {
                    line.param = line.defaultParam;
                }
            });
            return ret;
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        }
    }, Dynamic);

});

