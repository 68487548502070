define('dynamicCreateEditDescriptionComment',[
    'module',
    'formView',
    'template!dynamicCreateEditDescriptionComment',
    'moment',
    'underscore',
    'backbone'
], function (
    module,
    FormView,
    tpl,
    moment,
    _,
    Backbone
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        className: 'description-component',

        serializeData: function () {
            var templateData = {
                'description': this.model.get('description'),
                'comment': this.model.get('comment'),
                'created': '-',
                'createdBy': '-',
                'createdByTitle': '-',
                'updated': '-',
                'updatedBy': '-',
                'updatedByTitle': '-'
            };
            if (this.model.get('creatDate')) {
                templateData.created = moment(this.model.get('creatDate')).format('DD-MM-YYYY HH:mm');
            }
            if (this.model.get('creatBy')) {
                if (this.model.get('creatBy') instanceof Backbone.Model) {
                    templateData.createdBy = this.model.get('creatBy').get('nickName');
                    templateData.createdByTitle = this.model.get('creatBy').get('logonId');
                }
                if (_.isObject(this.model.get('creatBy'))) {
                    templateData.createdBy = this.model.get('creatBy').nickName;
                    templateData.createdByTitle = this.model.get('creatBy').logonId;
                }
            }
            if (this.model.get('modDate')) {
                templateData.updated = moment(this.model.get('modDate')).format('DD-MM-YYYY HH:mm');
            }
            if (this.model.get('modBy')) {
                if (this.model.get('modBy') instanceof Backbone.Model) {
                    templateData.updatedBy = this.model.get('modBy').get('nickName');
                    templateData.updatedByTitle = this.model.get('modBy').get('logonId');
                }
                if (_.isObject(this.model.get('modBy'))) {
                    templateData.updatedBy = this.model.get('modBy').nickName;
                    templateData.updatedByTitle = this.model.get('modBy').logonId;
                }
            }

            return templateData;
        },

        onRender: function () {
            this.setPermissions(this.options.editable !== undefined ? this.options.editable : true);
        }
    });
});
