define('dynamicCreateEditFile',[
	'module',
	'formView',
	'template!dynamicCreateEditFileTpl'
], function (
	module,
	FormView,
	tpl
) {
	'use strict';

	module.exports = FormView.extend({
		template: tpl,

		events: {
			'click .mdi-file-download': 'onDownloadFile',
			'change file': 'onUploadFile'
		},

		onDownloadFile: function () {
			var blob = new Blob([this.model.get(this.options.fieldName)]);
			var link = document.createElement('a');
			link.target = '_blank';
			link.href = window.URL.createObjectURL(blob);
			var fileName = this.options.fieldName;
			link.download = fileName;
			link.click();
		},

		onUploadFile: function (e) {
			var image = e.currentTarget.files[0];
			var buffer = image.arrayBuffer();
			var byteArray = new Int8Array(buffer);
			this.onChange(this.options.fieldName, byteArray);
		},

		serializeData: function () {
			var templateData = this.options;
			templateData.file = this.model.get(this.options.fieldName);
			return templateData;
		}
	});
});
