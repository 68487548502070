
define('template!runGenerateSampleIdView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group" data-field-name="sampleId">\n    <label data-field-name="sampleId">' +
((__t = ( _.i18n('run.sampleId.generate.labelStart') )) == null ? '' : __t) +
'</label>\n    <input class="js-info-input form-control" data-field-name="sampleId" name="sampleId" type="text" value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'">\n</div>\n<div class="row js-well-selection-region"></div>\n<div class="row">\n    <div class="form-group col-xs-4" data-field-name="step">\n        <label>' +
((__t = ( _.i18n('run.sampleId.generate.step') )) == null ? '' : __t) +
'</label>\n        <input class="js-info-input form-control" data-field-name="step" data-placement="top"\n               style="width: 45px;"\n               type="number" value="' +
((__t = ( step )) == null ? '' : __t) +
'">\n    </div>\n    <div class="form-group col-xs-4" data-field-name="firstStep">\n        <label>' +
((__t = ( _.i18n('run.sampleId.generate.firstStep') )) == null ? '' : __t) +
'</label>\n        <input class="js-info-input form-control" data-field-name="firstStep" data-placement="top"\n               style="width: 45px;"\n               type="number" value="' +
((__t = ( firstStep )) == null ? '' : __t) +
'">\n    </div>\n</div>\n<hr/>\n<div class="js-response-region row"></div>\n<hr/>\n<div class="js-cancel-action-region"></div>';

}
return __p
};});

