define('services/caccounts/kitlotpcr',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'rolesMixin',
    'privileges',
    'entities/caccounts/kitlotpcr'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    RolesMixin,
    Privileges
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ns.KitLotPcr,
                collection: app.ns.KitLotPcrCollection
            };
        },
        getName: function () {
            return 'kitlotspcr';
        },
        getUrl: function () {
            return 'rest/v2/kitlots/pcr/';
        },
        getDynamicJson: function () {
            return 'PcrKitLotJson';
        },
        _generateSelect: function () {
            var ret = ':';
            _.each(['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT', 'PAUSED', 'EXHAUSTED'], function (status) {
                ret += ';' + status + ':' + _.i18n('lot.' + status);
            });
            return ret;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: ' ',
                name: 'copyQc',
                sortable: false,
                search: false,
                classes: 'copyQC-link-action ignore-row-click',
                formatter: viewDynamic.copyQCActionIconFormatter,
                fixed: true,
                width: 20
            }, {
                label: _.i18n('kitLot.refKit'),
                name: 'kit.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'kit.code',
                searchoptions: {clearSearch: false},
                width: 60
            }, {
                label: _.i18n('kitLot.refKitName'),
                name: 'kit.name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'kit.name',
                searchoptions: {clearSearch: false},
                width: 60
            }, {
                label: _.i18n('kitLot.lotId'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 80
            }, {
                label: _.i18n('kitLot.status'),
                name: 'status',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'status',
                stype: 'select',
                searchrules: {select: true},
                searchoptions: {sopt: ['eq'], value: this._generateSelect(), clearSearch: false},
                width: 60
            }, {
                label: _.i18n('kitLot.quantity'),
                name: 'deliveryQuantity',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'deliveryQuantity',
                width: 40
            }, {
                label: _.i18n('kitLot.deliveryDate'),
                name: 'deliveryDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'deliveryDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('kitLot.testDate'),
                name: 'testDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'testDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('kitLot.validatedDate'),
                name: 'validatedDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'validatedDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('kitLot.currentDate'),
                name: 'currentDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'currentDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('kitLot.pausedDate'),
                name: 'pausedDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'pausedDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('kitLot.exhaustedDate'),
                name: 'exhaustedDate',
                formatter: viewDynamic.dateFormatterFromSettings, 
                search: true,
                index: 'exhaustedDate',
                width: 40,
                sorttype: 'date',
                searchoptions: {
                    autocomplete: 'off',
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd/mm/yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                }
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.importable = false;
            config.creatable = true;
            return config;
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            };
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getJqGridSidx: function () {
            return 'kit.code';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ns.KitLotPcrCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['refKit.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('comment');
            ignored.push('kit');
            ignored.push('assayReagentTargetPcrKitLots');
            ignored.push('pcrWellQualityControlForRefence');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({field: 'delivery', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'expiration', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'quantity', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'beginUse', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'endUse', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'validated', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({field: 'validatedBy', param: {display: {'class': 'col-xs-2 p-r-0'}}});
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'current',
                    'param': {
                        'type': 'RADIO',
                        'display': {
                            'class': 'col-w-3',
                            'readOnly': true
                        }
                    }
                },
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'deliveryDate', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'expiration', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'deliveryQuantity', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-8', 'readOnly': true}}},
                {'field': 'status', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'statusDate', 'param': {'type': 'DATE', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {'field': 'statusComment', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12', 'readOnly': true}}},
                {
                    'field': 'edit',
                    'param': {
                        'type': 'POPUP',
                        'needExist': true,
                        'config': this.showDetails,
                        'display': {'class': 'col-w-7', 'mdi': 'mdi-pencil'}
                    }
                },
                {
                    'field': 'show',
                    'param': {
                        'type': 'POPUP',
                        'config': this.editValues,
                        'display': {'class': 'col-w-7', 'mdi': 'mdi-target'}
                    }
                }
            ];
        },
        canAddNewModel: function () {
            return true;
        },
        getAutocompleteParamForAssay: function (config) {
            var configuration = Dynamic.getAutocompleteParam({}, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = this.getUrl() + config.model.get('secId') + '/assay?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        editValues: function (param) {
            require(['kitLotsPcrController'], function (Controller) {
                delete param.controllerName;
                Controller.editValues(param);
            });
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = Settings.get('currentUserModel').hasType('KIT0') && Settings.get('currentUserModel').hasRole('KIT');
            return config;
        },
        getDataForPcrKitLot: function (model) {
            return {
                url: Settings.url('rest/v2/kitlots/pcr/' + model.get('refKitLotPcr').get('secId') + '/values/' + model.get('secId') + '/controlKitLot/wells'),
                rowCountUrl: 'rest/v2/kitlots/pcr/' + model.get('refKitLotPcr').get('secId') + '/values/' + model.get('secId') + '/controlKitLot/wells/rowCount',
                service: this
            };
        },
        canWellValidation: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, 'SET_TEST');
        },
        showDetails: function (param) {
            require(['kitLotsPcrController'], function (Controller) {
                param.callBackOnClose = function () {
                    param.model.get('kit').fetch();
                };
                Controller.showDetails(param);
            });
        },
        canAddOrEdit: function () {
            if (!Settings.get('currentUserModel')) {
                return false;
            }
            return _.any([Privileges.DELIVERED, Privileges.TEST, Privileges.VAL1, Privileges.VAL2, Privileges.CURRENT, Privileges.PAUSED, Privileges.EXHAUSTED], function (status) {
                return Settings.get('currentUserModel').hasType(RolesMixin.KIT_PCR_LOT, status);
            });
        }
    }, Dynamic);
});

