/**
 * Created by BFR on 10/07/2017.
 */
define('mobioChartView',[
    'module',
    'underscore',
    'chartView',
    'colorUtils'
], function (
    module,
    _,
    ChartView,
    ColorUtils
) {
    'use strict';

    module.exports = ChartView.extend({

        min: 0,
        max: 0,
        slopeEnable: false,
        brutEnable: false,
        amplGraph: false,
        displayLegend: true,
        deriv2Enable: false,
        withoutCC: false,
        raw: false,
        fixedColor: false,

        generateLegend: function () {
            var minArray = [];
            var maxArray = [];
            _.each(this.data.datasets, function (dataset) {
                var xArray = _.pluck(dataset.data, 'x');
                minArray.push(_.min(xArray));
                maxArray.push(_.max(xArray));
            });
            var xMin = _.min(minArray);
            var xMax = _.max(maxArray);
            var stepSize = parseFloat(((this.max - this.min) / 10).toFixed(2)); // this.max-this.min>1.2?0.2:0.1;
            if (!stepSize) {
                stepSize = 0.01;
            }

            var xAxe = {
                type: 'linear',
                position: 'bottom',
                ticks: {
                    max: xMax,
                    min: xMin,
                    stepSize: 5
                }
            };

            if (this.xLabel) {
                xAxe.scaleLabel = {
                    display: true,
                    labelString: this.xLabel
                };
            }

            var yAxe;

            if (false && !this.brutEnable) {
                yAxe = {
                    ticks: {
                        max: this.max + (stepSize - (this.max % stepSize)),
                        min: this.min - (stepSize + (this.min % stepSize)),
                        stepSize: stepSize
                    }
                };
            } else {
                yAxe = {};
            }

            if (this.yLabel) {
                yAxe.scaleLabel = {
                    display: true,
                    labelString: this.yLabel
                };
            }

            this.options.scales = {
                xAxes: [xAxe],
                yAxes: [yAxe]
            };

            this.options.legend = {
                labels: {
                    boxWidth: 1
                },
                display: this.displayLegend,
                onClick: function (e, legendItem) {
                    var ci = this.chart;
                    var indexes = [];
                    _.each(ci.data.datasets, function (dataset, i) {
                        if (dataset.label === legendItem.text) {
                            indexes.push(i);
                        }
                    });

                    _.each(indexes, function (i) {
                        var meta = ci.getDatasetMeta(i);
                        meta.hidden = meta.hidden === null ? true : !meta.hidden;
                    });
                    ci.update();
                }
            };
        },

        getRandomColor: function () {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }

            return color;
        },

        getColorFromResult: function (assay, smpType, target, res, errCode, forceNull, highlight, resultColor) {
            if (highlight) {
                return {r: 0, g: 0, b: 0, line: 4};
            } else if (forceNull) {
                return {r: null, g: null, b: null, line: 1.7};
            } else if (resultColor) {
                var line = 1.7;
                var c = ColorUtils.hexToRgba(resultColor);
                var dashed = null;
                if (errCode === 'E') {
                    dashed = {
                        borderDashOffset: 3,
                        borderDash: [2, 4]
                    };
                } else if (errCode === 'W') {
                    dashed = {
                        borderDashOffset: 10,
                        borderDash: [12, 8]
                    };
                }
                if (!smpType || smpType === 'U' || smpType === 'P') {
                    line = 2;
                } else if (smpType === 'PC' || smpType === 'RC') {
                    line = 4;
                } else if (smpType === 'NC') {
                    line = 1;
                }
                return {r: c.r, g: c.g, b: c.b, line: line, dashed: dashed};
            } else {
                return {r: null, g: null, b: null, line: 1.7};
            }
        },

        getColorFromRes: function (model, assay, smpType, target, res, errCode, forceNull, highlight, targetColor) {
            if (highlight) {
                return {r: 0, g: 0, b: 0, line: 4};
            } else if (targetColor) {
                return ColorUtils.colorTarget(target, model);
            } else if (forceNull) {
                return {r: null, g: null, b: null, line: 1.7};
            } else {
                target = model.get('targets').findWhere({'channel': target});
                var line = 1.7;
                if (target && target.get('result')) {
                    var c = target.get('result').get('color');
                    if (c) {
                        c = ColorUtils.hexToRgba(c);
                        var dashed = null;
                        if (errCode === 'E') {
                            dashed = {
                                borderDashOffset: 3,
                                borderDash: [2, 4]
                            };
                        } else if (errCode === 'W') {
                            dashed = {
                                borderDashOffset: 10,
                                borderDash: [12, 8]
                            };
                        }
                        if (!smpType || smpType === 'U' || smpType === 'P') {
                            line = 2;
                        } else if (smpType === 'PC' || smpType === 'RC') {
                            line = 4;
                        } else if (smpType === 'NC') {
                            line = 1;
                        }
                        return {r: c.r, g: c.g, b: c.b, line: line, dashed: dashed};
                    }
                }
                return {r: null, g: null, b: null, line: line};
            }
        },

        getColorFromWellResChannel: function (well, target) {
            var retVal = {r: 54, g: 12, b: 12, line: 1, dashed: null};

            if (target && target.get('result')) {
                var targetColor = target.get('result').get('color');

                var line = 1.7;
                if (targetColor) {
                    targetColor = ColorUtils.hexToRgba(targetColor);
                    var dashed = null;

                    if (target.get('codeErr') === 'E' || target.get('codeErr') === 'W') {
                        dashed = {
                            borderDashOffset: 10,
                            borderDash: [12, 8]
                        };
                    }

                    if (!well.get('smpType') || well.get('smpType') === 'U' || well.get('smpType') === 'P') {
                        line = 2;
                    } else if (well.get('smpType') === 'PC' || well.get('smpType') === 'RC') {
                        line = 4;
                    } else if (well.get('smpType') === 'NC') {
                        line = 1;
                    }
                    retVal = {r: targetColor.r, g: targetColor.g, b: targetColor.b, line: line, dashed: dashed};
                }
            }
            return retVal;
        },

        addData: function (curveData, r, g, b, label, borderWidth, dashed, tooltip, well, prop, pointStyle, tension, smooth, withoutCC) {
            var dataCreated = [],
                color;

            if (!borderWidth) {
                borderWidth = 3;
            }
            if (!tension) {
                tension = 0;
            }

            if (r !== null && g !== null && b !== null) {
                color = 'rgba(' + r + ', ' + g + ', ' + b + ', 1)';
            } else {
                color = this.getRandomColor();
            }

            if (this.raw) {
                _.each(curveData, function (item) {
                    if (!item.data || item.exponential) {
                        return;
                    }
                    if (withoutCC) {
                        dataCreated.push({x: item.index, y: item.raw.noCC, exponential: item.exponential});
                    } else if (smooth) {
                        dataCreated.push({x: item.index, y: item.raw.smooth, exponential: item.exponential});
                    } else {
                        dataCreated.push({x: item.index, y: item.raw.raw, exponential: item.exponential});
                    }
                });
            } else if (!this.deriv1Enable && !this.deriv2Enable) {
                _.each(curveData, function (item) {
                    if (!item.data) {
                        return;
                    }
                    if (withoutCC) {
                        dataCreated.push({x: item.index, y: item.data.noCC, exponential: item.exponential});
                    } else if (smooth) {
                        dataCreated.push({x: item.index, y: item.data.smooth, exponential: item.exponential});
                    } else {
                        dataCreated.push({x: item.index, y: item.data.raw, exponential: item.exponential});
                    }
                });
            } else if (this.deriv1Enable && !this.deriv2Enable) {
                _.each(curveData, function (item) {
                    if (!item.dataD1) {
                        return;
                    }
                    if (withoutCC) {
                        dataCreated.push({x: item.index, y: item.dataD1.noCC, exponential: item.exponential});
                    } else if (smooth) {
                        dataCreated.push({x: item.index, y: item.dataD1.smooth, exponential: item.exponential});
                    } else {
                        dataCreated.push({x: item.index, y: item.dataD1.raw, exponential: item.exponential});
                    }
                });
            } else if (this.deriv2Enable) {
                _.each(curveData, function (item) {
                    if (!item.dataD2) {
                        return;
                    }
                    if (withoutCC) {
                        dataCreated.push({x: item.index, y: item.dataD2.noCC, exponential: item.exponential});
                    } else if (smooth) {
                        dataCreated.push({x: item.index, y: item.dataD2.smooth, exponential: item.exponential});
                    } else {
                        dataCreated.push({x: item.index, y: item.dataD2.raw, exponential: item.exponential});
                    }
                });
            }

            if (!this.options.hideExtraPoint) {
                for (var i = dataCreated.length; i < curveData.length; i++) {
                    dataCreated.push({x: dataCreated[dataCreated.length - 1].x + 1, y: null, exponential: true});
                }
            }

            dataCreated = _.sortBy(dataCreated, function (o) {
                return o.x;
            });

            var result = {
                label: label,
                tooltip: tooltip,
                borderColor: color,
                borderWidth: borderWidth,
                fill: false,
                pointRadius: [],
                cubicInterpolationMode: 'monotone',
                data: dataCreated,
                pointStyle: [],
                hidden: false,
                lineTension: tension,
                wellSecId: well.get('secId')
            };
            if (dashed) {
                result = _.defaults(dashed, result);
            }

            var pointRadius = [];

            if (prop && pointStyle) {
                var style = pointStyle;
                pointStyle = [];
                var p = typeof prop === 'string' || prop instanceof String ? JSON.parse(prop) : prop;
                if (p) {
                    _.each(result.data, _.bind(function (r, index) {
                        pointRadius[index] = 0;
                        pointStyle[index] = 'circle';

                        for (var i = 1; i < 3; i++) {
                            if (p['C' + i] && r.x === p['C' + i]) {
                                pointRadius[index] = 15;
                                pointStyle[index] = style;
                            }

                            if (p['PEAK-M'] && r.x === p['PEAK-M'].T) {
                                pointRadius[index] = 15;
                                pointStyle[index] = style;
                            }

                            if (p['PEAK-W'] && r.x === p['PEAK-W'].T) {
                                pointRadius[index] = 15;
                                pointStyle[index] = style;
                            }
                        }
                    }, this));
                    result.pointRadius = pointRadius;
                    result.pointStyle = pointStyle;
                }
            } else {
                result.pointRadius = 0;
            }

            return result;
        },

        addLine: function (value, name, r, g, b) {
            var dataCreated = [], dataItem, color;

            if (r !== null && g !== null && b !== null) {
                color = 'rgba(' + r + ', ' + g + ', ' + b + ', 1)';
            } else {
                color = this.getRandomColor();
            }

            for (var i = 0; i < this.data.datasets[0].data.length; i++) {
                dataItem = {
                    x: this.data.datasets[0].data[i].x,
                    y: parseFloat(value)
                };
                dataCreated.push(dataItem);
            }
            return {
                label: name,
                borderColor: color,
                fill: false,
                pointRadius: 0,
                cubicInterpolationMode: 'monotone',
                data: dataCreated
            };
        }
    });
});
