define('arrayCustomFromTypeView',[
    'module',
    'underscore',
    'autocompleteView',
    'dialogFormView',
    'template!arrayCustomFromTypeView',
    'app',
    'backbone',
    'arrayInputView'
], function (
    module,
    _,
    AutocompleteView,
    DialogFormView,
    Tpl,
    App,
    Backbone,
    ArrayInputView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            autocomplete: '.js-autocomplete',
            input: '.js-input'
        },

        modelEvents: {
            'change': 'updateModel'
        },

        className: 'col-w-all col-w-100 d-f',

        initialize: function () {
            this.model = new Backbone.Model();
            if (_.isString(this.options.model.get(this.options.fieldName))) {
                if (this.options.model.get(this.options.fieldName).length > 0) {
                    var split = this.options.model.get(this.options.fieldName).split(';');
                    this.model.set('type', split[0]);
                    this.model.set('param', _.rest(split).join(';'));
                }
            } else if (this.options.model.get(this.options.fieldName) instanceof Object) {
                this.model.set('type', this.options.model.get(this.options.fieldName).type);
                this.model.set('param', this.options.model.get(this.options.fieldName).param);
            } else if (this.options.model.get(this.options.fieldName) === null) {
                this.model.set('type', null);
                this.model.set('param', null);
            } else {
                throw new Error('Invalid type for ' + this.options.fieldName);
            }
        },
        serializeData: function () {
            return {
                'fieldName': this.options.fieldNameParam,
                'model': this.model.toJSON()
            };
        },
        onRender: function () {
            var CodeListService = require('services/admin/codelist');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(
                    CodeListService.getAutocompleteParamForCode({
                        modelProperty: 'type',
                        codeListCode: this.options.codeListCode ? this.options.codeListCode : this.options.fieldNameCodePrefix + this.options.fieldNameParam,
                        callBackOnChange: this.onChangeCodeList
                    })));
            this.getRegion('autocomplete').show(view);
            this.display();
        },
        onChangeCodeList: function (fieldName, model) {
            var lastType = this.model.get(fieldName);
            this.onChange(fieldName, model ? model.get('code') : null);
            if (lastType !== this.model.get(fieldName)) {
                this.onChange('param', null);
            }
            this.updateModel();
            this.display();
        },
        display: function () {
            if (this.model.get('type')) {
                this.getRegion('input').show(new ArrayInputView({
                    model: this.model,
                    field: new Backbone.Model({
                        field: 'param',
                        param: {arrayCode: (this.options.codeListCode ? this.options.codeListCode : this.options.fieldNameCodePrefix + this.options.fieldName.toUpperCase()) + '_' + this.model.get('type')}
                    })
                }));
            } else {
                this.getRegion('input').empty();
                var data = {};
                data[this.options.fieldName] = null;
                this.model.set(data, {silent: true});
            }
        },
        updateModel: function () {
            var data = {};
            data[this.options.fieldName] = this.model.get('type') + (this.model.get('param') ? ';' + this.model.get('param') : '');
            this.options.model.set(data, {silent: true});
            this.render();
        }
    });
});

