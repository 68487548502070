define('runOperationsView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'template!runOperationsTpl',
    'underscore',
    'app',
    'settings'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    chooseUploadLayoutTpl,
    _,
    App,
    Settings
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: chooseUploadLayoutTpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },

        fromTemplate: true,

        fieldsToValidate: [
            // {name: 'file', type: 'required'}
        ],

        // initialize: function () {
        // },

        serializeData: function () {
            var templateData = {};
            templateData.runName = this.model.get('name');
            return templateData;
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                var url = ''; // ligne pour éviter un problème jshint, à supprimer quand le jshint ignore sera enlevé
                url = Settings.url('rest/import/' + this.type + '/file', {fileName: encodeURIComponent(this.model.get('file'))});
                /* jshint ignore:start */
                fetch(url)
                    .done(_.bind(function (blob) {
                        var fd = new File([blob.arrayBuffer()], this.model.get('file'));
                        this.triggerMethod('run:import-xml', fd, false, true);
                        this.hide();
                    }, this));
                /* jshint ignore:end */
            }
        },

        hide: function () {
            this.box.modal('hide');
        }
    });
});

