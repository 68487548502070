
define('template!kitLotActionPcrWellCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<thead>\n<tr>\n    <th class="run">' +
((__t = ( _.i18n('run') )) == null ? '' : __t) +
'</th>\n    <th class="sampleId">' +
((__t = ( _.i18n('sampleId') )) == null ? '' : __t) +
'</th>\n    <th class="pos">' +
((__t = ( _.i18n('lot.referenceWell.pos') )) == null ? '' : __t) +
'</th>\n    <th class="pos">' +
((__t = ( _.i18n('lot.testWell.pos') )) == null ? '' : __t) +
'</th>\n    <th class="assay">' +
((__t = ( _.i18n('assay') )) == null ? '' : __t) +
'</th>\n    <th class="action"></th>\n</tr>\n</thead>\n<tbody></tbody>';

}
return __p
};});

