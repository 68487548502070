/**
 * Created by RKL on 21/08/2015.
 */
define('samplesController',[
    'module',
    'underscore',
    'sampleCreateEditLayoutView',
    'bootbox',
    'dynamicController',
    'jquery'
], function (
    module,
    _,
    CreateEditLayoutView,
    Bootbox,
    DynamicController,
    $
) {
    'use strict';

    module.exports = _.defaults({
        showListForQuickSearch: function (region, data, displayArchived) {
            data.entityNamePath = 'caccounts/sample';
            this._showList(region, data, displayArchived, require('services/caccounts/samples'));
        },
        showDetailsBySampleSecId: function (sampleSecId, callBackRefresh) {
            var SampleService = require('services/caccounts/samples');
            var model = SampleService.getModel({secId: sampleSecId});
            model.fetch().done(_.bind(function () {
                if (!model.get('secId')) {
                    Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                } else {
                    this.showDetails({service: SampleService, model: model, callBackRefresh: callBackRefresh});
                }
            }, this));
        },

        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            $('.js-global-loader').show();
            var SampleResultDefinitionService = require('services/caccounts/sampleResultDefinitions');
            var defers = [];
            defers.push(param.model.fetch());
            defers.push(SampleResultDefinitionService.findAll());
            $.when.apply($, defers).done(function (ignore, sampleResultDefinitions) {
                var createEditView = new CreateEditLayoutView({
                    model: param.model,
                    sampleResultDefinitions: sampleResultDefinitions
                });
                var service = require(param.model.service);
                createEditView.show({
                    title: _.i18n(service.getName() + '.new'),
                    className: 'baseTableEditAddPopup sampleBaseTable',
                    service: service
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            }).always(function () {
                $('.js-global-loader').hide();
            });
        }
    }, DynamicController);
});

