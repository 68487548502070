define('menuGroupItem',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!menuGroupItem'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    /**
     * @class MenuGroupItemView
     * @extends Marionette.View
     * @param {Object} options
     * @param {Object} options.model
     * @param {Object} options.model.name
     * @param {CallableFunction} options.model.click
     *
     * model {
     *     mdi: String
     *     name: String
     *     click: CallableFunction
     * }
     */
    module.exports = Marionette.View.extend({
        template: Tpl,
        tagName: 'li',
        className: function () {
            var className = 'btn clickable';
            if (this.model.get('className')) {
                className += ' ' + this.model.get('className');
            }
            return className;
        },
        ui: {
            select: 'select'
        },
        events: {
            'click': 'onClick'
        },
        modelEvents: {
            'enable': 'defineEnable'
        },
        serializeData: function () {
            return {
                name: _.i18n(this.model.get('nameCode')),
                displaySelect: !!this.model.get('select')
            };
        },
        attributes: function () {
            var attributes = this.model.get('attributes') || {};
            attributes.title = _.i18n(this.model.get('nameCode') + '.popover');
            attributes['data-code'] = this.model.get('nameCode');
            return attributes;
        },
        onClick: function (e) {
            // if event link to select, we stop the progapation
            if (e.target.tagName === 'SELECT') {
                e.stopImmediatePropagation();
                return false;
            }
            if (this.$el.hasClass('clickable')) {
                var value;
                if (this.ui.select.length) {
                    value = this.ui.select.val();
                }
                this.model.get('click')(value);
            }
        },
        defineEnable: function (enable) {
            if (enable) {
                this.$el.removeClass('disabled');
                this.$el.addClass('clickable');
            } else {
                this.$el.addClass('disabled');
                this.$el.removeClass('clickable');
            }
            this.$el.attr('disabled', !enable);
        },
        onRender: function () {
            this.defineEnable(!this.model.get('disabled'));
            // If the item has a select, we need to add item to the select, with jquery
            if (this.model.get('select')) {
                var select = this.ui.select;
                _.each(this.model.get('select'), function (item) {
                    select.append('<option value="' + item.value + '">' + item.name + '</option>');
                });
            }
        }
    });
});

