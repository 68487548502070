define('polyglotInstance',[
    'polyglot',
    'module'
], function (
    Polyglot,
    module) {
    'use strict';

    module.exports = new Polyglot();
});
